import { useState, useEffect } from 'react';
import { COUNTRY, REGION, CITY } from '@features/doctor-search/utilities/constants';

const simpleSearch = (target) => (item) => item === target;

export const useGeographicalState = (
  data,
  { country: initialCountry = '', region: initialRegion = '', city: initialCity = '' },
) => {
  const [countryIdx, setCountryIdx] = useState({ value: -1, error: false });
  const [regionIdx, setRegionIdx] = useState(-1);
  const [cityIdx, setCityIdx] = useState(-1);

  function filteredLocationList(
    key,
    countryId = countryIdx.value,
    regionId = regionIdx,
    cityId = cityIdx,
  ) {
    switch (key) {
      case COUNTRY:
        return data.map((location) => location.country);
      case REGION:
        return data[countryId]?.regions.map((location) => location.region);
      case CITY:
        return data[countryId]?.regions[regionId]?.cities.map((location) => location.city);
      default:
        break;
    }
  }

  const geographicalState = {
    [COUNTRY]:
      countryIdx.value === -1
        ? undefined
        : { value: filteredLocationList(COUNTRY)[countryIdx.value], error: countryIdx.error },
    [REGION]: regionIdx === -1 ? undefined : filteredLocationList(REGION)[regionIdx],
    [CITY]: cityIdx === -1 ? undefined : filteredLocationList(CITY)[cityIdx],
  };

  function handleCountryChange(newCountry) {
    const newCountryIdx = data.findIndex(({ country }) => country === newCountry);
    setCountryIdx({ value: newCountryIdx, error: false });
    setRegionIdx(-1);
    setCityIdx(-1);
  }

  function handleRegionChange(newRegion) {
    const newRegionIdx = filteredLocationList(REGION).findIndex(simpleSearch(newRegion));
    setRegionIdx(newRegionIdx);
    setCityIdx(-1);
  }

  function handleCityChange(newCity) {
    const newCityIdx = filteredLocationList(CITY).findIndex(simpleSearch(newCity));
    setCityIdx(newCityIdx);
  }

  const disableConfig = {
    [REGION]: filteredLocationList(REGION) === undefined || !filteredLocationList(REGION).length,
    [CITY]: filteredLocationList(CITY) === undefined || !filteredLocationList(CITY).length,
  };

  useEffect(() => {
    if (initialCountry && !!data.length) {
      const newCountryIdx = filteredLocationList(COUNTRY).findIndex(simpleSearch(initialCountry));
      setCountryIdx({ value: newCountryIdx, error: false });
      if (initialRegion) {
        const newRegionIdx = filteredLocationList(REGION, newCountryIdx).findIndex(
          simpleSearch(initialRegion),
        );
        setRegionIdx(newRegionIdx);
        if (initialCity) {
          const newCityIdx = filteredLocationList(CITY, newCountryIdx, newRegionIdx).findIndex(
            simpleSearch(initialCity),
          );
          setCityIdx(newCityIdx);
        }
      }
    }
  }, [data, initialCountry, initialRegion, initialCity]);

  return {
    country: geographicalState[COUNTRY],
    region: geographicalState[REGION],
    city: geographicalState[CITY],
    setCountry: (country) => handleCountryChange(country),
    setRegion: (region) => handleRegionChange(region),
    setCity: (city) => handleCityChange(city),
    disableConfig,
    parsedData: (type) => filteredLocationList(type),
    setCountryError: (bool) => setCountryIdx({ ...countryIdx, error: bool }),
  };
};
