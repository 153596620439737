import React from 'react';
import {
  Avatar,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  withStyles,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import BigCalendar from '../../common/big_calendar/big_calendar';
import FullCalendarHeader from '../../common/full_calendar/full_calendar_header';
import FullCalendarGrid from '../../common/full_calendar/full_calendar_grid';
import { generateTimeRangeByDuration } from '@services/common_services/common-services';
import './create_schedule.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import moment from 'moment';
import {
  tzValue,
  dateConvert,
  getTimeZoneString,
  convertAppointmentTime,
  convertAppointmentDate,
  convertFullDisplayDate,
} from '../../common/config';
import { getValueOrDefault, getValueByCondition } from '../../../utils/utils'
import { FullCalendarComponent } from '@components/full-calendar/FullCalendar';
import { CreateAppointmentDialog } from '@components/dialog/Dialog';
import { ConfirmationAlert } from '@components/alert/Alert';
import {
  getBookingDetails,
  getProviderSchedule,
  postCreateSchedule,
  postCreateScheduleSubmit,
  getCaseDetails,
} from '@api';
import 'moment-timezone';
import { ReactHelmet } from '@components/react-helmet/Helmet';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const useStyles = (theme) => ({
  calenderContainer: {
    flexGrow: 1,
    padding: 5,
    marginBottom: 30,
  },
  avatarSelected: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: '#F37D2A',
  },
  avatarFull: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: 'lightgrey',
  },
  list: {
    display: 'flex',
    flexGrow: 1,
    paddingBottom: 0,
  },
  avatarList: {
    minWidth: 25,
  },
});

class CreateSchedule extends React.Component {
  calendarComponentRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: new Date(),
      doctorCalendar: [],
      bookedSchedule: [],
      timeSeries: [],
      patientSelectedSlot: [],
      openDialog: false,
      selectedAppointmentData: {
        SelectedDate: new Date().toLocaleDateString(),
        StartTime: { id: 0, name: `${new Date().getHours()}:${new Date().getMinutes()}` },
        EndTime: { id: 0, name: `${new Date().getHours()}:${new Date().getMinutes()}` },
      },
      caseData: null,
      checkDocumentsLength: 0,
      appointmentStatus: '',
      errorSubmit: false,
      timezone: null,
      searchOptions: [],
      calendarToggle: false,
      selectDropdownOption: 'Week',
      defaultView: 'timeGridWeek',
      isPreferredSlot: false,
      isShowConfirmation: false,
      isLoading: false,
      dateRange: { start: new Date(), end: new Date() },
    };
  }

  async componentDidMount() {
    await this.initPaitentCaseInfo();
    await this.initDoctorCalendar();
    await this.initSpecialistSchedule();
    await this.openingHoursCheck();

    if (this.calendarComponentRef.current) {
      const calendarRef = this.calendarComponentRef.current.getApi();
      this.setState({ dateRange: { start: calendarRef.view.activeStart, end: calendarRef.view.activeEnd - 1} })
    }
  }

  /**
   * Init Patient Case info
   * */
  initPaitentCaseInfo = async () => {
    const patientPreferredSlots = await getBookingDetails(
      this.props.match.params.unitId,
      this.props.match.params.requesterId,
    );

    if (patientPreferredSlots.code && patientPreferredSlots.data) {
      if (
        Array.isArray(patientPreferredSlots.data.bookedSchedule) &&
        patientPreferredSlots.data.bookedSchedule.length > 0
      ) {
        let bookedSchedules = [...this.state.bookedSchedule];
        patientPreferredSlots.data.bookedSchedule.forEach((patientSchedule, idx) => {
          if (!this.state.timezone) {
            this.setState({ timezone: getTimeZoneString(patientSchedule.start) });
          }
          //preferred choice with no fully booked slots
          const bookingObject = {
            id: idx,
            title: patientPreferredSlots.data.caseData.person.name,
            start: dateConvert(patientSchedule.start, this.state.timezone),
            end: dateConvert(patientSchedule.end, this.state.timezone),
            display: 'background',
            type: 'booked',
          };
          bookedSchedules.push(bookingObject);
        });
        this.setState({
          bookedSchedule: bookedSchedules,
          patientSelectedSlot: bookedSchedules,
          caseData: patientPreferredSlots.data.caseData,
          checkDocumentsLength: patientPreferredSlots.data.caseData.person.documentLinks.length,
        });
      }
    }
  };

  /**
   * Init Specialist Schedule (fully booked slots)
   * */
  initSpecialistSchedule = async () => {
    const doctorSchedules = await getProviderSchedule(this.props.match.params.unitId);
    if (
      doctorSchedules.code &&
      Array.isArray(doctorSchedules.data) &&
      doctorSchedules.data.length > 0
    ) {
      let bookedSchedule = [...this.state.bookedSchedule];
      let searchOptions = [...this.state.searchOptions];
      doctorSchedules.data.forEach((booked, idx) => {
        booked.hasOwnProperty('schedules') &&
          booked.schedules.length > 0 &&
          booked.schedules.forEach((schedule, si) => {
            if (
              schedule.hasOwnProperty('bookedSlots') &&
              Array.isArray(schedule.bookedSlots) &&
              schedule.bookedSlots.length > 0
            ) {
              schedule.bookedSlots.forEach((bookedSlot, index) => {
                const bookedObject = {
                  id: bookedSchedule.length + index,
                  title: bookedSlot.bookedCaseData.displayName
                    ? bookedSlot.bookedCaseData.displayName
                    : bookedSlot.bookedCaseData.referenceId,
                  referenceId: bookedSlot.bookedCaseData.referenceId,
                  start: dateConvert(bookedSlot.slotStartAt, this.state.timezone),
                  end: dateConvert(bookedSlot.slotEndAt, this.state.timezone),
                  type: 'slot',
                };
                bookedSchedule.push(bookedObject);
                searchOptions.push(bookedObject);
              });
            }
          });
      });
      this.setState({ bookedSchedule: bookedSchedule, searchOptions: searchOptions });
    }
  };

  /**
   * init Doctor Calendar
   * */
  initDoctorCalendar = async () => {
    const respondData = await getProviderSchedule(this.props.match.params.unitId);
    if (respondData.code && respondData.data) {
      if (Array.isArray(respondData.data) && respondData.data.length > 0) {
        const calendarSummary = respondData.data.map((data) => {
          const isPatientBooked = this.state.patientSelectedSlot.find(
            (x) =>
              new Date(
                new Date(x.start).toLocaleString('en-US', {
                  timeZone: tzValue(this.state.timezone),
                }),
              ).setHours(0, 0, 0, 0) ===
              dateConvert(data.start, this.state.timezone).setHours(0, 0, 0, 0),
          );
          function getStatus() {
            if (isPatientBooked) {
              return 'selected'
            }
            return (data.dayStatus === 'busy') ? 'force-booking' : data.dayStatus
          }
          return {
            status: getStatus(),
            calendarDate: dateConvert(data.start, this.state.timezone),
          };
        });
        this.setState({
          doctorCalendar: calendarSummary,
          selectedDate: calendarSummary.find((s) => s.status != 'closed').calendarDate,
        });
      }
    }
  };

  /**
   * Capture User actions and update the state data
   ** @value user selected/enter value
   ** @property action/element name
   * */
  handleDialogOnClose = (value, propertyName) => {
    this.setState({ [propertyName]: value });
  };

  /**
   * Capture New Appoint Data
   * @event capture user event
   * * **/
  onAddAppointment = (event) => {
    let newAppointmentData = { ...this.state.selectedAppointmentData };
    if (event && event.target.name === 'StartTime') {
      const startTimeindex = this.state.timeSeries.findIndex((x) => x.id === event.target.value);
      if (startTimeindex >= 0 && startTimeindex < this.state.timeSeries.length - 1) {
        newAppointmentData.EndTime = this.state.timeSeries[startTimeindex + 1].id;
      }
    }
    newAppointmentData[event.target.name] = event.target.value;
    this.setState({ selectedAppointmentData: newAppointmentData });
  };

  onAppointmentLabelSelect = (e) => {
    this.setState({ appointmentStatus: e.target.value });
  };

  onCalendarSelect = (value) => {
    const calendarRef = this.calendarComponentRef.current.getApi();
    calendarRef.gotoDate(new Date(value));
    this.setState({ selectedDate: new Date(value), dateRange: { start: calendarRef.view.activeStart, end: calendarRef.view.activeEnd - 1} });
  };

  /**
   * Capture Selected Date & Time
   * @start start Date & Time
   * @end end Date & Time
   * * **/
  onDateSelect = async (event) => {
    if (event.start) {
      const respondData = await postCreateSchedule(
        this.props.match.params.unitId,
        JSON.stringify(moment(event.start).tz(tzValue(this.state.timezone), true).toISOString()),
      );
      if (
        respondData.code &&
        respondData.data &&
        respondData.data.hasOwnProperty('selectedSchedule') &&
        respondData.data.hasOwnProperty('defaultDurationInMinute')
      ) {
        const timeSeries = generateTimeRangeByDuration(
          dateConvert(respondData.data.selectedSchedule.start, this.state.timezone),
          dateConvert(respondData.data.selectedSchedule.end, this.state.timezone),
          respondData.data.defaultDurationInMinute,
        );
        Array.isArray(timeSeries) && timeSeries.length > 0 && this.setState({ timeSeries: timeSeries });
      }
    }

    let newAppointmentData = { ...this.state.selectedAppointmentData };
    const startDate = this.state.timeSeries.find((x) => x.id === new Date(event.start).getTime());
    if (startDate) {
      newAppointmentData.StartTime = startDate.id;
      const endIdx = this.state.timeSeries.findIndex((x) => x.id === startDate.id);
      newAppointmentData.EndTime = this.state.timeSeries[endIdx >= 0 && (endIdx + 1)].id;
    }
    newAppointmentData.SelectedDate = new Date(event.start);
    
    this.setState({
      openDialog: true,
      selectedAppointmentData: newAppointmentData,
      isLoading: false,
    });
  };

  onSubmit = async () => {
    this.setState({ isLoading: true });

    const slotInfo = await postCreateSchedule(
      this.props.match.params.unitId,
      JSON.stringify(new Date(this.state.selectedAppointmentData.StartTime).toISOString()),
    );

    const assignSlot = {
      selectedSchedule: slotInfo.data.selectedSchedule,

      assignedSlot: {
        slotStartAt: moment(new Date(this.state.selectedAppointmentData.StartTime))
          .tz(tzValue(this.state.timezone), true)
          .toISOString(),
        slotEndAt: moment(new Date(this.state.selectedAppointmentData.EndTime))
          .tz(tzValue(this.state.timezone), true)
          .toISOString(),
      },
    };

    postCreateScheduleSubmit(
      this.props.match.params.unitId,
      this.props.match.params.requesterId,
      assignSlot,
    )
      .then((resp) =>
        //successful
        getCaseDetails(this.props.match.params.serviceType, this.props.match.params.requesterId)
          .then((res) => {
            if (res.code && res.data) {
              if (Array.isArray(res.data.data.items)) {
                res.data.data.items.map((item) => {
                  this.setState({ isShowConfirmation: true, isPreferredSlot: item.appointmentStatus === 'Scheduled' })
                });
              }
            }
          })
          .catch((err) => console.log('something went wrong', err)),
      )
      .catch((err) =>
        //fail
        {
          console.log('something went wrong', err);
          this.setState({ errorSubmit: true });
        },
      );

    this.setState({ openDialog: false, isLoading: false });
  };

  //to disable non-selectable slots based on opening hours
  openingHoursCheck = async () => {
    const respondData = await getProviderSchedule(this.props.match.params.unitId);
    if (respondData.code && respondData.data) {
      if (Array.isArray(respondData.data) && respondData.data.length > 0) {
        let openingHours = [...this.state.bookedSchedule];
        respondData.data.map((data) => {
          if (data.dayStatus !== 'closed' && data.hasOwnProperty('schedules')) {
            data.schedules.map((schedule) => {
              const opening = {
                groupId: 1,
                start: dateConvert(schedule.start, this.state.timezone),
                end: dateConvert(schedule.end, this.state.timezone),
                display: 'inverse-background',
                type: 'open',
              };
              openingHours.push(opening);
            });
          }
        });
        this.setState({ bookedSchedule: openingHours });
      }
    }
  };

  switchChange = () => {
    this.setState({ calendarToggle: !this.state.calendarToggle });
  };

  onCalendarArrowChange = (value, func) => {
    const calendarRef = this.calendarComponentRef.current.getApi();
    if (func === 'prev') {
      calendarRef.prev();
      if (this.state.defaultView === 'timeGridDay') {
        this.setState({ selectedDate: calendarRef.getDate() })
      }
    } else if (func === 'next') {
      calendarRef.next();
      if (this.state.defaultView === 'timeGridDay') {
        this.setState({ selectedDate: calendarRef.getDate() })
      }
    } else if (func === 'goback') {
      let current = new Date(value);
      calendarRef.gotoDate(current.setDate(current.getDate() - 1));
      this.setState({ selectedDate: current });
    } else if (func === 'goto') {
      let current = new Date(value);
      calendarRef.gotoDate(current.setDate(current.getDate() + 1));
      this.setState({ selectedDate: current });
    } else if (func === 'today') {
      calendarRef.today();
      this.setState({ selectedDate: new Date() });
    }
    this.setState({ dateRange: { start: calendarRef.view.activeStart, end: calendarRef.view.activeEnd - 1} });
  };

  handleOptionClick = (option) => {
    this.setState({
      selectDropdownOption: option,
      defaultView: option === 'Week' ? 'timeGridWeek' : 'timeGridDay',
    });
    setTimeout(() => {
      let calendarApi = this.calendarComponentRef.current.getApi();
      calendarApi.changeView(this.state.defaultView);
      calendarApi.gotoDate(new Date(this.state.selectedDate));
      this.setState({ dateRange: { start: calendarApi.view.activeStart, end: calendarApi.view.activeEnd - 1} });
    });
  };

  render() {
    return (
      <React.Fragment>
        <ReactHelmet title="Appointment Management" />
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          spacing={3}
          className={this.props.classes.calenderContainer}
          style={{ display: getValueByCondition(this.state.isShowConfirmation, 'none', null) }}
        >
          <Grid item xs={12} style={{ height: 180 }}>
            <Link component="button" onClick={this.props.history.goBack}>
              <Typography variant="h6" style={{ marginTop: 40 }}>
                <ArrowBackIosIcon style={{ fontSize: '1rem' }} /> Back
              </Typography>
            </Link>
          </Grid>
          {/*Calender Component**/}
          <Grid item xs={12} md={3}>
            <Grid
              container
              direction="column"
              justifyContent="space-evenly"
              spacing={4}
              wrap="wrap"
              style={{ display: getValueByCondition(this.state.calendarToggle, 'none', null) }}
            >
              <Grid item xs={12} style={{ marginTop: 25 }}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <Typography id="latobold">Patient Details</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography id="font15">Name:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography id="font15">
                      {getValueOrDefault(this.state.caseData?.person?.name)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography id="font15">Gender / Age:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography id="font15">
                      {getValueOrDefault(this.state.caseData?.person?.gender)} /{' '}
                      {getValueOrDefault(this.state.caseData?.person?.age)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography id="font15">Date of Birth:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography id="font15">
                      {convertFullDisplayDate(this.state.caseData?.person?.dob)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography id="font15">Medical History:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography id="font15">
                    {getValueOrDefault(this.state.caseData?.person?.medicalCondition, 'N/A')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography id="font15">
                      Reason for Consultation / Any Other Information:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography id="font15">
                      {getValueOrDefault(this.state.caseData?.person?.otherReason, 'N/A')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography id="font15">Email Address:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography id="font15">
                      {getValueOrDefault(this.state.caseData?.person?.email)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography id="font15">Mobile:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography id="font15">
                    {getValueOrDefault(this.state.caseData?.person?.sms)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography id="font15">Documents:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <List style={{ paddingTop: 0 }}>
                      {this.state.checkDocumentsLength !== 0 ? (
                        this.state.caseData.person.documentLinks.map((doc, i) => {
                          return (
                            <ListItem style={{ paddingTop: 0, paddingLeft: 0 }} key={i}>
                              <Link href={doc} key={i + 1}>
                                Document {i + 1}
                              </Link>
                            </ListItem>
                          );
                        })
                      ) : (
                        <Typography id="font15">No Documents</Typography>
                      )}
                    </List>
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: 10, marginTop: 25 }}>
                    <Typography id="latobold">Preferred Time Slots</Typography>
                  </Grid>

                  {this.state.patientSelectedSlot.map((patient, id) => (
                    <React.Fragment key={id}>
                      <Grid item xs={6}>
                        <Typography id="font15">
                          <Link
                            style={{ cursor: 'pointer' }}
                            onClick={() => this.onCalendarSelect(patient.start)}
                          >
                            {convertAppointmentDate(patient.start)}
                          </Link>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography id="font15">
                          {convertAppointmentTime(patient.start, patient.end)}
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              direction="column"
              justifyContent="space-evenly"
              spacing={4}
              wrap="wrap"
              style={{ display: this.state.calendarToggle ? null : 'none' }}
            >
              <Grid item xs={12} style={{ justifyContent: 'center', display: 'grid' }}>
                <BigCalendar
                  class={'calSmall'}
                  onChange={this.onCalendarSelect}
                  selectedDate={this.state.selectedDate}
                  calendarSummary={this.state.doctorCalendar}
                />
              </Grid>
              <Grid item xs={12} style={{ justifyContent: 'center', display: 'grid' }}>
                <div>
                  <List className={this.props.classes.list} dense={true}>
                    <ListItem style={{ paddingLeft: 30, paddingRight: 0 }}>
                      <ListItemAvatar classes={{ root: this.props.classes.avatarList }}>
                        <Avatar variant="square" className={this.props.classes.avatarSelected}>
                          {' '}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText style={{ width: '140px' }} primary="Patient's Choice(s)" />
                    </ListItem>

                    <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <ListItemAvatar classes={{ root: this.props.classes.avatarList }}>
                        <Avatar variant="square" className={this.props.classes.avatarFull}>
                          {' '}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText style={{ width: '140px' }} primary="Force Booking" />
                    </ListItem>
                  </List>
                </div>
              </Grid>
            </Grid>

            <Grid
              container
              direction="column"
              justifyContent="space-evenly"
              spacing={4}
              wrap="wrap"
              style={{ marginTop: 25 }}
            >
              <Grid item xs={12} style={{ justifyContent: 'left', display: 'grid' }}>
                <Typography component="div" id="font15">
                  Calendar View:{' '}
                  <Link component="button" onClick={() => this.switchChange()}>
                    <Typography component="div" id="font15">
                      Turn {this.state.calendarToggle ? 'Off' : 'On'}
                    </Typography>
                  </Link>
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              direction="column"
              justifyContent="space-evenly"
              spacing={4}
              wrap="wrap"
              style={{ marginTop: 25 }}
            >
              <Grid item xs={12}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <Typography id="latobold">Showing Calendar for</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography id="font15">
                      {this.state.caseData !== null ? this.state.caseData.bookingResourceTitle : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography id="font15" style={{ width: 150 }}>
                      {this.state.caseData !== null ? this.state.caseData.bookingLocation : ''}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End Calender Component**/}
          <Grid item xs={12} md={9}>
            {this.state.errorSubmit ? (
              <Alert
                style={{ marginBottom: 10 }}
                className={this.props.classes.mobileViewAlertControl}
                severity="error"
              >
                Failed to submit.
              </Alert>
            ) : null}
            <FullCalendarGrid>
              <Grid container item xs={12}>
                <Grid item xs={12} style={{ marginBottom: 25 }}>
                  <Typography variant="h6" color="inherit" align={'right'}>
                    {this.state.defaultView === 'timeGridWeek'
                      ? `${new Date(this.state.dateRange.start).toLocaleDateString('en-GB', { month: 'short', day: 'numeric' })} - ${convertAppointmentDate(this.state.dateRange.end)}`
                          .toUpperCase()
                      : convertAppointmentDate(this.state.selectedDate)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FullCalendarHeader
                    selectedDate={this.state.selectedDate}
                    onCalendarArrowChange={this.onCalendarArrowChange}
                    selectDropdownOption={this.state.selectDropdownOption}
                    handleOptionClick={this.handleOptionClick}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} style={{ paddingTop: 25, maxHeight: 700 }}>
                  <FullCalendarComponent
                    defaultView={this.state.defaultView}
                    calendarComponentRef={this.calendarComponentRef}
                    bookedSchedule={this.state.bookedSchedule}
                    selectedDate={this.state.selectedDate}
                    select={this.onDateSelect}
                  />
                </Grid>
              </Grid>
            </FullCalendarGrid>
          </Grid>
          <CreateAppointmentDialog
            openDialog={this.state.openDialog}
            handleOnClose={this.handleDialogOnClose}
            selectedAppointmentData={this.state.selectedAppointmentData}
            timeSeries={this.state.timeSeries}
            onAddAppointment={this.onAddAppointment}
            onSubmit={this.onSubmit}
            loading={this.state.isLoading}
          />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          className={this.props.classes.calenderContainer}
          style={{ display: getValueByCondition(this.state.isShowConfirmation, null, 'none') }}
        >
          <ConfirmationAlert
            patientName={getValueOrDefault(this.state.caseData?.person?.name)}
            apptStartDate={this.state.selectedAppointmentData.StartTime}
            apptEndDate={this.state.selectedAppointmentData.EndTime}
            apptLocation={getValueOrDefault(this.state.caseData?.bookingLocation)}
            isPreferredSlot={this.state.isPreferredSlot}
            onClick={() => window.location = '/'}
          />
        </Grid>
      </React.Fragment>
    );
  }
}
export default withStyles(useStyles)(CreateSchedule);
