import React from 'react';
import { Grid, InputAdornment, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import { LoadingButton } from '@components/button';

export const SignInFields = ({
  handleEmptyFieldError,
  handleHelperText,
  values,
  handleTextOnChange,
  classNames,
  fieldType,
  checkValue,
  handleCheckOnChange,
  logo,
  classNameLogo,
}) => {
  return (
    <React.Fragment>
      <div>
        <Grid item style={{ margin: 'auto', marginBottom: '10px' }} className={[classNameLogo]}>
          <img src={logo} alt="logo" style={{ width: '100%' }} />
        </Grid>
        <TextField
          error={handleEmptyFieldError[0]}
          helperText={handleHelperText(handleEmptyFieldError[0])}
          value={values[0]}
          onChange={handleTextOnChange}
          name="email"
          className={classNames[0]}
          variant="outlined"
          placeholder="User ID (Email)"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircleIcon className={classNames[1]} />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <div style={{ marginTop: 10 }}>
        <TextField
          error={handleEmptyFieldError[1]}
          helperText={handleHelperText(handleEmptyFieldError[1])}
          value={values[1]}
          onChange={handleTextOnChange}
          name="password"
          type={fieldType}
          className={classNames[0]}
          variant="outlined"
          placeholder="Password"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon className={classNames[1]} />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <div style={{ marginTop: 10, marginBottom: 10 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checkValue}
              onChange={handleCheckOnChange}
              color="primary"
              size="small"
            />
          }
          label={<span style={{ fontSize: 14 }}>Show Password</span>}
        />
      </div>
    </React.Fragment>
  );
};

export const LoginActions = ({ classNames, text, loading, ...props }) => {
  return (
    <React.Fragment>
      <LoadingButton
        loading={loading}
        text={text}
        classes={classNames[0]}
        disableElevation
        disableRipple
        {...props}
      />
      <br />
      <div style={{ marginTop: '8px' }} className={classNames[1]}>
        {props.children}
      </div>
    </React.Fragment>
  );
};
