import { useState } from 'react';
import compose from 'recompose/compose';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';

import { Loading } from 'components/loading';
import { SmarterTreeView } from '../treeview';
import SmarterEmpty from '../empty';
import { SmarterTextField } from '../input';
import withDataFetching from './withDataFetching';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: theme.spacing(37.5),
  },
  wrapper: (props) => ({
    width: '100%',
    height: theme.spacing(37.5),
    display: props.loading && 'flex',
    flexDirection: 'column',
    justifyContent: props.loading && 'center',
    alignItems: props.loading && 'center',
  }),
  content: { width: '100%', height: theme.spacing(37.5) },
}));

export const ProgressiveSearchComponent = ({
  data,
  loading,
  select,
  onSelect,
  search,
  onSearch,
  showCode,
  ...props
}) => {
  const classes = useStyles({ loading });
  const [valueTextField, setValueTextField] = useState(search);
  const [isSelectOption, setIsSelectOption] = useState(false);

  const handleSearch = () => onSearch(valueTextField);

  const handleChange = (value = '') => {
    setValueTextField(value);
    setIsSelectOption(false);
  };

  const handleOnClick = (code, description) => {
    onSelect({ code, description });
    setIsSelectOption(true);
  };

  return (
    <div className={classes.wrapper}>
      <Loading isLoading={loading}>
        {props.width === 'mobile' && (
          <SmarterTextField
            placeholder="Enter Diagnosis"
            value={valueTextField}
            size="small"
            onChange={handleChange}
            onEnter={handleSearch}
            disabled={loading}
            allowClear={isSelectOption}
            InputProps={{
              endAdornment: (
                <SearchIcon position="end" style={{ cursor: 'pointer' }} onClick={handleSearch} />
              ),
            }}
          />
        )}
        {!loading && data.length === 0 && (
          <div className={classes.root}>
            <SmarterEmpty />
          </div>
        )}
        {!loading && data.length !== 0 && (
          <div className={classes.content}>
            <p style={{ opacity: '0.7' }}>
              <i>* Please choose diagnosis</i>
            </p>
            <SmarterTreeView
              data={data}
              highlight={valueTextField}
              onClick={handleOnClick}
              showCode={showCode}
            />
          </div>
        )}
      </Loading>
    </div>
  );
};

export const ProgressiveSearch = compose(withDataFetching)(ProgressiveSearchComponent);
