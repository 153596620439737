//get time zone e.g. [Asia/Singapore]
export const getTimeZone = (date) => date.toString().match(/\[(.*?)\]/)[0]; //NOSONAR - to find alternative solution instead of using regex

//get time zone string e.g. Asia/Singapore
export const getTimeZoneString = (date) => date.toString().match(/\[(.*?)\]/)[1]; //NOSONAR - to find alternative solution instead of using regex

export const tzValue = (tz) => (tz ? tz : 'Asia/Singapore');

//timezone
export const dateConvert = (date, tz) =>
  new Date(
    new Date(date.toString().replace(getTimeZone(date), '')).toLocaleString('en-US', {
      timeZone: tzValue(tz),
    }),
  );

//e.g format: 0830 to 1230
export const convertAppointmentTime = (start, end) =>
  `${new Date(start).getHours() < 10 ? '0' : ''}${new Date(start).getHours()}${
    new Date(start).getMinutes() > 0 ? '' : '0'
  }${new Date(start).getMinutes()} to ${new Date(end).getHours() < 10 ? '0' : ''}${new Date(
    end,
  ).getHours()}${new Date(end).getMinutes() > 0 ? '' : '0'}${new Date(end).getMinutes()}`;

//e.g format: 1 MAR 2021
export const convertAppointmentDate = (date) =>
  `${new Date(date)
    .toLocaleDateString('en-GB', { year: 'numeric', month: 'short', day: '2-digit' })
    .toUpperCase()}`;

export const convertFullDisplayDate = (date) =>
  date
    ? `${new Date(date).toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })}`
    : '';

export const getClientNameForLoginAndRegistration = (client) => {
  if (client.toLowerCase() === allianz.toLowerCase()) {
    return allianz;
  }
  if (client.toLowerCase() === lippolife.toLowerCase()) {
    return lippolife;
  }
  return client.toLowerCase() === admedika.toLowerCase() ? admedika : smarterhealth;
};

export const maxPatientChoice = 2;

export const minPatientChoice = 2;

export const maxPatientChoiceString = 'TWO';

export const authValue = process.env.REACT_APP_ARROW_AUTH ?? 'abc123';

export const paginationLimit = 5;

export const filterDateRangeInMonths = 3;

export const bentoApiPrefix = 'api/v1';

export const arrowApiPrefix = 'arrow/api';

export const apptAttended = 5;

export const apptNoShow = 4;

export const apptCancel = 2;

export const fullCalendarMinTime = '07:00';

export const fullCalendarMaxTime = '22:00';

export const allianz = 'Allianz';

export const smarterhealth = 'SmarterHealth';

export const admedika = 'Admedika';

export const lippolife = 'LippoLife';

//to store redirect url in session
export const redirectKey = 'redirectURL';

export const clientKey = 'client';

export const worklistRowsPerPage = [25, 50, 100];

export const defaultRowsPerPage = 25;

export const maxPackage = 5;

//cookie keys
export const roleTypeKey = 'roleId';

export const userIdKey = 'userId';

export const clientCountryKey = 'clientCountry';

export const CLIENT_ID = 'clientId';

//client countries
export const singapore = 'singapore';

export const malaysia = 'malaysia';

export const indonesia = 'indonesia';

export const hongKong = 'hong kong';

//roleType values
export const liaisonManager = 'Liaison Manager';

export const specialist = 'Specialist';

export const patient = 'Patient';

export const PAYOR_PAF_MANAGER = 'Payor Paf Manager';

//e.g. 01 Nov 2021 for MUI DatePicker
export const muiDateFormat = 'dd MMM yyyy';

//e.g. 01 Nov 2021 for moment.format
export const momentDateFormat = 'DD MMM YYYY';

// Google Analytics Tracking
export const GTAG_SRC = 'https://www.googletagmanager.com/gtag/js?id=UA-119243848-1';

export const GTAG_DATA_LAYER = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'UA-119243848-1');`;

export const FWD = 'FWD';

// api params
export const FORM_TYPE = 'PAF';
export const SECTION_C = 'section-c';
export const SECTION_D = 'section-d';
export const SECTION_D_FEE_GENERATOR = 'section-d-estimateFeeGenerator';

export const SENTRY_DSN =
  'https://ab96d62d60a947daac6adc7a489e79aa@o973948.ingest.sentry.io/5925240';
