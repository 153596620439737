import _ from 'lodash';
import { FormItem } from './FormItem';
import {
  SmarterTextField,
  DateSelection,
  SmarterAutoComplete,
  SmarterTextArea,
  SmarterCheckbox,
  SmarterRadio,
} from '../input';
import { UploadFileContainer } from '../input/upload-file';
import { FormDropdown } from './FormDropdown';
import React, { Fragment } from 'react';
import { SmarterCalendar } from '../input/Calendar';
import PhoneInput from 'react-phone-input-2';
import { makeStyles } from '@material-ui/core';
import { Group } from '../group/Group';
import { SmarterNumeric } from 'components/input/numeric/Numeric';
import { SmarterDateTimePicker } from '../input/TimePicker';
import PhoneNumber from '../input/PhoneNumber';

const useStyles = makeStyles((theme) => ({
  inputClass: {
    width: '100% !important',
    paddingBottom: '10px !important',
    paddingTop: '10px !important',
    '&:disabled': {
      backgroundColor: '#ECECEC',
      pointerEvents: 'none',
    },
  },
}));

export const DynamicForm = ({ formConfig, formModel, setFormModel, watch }) => {
  const classes = useStyles();
  const computeMUIProps = (props) => {
    const temp = { ...props };
    delete temp.editable;
    delete temp.dataSource;
    delete temp.dependentKeys;
    return temp;
  };

  const isItemVisible = (item) => {
    const visibleProp = item.props?.visible;
    return (
      typeof visibleProp === 'undefined' ||
      (typeof visibleProp === 'boolean' && visibleProp) ||
      (typeof visibleProp === 'object' && formModel[visibleProp.inputName] === visibleProp.value)
    );
  };

  const isItemDisabled = (item) => {
    const disableProp = item.props?.disabled;
    if (!_.isObject(disableProp)) return disableProp;
    return disableProp.value === formModel[disableProp.inputName];
  };

  const renderForm = (item, index) => {
    return (
      isItemVisible(item) && (
        <FormItem
          isRequired={item.isRequired}
          value={item.value}
          label={item.uiLabel}
          editable={item?.props?.editable}
          key={item.uiLabel + index}
          inputType={item.type}
        >
          {(() => {
            switch (item.type) {
              case 'dateSelection':
                return <DateSelection value={item.value} />;
              case 'date':
                return (
                  <SmarterCalendar
                    dateFormat={item?.props?.dateFormat}
                    value={formModel[item.inputName] || null}
                    onChange={(event) => handleOnChange(item.inputName, event, item.onChange)}
                    minDate={item?.props?.minDate}
                    {...computeMUIProps(item?.props)}
                  />
                );
              case 'datetime':
                return (
                  <SmarterDateTimePicker
                    value={formModel[item.inputName] || null}
                    onChange={(event) => handleOnChange(item.inputName, event, item.onChange)}
                    format={'dd MMM yyyy hh:mm a'}
                    {...computeMUIProps(item?.props)}
                  />
                );
              case 'autoComplete':
                return (
                  <SmarterAutoComplete
                    size="small"
                    onChange={(event) => handleOnChange(item.inputName, event)}
                    dataSource={item?.props.dataSource}
                    {...computeMUIProps(item?.props)}
                  />
                );
              case 'textArea':
                return (
                  <SmarterTextArea
                    onChange={(event) => handleOnChange(item.inputName, event)}
                    {...computeMUIProps(item?.props)}
                  />
                );
              case 'uploadImage':
                return (
                  <UploadFileContainer
                    label={item.uiLabel}
                    allowUploadMultiDoc={item?.props?.allowUploadMultiDoc}
                    {...computeMUIProps(item?.props)}
                    onChange={(event) => handleOnChange(item.inputName, event)}
                  />
                );
              case 'radio':
                return (
                  <SmarterRadio
                    data={item?.props?.dataSource}
                    value={formModel[item.inputName]}
                    handleChange={(event) => handleOnChange(item.inputName, event, item.onChange)}
                    {...computeMUIProps(item?.props)}
                    optionProps={item?.optionProps}
                  />
                );
              case 'confirmCheckbox':
                return (
                  <SmarterCheckbox
                    onChange={(event) => handleOnChange(item.inputName, event, item.onChange)}
                    value={formModel[item.inputName]}
                    {...computeMUIProps(item?.props)}
                  />
                );
              case 'dropdown':
                return (
                  <FormDropdown
                    size="small"
                    data={item?.props?.dataSource?.map((option) => option.value) || []}
                    state={formModel[item.inputName]}
                    handleChange={(event) => handleOnChange(item.inputName, event, item.onChange)}
                    {...computeMUIProps(item.props)}
                  />
                );

              case 'phoneNumber':
                return (
                  <PhoneNumber
                    value={formModel[item.inputName]}
                    onChange={(phone) => handleOnChange(item.inputName, phone)}
                    specialLabel={null}
                    placeholder={null}
                    inputClass={classes.inputClass}
                    {...computeMUIProps(item.props)}
                  />
                );
              case 'number':
                const value = !_.isFunction(item.props?.dependentValue)
                  ? formModel[item.inputName]
                  : item.props.dependentValue(
                      formModel,
                      ...item.props.dependentKeys
                        ?.map((key) => watch[key])
                        ?.filter((item) => !!item),
                    );
                return (
                  <SmarterNumeric
                    value={value}
                    onChange={(event) => handleOnChange(item.inputName, event)}
                    {...computeMUIProps(item?.props)}
                  />
                );
              case 'group':
                return (
                  item.visible && (
                    <Group
                      addLabel={item?.uiLabel}
                      data={formModel[item.inputName]}
                      fields={item.subfields}
                      onChange={(event) => {
                        handleOnChange(item.inputName, event);
                      }}
                    />
                  )
                );

              default:
                return (
                  <SmarterTextField
                    size="small"
                    value={formModel[item.inputName]}
                    onChange={(event) => handleOnChange(item.inputName, event)}
                    {...computeMUIProps(item?.props)}
                  />
                );
            }
          })()}
        </FormItem>
      )
    );
  };

  const handleOnChange = (fieldName, value, callback) => {
    setFormModel({
      ...formModel,
      [fieldName]: value,
    });
    callback && callback(value);
  };
  return (
    <>
      {formConfig.map((section) => (
        <Fragment key={section.section}>
          <h2>{section.section}</h2>
          {section.fields.map((field, idx) => renderForm(field, idx))}
        </Fragment>
      ))}
    </>
  );
};
