import React, { useEffect, useState } from 'react';
import { List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { PaginatedButtonBar } from '@components/button';
import { LandscapeDoctorCard } from '../card';

export const VerticalList = ({ doctors, handleSelect, handleNewPage, submitting }) => {
  const classes = useStyles();
  const [card, setCard] = useState(0);
  const [data, setData] = useState([]);
  const max = 9;

  function handlePageChange(page) {
    setCard(page * max);
  }

  function pagination() {
    const newData = doctors.slice(card, max + card < doctors.length ? max + card : doctors.length);
    setData(newData);
  }

  useEffect(() => {
    pagination();
    handleNewPage();
  }, [card, doctors]);

  return (
    <div className={classes.root}>
      <List className={classes.list}>
        {data.map((doctor, idx) => (
          <LandscapeDoctorCard
            key={idx}
            doctor={doctor}
            onSelect={handleSelect}
            submitting={submitting}
          />
        ))}
      </List>

      <PaginatedButtonBar
        className={classes.pagination}
        dataLength={doctors.length}
        itemsPerPage={max}
        toPage={handlePageChange}
        currentItem={card}
        pageSpreadMax={7}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginBottom: '10px',
  },
  list: {
    marginBottom: '10px',
    paddingRight: theme.spacing(1),
  },
  pagination: {
    position: 'absolute',
    right: '0',
    width: 'fit-content',
  },
}));
