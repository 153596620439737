import { DynamicForm } from '../../../../components/form/DynamicForm';
import React, { useEffect } from 'react';
import { buildFormModel } from '../../utils/utils';

export const GuardianOfKin = ({ formModel, setFormModel, config }) => {
  useEffect(() => {
    const temp = buildFormModel(config);
    setFormModel(temp);
  }, []);
  return <DynamicForm formConfig={config} formModel={formModel} setFormModel={setFormModel} />;
};
