import React from "react";
import {
  Grid,
  Paper,
  withStyles
} from "@material-ui/core";


const useStyles = theme => ({

  mobileViewFullCalendarControl: {
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
      [theme.breakpoints.up('lg')]: {
        ustifyContent: 'center',
      },
    },
  },
  mobileViewFullCalendarPaperControl: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '95%',
        [theme.breakpoints.up('lg')]: {
          width: '100%',
        },
      },
    },
  },


});

//common full calendar <Grid> and <Paper> as it includes mobile view control

class FullCalendarGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }


  render() {
      return (
        <Grid container item xs={12} style={this.props.styling} className={this.props.classes.mobileViewFullCalendarControl}>
          <Paper className={this.props.classes.mobileViewFullCalendarPaperControl} style={{padding: 15, borderRadius: '0.3cm'}}>
            {this.props.children}
          </Paper>
        </Grid>
      );
  }

}

export default withStyles(useStyles)(FullCalendarGrid)
