import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Button, CardContent, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import TranslateOutlinedIcon from '@material-ui/icons/TranslateOutlined';

import { useResolution } from '@hooks';
import { prioritizePanels, splitBackground, collapsibleList, truncatedName } from './utilities';
import { MappedPracticeLocations, HiddenContent } from './utilities/components';
import { HORIZONTAL, LOC } from '../../utilities/constants';
import { CardInformationList } from '@components/card';
import { LoadingButton } from 'components/button';

export const PortraitDoctorCard = ({ doctor, onSelect, submitting }) => {
  const size = useResolution();
  const [isFrontContentShown, setIsFrontContentShown] = useState(true);
  const [hiddenContent, setHiddenContent] = useState([]);
  const classes = useStyles();
  const {
    doctorId,
    consultFee,
    fullname,
    practiceLocations,
    languages,
    profileImageUrlSession,
    subSpecialties,
    qualifications,
    workExperiences,
    background,
  } = doctor;

  const listsConfig = {
    [LOC]: {
      list: () => {
        return collapsibleList({
          list: MappedPracticeLocations(prioritizePanels(practiceLocations), size, HORIZONTAL),
          breakpoint: 5,
          expanded: true,
        });
      },
    },
  };

  useEffect(() => {
    function createSecondaryData() {
      const content = [
        { title: 'Qualifications', data: qualifications },
        { title: 'Work Experiences', data: !!workExperiences.length ? workExperiences : null },
        {
          title: 'Background',
          data: !!background ? splitBackground(background) : null,
        },
      ];
      setHiddenContent(content);
    }
    createSecondaryData();
  }, [background, qualifications, workExperiences]);

  return (
    <Card className={classes.root}>
      <CardContent
        className={isFrontContentShown ? classes.contentRoot : classes.contentRootExpanded}
      >
        <div className={isFrontContentShown ? classes.hidden : classes.shown}>
          <HiddenContent content={hiddenContent} size={size} />
        </div>
        <div className={isFrontContentShown ? '' : classes.hidden}>
          <div className={classes.header}>
            <img className={classes.image} src={`${profileImageUrlSession}`} alt="Doctor" />
            <div className={classes.headerContent}>
              <Typography className={classes.title}>{`Dr. ${truncatedName(fullname)}`}</Typography>
              <CardInformationList
                className={classes.headerInfo}
                list={subSpecialties.filter(
                  (content) => content !== 'Not Otherwise Specified (NOS)',
                )}
                listItemClassName={classes.headerInfoText}
                leftBorder
              />
            </div>
          </div>
          <div className={classes.content}>
            <CardInformationList
              className={classes.lang}
              icon={<TranslateOutlinedIcon />}
              textClassName={classes.langTitle}
              text={mappedText(languages.filter((lang) => lang !== 'Other'))}
            />
            <div style={{ display: 'flex' }}>
              <CardInformationList
                className={classes.location}
                textClassName={classes.locationTitle}
                text="Practice Location(s):"
                listItemClassName={classes.locationListItem}
                list={listsConfig[LOC].list()[0]}
              />
              {consultFee && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'absolute',
                    bottom: '80px',
                    right: '24px',
                    textAlign: 'right',
                  }}
                >
                  <Typography style={{ fontSize: '16px' }}>consult fee (est):</Typography>
                  <Typography className={classes.feeText}>
                    {consultFee.replace(/\s+/g, '')}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </div>
      </CardContent>
      <div className={classes.buttonBar}>
        {isFrontContentShown ? (
          <Button
            className={classes.expandButton}
            disableRipple
            disableElevation
            onClick={() => setIsFrontContentShown((state) => !state)}
            variant="text"
            startIcon={<ExpandMoreIcon />}
          >
            More Info
          </Button>
        ) : (
          <Button
            className={classes.expandButton}
            disableRipple
            disableElevation
            onClick={() => setIsFrontContentShown((state) => !state)}
            variant="text"
            startIcon={<ExpandLessIcon />}
          >
            Less Info
          </Button>
        )}
        <LoadingButton
          className={classes.selectButton}
          onClick={() => onSelect(doctorId)}
          text="Select"
          loading={submitting.loading && submitting.id === doctorId}
        />
      </div>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  hidden: { display: 'none' },
  shown: { overflow: 'auto', marginTop: theme.spacing(1) },

  root: {
    '@media (min-width:1550px)': {
      height: '500px',
    },
    '@media (max-width:1550px)': {
      height: '450px',
    },
    width: '94%',
    position: 'relative',
    borderRadius: '0.5cm',
    marginLeft: '0.625rem',
    marginBottom: '0.625rem',
  },
  contentRoot: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: '85%',
  },
  contentRootExpanded: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: '85%',
    overflow: 'scroll',
  },
  header: {
    display: 'flex',
    height: '40%',
    justifyContent: 'space-between',
    width: '100',
  },
  image: {
    '@media (min-width:1550px)': {
      width: '129px',
      height: '172px',
    },
    '@media (max-width:1550px)': {
      width: '118.5px',
      height: '158px',
    },
    '@media (max-width:1380px)': {
      width: '108px',
      height: '144px',
    },
    marginRight: theme.spacing(2),
  },
  headerContent: {
    height: '90%',
    width: '100%',
  },
  headerInfo: {
    marginTop: theme.spacing(1),
  },
  headerInfoTitle: {
    '@media (min-width:1550px)': {
      fontSize: '12px',
    },
    '@media (max-width:1550px)': {
      fontSize: '11px',
    },
    '@media (max-width:1380px)': {
      fontSize: '10px',
    },
  },
  headerInfoText: {
    '@media (min-width:1550px)': {
      fontSize: '14px',
    },
    '@media (max-width:1550px)': {
      fontSize: '12px',
    },
    '@media (max-width:1380px)': {
      fontSize: '12px',
    },
  },
  lang: {
    marginTop: theme.spacing(1),
  },
  langTitle: { marginLeft: '6px', fontSize: '14px' },
  title: {
    '@media (max-width:1380px)': {
      fontSize: '0.9rem',
    },
    '@media (max-width:1550px)': {
      fontSize: '1.0rem',
    },
    '@media (min-width:1550px)': {
      fontSize: '1.1rem',
    },
    fontWeight: '400',
    fontFamily: 'Lato-Bold',
  },
  location: {
    marginTop: theme.spacing(2),
  },
  locationTitle: {
    fontSize: '16px',
  },
  locationListItem: {
    marginLeft: theme.spacing(1),
    marginTop: '8px',
    fontSize: '12px',
    display: 'flex',
  },
  feeText: {
    '@media (max-width:1580px)': {
      fontSize: '1.2rem',
    },
    '@media (min-width:1580px)': {
      fontSize: '1.4rem',
    },
    color: '#0099CC',
    fontWeight: 'bold',
  },
  content: {
    height: '65%',
    zIndex: 2,
  },
  infoBox: {
    height: '24px',
  },
  infoText: {
    '@media (max-width:1580px)': {
      fontSize: 10,
    },
    '@media (min-width:1580px)': {
      fontSize: 12,
    },
  },
  buttonBar: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
  },
  expandButton: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  selectButton: {
    color: 'white',
    backgroundColor: '#03ACEF',
    height: '36px',
    '&:hover': {
      backgroundColor: '#03ACEF',
    },
    maxWidth: '120px',
    minWidth: '120px',
    minHeight: '36px',
  },
}));

const mappedText = (phrases) => {
  let longText = '';
  for (let i = 0; i < phrases.length; i++) {
    const phrase = phrases[i];
    if (!i) longText += phrase;
    else longText += `, ${phrase}`;
  }
  return longText;
};
