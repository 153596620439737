import _ from 'lodash';
import { INFRA_MAPPING } from './constant';

export const formatByte = (bytes, unit, decimal = 2) => {
  const corresponding = {
    KB: 1024,
    MB: 1024 * 1024,
  };
  return (bytes / corresponding[unit]).toFixed(decimal);
};

export const getValueOrDefault = (value, defaultValue = '') => {
  if (value) return value;
  else return defaultValue;
};

export const getValueByCondition = (condition, ifValue, elseValue) => {
  if (condition) return ifValue;
  else return elseValue;
};

export const numberSeparator = (bigNum) =>
  String(bigNum).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');

export const isPasswordValid = (password) => {
  let listErrors = {
    eightCharacters: true,
    uppercase: true,
    lowercase: true,
    specialCharacter: true,
    number: true,
  };
  let minEight = /(?=^.{8,})/;
  let oneUppercase = /([A-Z])/;
  let oneLowercase = /([a-z])/;
  let oneSpecialCharacter = /[@$!%*?#&]/;
  let oneDigit = /(\d)/;

  if (!password.match(minEight)) {
    listErrors['eightCharacters'] = false;
  }
  if (!password.match(oneUppercase)) {
    listErrors['uppercase'] = false;
  }
  if (!password.match(oneLowercase)) {
    listErrors['lowercase'] = false;
  }
  if (!password.match(oneSpecialCharacter)) {
    listErrors['specialCharacter'] = false;
  }
  if (!password.match(oneDigit)) {
    listErrors['number'] = false;
  }

  return listErrors;
};

export const separateObject = (obj) => [Object.keys(obj)[0], Object.values(obj)[0]];

export const pennify = (num) => _.round(num, 2).toFixed(2);

export const reversePreferredElement = (arr) => {
  const result = [];
  if (arr) {
    for (const element of arr) {
      if (element.preferred === 'false') {
        result.push(element);
      } else {
        result.unshift(element);
      }
    }
  }
  return result;
};

export const validateEmail = (email = '') => {
  let re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!re.test(email)) {
    return 'E-mail address must be valid';
  }
  return '';
};

export function detectEnv() {
  const host = window?.location?.host;
  for (const [key, value] of Object.entries(INFRA_MAPPING)) {
    if (value === host) {
      return key;
    }
  }
  return 'local';
}

export const handleLegacyDBAddress = (addresses = []) => {
  let newAddresses = [...addresses];
  newAddresses.forEach((add) => {
    if (add['level'] && add['tempUnit']) {
      add['unit'] = `${add['level']}-${add['tempUnit']}`;
    } else if (add['level'] && !add['tempUnit']) {
      add['unit'] = add['level'];
    } else if (!add['level'] && add['tempUnit']) {
      add['unit'] = add['tempUnit'];
    }
    delete add['level'];
    delete add['tempUnit'];
  });
  return newAddresses;
};
