import { DynamicForm } from '@components/form/DynamicForm';
import React, { forwardRef, useEffect, useImperativeHandle, useState, useRef } from 'react';
import { DetailOfReferral, defaultConfig } from '../components/Referral/DetailOfReferral';
import { UploadFileContainer } from '../../../components/input/upload-file';
import _ from 'lodash';
import { OtherDocuments } from '../components/Referral/OtherDocuments';
import { buildFormModel, validate, convertDateTimeForServer } from '../utils/utils';
import { saveReferral } from '../../../apis/paf';
import { SmarterErrors } from '@components/alert';

export const Referral = forwardRef(({ caseId, payorName, ...props }, ref) => {
  const [formModel, setFormModel] = useState({});
  const [documents, setDocuments] = useState([
    {
      documentType: '',
      document: '',
    },
  ]);
  const [referralLetter, setReferralLetter] = useState([]);
  const [showDetailReferralDoctor, setShowDetailReferralDoctor] = useState(false);
  const [showReferralLetter, setShowReferralLetter] = useState(false);
  const [errors, setErrors] = useState([]);
  const errorRef = useRef(null);

  const referral = [
    {
      section: 'Referral',
      fields: [
        {
          inputName: 'isPatientReferred',
          isRequired: false,
          uiLabel: 'Was this patient referred to you ?',
          name: 'isPatientReferred',
          type: 'radio',
          value: 'No',
          props: {
            dataSource: ['Yes', 'No'],
          },
          onChange: (value) => {
            setFormModel({
              ...formModel,
              isReferralLetter: undefined,
              isPatientReferred: value,
            });
          },
        },
        {
          inputName: 'isReferralLetter',
          isRequired: true,
          uiLabel: 'Do you have a copy of the referral letter ?',
          name: 'isReferralLetter',
          type: 'radio',
          props: {
            dataSource: ['Yes', 'No'],
            visible: { inputName: 'isPatientReferred', value: 'Yes' },
          },
          onChange: (value) => {
            setShowDetailReferralDoctor(value === 'No');
            setShowReferralLetter(value === 'Yes');
          },
        },
      ],
    },
  ];

  const executeScroll = () =>
    errorRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });

  const submit = () =>
    new Promise((resolve, reject) => {
      let errs = validate(referral, formModel);
      let errReferralDetails =
        formModel.isPatientReferred === 'Yes' && formModel.isReferralLetter === 'No'
          ? validate(defaultConfig, formModel)
          : [];
      if (!_.isEmpty(errs) || !_.isEmpty(errReferralDetails)) {
        let combineError = [...errs, ...errReferralDetails];
        setErrors(combineError);
        executeScroll();
        reject(false);
        return;
      }
      const {
        isPatientReferred,
        isReferralLetter,
        referralDoctorName,
        referralDoctorClinicDetails,
        referralDoctorPhone,
        dateTimeOfReferral,
      } = formModel;
      const payload = {
        isPatientReferred: isPatientReferred === 'Yes',
        isReferralLetter: isReferralLetter === 'Yes',
        referralDoctorName: showDetailReferralDoctor ? referralDoctorName : '',
        referralDoctorClinicDetails: showDetailReferralDoctor ? referralDoctorClinicDetails : '',
        referralDoctorPhone: showDetailReferralDoctor ? referralDoctorPhone : '',
        documents: [...documents, ...referralLetter],
        dateTimeOfReferral: convertDateTimeForServer(dateTimeOfReferral),
      };
      saveReferral(payload, caseId)
        .then((res) => resolve(true))
        .catch((err) => {
          console.log(err);
          if (err?.response?.status === 413) {
            setErrors(['Only files that are 5MB or less are allowed.']);
          } else {
            setErrors(['Unable to perform submission.']);
          }
          executeScroll();
          reject(false);
        });
    });

  useEffect(() => {
    setFormModel(buildFormModel(referral));
  }, []);

  useImperativeHandle(ref, () => ({
    next: async () => {
      return submit();
    },
    back: () => {
      return true;
    },
  }));

  useEffect(() => {
    if (formModel.isPatientReferred === 'No') {
      setShowDetailReferralDoctor(false);
      setShowReferralLetter(false);
    }
  }, [formModel.isPatientReferred]);

  useEffect(() => {
    if (!showReferralLetter) {
      onChangeReferralLetter([]);
    }
  }, [showReferralLetter]);

  const onChangeReferralLetter = (value) => {
    if (value?.length) {
      const newDocs = [
        {
          documentType: 'Referral Letter',
          document: value[0],
        },
      ];
      setReferralLetter(newDocs);
    } else {
      setReferralLetter([]);
    }
  };

  return (
    <div ref={errorRef} style={{ width: '700px', margin: 'auto' }}>
      <SmarterErrors errors={errors} />
      <DynamicForm formConfig={referral} formModel={formModel} setFormModel={setFormModel} />
      {showReferralLetter && (
        <div>
          <UploadFileContainer
            allowUploadMultiDoc={false}
            uiLabel="Please attach a copy of the referral letter"
            onChange={onChangeReferralLetter}
          />
        </div>
      )}
      {showDetailReferralDoctor && (
        <DetailOfReferral formModel={formModel} setFormModel={setFormModel} payorName={payorName} />
      )}
      <OtherDocuments documents={documents} setDocuments={setDocuments} />
    </div>
  );
});
