import React, { useState, useEffect } from 'react';
import { isBefore } from 'date-fns';
import { ResetPasswordPage } from './ResetPasswordPage';
import { postForgotPassword } from '../../apis';
import { SmarterTextField } from '@components/input';
import { FormItem } from '../../components/form/FormItem.jsx';

export const EnterEmail = ({ onSubmit }) => {
  const [isEmailError, setIsEmailError] = useState(false);
  const [email, setEmail] = useState(sessionStorage.getItem('email') || '');
  const [isEmptyEmailPayload, setIsEmptyEmailPayload] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    let intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [currentDate]);

  const onChangeEmail = (value) => {
    setIsEmptyEmailPayload(false);
    setEmail(value);
    sessionStorage.setItem('email', value);
  };

  const forgotPassword = async () => {
    if (!email) {
      setIsEmptyEmailPayload(true);
      setIsEmailError(false);
    } else {
      setIsEmptyEmailPayload(false);
      try {
        await postForgotPassword({ email: email });
        let now = new Date();
        now.setSeconds(now.getSeconds() + 90);
        localStorage.setItem('endTime', now);
        onSubmit();
      } catch (error) {
        setIsEmailError(true);
        return 400;
      }
    }
  };
  const isButtonDisabled = isBefore(new Date(), new Date(localStorage.getItem('endTime')));
  return (
    <ResetPasswordPage
      title="Reset password"
      description="Please enter the email address you used to register with us. A verification code will be
        sent to your email address to reset your password"
      buttonProps={{
        text: 'Continue',
        onClick: forgotPassword,
        disabled: isButtonDisabled,
      }}
    >
      <FormItem label="Email">
        <SmarterTextField
          onChange={onChangeEmail}
          value={email}
          id="resetPassword-reset-email"
          inputProps={{ 'data-testid': 'resetPassword-reset-email' }}
          error={isEmptyEmailPayload || isEmailError}
          helperText={
            isEmptyEmailPayload
              ? 'Please fill your e-mail address'
              : isEmailError
              ? "E-mail doesn't exist"
              : ''
          }
        />
      </FormItem>
    </ResetPasswordPage>
  );
};
