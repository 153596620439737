import React, { Component, Suspense, lazy } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import './App.css';
import { smarterhealth } from './features/common/config';
import AgentHome from './features/create-booking/home/index';
import CreateSchedule from './features/manage-booking/create_schedule/index';
import CreatePatientAppointment from './features/create-booking/create_patient_appointment/index';
import Layout from './features/layout/layout';
import DoctorLanding from './features/manage-booking/home/specialist/index';
import ClinicSchedule from './features/manage-booking/clinic_schedule/index';
import HSPClinicSchedule from './features/manage-booking/hsp-clinic-schedule';
import PatientAdminLanding from './features/synopsis-booking/home/index';
import PatientLanding from './features/manage-booking/home/patient/index';
import Login from './features/account-management/login/index';
import MyServices from './features/my-services/index';
import AccountSettings from './features/account-management/registration/account_settings/index';
import PersonalProfile from './features/account-management/registration/personal_profile/index';
import ReviewSubmit from './features/account-management/registration/review_submit/index';
import { SearchDoctorContainer } from './features/doctor-search';
import { OafContainer } from './features/oaf';
import { McuFormContainer } from './features/mcu-form';
import AppointmentMessage from './features/synopsis-booking/appointment-message';
import CaseDetails from './features/manage-booking/home/case-details';
import Cookies from 'universal-cookie';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import { PAFContainer } from './features/paf/PAFContainer';
import PAFCaseDetails from './features/paf/PAFCaseDetails';
import { ResetPassword } from './features/reset-password/ResetPassword';
import { MyAccount } from 'features/user-profile/MyAccount.jsx';
import { AccountSecurity } from 'features/user-profile/AccountSecurity.jsx';
import GlobalAuthTokenHandler from './components/auth/GlobalAuthTokenHandler';

const cookies = new Cookies();
const BookScreening = lazy(() => import('./features/book-health-screening'));

const useStyles = (theme) => ({
  wrapper: {
    '&.isLandscape': {
      width: '100%',
      overflow: 'hidden',
      height: '100%',
      '& .landscapeView': {
        zIndex: 1,
        backgroundColor: '#ffffff',
        height: '100%',
        width: '100%',
      },
      '& .mainContainer': {
        opacity: 0,
        height: 0,
      },
    },
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginType:
        this.props.location.pathname.indexOf('agent') > -1
          ? 'agent'
          : this.props.location.pathname.indexOf('doctor/schedule/create') === 1 ||
            this.props.location.pathname.indexOf('provider') === 1 ||
            this.props.location.pathname.indexOf('schedule/clinic/provider') === 1 ||
            (this.props.location.pathname.indexOf('view') === 1 &&
              cookies.get('client') === smarterhealth) ||
            this.props.location.pathname.indexOf('schedule/clinic/healthscreening') === 1
          ? 'doctor'
          : this.props.location.pathname.indexOf('schedule/create') === 1 && !cookies.get('client')
          ? null
          : 'sidebar',
      isLoggedIn:
        this.props.location.pathname.indexOf('signup') === 1 ||
        this.props.location.pathname.indexOf('login') >= 1 ||
        this.props.location.pathname.indexOf('reset-password') >= 1
          ? false
          : true,
      authTokenChecked: false,
    };
  }

  onAuthTokenComplete = () => {
    this.setState({ authTokenChecked: true });
  };

  render() {
    const { authTokenChecked } = this.state;
    if (!authTokenChecked) {
      return <GlobalAuthTokenHandler onAuthComplete={this.onAuthTokenComplete} />;
    }
    return (
      <div>
        <div className="mainContainer">
          <Switch>
            <Layout
              onThemeTypeSwitch={this.props.changeTheme}
              theme={this.props.theme}
              loginType={this.state.loginType}
              isLoggedIn={this.state.isLoggedIn}
            >
              {/* Agent Home Route **/}
              <Route
                exact
                path="/agent/home/:providerId"
                render={(props) => <AgentHome {...props} />}
              />
              {/* End Agent Home Route**/}
              {/* Doctor  Route**/}
              <Route
                exact
                path="/provider/:providerId"
                render={(props) => <DoctorLanding {...props} />}
              />
              <Route
                exact
                path="/doctor/schedule/create/:unitId/:requesterId/:serviceType"
                render={(props) => <CreateSchedule {...props} />}
              />
              <Route
                exact
                path="/schedule/clinic/provider/:providerId"
                render={(props) => <ClinicSchedule {...props} />}
              />
              <Route
                exact
                path="/schedule/clinic/healthscreening/provider/:providerId"
                render={(props) => <HSPClinicSchedule {...props} />}
              />
              {/* End Doctor Route**/}
              {/* Patient Route**/}
              <Route
                exact
                path="/schedule/create/:unitId/:requesterId/:serviceType"
                render={(props) => <CreatePatientAppointment {...props} />}
              />
              <Route exact path="/patient" render={(props) => <PatientLanding {...props} />} />
              <Route exact path="/myservices" render={(props) => <MyServices {...props} />} />
              <Route
                exact
                path="/view/:serviceType/:requesterId"
                render={(props) => <CaseDetails {...props} />}
              />
              {/* End Patient Route**/}
              {/* Patient Admin Route**/}
              <Route
                exact
                path="/patientadmin"
                render={(props) => <PatientAdminLanding {...props} />}
              />
              {/* End Patient Admin Route**/}
              {/* Login/Registration Route**/}
              <Route
                exact
                path="/signup/:clientName/accountsettings"
                render={(props) => <AccountSettings {...props} />}
              />
              <Route
                exact
                path="/signup/:clientName/personalprofile"
                render={(props) => <PersonalProfile {...props} />}
              />
              <Route
                exact
                path="/signup/:clientName/review/submit"
                render={(props) => <ReviewSubmit {...props} />}
              />
              <Route exact path="/:clientName/login" render={(props) => <Login {...props} />} />
              {/* End Login/Registration Route**/}
              <Route path="/search-doctor">
                <SearchDoctorContainer />
              </Route>
              <Route path="/opd/patient/client/form/:requesterId">
                <OafContainer />
              </Route>
              <Route path="/mcu/patient/client/form/:requesterId">
                <McuFormContainer />
              </Route>
              <Route path="/health-screening">
                <Suspense fallback={<p>Loading...</p>}>
                  <BookScreening {...this.props} />
                </Suspense>
              </Route>
              <Route
                exact
                path="/appointment/:serviceType/:requesterId/complete"
                render={(props) => <AppointmentMessage {...props} />}
              />
              <Route path="/paf">
                <PAFContainer />
              </Route>
              <Route
                exact
                path="/epaf/role/:roleId/case/:caseId"
                render={(props) => <PAFCaseDetails {...props} />}
              />
              <Route exact path="/reset-password" render={() => <ResetPassword />} />
              <Route exact path="/user-profile/my-account" render={() => <MyAccount />} />
              <Route exact path="/user-profile/account-security">
                <AccountSecurity />
              </Route>
            </Layout>
          </Switch>
        </div>
      </div>
    );
  }
}

export default compose(withRouter, withStyles(useStyles))(App);
