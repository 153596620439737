import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { getTimeZoneString, dateConvert } from '../../../common/config';
import { getCaseDetails, getBookingDetails } from '@api';
import { ViewCaseDetails, AppointmentDate } from '../home_components';
import { getClientNameForLoginAndRegistration, smarterhealth } from '../../../common/config';
import { ReactHelmet } from '@components/react-helmet/Helmet';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const useStyles = (theme) => ({});

class CaseDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unitId: null,
      patientDetails: {
        gender: null,
        age: null,
        email: null,
        sms: null,
        documentLinks: null,
        name: null,
        dob: null,
      },
      checkDocumentsLength: 0,
      medicalCondition: [],
      otherReason: '',
      facilityName: null,
      appointmentStatus: null,
      specialistName: null,
      packageName: null,
      appointmentDates: [],
    };
  }

  async componentDidMount() {
    await this.getAppointmentCaseDetails();
    await this.getPreferredDates();
  }

  getAppointmentCaseDetails = async () => {
    try {
      const respondData = await getCaseDetails(
        this.props.match.params.serviceType,
        this.props.match.params.requesterId,
      );
      if (respondData.code && respondData.data) {
        if (Array.isArray(respondData.data.data.items)) {
          respondData.data.data.items.map((item) => {
            this.setState({
              unitId: item.unitId,
              patientDetails: item.patient,
              checkDocumentsLength: item.patient.documentLinks.length,
              medicalCondition: item.patient.medicalCondition.split(','),
              otherReason: item.patient.otherReason,
              facilityName: item.facility.facilityName,
              appointmentStatus: item.appointmentStatus,
            });
            if (item.requestType.toLowerCase() === 'opd') {
              this.setState({ specialistName: item.specialist.displayName });
            } else {
              this.setState({ packageName: item.screening.packageName });
            }
          });
        }
      }
    } catch (error) {
      console.log('something went wrong', error);
    }
  };

  getPreferredDates = async () => {
    try {
      const respondData = await getBookingDetails(
        this.state.unitId,
        this.props.match.params.requesterId,
      );
      if (respondData.code && respondData.data) {
        if (
          Array.isArray(respondData.data.assignedSlot) &&
          respondData.data.assignedSlot.length !== 0
        ) {
          // PROVIDER
          const temp = respondData.data.assignedSlot.map(item => ({
            start: item.assignedSlot.slotStartAt,
            end: item.assignedSlot.slotEndAt,
          }));
          this.setState({ appointmentDates: temp });
        } else {
          // PATIENT
          this.setState({ appointmentDates: respondData.data.bookedSchedule });
        }
      }
    } catch (error) {
      console.log('something went wrong', error);
    }
  };

  render() {
    const getClient = cookies.get('client') ?? smarterhealth;
    const isClientSH = getClientNameForLoginAndRegistration(getClient) === smarterhealth;

    return (
      <React.Fragment>
        <ReactHelmet title="Case Details" />
        <ViewCaseDetails
          onClick={() => (window.location = '/')}
          serviceType={this.props.match.params.serviceType}
          appointmentDetails={[
            this.state.specialistName,
            this.state.packageName,
            this.state.facilityName,
          ]}
          patientDetails={this.state.patientDetails}
          medicalCondition={this.state.medicalCondition}
          otherReason={this.state.otherReason}
          checkDocumentsLength={this.state.checkDocumentsLength}
        >
          <AppointmentDate
            onClick={
              isClientSH
                ? () =>
                    this.props.history.push({
                      pathname: `/doctor/schedule/create/${this.state.unitId}/${this.props.match.params.requesterId}/${this.props.match.params.serviceType}`,
                      state: { isReschedule: true },
                    })
                : null
            }
            appointmentDates={this.state.appointmentDates}
            appointmentStatus={!isClientSH && this.state.appointmentStatus}
          />
        </ViewCaseDetails>
      </React.Fragment>
    );
  }
}
export default withStyles(useStyles)(CaseDetails);
