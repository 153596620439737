import React from 'react';
import materialTheme from './theme.js';
import {
  Grid,
  withStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Paper,
  Checkbox,
  Container,
  InputAdornment,
  Link,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';
import { ThemeProvider } from '@material-ui/styles';
import { get, post } from '../../../../services/http_services/http_services';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import {
  filterDateRangeInMonths,
  arrowApiPrefix,
  bentoApiPrefix,
  getTimeZoneString,
  worklistRowsPerPage,
  defaultRowsPerPage,
  dateConvert,
  momentDateFormat,
  muiDateFormat,
} from '../../../common/config';
import { ReactHelmet } from '@components/react-helmet/Helmet';
import locale from 'date-fns/locale/en-US';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const useStyles = (theme) => ({
  tableContainer: {
    marginBottom: '50px',
    '@media (max-width:450px)': {
      width: '120%',
      marginLeft: '-10%',
    },
  },
  table: {
    minWidth: 700,
  },
  mobileViewControl: {
    [theme.breakpoints.up('1400')]: {
      display: 'flex',
    },
  },
  mobileViewControlExpansionPanel: {
    border: '1px solid #C4C4C4',
    [theme.breakpoints.up('lg')]: {
      border: '1px solid #C4C4C4',
      width: '340px',
    },
  },
  mobileViewControlExpansionPanelChanged: {
    border: '2px solid #C4C4C4',
    [theme.breakpoints.up('lg')]: {
      border: '2px solid #C4C4C4',
      width: '340px',
    },
  },
  pageTitle: {
    //color: '#2F318B',
    marginBottom: 40,
  },
  paper: {
    width: '100%',
    borderRadius: '0.3cm',
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
  },
  alertPopup: {
    marginBottom: 10,
    width: '100%',
  },
});

const cookie = { userId: cookies.get('userId'), client: cookies.get('client') };

const onFirstLoadAPI = `${arrowApiPrefix}/worklist/patient/${cookie.userId}`;

const filterAPI = `${arrowApiPrefix}/worklist/patient/${cookie.userId}/filter`;

const incompleteRequestAPI = `${arrowApiPrefix}/opd/pending/link`;

class LocalizedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, 'MMM yyyy').toUpperCase();
  }
  getWeekdays() {
    return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  }
}

if (locale && locale.options) {
  locale.options.weekStartsOn = 1;
}

class PatientHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnNames: [],
      rowData: [],
      columnCheck: [],
      page: 0,
      checkedStatus: [],
      checkedRequestType: [],
      statusList: [],
      requestTypeList: [],
      defaultStartDate: null,
      defaultEndDate: null,
      defaultStatusList: [],
      defaultRequestTypeList: [],
      selectedDateStart: null,
      selectedDateEnd: null,
      totalSize: 0,
      pageSend: 0,
      paginationLimit: defaultRowsPerPage,
      errorFilter: false,
      actionMessage: '',
      errorAction: false,
      incompleteLink: null,
    };
    this.state.statusList.forEach((status) => {
      this.setState({
        [`status-${status.replace(/\s+/g, '')}`]: false,
      });
    });
    this.state.requestTypeList.forEach((request) => {
      this.setState({
        [`request-${request.replace(/\s+/g, '')}`]: false,
      });
    });
  }

  async componentDidMount() {
    await this.initLandingData();
    this.checkIncomplete();

    const paginationScroll = document.getElementById('paginationScroll');
    const tableScroll = document.getElementById('tableScroll');
    if (paginationScroll && tableScroll) {
      tableScroll.onscroll = () => {
        paginationScroll.scrollLeft = tableScroll.scrollLeft;
      };
    }
  }

  initLandingData = async () => {
    const pageFirstLoad = {
      limit: this.state.paginationLimit,
    };

    const respondData = await post(onFirstLoadAPI, pageFirstLoad);

    if (respondData.code && respondData.data) {
      if (
        Array.isArray(respondData.data.dataTable.columns) &&
        respondData.data.dataTable.columns.length > 0
      ) {
        //columnNames for table header
        const columns = respondData.data.dataTable.columns.map((column) => {
          return {
            name: column,
          };
        });
        this.setState({ columnNames: columns });

        //columnCheck for storing table header with no spaces
        const columnCheck = respondData.data.dataTable.columns.map((column) => {
          return column.replace(/\s+/g, '');
        });
        this.setState({ columnCheck: columnCheck });
      }
      //get table data
      if (Array.isArray(respondData.data.dataTable.data)) {
        var results = [];
        respondData.data.dataTable.data.forEach((row) => {
          const newRow = {};
          for (var propertyName in row) {
            var newPropertyName = propertyName.replace(/\s+/g, '');
            newRow[newPropertyName] = row[propertyName];
          }
          results.push(newRow);
        });
        this.setState({ rowData: results });
      }

      //get status filter list for display
      if (
        respondData.data.filters.options.status.value.length > 0 &&
        this.state.statusList.length === 0
      ) {
        let statuslist = [...this.state.statusList];
        respondData.data.filters.options.status.value.forEach((status) => {
          statuslist.push(status);
        });
        this.setState({ statusList: statuslist });
      }
      //get request type filter list for display
      if (
        respondData.data.filters.options.requestType.value.length > 0 &&
        this.state.requestTypeList.length === 0
      ) {
        let requestlist = [...this.state.requestTypeList];
        respondData.data.filters.options.requestType.value.forEach((request) => {
          requestlist.push(request);
        });
        this.setState({ requestTypeList: requestlist });
      }

      //get default status
      if (
        respondData.data.filters.selectedOptions.status.value.length > 0 &&
        this.state.defaultStatusList.length === 0
      ) {
        let defaultStatus = [...this.state.defaultStatusList];
        let checkedstatuslist = [...this.state.checkedStatus];
        respondData.data.filters.selectedOptions.status.value.forEach((status) => {
          defaultStatus.push(status);
          checkedstatuslist.push(status);
          this.setState({ [`status-${status.replace(/\s+/g, '')}`]: true });
        });
        this.setState({ defaultStatusList: defaultStatus, checkedStatus: checkedstatuslist });
      }
      //get default request type
      if (
        respondData.data.filters.selectedOptions.requestType.value.length > 0 &&
        this.state.defaultRequestTypeList.length === 0
      ) {
        let defaultRequest = [...this.state.defaultRequestTypeList];
        let checkedrequestlist = [...this.state.checkedRequestType];
        respondData.data.filters.selectedOptions.requestType.value.forEach((request) => {
          defaultRequest.push(request);
          checkedrequestlist.push(request);
          this.setState({ [`request-${request.replace(/\s+/g, '')}`]: true });
        });
        this.setState({
          defaultRequestTypeList: defaultRequest,
          checkedRequestType: checkedrequestlist,
        });
      }

      //get default start date
      if (respondData.data.filters.options.startDate.value && !this.state.defaultStartDate) {
        this.setState({ defaultStartDate: respondData.data.filters.options.startDate.value });
      }
      //get default end date
      if (respondData.data.filters.options.endDate.value && !this.state.defaultEndDate) {
        this.setState({ defaultEndDate: respondData.data.filters.options.endDate.value });
      }
      //get total size
      this.setState({ totalSize: respondData.data.totalSize });
    }
  };

  onSubmitFilter = (selectedStartDate, selectedEndDate, selectedStatus, selectedRequestType) => {
    this.setState({ page: 0 });

    const filter = {
      limit: this.state.paginationLimit,
      startDate: selectedStartDate
        ? moment(selectedStartDate).format('YYYY-MM-DD')
        : moment(this.state.defaultStartDate).format('YYYY-MM-DD'),
      endDate: selectedEndDate
        ? moment(selectedEndDate).format('YYYY-MM-DD')
        : moment(this.state.defaultEndDate).format('YYYY-MM-DD'),
      status: selectedStatus,
      requestType: selectedRequestType,
    };

    post(filterAPI, filter)
      .then((respondData) => {
        if (respondData.code && respondData.data) {
          if (Array.isArray(respondData.data.dataTable.data)) {
            var results = [];
            respondData.data.dataTable.data.forEach((row) => {
              const newRow = {};
              for (var propertyName in row) {
                var newPropertyName = propertyName.replace(/\s+/g, '');
                newRow[newPropertyName] = row[propertyName];
              }
              results.push(newRow);
            });
            this.setState({ rowData: results });
          }
          this.setState({ totalSize: respondData.data.totalSize, errorFilter: false });
        }
      })
      .catch((err) =>
        //fail
        {
          console.log('something went wrong', err);
          this.setState({ errorFilter: true });
        },
      );
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });

    const paginationData = {
      limit: this.state.paginationLimit,
      action: this.state.page < newPage ? 'newer' : 'older',
      created:
        this.state.page < newPage
          ? this.state.rowData[this.state.paginationLimit - 1].created
          : this.state.rowData[0].created,
      page: newPage + 1,
      startDate: this.state.selectedDateStart
        ? moment(this.state.selectedDateStart).format('YYYY-MM-DD')
        : moment(this.state.defaultStartDate).format('YYYY-MM-DD'),
      endDate: this.state.selectedDateEnd
        ? moment(this.state.selectedDateEnd).format('YYYY-MM-DD')
        : moment(this.state.defaultEndDate).format('YYYY-MM-DD'),
      status: this.state.checkedStatus,
      requestType: this.state.checkedRequestType,
    };

    post(onFirstLoadAPI, paginationData)
      .then((respondData) => {
        if (respondData.code && respondData.data) {
          if (Array.isArray(respondData.data.dataTable.data)) {
            var results = [];
            respondData.data.dataTable.data.forEach((row) => {
              const newRow = {};
              for (var propertyName in row) {
                var newPropertyName = propertyName.replace(/\s+/g, '');
                newRow[newPropertyName] = row[propertyName];
              }
              results.push(newRow);
            });
            this.setState({ rowData: results });
          }
          this.setState({ totalSize: respondData.data.totalSize });

          this.setState({ pageSend: respondData.data.page });
        }
      })
      .catch((err) =>
        //fail
        console.log('something went wrong', err),
      );
  };

  //status checkbox for filter
  handleStatusChange = (event) => {
    let checked = [...this.state.checkedStatus];
    const index = checked.indexOf(event.target.name);
    if (event.target.checked) {
      checked.push(event.target.name);
      this.setState({
        checkedStatus: checked,
        [`status-${event.target.name.replace(/\s+/g, '')}`]: true,
      });
    } else {
      checked.splice(index, 1);
      this.setState({
        checkedStatus: checked,
        [`status-${event.target.name.replace(/\s+/g, '')}`]: false,
      });
    }
    this.onSubmitFilter(
      this.state.selectedDateStart,
      this.state.selectedDateEnd,
      checked,
      this.state.checkedRequestType,
    );
  };

  //request type checkbox for filter
  handleRequestTypeChange = (event) => {
    let checked = [...this.state.checkedRequestType];
    const index = checked.indexOf(event.target.name);
    if (event.target.checked) {
      checked.push(event.target.name);
      this.setState({
        checkedRequestType: checked,
        [`request-${event.target.name.replace(/\s+/g, '')}`]: true,
      });
    } else {
      checked.splice(index, 1);
      this.setState({
        checkedRequestType: checked,
        [`request-${event.target.name.replace(/\s+/g, '')}`]: false,
      });
    }
    this.onSubmitFilter(
      this.state.selectedDateStart,
      this.state.selectedDateEnd,
      this.state.checkedStatus,
      checked,
    );
  };

  //from and to dates for filter
  handleDateChangeStart = (date) => {
    this.setState({ selectedDateStart: new Date(date), selectedDateEnd: null });
  };

  handleDateChangeEnd = (date) => {
    this.setState({ selectedDateEnd: new Date(date) });

    this.onSubmitFilter(
      this.state.selectedDateStart,
      new Date(date),
      this.state.checkedStatus,
      this.state.checkedRequestType,
    );
  };

  handleClearAll = () => {
    this.setState({ selectedDateStart: null, selectedDateEnd: null, page: 0 });
    this.onSubmitFilter(null, null, this.state.checkedStatus, this.state.checkedRequestType);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ paginationLimit: event.target.value, page: 0 });

    const paginationLimitData = {
      limit: event.target.value,
      startDate: this.state.selectedDateStart
        ? moment(this.state.selectedDateStart).format('YYYY-MM-DD')
        : moment(this.state.defaultStartDate).format('YYYY-MM-DD'),
      endDate: this.state.selectedDateEnd
        ? moment(this.state.selectedDateEnd).format('YYYY-MM-DD')
        : moment(this.state.defaultEndDate).format('YYYY-MM-DD'),
      status: this.state.checkedStatus,
      requestType: this.state.checkedRequestType,
    };

    post(onFirstLoadAPI, paginationLimitData)
      .then((respondData) => {
        if (respondData.code && respondData.data) {
          if (Array.isArray(respondData.data.dataTable.data)) {
            var results = [];
            respondData.data.dataTable.data.forEach((row) => {
              const newRow = {};
              for (var propertyName in row) {
                var newPropertyName = propertyName.replace(/\s+/g, '');
                newRow[newPropertyName] = row[propertyName];
              }
              results.push(newRow);
            });
            this.setState({ rowData: results });
          }
          this.setState({ totalSize: respondData.data.totalSize });

          this.setState({ pageSend: respondData.data.page });
        }
      })
      .catch((err) =>
        //fail
        console.log('something went wrong', err),
      );
  };

  handleAction = (method, action, label, requestId) => {
    const success = () => {
      this.initLandingData();
      this.setState(
        {
          actionMessage: `Successfully ${label.toLowerCase()}ed appointment request.`,
          errorAction: false,
        },
        () => {
          setTimeout(() => {
            this.setState({ actionMessage: '' });
          }, 5000);
        },
      );
    };

    const fail = (error) => {
      this.setState(
        {
          actionMessage: `Unable to ${label.toLowerCase()} appointment request.`,
          errorAction: true,
        },
        () => {
          setTimeout(() => {
            this.setState({ actionMessage: '' });
          }, 5000);
        },
      );
      console.log('something went wrong', error);
    };

    const json = {
      requestId: requestId,
      pId: Number(cookie.userId),
    };

    if (method === 'POST') {
      //for Accept / Abort
      post(action, json)
        .then((respondData) => {
          success();
        })
        .catch((err) => {
          fail(err);
        });
    } else {
      //for View
      get(action)
        .then((respondData) => {
          if (respondData.code && respondData.data) {
            if (Array.isArray(respondData.data.data.items)) {
              respondData.data.data.items.map((item) => {
                this.props.history.push(`/view/${item.requestType}/${item.referenceNo}`);
              });
            }
          }
        })
        .catch((err) => {
          fail(err);
        });
    }
  };

  checkIncomplete = () => {
    get(incompleteRequestAPI)
      .then((respondData) => {
        if (respondData.code && respondData.data) {
          this.setState({ incompleteLink: respondData.data.url });
        }
      })
      .catch((err) => {
        console.log('something went wrong', err);
      });
  };

  render() {
    return (
      <Container className={this.props.classes.tableContainer}>
        <Grid container direction="row" justifyContent="space-evenly">
          <ReactHelmet title="Home" />
          {this.state.incompleteLink && (
            <Alert className={this.props.classes.alertPopup} severity="info">
              Your appointment request was incomplete. Click{' '}
              <Link href={this.state.incompleteLink} style={{ cursor: 'pointer' }}>
                here
              </Link>{' '}
              to search for a suitable doctor.
            </Alert>
          )}
          {this.state.actionMessage && (
            <Alert
              className={this.props.classes.alertPopup}
              severity={this.state.errorAction ? 'error' : 'success'}
            >
              {this.state.actionMessage}
            </Alert>
          )}
          {this.state.errorFilter && (
            <Alert className={this.props.classes.alertPopup} severity="error">
              Failed to filter.
            </Alert>
          )}
          <Paper className={this.props.classes.paper}>
            <Grid item xs={12} style={{ display: 'grid', justifyContent: 'left' }}>
              <Typography className={this.props.classes.pageTitle} id="title">
                My Inbox
              </Typography>
              <div className={this.props.classes.mobileViewControl}>
                <div>
                  <Accordion
                    elevation={0}
                    className={
                      (this.state.selectedDateStart && this.state.selectedDateEnd) ||
                      this.state.selectedDateEnd
                        ? this.props.classes.mobileViewControlExpansionPanelChanged
                        : this.props.classes.mobileViewControlExpansionPanel
                    }
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      style={{ padding: '0px 14px' }}
                    >
                      <Typography style={{ marginLeft: 10, opacity: 0.7 }}>
                        Date:{' '}
                        <b>
                          {moment(
                            this.state.selectedDateStart
                              ? this.state.selectedDateStart
                              : this.state.defaultStartDate,
                          ).format(momentDateFormat)}{' '}
                          to{' '}
                          {this.state.selectedDateEnd
                            ? moment(this.state.selectedDateEnd).format(momentDateFormat)
                            : this.state.selectedDateStart
                            ? null
                            : moment(this.state.defaultEndDate).format(momentDateFormat)}
                        </b>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ justifyContent: 'center' }}>
                      <div>
                        <div>
                          <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
                            <ThemeProvider theme={materialTheme}>
                              <DatePicker
                                inputVariant="outlined"
                                disableToolbar
                                variant="inline"
                                format={muiDateFormat}
                                margin="normal"
                                placeholder="Start Date"
                                label="From"
                                value={this.state.selectedDateStart ?? this.state.defaultStartDate}
                                onChange={this.handleDateChangeStart}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <DateRangeIcon style={{ color: '#666666' }} />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </ThemeProvider>
                          </MuiPickersUtilsProvider>
                        </div>

                        <div>
                          <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
                            <ThemeProvider theme={materialTheme}>
                              <DatePicker
                                inputVariant="outlined"
                                disableToolbar
                                variant="inline"
                                format={muiDateFormat}
                                margin="normal"
                                placeholder="End Date"
                                label="To"
                                value={
                                  !this.state.selectedDateStart && !this.state.selectedDateEnd
                                    ? this.state.defaultEndDate
                                    : this.state.selectedDateEnd
                                }
                                onChange={this.handleDateChangeEnd}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <DateRangeIcon style={{ color: '#666666' }} />
                                    </InputAdornment>
                                  ),
                                }}
                                minDate={
                                  this.state.selectedDateStart ?? this.state.defaultStartDate
                                }
                                maxDate={moment(
                                  this.state.selectedDateStart ?? this.state.defaultStartDate,
                                ).add(filterDateRangeInMonths, 'months')}
                              />
                            </ThemeProvider>
                          </MuiPickersUtilsProvider>
                        </div>

                        <Link
                          style={{ float: 'right', textDecoration: 'underline', fontSize: '15px' }}
                          component="button"
                          onClick={this.handleClearAll}
                        >
                          Reset
                        </Link>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <span style={{ marginRight: 20 }} />

                <div>
                  <Accordion
                    elevation={0}
                    className={
                      this.state.checkedStatus.length === this.state.defaultStatusList.length &&
                      this.state.defaultStatusList.every((val) =>
                        this.state.checkedStatus.includes(val),
                      )
                        ? this.props.classes.mobileViewControlExpansionPanel
                        : this.props.classes.mobileViewControlExpansionPanelChanged
                    }
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      style={{ padding: '0px 14px' }}
                    >
                      <Typography style={{ marginLeft: 10, opacity: 0.7 }}>
                        Status:{' '}
                        <b>
                          {this.state.checkedStatus.length === this.state.statusList.length
                            ? 'All'
                            : this.state.checkedStatus.length === 0
                            ? 'None'
                            : `${this.state.checkedStatus[0]} ${
                                this.state.checkedStatus.length === 1
                                  ? ''
                                  : `+${this.state.checkedStatus.length - 1} more`
                              }`}
                        </b>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div>
                        {this.state.statusList.map((status, id) => (
                          <div key={id}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    this.state[`status-${status.replace(/\s+/g, '')}`]
                                      ? true
                                      : false
                                  }
                                  onChange={this.handleStatusChange}
                                  name={status}
                                  color="primary"
                                />
                              }
                              label={status}
                            />
                          </div>
                        ))}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <span style={{ marginRight: 20 }} />
                <div>
                  <Accordion
                    elevation={0}
                    className={
                      this.state.checkedRequestType.length ===
                        this.state.defaultRequestTypeList.length &&
                      this.state.defaultRequestTypeList.every((val) =>
                        this.state.checkedRequestType.includes(val),
                      )
                        ? this.props.classes.mobileViewControlExpansionPanel
                        : this.props.classes.mobileViewControlExpansionPanelChanged
                    }
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      style={{ padding: '0px 14px' }}
                    >
                      <Typography style={{ marginLeft: 10, opacity: 0.7 }}>
                        Request Type:{' '}
                        <b>
                          {this.state.checkedRequestType.length ===
                          this.state.requestTypeList.length
                            ? 'All'
                            : this.state.checkedRequestType.length === 0
                            ? 'None'
                            : `${this.state.checkedRequestType[0]} ${
                                this.state.checkedRequestType.length === 1
                                  ? ''
                                  : `+${this.state.checkedRequestType.length - 1} more`
                              }`}
                        </b>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div>
                        {this.state.requestTypeList.map((request, id) => (
                          <div key={id}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    this.state[`request-${request.replace(/\s+/g, '')}`]
                                      ? true
                                      : false
                                  }
                                  onChange={this.handleRequestTypeChange}
                                  name={request}
                                  color="primary"
                                />
                              }
                              label={request}
                            />
                          </div>
                        ))}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </Grid>

            <TableContainer
              component={Paper}
              elevation={0}
              id="tableScroll"
              style={{ marginTop: 30, borderRadius: '0.3cm' }}
            >
              <Table className={this.props.classes.table}>
                <TableHead style={{ backgroundColor: '#FAFAFA' }}>
                  <TableRow>
                    {this.state.columnNames.map((column, id) => (
                      <TableCell
                        style={{ color: 'black', fontSize: '15px' }}
                        id="latobold"
                        key={id}
                      >
                        {column.name}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.rowData.map((rows, rId) => (
                    <TableRow key={rId}>
                      {this.state.columnCheck.map((col, cId) => {
                        if (rows[col].type === 'text') {
                          return <TableCell key={cId}>{rows[col].value}</TableCell>;
                        } else if (rows[col].type === 'json.tag') {
                          return (
                            <TableCell key={cId}>
                              {rows[col].value.map((status, sId) => {
                                if (status.type === 'form') {
                                  return (
                                    <React.Fragment key={`status-${sId}`}>
                                      <Link
                                        style={{ cursor: 'pointer' }}
                                        onClick={() =>
                                          this.handleAction(
                                            status.attribute.method,
                                            status.attribute.action,
                                            status.label,
                                            rows.requestId,
                                          )
                                        }
                                      >
                                        {status.label}
                                      </Link>
                                      {sId !== rows[col].value.length - 1 && <span> / </span>}
                                    </React.Fragment>
                                  );
                                } else {
                                  return (
                                    <React.Fragment key={`status-${sId}`}>
                                      <Link
                                        style={{ cursor: 'pointer' }}
                                        onClick={() =>
                                          this.props.history.push(
                                            status.attribute.href.replaceAll('/app', ''),
                                          )
                                        }
                                      >
                                        {status.label}
                                      </Link>
                                      {sId !== rows[col].value.length - 1 && <span> / </span>}
                                    </React.Fragment>
                                  );
                                }
                              })}
                            </TableCell>
                          );
                        } else if (rows[col].type === 'milliseconds') {
                          return (
                            <TableCell key={cId}>
                              {moment.duration(rows[col].value, 'ms').humanize()}
                            </TableCell>
                          );
                        } else {
                          return <TableCell key={cId}>{rows[col].value}</TableCell>;
                        }
                      })}
                    </TableRow>
                  ))}
                  {this.state.rowData.length === 0 ? (
                    <TableRow>
                      <TableCell
                        style={{ textAlign: 'center' }}
                        colSpan={this.state.columnNames.length}
                      >
                        No results found
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>

            <Grid item xs={12} style={{ display: 'grid', justifyContent: 'right' }}>
              <TablePagination
                id="paginationScroll"
                component="div"
                count={this.state.totalSize}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                rowsPerPageOptions={worklistRowsPerPage}
                rowsPerPage={this.state.paginationLimit}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                labelRowsPerPage="Rows:"
              />
            </Grid>
          </Paper>
        </Grid>
      </Container>
    );
  }
}
export default withStyles(useStyles)(PatientHome);
