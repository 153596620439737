import { convertDateTimeForServer } from 'features/paf/utils/utils';
import { separateObject } from 'utils/utils';

const operators = {
  equal: (arg1, arg2) => Number(arg1) === Number(arg2) || String(arg1) === String(arg2),
  lessThan: (arg1, arg2) => Number(arg1) < Number(arg2),
  moreThan: (arg1, arg2) => Number(arg1) > Number(arg2),
};
const buildCallback =
  ({ type, key, value }) =>
  (formValues) => {
    const similarKey = Object.keys(formValues).find((item) => item.includes(key));
    return operators[type](formValues[similarKey], value);
  };

function cache() {
  const store = {};
  function setStore(key, val) {
    if (!store[key]) store[key] = [val];
    else store[key] = [...store[key], val];
  }
  return { store, setStore };
}

// UPDATE FIELD FUNCTIONS
const formMethods = {
  findTargetIdx: (schema, targetId) => schema.findIndex((field) => field.id === targetId),
  newField: ({ fields, update, targetId }) => {
    const idx = formMethods.findTargetIdx(fields, targetId);
    const currentField = fields[idx];
    return { ...currentField, ...update };
  },
  multiFormUpdate: (fields, updates) =>
    Object.keys(updates).reduce(
      (accumulator, attribute) => {
        const triggered = updates[attribute];
        triggered.forEach((item) => {
          const [targetId, update] = separateObject(item);
          const idx = formMethods.findTargetIdx(fields, targetId);
          const newField = formMethods.newField({
            fields: accumulator,
            update: { [attribute]: update },
            targetId,
          });
          accumulator = [...accumulator.slice(0, idx), newField, ...accumulator.slice(idx + 1)];
        });
        return accumulator;
      },
      [...fields],
    ),
};

const triggerMethods = {
  build: (fields) => {
    const { store, setStore } = cache();
    const addToStore = (props) => (key) => setStore(key, props);

    function buildFromChildren(nodes) {
      nodes.forEach((node) => {
        if (node.children) buildFromChildren(node.children);
        const { dependencies } = node;
        if (!dependencies) return;

        const types = Object.keys(dependencies);
        types.forEach((type) => {
          const { triggers, condition } = dependencies[type];
          const triggerProps = { key: node.id, condition, type };
          triggers.forEach(addToStore(triggerProps));
        });
      });
    }
    buildFromChildren(fields);
    checkForCyclicalDependency(store);
    return store;
  },
  applyTriggers: ({ fields, targetId, triggers }) => {
    let rootFields = [...fields];
    function cascadeTriggers(children) {
      for (const childTriggers of children) {
        const grandchildren = [];
        const { store: updates, setStore: setUpdates } = cache();

        for (const childTrigger of childTriggers) {
          const { key, condition, type } = childTrigger;
          const callback = buildCallback(condition);

          if (triggers[key]) grandchildren.push(triggers[key]);
          const formValues = rootFields.reduce((acc, field) => {
            const isVisible = !(field.isVisible !== undefined && !field.isVisible);
            acc[field.id] = isVisible ? field.value : '';
            return acc;
          }, {});

          setUpdates(type, { [key]: callback(formValues) });
        }
        rootFields = formMethods.multiFormUpdate(rootFields, updates); // updates store

        if (!!grandchildren.length) cascadeTriggers(grandchildren); // cascades triggers to next level
      }
    }
    cascadeTriggers([triggers[targetId]]); // starting point

    return rootFields;
  },
};

function checkForCyclicalDependency(triggers) {
  const triggerList = Object.keys(triggers).map((key) => ({ key }));
  const max = triggerList.length; // trigger dependencies cannot be longer than length of array
  function recursiveCheck(len) {
    return ({ key }) => {
      if (len > max)
        throw new Error(
          `Trigger List: "${JSON.stringify(triggers)}"- Cyclical dependencies detected`,
        );
      const cascading = triggers[key];
      if (!cascading) return; // stops at the bottom level of a tree
      return cascading.forEach(recursiveCheck(len + 1));
    };
  }
  triggerList.forEach(recursiveCheck(0));
}
function prepValuesForSubmission(val, type) {
  switch (type) {
    case 'date':
      return convertDateTimeForServer(val);
    case 'radio':
      return val;
    case 'diagnosisCode':
    case 'procedureCode':
      if (!val.code) return '';
      return `${val.code}: ${val.description}`;
    default:
      return val;
  }
}

export { formMethods, triggerMethods, prepValuesForSubmission };
