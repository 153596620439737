import { DynamicForm } from '../../../../components/form/DynamicForm';
import React, { useEffect } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { buildFormModel } from '../../utils/utils';
import { FWD } from 'features/common/config';

export const defaultConfig = [
  {
    section: '',
    fields: [
      {
        inputName: 'referralDoctorName',
        isRequired: true,
        value: '',
        uiLabel: 'Doctor’s Name',
        name: 'referralDoctorName',
        type: 'textField',
        props: {},
      },
      {
        inputName: 'referralDoctorClinicDetails',
        isRequired: true,
        value: '',
        uiLabel: 'Clinic',
        name: 'referralDoctorClinicDetails',
        type: 'textField',
        props: {},
      },

      {
        inputName: 'referralDoctorPhone',
        isRequired: true,
        value: '',
        uiLabel: 'Phone number',
        name: 'referralDoctorPhone',
        type: 'phoneNumber',
      },
    ],
  },
];

const extraField = {
  inputName: 'dateTimeOfReferral',
  isRequired: true,
  value: '',
  uiLabel: 'Date/Time of Referral',
  name: 'dateTimeOfReferral',
  type: 'datetime',
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
  },
  form: {
    width: '100%',
    padding: '32px 54px',
    border: 'solid 1px rgba(0, 0, 0, 0.54)',
    borderRadius: '16px',
  },
}));

export const DetailOfReferral = ({ formModel, setFormModel, payorName }) => {
  const classes = useStyles();

  const handleDoctorModel = (value) => {
    const temp = {
      ...formModel,
      ...value,
    };
    setFormModel(temp);
  };

  function withExtraField(schema) {
    if (payorName !== FWD) return schema;
    schema.forEach((section) => {
      const dateTimeOfReferralId = section.fields.findIndex(
        (item) => item.name === 'dateTimeOfReferral',
      );
      dateTimeOfReferralId === -1 && section.fields.splice(0, 0, extraField);
    });
    return schema;
  }

  useEffect(() => {
    handleDoctorModel(buildFormModel(defaultConfig));
  }, []);
  return (
    <Grid className={classes.container}>
      <h3>Please provide details of the referral doctor</h3>
      <div className={classes.form}>
        <DynamicForm
          formConfig={withExtraField(defaultConfig)}
          formModel={formModel}
          setFormModel={handleDoctorModel}
        />
      </div>
    </Grid>
  );
};
