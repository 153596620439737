import React from 'react';
import {
  Grid,
  withStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Paper,
  Checkbox,
  InputAdornment,
  Link,
  createTheme,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';
import { ThemeProvider } from '@material-ui/styles';
import { get, post } from '@services/http_services/http_services';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SortIcon from '@material-ui/icons/Sort';
import moment from 'moment';
import {
  filterDateRangeInMonths,
  arrowApiPrefix,
  bentoApiPrefix,
  getTimeZone,
  tzValue,
  dateConvert,
  getTimeZoneString,
  convertAppointmentTime,
  convertAppointmentDate,
  worklistRowsPerPage,
  defaultRowsPerPage,
  momentDateFormat,
  muiDateFormat,
} from '../../../common/config';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { ReactHelmet } from '@components/react-helmet/Helmet';
import locale from 'date-fns/locale/en-US';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const useStyles = (theme) => ({
  tableContainer: {
    flexGrow: 1,
    padding: 5,
  },
  table: {
    minWidth: 700,
  },
  customFilterButton: {
    textTransform: 'none',
    color: 'white',
    fontSize: '15px',
    backgroundColor: '#03ACEF',
    width: '30%',
    height: '45px',
    '&:hover': {
      backgroundColor: '#03ACEF',
    },
    float: 'right',
    marginTop: '20px',
  },
  mobileViewControl: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
  mobileViewControlExpansionPanel: {
    border: '1px solid #03ACEF',
    [theme.breakpoints.up('lg')]: {
      border: '1px solid #03ACEF',
      width: '370px',
    },
  },
  mobileViewControlExpansionPanelChanged: {
    border: '2px solid #03ACEF',
    [theme.breakpoints.up('lg')]: {
      border: '2px solid #03ACEF',
      width: '370px',
    },
  },
  mobileViewControlCaseDetails: {
    height: 120,
    textAlign: 'right',
    [theme.breakpoints.up('sm')]: {
      height: 50,
      textAlign: 'right',
    },
  },
  customRescheduleButton: {
    textTransform: 'none',
    color: 'white',
    fontSize: '15px',
    backgroundColor: '#03ACEF',
    '&:hover': {
      backgroundColor: '#03ACEF',
    },
    marginLeft: '10px',
  },
  errorMessage: {
    marginBottom: theme.spacing(1),
    width: '100%',
  },
});

const materialTheme = createTheme({
  overrides: {
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: '#03ACEF',
        color: 'white',
        marginTop: '0',
        marginBottom: '30px',
      },
      iconButton: {
        backgroundColor: '#03ACEF',
        color: 'white',
      },
      dayLabel: {
        color: 'black',
        fontWeight: 'bold',
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#03ACEF',
        '&:hover': {
          backgroundColor: '#03ACEF',
        },
      },
      current: {
        color: 'black',
        textDecorationLine: 'underline',
      },
      hidden: {
        color: '#757575',
        opacity: 0.4,
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: '0.3cm',
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        justifyContent: 'unset',
      },
    },

    MuiOutlinedInput: {
      root: {
        '& fieldset': {
          borderColor: '#03ACEF',
        },
        '&:hover fieldset': {
          borderColor: '#03ACEF !important',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#03ACEF !important',
        },
        '&.Mui-disabled fieldset': {
          borderColor: '#03ACEF !important',
        },
        '&.Mui-error fieldset': {
          borderColor: 'red !important',
        },
        backgroundColor: 'white',
        marginBottom: 10,
        height: '50px',
      },
    },
    MuiInputLabel: {
      outlined: {
        color: '#03ACEF !important',
        '&.Mui-error': {
          color: 'red !important',
        },
      },
    },
  },
});

class LocalizedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, 'MMM yyyy').toUpperCase();
  }
  getWeekdays() {
    return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  }
}

if (locale && locale.options) {
  locale.options.weekStartsOn = 1;
}

class DoctorHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnNames: [],
      rowData: [],
      columnCheck: [],
      page: 0,
      checkedStatus: [],
      checkedRequestType: [],
      statusList: [],
      requestTypeList: [],
      defaultStartDate: null,
      defaultEndDate: null,
      defaultStatusList: [],
      defaultRequestTypeList: [],
      selectedDateStart: null,
      selectedDateEnd: null,
      totalSize: 0,
      pageSend: 0,
      paginationLimit: defaultRowsPerPage,
      errorFilter: false,
      errorCaseDetails: false,
    };
    this.state.statusList.forEach((status) => {
      this.setState({
        [status.replace(/\s+/g, '')]: false,
      });
    });
    this.state.requestTypeList.forEach((request) => {
      this.setState({
        [request.replace(/\s+/g, '')]: false,
      });
    });
  }

  async componentDidMount() {
    await this.initLandingData();
    window.localStorage.setItem('providerId', this.props.match.params.providerId);
  }

  initLandingData = async () => {
    const pageFirstLoad = {
      limit: this.state.paginationLimit,
    };

    const respondData = await post(
      `${arrowApiPrefix}/worklist/clients/${cookies.get('roleId')}/${cookies.get('userId')}`,
      pageFirstLoad,
    );

    if (respondData.code && respondData.data !== null) {
      if (
        Array.isArray(respondData.data.dataTable.columns) &&
        respondData.data.dataTable.columns.length > 0
      ) {
        //columnNames for table header
        const columns = respondData.data.dataTable.columns.map((column) => {
          return {
            name: column,
          };
        });
        this.setState({ columnNames: columns });

        //columnCheck for storing table header with no spaces
        const columnCheck = respondData.data.dataTable.columns.map((column) => {
          return column.replace(/\s+/g, '');
        });
        this.setState({ columnCheck: columnCheck });
      }
      //get table data
      if (
        Array.isArray(respondData.data.dataTable.data) &&
        respondData.data.dataTable.data.length > 0
      ) {
        var results = [];
        respondData.data.dataTable.data.forEach((row) => {
          const newRow = {};
          for (var propertyName in row) {
            var newPropertyName = propertyName.replace(/\s+/g, '');
            newRow[newPropertyName] = row[propertyName];
          }
          results.push(newRow);
        });
        this.setState({ rowData: results });
      }

      //get status filter list for display
      if (
        respondData.data.filters.options.status.value.length > 0 &&
        this.state.statusList.length === 0
      ) {
        let statuslist = [...this.state.statusList];
        respondData.data.filters.options.status.value.forEach((status) => {
          statuslist.push(status);
        });
        this.setState({ statusList: statuslist });
      }
      //get request type filter list for display
      if (
        respondData.data.filters.options.requestType.value.length > 0 &&
        this.state.requestTypeList.length === 0
      ) {
        let requestlist = [...this.state.requestTypeList];
        respondData.data.filters.options.requestType.value.forEach((request) => {
          requestlist.push(request);
        });
        this.setState({ requestTypeList: requestlist });
      }

      //get default status
      if (
        respondData.data.filters.selectedOptions.status.value.length > 0 &&
        this.state.defaultStatusList.length === 0
      ) {
        let defaultStatus = [...this.state.defaultStatusList];
        let checkedstatuslist = [...this.state.checkedStatus];
        respondData.data.filters.selectedOptions.status.value.forEach((status) => {
          defaultStatus.push(status);
          checkedstatuslist.push(status);
          this.setState({ [status.replace(/\s+/g, '')]: true });
        });
        this.setState({ defaultStatusList: defaultStatus, checkedStatus: checkedstatuslist });
      }
      //get default request type
      if (
        respondData.data.filters.selectedOptions.requestType.value.length > 0 &&
        this.state.defaultRequestTypeList.length === 0
      ) {
        let defaultRequest = [...this.state.defaultRequestTypeList];
        let checkedrequestlist = [...this.state.checkedRequestType];
        respondData.data.filters.selectedOptions.requestType.value.forEach((request) => {
          defaultRequest.push(request);
          checkedrequestlist.push(request);
          this.setState({ [request.replace(/\s+/g, '')]: true });
        });
        this.setState({
          defaultRequestTypeList: defaultRequest,
          checkedRequestType: checkedrequestlist,
        });
      }

      //get default start date
      if (respondData.data.filters.options.startDate.value && !this.state.defaultStartDate) {
        this.setState({ defaultStartDate: respondData.data.filters.options.startDate.value });
      }
      //get default end date
      if (respondData.data.filters.options.endDate.value && !this.state.defaultEndDate) {
        this.setState({ defaultEndDate: respondData.data.filters.options.endDate.value });
      }
      //get total size
      this.setState({ totalSize: respondData.data.totalSize });
    }
  };

  onSubmitFilter = (selectedStartDate, selectedEndDate, selectedStatus, selectedRequestType) => {
    this.setState({ page: 0 });

    const filter = {
      limit: this.state.paginationLimit,
      startDate: selectedStartDate
        ? moment(selectedStartDate).format('YYYY-MM-DD')
        : moment(this.state.defaultStartDate).format('YYYY-MM-DD'),
      endDate: selectedEndDate
        ? moment(selectedEndDate).format('YYYY-MM-DD')
        : moment(this.state.defaultEndDate).format('YYYY-MM-DD'),
      status: selectedStatus,
      requestType: selectedRequestType,
    };

    post(
      `${arrowApiPrefix}/worklist/clients/${cookies.get('roleId')}/${cookies.get('userId')}/filter`,
      filter,
    )
      .then((respondData) => {
        if (respondData.code && respondData.data !== null) {
          if (Array.isArray(respondData.data.dataTable.data)) {
            var results = [];
            respondData.data.dataTable.data.forEach((row) => {
              const newRow = {};
              for (var propertyName in row) {
                var newPropertyName = propertyName.replace(/\s+/g, '');
                newRow[newPropertyName] = row[propertyName];
              }
              results.push(newRow);
            });
            this.setState({ rowData: results });
          }
          this.setState({ totalSize: respondData.data.totalSize });
        }
      })
      .catch((err) =>
        //fail
        {
          console.log('something went wrong', err);
          this.setState({ errorFilter: true });
        },
      );
  };

  /**
   * Capture User actions and update the state data
   * */

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });

    const paginationData = {
      limit: this.state.paginationLimit,
      action: this.state.page < newPage ? 'newer' : 'older',
      created:
        this.state.page < newPage
          ? this.state.rowData[this.state.paginationLimit - 1].created
          : this.state.rowData[0].created,
      page: newPage + 1,
      startDate: this.state.selectedDateStart
        ? moment(this.state.selectedDateStart).format('YYYY-MM-DD')
        : moment(this.state.defaultStartDate).format('YYYY-MM-DD'),
      endDate: this.state.selectedDateEnd
        ? moment(this.state.selectedDateEnd).format('YYYY-MM-DD')
        : moment(this.state.defaultEndDate).format('YYYY-MM-DD'),
      status: this.state.checkedStatus,
      requestType: this.state.checkedRequestType,
    };

    post(
      `${arrowApiPrefix}/worklist/clients/${cookies.get('roleId')}/${cookies.get('userId')}`,
      paginationData,
    )
      .then((respondData) => {
        if (respondData.code && respondData.data !== null) {
          if (Array.isArray(respondData.data.dataTable.data)) {
            var results = [];
            respondData.data.dataTable.data.forEach((row) => {
              const newRow = {};
              for (var propertyName in row) {
                var newPropertyName = propertyName.replace(/\s+/g, '');
                newRow[newPropertyName] = row[propertyName];
              }
              results.push(newRow);
            });
            this.setState({ rowData: results });
          }
          this.setState({ totalSize: respondData.data.totalSize });

          this.setState({ pageSend: respondData.data.page });
        }
      })
      .catch((err) =>
        //fail
        console.log('something went wrong', err),
      );
  };

  //status checkbox for filter
  handleStatusChange = (event) => {
    let checked = [...this.state.checkedStatus];
    const index = checked.indexOf(event.target.name);
    if (event.target.checked) {
      checked.push(event.target.name);
      this.setState({ checkedStatus: checked, [event.target.name.replace(/\s+/g, '')]: true });
    } else {
      checked.splice(index, 1);
      this.setState({ checkedStatus: checked, [event.target.name.replace(/\s+/g, '')]: false });
    }

    this.onSubmitFilter(
      this.state.selectedDateStart,
      this.state.selectedDateEnd,
      checked,
      this.state.checkedRequestType,
    );
  };

  //request type checkbox for filter
  handleRequestTypeChange = (event) => {
    let checked = [...this.state.checkedRequestType];
    const index = checked.indexOf(event.target.name);
    if (event.target.checked) {
      checked.push(event.target.name);
      this.setState({ checkedRequestType: checked, [event.target.name.replace(/\s+/g, '')]: true });
    } else {
      checked.splice(index, 1);
      this.setState({
        checkedRequestType: checked,
        [event.target.name.replace(/\s+/g, '')]: false,
      });
    }

    this.onSubmitFilter(
      this.state.selectedDateStart,
      this.state.selectedDateEnd,
      this.state.checkedStatus,
      checked,
    );
  };

  //from and to dates for filter
  handleDateChangeStart = (date) => {
    this.setState({ selectedDateStart: new Date(date), selectedDateEnd: null });
  };

  handleDateChangeEnd = (date) => {
    this.setState({ selectedDateEnd: new Date(date) });

    this.onSubmitFilter(
      this.state.selectedDateStart,
      new Date(date),
      this.state.checkedStatus,
      this.state.checkedRequestType,
    );
  };

  handleClearAll = () => {
    this.setState({ selectedDateStart: null, selectedDateEnd: null, page: 0 });
    this.onSubmitFilter(null, null, this.state.checkedStatus, this.state.checkedRequestType);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ paginationLimit: event.target.value, page: 0 });

    const paginationLimitData = {
      limit: event.target.value,
      startDate: this.state.selectedDateStart
        ? moment(this.state.selectedDateStart).format('YYYY-MM-DD')
        : moment(this.state.defaultStartDate).format('YYYY-MM-DD'),
      endDate: this.state.selectedDateEnd
        ? moment(this.state.selectedDateEnd).format('YYYY-MM-DD')
        : moment(this.state.defaultEndDate).format('YYYY-MM-DD'),
      status: this.state.checkedStatus,
      requestType: this.state.checkedRequestType,
    };

    post(
      `${arrowApiPrefix}/worklist/clients/${cookies.get('roleId')}/${cookies.get('userId')}`,
      paginationLimitData,
    )
      .then((respondData) => {
        if (respondData.code && respondData.data !== null) {
          if (Array.isArray(respondData.data.dataTable.data)) {
            var results = [];
            respondData.data.dataTable.data.forEach((row) => {
              const newRow = {};
              for (var propertyName in row) {
                var newPropertyName = propertyName.replace(/\s+/g, '');
                newRow[newPropertyName] = row[propertyName];
              }
              results.push(newRow);
            });
            this.setState({ rowData: results });
          }
          this.setState({ totalSize: respondData.data.totalSize });

          this.setState({ pageSend: respondData.data.page });
        }
      })
      .catch((err) =>
        //fail
        console.log('something went wrong', err),
      );
  };

  caseDetails = (api) => {
    get(api)
      .then((respondData) => {
        if (respondData.code && respondData.data) {
          if (Array.isArray(respondData.data.data.items)) {
            respondData.data.data.items.map((item) => {
              this.props.history.push(`/view/${item.requestType}/${item.referenceNo}`);
            });
          }
        }
      })
      .catch((err) =>
        //fail
        {
          console.log('something went wrong', err);
          this.setState({ errorCaseDetails: true });
        },
      );
  };

  render() {
    return (
      <React.Fragment>
        <ReactHelmet title="Home" />
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          className={this.props.classes.tableContainer}
        >
          {this.state.errorFilter && (
            <Alert className={this.props.classes.errorMessage} severity="error">
              Failed to filter.
            </Alert>
          )}
          {this.state.errorCaseDetails && (
            <Alert className={this.props.classes.errorMessage} severity="error">
              Failed to display case details.
            </Alert>
          )}
          <Grid item xs={12} style={{ display: 'grid', justifyContent: 'left', marginTop: 20 }}>
            <div className={this.props.classes.mobileViewControl}>
              <div>
                <Accordion
                  elevation={0}
                  className={
                    (this.state.selectedDateStart && this.state.selectedDateEnd) ||
                    this.state.selectedDateEnd
                      ? this.props.classes.mobileViewControlExpansionPanelChanged
                      : this.props.classes.mobileViewControlExpansionPanel
                  }
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ padding: '0px 14px' }}>
                    <Typography style={{ marginLeft: 10, opacity: '0.7' }}>
                      Date:{' '}
                      <b>
                        {moment(
                          this.state.selectedDateStart
                            ? this.state.selectedDateStart
                            : this.state.defaultStartDate,
                        ).format(momentDateFormat)}{' '}
                        to{' '}
                        {this.state.selectedDateEnd
                          ? moment(this.state.selectedDateEnd).format(momentDateFormat)
                          : this.state.selectedDateStart
                          ? null
                          : moment(this.state.defaultEndDate).format(momentDateFormat)}
                      </b>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ justifyContent: 'center' }}>
                    <div>
                      <div>
                        <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
                          <ThemeProvider theme={materialTheme}>
                            <DatePicker
                              inputVariant="outlined"
                              disableToolbar
                              variant="inline"
                              format={muiDateFormat}
                              margin="normal"
                              placeholder="Start Date"
                              label="From"
                              value={
                                this.state.selectedDateStart
                                  ? this.state.selectedDateStart
                                  : this.state.defaultStartDate
                              }
                              onChange={this.handleDateChangeStart}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <DateRangeIcon style={{ color: '#03ACEF' }} />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>
                      </div>

                      <div>
                        <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
                          <ThemeProvider theme={materialTheme}>
                            <DatePicker
                              inputVariant="outlined"
                              disableToolbar
                              variant="inline"
                              format={muiDateFormat}
                              margin="normal"
                              placeholder="End Date"
                              label="To"
                              value={
                                this.state.selectedDateEnd
                                  ? this.state.selectedDateEnd
                                  : this.state.selectedDateStart
                                  ? this.state.selectedDateEnd
                                  : this.state.defaultEndDate
                              }
                              onChange={this.handleDateChangeEnd}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <DateRangeIcon style={{ color: '#03ACEF' }} />
                                  </InputAdornment>
                                ),
                              }}
                              minDate={
                                this.state.selectedDateStart
                                  ? this.state.selectedDateStart
                                  : this.state.defaultStartDate
                              }
                              maxDate={moment(
                                this.state.selectedDateStart
                                  ? this.state.selectedDateStart
                                  : this.state.defaultStartDate,
                              ).add(filterDateRangeInMonths, 'months')}
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>
                      </div>

                      <Link
                        style={{ float: 'right', textDecoration: 'underline', fontSize: '15px' }}
                        component="button"
                        onClick={this.handleClearAll}
                      >
                        Reset
                      </Link>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <span style={{ marginRight: 20 }} />

              <div>
                <Accordion
                  elevation={0}
                  className={
                    this.state.checkedStatus.length === this.state.defaultStatusList.length &&
                    this.state.defaultStatusList.every((val) =>
                      this.state.checkedStatus.includes(val),
                    )
                      ? this.props.classes.mobileViewControlExpansionPanel
                      : this.props.classes.mobileViewControlExpansionPanelChanged
                  }
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ padding: '0px 14px' }}>
                    <Typography style={{ marginLeft: 10, opacity: 0.7 }}>
                      Status:{' '}
                      <b>
                        {this.state.checkedStatus.length === this.state.statusList.length
                          ? 'All'
                          : this.state.checkedStatus.length === 0
                          ? 'None'
                          : `${this.state.checkedStatus[0]} ${
                              this.state.checkedStatus.length === 1
                                ? ''
                                : `+${this.state.checkedStatus.length - 1} more`
                            }`}
                      </b>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      {this.state.statusList.map((status, id) => (
                        <div key={id}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state[status.replace(/\s+/g, '')] ? true : false}
                                onChange={this.handleStatusChange}
                                name={status}
                                color="primary"
                              />
                            }
                            label={status}
                          />
                        </div>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>

              <span style={{ marginRight: 20 }} />
              <div>
                <Accordion
                  elevation={0}
                  className={
                    this.state.checkedRequestType.length ===
                      this.state.defaultRequestTypeList.length &&
                    this.state.defaultRequestTypeList.every((val) =>
                      this.state.checkedRequestType.includes(val),
                    )
                      ? this.props.classes.mobileViewControlExpansionPanel
                      : this.props.classes.mobileViewControlExpansionPanelChanged
                  }
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ padding: '0px 14px' }}>
                    <Typography style={{ marginLeft: 10, opacity: 0.7 }}>
                      Request Type:{' '}
                      <b>
                        {this.state.checkedRequestType.length === this.state.requestTypeList.length
                          ? 'All'
                          : this.state.checkedRequestType.length === 0
                          ? 'None'
                          : `${this.state.checkedRequestType[0]} ${
                              this.state.checkedRequestType.length === 1
                                ? ''
                                : `+${this.state.checkedRequestType.length - 1} more`
                            }`}
                      </b>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      {this.state.requestTypeList.map((request, id) => (
                        <div key={id}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state[request.replace(/\s+/g, '')] ? true : false}
                                onChange={this.handleRequestTypeChange}
                                name={request}
                                color="primary"
                              />
                            }
                            label={request}
                          />
                        </div>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </Grid>

          <TableContainer
            component={Paper}
            elevation={0}
            style={{ marginTop: 30, borderRadius: '0.3cm' }}
          >
            <Table className={this.props.classes.table}>
              <TableHead style={{ backgroundColor: '#03ACEF' }}>
                <TableRow>
                  {this.state.columnNames.map((column, id) => (
                    <TableCell style={{ color: 'white', fontSize: '15px' }} key={id}>
                      {column.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.rowData.map((rows, rId) => (
                  <TableRow key={rId}>
                    {this.state.columnCheck.map((col, cId) => {
                      if (rows[col].type === 'text') {
                        return <TableCell key={cId}>{rows[col].value}</TableCell>;
                      } else if (rows[col].type === 'url') {
                        if (rows[col].contentTypeOfCalledURL === 'json') {
                          return (
                            <TableCell key={cId}>
                              <Link
                                href={rows[col].url.replaceAll(':roleId', cookies.get('roleId'))}
                              >
                                {rows[col].value}
                              </Link>
                            </TableCell>
                          );
                        } else if (
                          rows[col].contentTypeOfCalledURL === 'html' &&
                          rows[col].value === 'Pending Patient Response'
                        ) {
                          return (
                            <TableCell key={cId}>
                              <Link
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  this.caseDetails(
                                    rows[col].url.replaceAll(':roleId', cookies.get('roleId')),
                                  )
                                }
                              >
                                {rows[col].value}
                              </Link>
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell key={cId}>
                              <Link
                                href={rows[col].url.replaceAll(':roleId', cookies.get('roleId'))}
                              >
                                {rows[col].value}
                              </Link>
                            </TableCell>
                          );
                        }
                      } else if (rows[col].type === 'milliseconds') {
                        return (
                          <TableCell key={cId}>
                            {moment.duration(rows[col].value, 'ms').humanize()}
                          </TableCell>
                        );
                      } else {
                        return <TableCell key={cId}>{rows[col].value}</TableCell>;
                      }
                    })}
                  </TableRow>
                ))}
                {this.state.rowData.length === 0 ? (
                  <TableRow>
                    <TableCell
                      style={{ textAlign: 'center' }}
                      colSpan={this.state.columnNames.length}
                    >
                      No results found
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid item xs={12} style={{ display: 'grid', justifyContent: 'right' }}>
            <TablePagination
              component="div"
              count={this.state.totalSize}
              page={this.state.page}
              onChangePage={this.handleChangePage}
              rowsPerPageOptions={worklistRowsPerPage}
              rowsPerPage={this.state.paginationLimit}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              labelRowsPerPage="Rows:"
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
export default withStyles(useStyles)(DoctorHome);
