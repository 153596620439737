import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { validateEmail } from '../../../utils/utils.js';
import { InputTitle } from './InputTitle.jsx';
import { SmarterTextField } from '@components/input';

export const Email = (props) => {
  const {
    index,
    emails,
    setAsPreferred,
    isDisabled = false,
    setEmails,
    deleteEmail,
    isSubmitted,
    userDataLength,
  } = props;

  const isError =
    isSubmitted && !emails[index].value.length
      ? true
      : emails[index].value.length > 0 && validateEmail(emails[index].value).length;

  const changeEmail = (value) => {
    const newEmails = emails.map((email, eIdx) => {
      if (index === eIdx) {
        email.value = value;
      }
      return email;
    });
    setEmails(newEmails);
  };
  return (
    <Fragment key={`${index}-email`}>
      <Grid item xs={12}>
        <InputTitle
          index={index}
          items={emails}
          setAsPreferred={setAsPreferred}
          deleteItem={deleteEmail}
          type="email"
          userDataLength={userDataLength}
        />
        <label htmlFor={`email-${index}`}>E-mail*</label>
        <SmarterTextField
          value={emails[index].value}
          onChange={changeEmail}
          disabled={isDisabled}
          inputProps={{ 'data-testid': `email-${index}` }}
          error={isError}
          helperText={
            isSubmitted && emails[index].value.length === 0
              ? 'E-mail address should be filled'
              : emails[index].value.length > 0
              ? validateEmail(emails[index].value)
              : ''
          }
          id={`email-${index}`}
        />
      </Grid>
    </Fragment>
  );
};
Email.propTypes = {
  index: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
  setAsPreferred: PropTypes.func.isRequired,
  emails: PropTypes.array.isRequired,
  deleteEmail: PropTypes.func.isRequired,
  setEmails: PropTypes.func.isRequired,
  userDataLength: PropTypes.number,
};
