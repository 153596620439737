/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import { FormDropdown, Form } from '@components/form';
import { SmarterTextField } from '@components/input';
import SmarterPopover from '@components/popover';
import { useCookies, useSearchDoctorTextState, useGeographicalState } from '@hooks';
import {
  COUNTRY,
  REGION,
  CITY,
  NAME,
  SPECIALTIES,
  PROCEDURE,
  SHORT_TEXT,
  DIAGNOSIS,
} from '../utilities/constants';
import { DiagnosisSearch } from 'components/input/textfield/DiagnosisSearch';
import ProcedureCodeSearch from '../components/ProcedureCodeSearch';

const simpleSearch = (target) => (item) => item === target;
const ALLOW_COUNTRY_FOR_SEARCH = 'Malaysia';

export const SearchDoctor = ({
  locations,
  specialties,
  onSubmit,
  onCountrySelected,
  searching,
}) => {
  const classes = useStyles();
  const { clientCountry } = useCookies();
  const {
    country,
    region,
    city,
    setCountry,
    setRegion,
    setCity,
    disableConfig,
    parsedData,
    setCountryError,
  } = useGeographicalState(locations, { country: clientCountry });

  const [specialtyIdx, setSpecialtyIdx] = useState(-1);
  const [nameText, setNameText, validateNameText] = useSearchDoctorTextState('');
  const [procedureText, setProcedureText] = useSearchDoctorTextState('');
  const [diagnosisText, setDiagnosisText, validateDiagnosisText] = useSearchDoctorTextState('');
  const [isVisiblePopover, setIsVisiblePopover] = useState(false);
  const [isSearchingDiagnosis, setIsSearchingDiagnosis] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [formErrors, setFormErrors] = useState([]);
  const [isSearchProcedureCode, setIsSearchProcedureCode] = useState(false);

  const nameRef = useRef();
  const diagnosisRef = useRef();
  const procedureRef = useRef();
  const required = '*';

  function handleCountryChange(newCountry) {
    setCountry(newCountry);
    onCountrySelected(newCountry);
  }

  function handleSpecialtyChange(newSpec) {
    const newSpecialtyIdx = specialties.findIndex(simpleSearch(newSpec));
    setSpecialtyIdx(newSpecialtyIdx);
    setNameText({ error: false });
  }

  const textChangeConfig = {
    [NAME]: (value) => {
      setNameText({ value });
      setProcedureText({ error: false });
      setDiagnosisText({ error: false });
    },
    [PROCEDURE]: (value) => {
      setProcedureText({ value });
      setNameText({ error: false });
      setDiagnosisText({ error: false });
    },
    [DIAGNOSIS]: (value) => {
      setDiagnosisText({ value, label: value });
      setNameText({ error: false });
      setProcedureText({ error: false });
    },
  };

  const validationConfig = {
    [SHORT_TEXT]: (name) => (value) =>
      value.length < 3 ? `Search phrase for a ${name} is too short.` : '',
  };

  function handleSubmit() {
    function blurTextfields() {
      nameRef.current.blur();
      procedureRef?.current?.blur();
      diagnosisRef?.current?.blur();
    }
    blurTextfields();

    const validatedErrors = [];
    function validateSubmission() {
      function handleTextError() {
        switch (true) {
          case !!nameText.value:
            const nameErrors = validateNameText(validationConfig[SHORT_TEXT]("doctor's name"));
            validatedErrors.push(nameErrors);
            break;
          case specialtyIdx > 0:
            break;
          default:
            break;
        }
      }
      function handleGeographicalError() {
        if (!country.value) {
          setCountryError(true);
          validatedErrors.push('Please select a country');
        }
      }
      handleTextError();
      handleGeographicalError();
    }
    validateSubmission();
    const filteredErrors = validatedErrors.filter((err) => err !== '');
    setFormErrors(filteredErrors);
    if (!filteredErrors.length) {
      onSubmit({
        country: country.value,
        region,
        city,
        name: nameText.value,
        specIdx: specialtyIdx,
        diagnosis: diagnosisText.value,
        procedure: procedureText.value,
      });
    }
  }

  const textfieldDisableConfig = {
    [NAME]: diagnosisText.value !== '' || procedureText.value !== '',
    [SPECIALTIES]: !specialties.length || diagnosisText.value !== '' || procedureText.value !== '',
    [PROCEDURE]: diagnosisText.value !== '' || nameText.value !== '' || specialtyIdx > -1,
    [DIAGNOSIS]: procedureText.value !== '' || nameText.value !== '' || specialtyIdx > -1,
  };

  const onCloseSearchPopover = () => {
    setIsVisiblePopover(false);
    setAnchorEl(null);
  };

  const handleSetDiagnosis = ({ code = '', description = '' }) => {
    setDiagnosisText({ value: code, label: description, error: false });
  };

  const handleSetProcedureCode = (code, description) => {
    setProcedureText({ value: code, label: description, error: false });
    onCloseSearchPopover();
    setIsSearchProcedureCode(false);
  };

  const handleSearchProcedureCode = (event) => {
    if (event) {
      setIsVisiblePopover(true);
      setAnchorEl(event.currentTarget);
      setIsSearchProcedureCode(true);
    }
  };

  useEffect(() => {
    onCountrySelected(clientCountry);
  }, [clientCountry]);

  return (
    <Form
      submitButtonText="Search"
      helmet="Find A Doctor"
      title="Find A Doctor/Make An Appointment"
      errors={formErrors}
      submit={handleSubmit}
      submitting={searching}
      disabledSubmit={isSearchingDiagnosis || !region}
      submitOnKeyPressEnter={false}
    >
      <Grid container spacing={3} style={{ marginTop: '44px' }}>
        <Grid item xs={12} sm={12} md={4}>
          <FormDropdown
            id="country"
            label="Select Your Location *"
            formControlStyles={clsx(classes.formControl, classes.dropdown)}
            innerLabel={`Countries${required}`}
            state={country?.value}
            data={parsedData(COUNTRY)}
            handleChange={handleCountryChange}
            error={country?.error}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <FormDropdown
            id="region-state"
            innerLabel="Regions/States"
            state={region}
            data={parsedData(REGION)}
            handleChange={setRegion}
            defaultState="Select Region/State"
            disabled={disableConfig[REGION]}
            formControlStyles={clsx(classes.formControl, classes.dropdown)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <FormDropdown
            id="city"
            innerLabel="Cities"
            state={city}
            data={parsedData(CITY)}
            handleChange={setCity}
            defaultState="All Cities"
            disabled={disableConfig[CITY]}
            formControlStyles={clsx(classes.formControl, classes.dropdown)}
          />
        </Grid>
      </Grid>
      <Typography style={{ marginBottom: '10px', marginTop: '15px' }}>
        Search By (Optional)
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6}>
          {/* SEARCH BY NAME */}
          <SmarterTextField
            variant="outlined"
            placeholder="Enter Doctor's Name"
            value={nameText.value}
            onChange={textChangeConfig[NAME]}
            error={nameText.error}
            disabled={textfieldDisableConfig[NAME]}
            inputRef={nameRef}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormDropdown
            id="specialties"
            innerLabel="Specialties"
            state={specialtyIdx > -1 ? specialties[specialtyIdx] : null}
            data={specialties}
            handleChange={handleSpecialtyChange}
            defaultState="All Specialties"
            disabled={textfieldDisableConfig[SPECIALTIES]}
            style={{ marginTop: '8px' }}
          />
        </Grid>
      </Grid>
      {country && country.value === ALLOW_COUNTRY_FOR_SEARCH && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              {/* SEARCH BY DIAGNOSIS */}
              <Typography className={classes.alternativeTextTitle}>Or</Typography>
              <DiagnosisSearch
                value={{ ...diagnosisText, description: diagnosisText.label }}
                onChange={handleSetDiagnosis}
                isSearching={isSearchingDiagnosis}
                onSearching={setIsSearchingDiagnosis}
                disabled={textfieldDisableConfig[DIAGNOSIS]}
                isFullDiagnosisCodes={false}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              {/* SEARCH BY PROCEDURE */}
              <Typography className={classes.alternativeTextTitle}>Or</Typography>
              <SmarterTextField
                placeholder="Select Procedure"
                value={procedureText.label}
                onChange={textChangeConfig[PROCEDURE]}
                error={procedureText.error ? procedureText.error : undefined}
                disabled={textfieldDisableConfig[PROCEDURE]}
                inputRef={procedureRef}
                onClick={handleSearchProcedureCode}
              />
            </Grid>
          </Grid>
          <SmarterPopover
            id={isVisiblePopover ? 'simple-popover' : undefined}
            open={isVisiblePopover}
            anchorEl={anchorEl}
            onClose={onCloseSearchPopover}
          >
            {isSearchProcedureCode && (
              <ProcedureCodeSearch
                valueSearch={procedureText.value}
                setValueSearch={handleSetProcedureCode}
              />
            )}
          </SmarterPopover>
        </>
      )}
    </Form>
  );
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
  },
  dropdown: {
    textOverflow: 'ellipsis',
  },
  tightFormControl: { marginBottom: theme.spacing(2) },
  alternativeTextTitle: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: '4px',
  },
}));
