import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Link,
  MenuItem,
  Select,
  styled,
  Typography,
} from '@material-ui/core';
import { SmarterTextField } from '@components/input';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';
import { STATUS_LIST } from '../../../utils/constant';
import { LoadingButton } from '@components/button';
import Cookies from 'universal-cookie';
import { FormDropdown } from '../../../../../components/form';
import { momentDateFormat, muiDateFormat } from '../../../../common/config';
import locale from 'date-fns/locale/en-US';

class LocalizedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, 'MMM yyyy').toUpperCase();
  }
  getWeekdays() {
    return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  }
}

if (locale && locale.options) {
  locale.options.weekStartsOn = 1;
}

const useStyles = makeStyles((theme) => ({
  accordion: {
    border: '1px solid #03ACEF',
  },
  textfield: {
    '& .MuiInputBase-input': {
      padding: '15.5px 14px',
    },
    '& .MuiOutlinedInput-root': {
      // - The Input-root, inside the TextField-root
      '& fieldset': {
        // - The <fieldset> inside the Input-root
        borderColor: '#03ACEF', // - Set the Input border
      },
      '&:hover fieldset': {
        borderColor: '#03ACEF', // - Set the Input border when parent has :hover
      },
      '&.Mui-focused fieldset': {
        // - Set the Input border when parent is focused
        borderColor: '#03ACEF',
      },
    },
  },
  datepicker: {
    '& .MuiInputLabel-root': {
      color: '#03ACEF',
    },
    '& .MuiOutlinedInput-root': {
      // - The Input-root, inside the TextField-root
      '& fieldset': {
        // - The <fieldset> inside the Input-root
        borderColor: '#03ACEF', // - Set the Input border
      },
      '&:hover fieldset': {
        borderColor: '#03ACEF', // - Set the Input border when parent has :hover
      },
      '&.Mui-focused fieldset': {
        // - Set the Input border when parent is focused
        borderColor: '#03ACEF',
      },
    },
  },
  wrapperDate: {
    display: 'flex',
    flexDirection: 'column',
  },
  customOutline: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#03ACEF',
    },
    '& .MuiOutlinedInput-input': {
      padding: '15.5px 14px',
    },
    marginTop: '8px',
  },
}));

export const FilterBar = ({ togglePAFForm, filter, setFilter, handleFilter }) => {
  const classes = useStyles();

  const updateFilter = (params, value) => {
    const temp = { ...filter, [params]: value };
    setFilter(temp);
  };

  const cookies = new Cookies();
  const isSpecialist = cookies.get('roleId') === 'Specialist';

  const startDate = (date) => moment(date).startOf('day');
  const endDate = (date) => (date ? moment(date).endOf('day') : moment().add(60, 'd').endOf('day'));

  const onChangeStartDate = (date) => {
    const temp = { ...filter, startDate: startDate(date), endDate: null };
    setFilter(temp);
  };

  const onChangeEndDate = (date) => {
    const temp = { ...filter, endDate: endDate(date) };
    setFilter(temp);
  };

  const resetCalendar = () => {
    const temp = { ...filter, startDate: startDate(), endDate: endDate() };
    setFilter(temp);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <SmarterTextField
          classes={{ root: classes.textfield }}
          placeholder={isSpecialist ? "Patient's Identication No" : "Patient's Name"}
          value={filter.searchText}
          onChange={(value) => updateFilter('searchText', value)}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Accordion elevation={0} classes={{ root: classes.accordion }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography style={{ opacity: 0.7 }}>
              Date:{' '}
              <b>{`${filter.startDate?.format(momentDateFormat)} to ${
                filter.endDate ? filter.endDate?.format(momentDateFormat) : ''
              }`}</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.wrapperDate}>
              <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
                <DatePicker
                  inputVariant="outlined"
                  disableToolbar
                  variant="inline"
                  format={muiDateFormat}
                  margin="normal"
                  placeholder="Start Date"
                  label="From"
                  value={filter.startDate}
                  className={classes.datepicker}
                  onChange={(value) => onChangeStartDate(value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DateRangeIcon style={{ color: '#666666' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
                <DatePicker
                  inputVariant="outlined"
                  disableToolbar
                  variant="inline"
                  format={muiDateFormat}
                  margin="normal"
                  placeholder="End Date"
                  label="To"
                  value={filter.endDate}
                  onChange={(value) => onChangeEndDate(value)}
                  className={classes.datepicker}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DateRangeIcon style={{ color: '#666666' }} />
                      </InputAdornment>
                    ),
                  }}
                  minDate={filter.startDate}
                />
              </MuiPickersUtilsProvider>
              <div>
                <Link
                  style={{ float: 'right', textDecoration: 'underline', fontSize: '15px' }}
                  component="button"
                  onClick={resetCalendar}
                >
                  Reset
                </Link>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormDropdown
          formControlStyles={classes.customOutline}
          state={filter.status}
          data={STATUS_LIST.map((item) => item.label)}
          handleChange={(value) => updateFilter('status', value)}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <LoadingButton
          onClick={handleFilter}
          text="Filter"
          variant="outlined"
          component="span"
          style={{ marginRight: '5px', marginBottom: '5px' }}
        />
        {isSpecialist && (
          <LoadingButton
            onClick={togglePAFForm}
            text="New"
            variant="outlined"
            component="span"
            style={{ marginBottom: '5px' }}
          />
        )}
      </Grid>
    </Grid>
  );
};
