import React from 'react';
import { SvgIcon, Collapse } from '@material-ui/core';

export const CardInformationList = ({
  icon,
  text,
  className = {},
  textClassName,
  list = [],
  listItemClassName = {},
  dots = false,
  leftBorder = false,
  secondaryList = [],
}) => (
  <div>
    <div
      className={className}
      style={{
        marginTop: leftBorder && '10px',
        marginBottom: leftBorder && '8px',
        paddingLeft: leftBorder && '8px',
        borderLeft: leftBorder && '3px solid #ccc',
      }}
    >
      <div style={{ display: 'flex' }}>
        {icon && (
          <SvgIcon style={{ fontSize: '16px' }} htmlColor="#03ACEF">
            {icon}
          </SvgIcon>
        )}
        <div className={textClassName}>{text}</div>
      </div>
      {list.map((content, index) => (
        <li
          key={`card-info-${index}`}
          className={listItemClassName}
          style={{ listStyleType: dots ? 'space-counter' : 'none' }}
        >
          {content}
        </li>
      ))}
    </div>
    <Collapse
      style={{
        marginTop: leftBorder && '-8px',
        marginBottom: leftBorder && '8px',
        paddingLeft: leftBorder && '8px',
        borderLeft: leftBorder && '3px solid #ccc',
      }}
      in={!!secondaryList.length}
      timeout={300}
    >
      {secondaryList.map((content, index) => (
        <li
          key={`card-info-${index}-secondary`}
          className={listItemClassName}
          style={{ listStyleType: dots ? 'space-counter' : 'none' }}
        >
          {content}
        </li>
      ))}
    </Collapse>
  </div>
);
