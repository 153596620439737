import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';

export const SmarterNestedErrors = ({ errors }) => {
  return (
    errors.length > 0 && (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        <ErrorMapping errors={errors} />
      </Alert>
    )
  );
};

export const SmarterErrors = ({ errors }) => {
  return (
    !_.isEmpty(errors) && (
      <div>
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errors.map((error, index) => {
            const idx = index + 1;
            return <Typography key={`search-form-error-${idx}`}>{`${idx}: ${error}`}</Typography>;
          })}
        </Alert>
      </div>
    )
  );
};

const ErrorMapping = ({ errors }) => {
  const classes = useStyles();
  return errors.map((item, index) => {
    const idx = index + 1;
    const key = `${item.name}-${index}`;
    if (!_.isArray(item.value)) return <ErrorField key={key} error={item} idx={idx} />;
    return (
      <React.Fragment key={key}>
        <Typography>
          {idx}: <b>{item.name}</b>
        </Typography>
        <div className={classes.indent}>
          <ErrorMapping errors={item.value} />
        </div>
      </React.Fragment>
    );
  });
};

const ErrorField = ({ error, idx }) => {
  const { name, value } = error;
  return (
    <Typography key={`error-${idx} `}>
      {idx}: <b>{name}</b> {value}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  indent: { marginLeft: theme.spacing(2) },
}));
