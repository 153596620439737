import React from "react";
import PropTypes from "prop-types";
import styles from "./list_dialog.css";
import {Typography, Dialog, DialogTitle, DialogContent, FormControl, FormControlLabel, RadioGroup, Radio } from "@material-ui/core";


/**
*Reusable Session card function
 **@listItems Array - list items to render the list
 **@dialogState Boolean - state to open/close dialog
 **@dialogOnClick Function - On dialog close trigger
 **@dialogTitle String - Dialog Title
 **@dialogAction Function - List Items Action trigger
 **@actionSelectedValue Any - List Item Selected Value
 ****** @listType String - List Type
**/
export default function ListDialog({data, listItems, dialogState, dialogOnClick, dialogTitle, dialogAction, actionSelectedValue, listType}) {

  /*
  * Capture user selection for Session
  **/
  const onCardSelect = (event) =>{
      dialogAction(event.target.value);
  };

  const onDialogClose = () =>{
      dialogOnClick(!dialogState);
  };

  return (
      <Dialog onClose={onDialogClose} aria-labelledby="simple-dialog-title" open={dialogState}>
          <DialogTitle id="simple-dialog-title">{dialogTitle}</DialogTitle>
          <DialogContent>
              <div style={{textAlign: 'center', padding: 5}}>
                  <Typography variant="body1" gutterBottom={true}>
                      <strong>{data.name}</strong>
                  </Typography>
                  <Typography variant="subtitle2" gutterBottom={true}>
                      <strong>{new Date().toLocaleDateString()}</strong> To <strong>{new Date().toLocaleDateString()}</strong>
                  </Typography>
              </div>
          {
              listType === "Radio" && <FormControl component="fieldset">
                  <RadioGroup aria-label="gender" name="gender1" value={actionSelectedValue} onChange={onCardSelect}>
                      {
                          listItems.map(x  => <FormControlLabel key={x} value={x} control={<Radio color="primary"/>} label={x} />)
                      }
                  </RadioGroup>
              </FormControl>
          }
          </DialogContent>
      </Dialog>
  );
}
