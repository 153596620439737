import React from 'react';
import { Grid, Divider, Typography, ListItem, Link, makeStyles } from '@material-ui/core';
import { LoadingButton } from '@components/button';
import { SmarterTextField } from '@components/input';
import { convertTimeStampToDate } from 'features/paf/utils/utils';

const buttonColor = '#03ACEF';
const backButtonColor = '#f37d2a';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  listItems: {
    paddingTop: 0,
    paddingLeft: 0,
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  downloadLink: {
    textDecoration: 'underline',
    textAlign: 'inherit',
    fontSize: 18,
  },
  textbox: {
    marginBottom: theme.spacing(2),
    width: 200,
    '& .MuiOutlinedInput-root': {
      background: 'white',
    },
    '& .MuiFormHelperText-contained': {
      color: 'red',
    },
  },
  textboxGroup: {
    display: 'grid',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  hospitalField: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  buttonGroup: {
    marginTop: 40,
    float: 'right',
  },
  contentTitle: {
    fontSize: '16px',
    textAlign: 'center',
  },
  title: {
    fontSize: '24px',
    textAlign: 'center',
    marginBottom: '32px',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 100,
      paddingRight: 100,
    },
  },
  statusList: {
    float: 'right',
    display: 'flex',
  },
}));

export const ViewPAFCaseDetails = ({
  status,
  caseInfo,
  onClickBack,
  onClickProceed,
  onTextFieldChange,
  emptyFieldError,
  currencyLabel,
}) => {
  const classes = useStyles();

  const renderProcedures = () => {
    return (
      caseInfo.procedureCodes &&
      caseInfo.procedureCodes.map((procedure, i) => (
        <ListItem className={classes.listItems} key={i} component="div">
          <Typography>{procedure}</Typography>
        </ListItem>
      ))
    );
  };

  const caseDetails = [
    {
      label: `Patient's Full Name:`,
      value: caseInfo.fullName,
    },
    {
      label: `Patient's Gender:`,
      value: caseInfo.gender,
    },
    {
      label: `Patient's Age:`,
      value: caseInfo.age,
    },
    {
      label: 'Date of Admission:',
      value: caseInfo.admissionDate && convertTimeStampToDate(caseInfo.admissionDate),
    },
    {
      label: 'Length of Stay:',
      value: `${caseInfo.lengthOfStay} Days`,
    },
    {
      label: 'Primary Diagnosis:',
      value: caseInfo.primaryDiagnosis,
    },
    {
      label: 'Treatment Type:',
      value: caseInfo.treatmentType,
    },
    {
      label: 'Ward Type:',
      value: `${caseInfo.wardType} ${
        caseInfo.wardTypeOthers ? '- ' + caseInfo.wardTypeOthers : ''
      }`,
    },
    {
      label: 'Principal Surgeon/Doctor:',
      value: caseInfo.doctorName,
    },
    {
      label: 'Procedure(s):',
      renderValue: renderProcedures,
    },
  ];

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      className={classes.tableContainer}
    >
      <ViewPAFCaseIDStatus caseId={caseInfo.caseId} status={status} onClickPDF={caseInfo.pdfLink} />
      <Grid item xs={12}>
        <Grid container direction="row" spacing={2}>
          {caseDetails.map((detail, id) => (
            <React.Fragment key={`${detail.label}-${id}`}>
              <Grid item xs={5}>
                <Typography style={{ fontWeight: 'bold' }}>{detail.label}</Typography>
              </Grid>
              <Grid item xs={7}>
                {detail.renderValue ? (
                  detail.renderValue()
                ) : (
                  <Typography>{detail.value}</Typography>
                )}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
        <div style={{ marginTop: 40, display: onTextFieldChange ? null : 'none' }}>
          <PAFHospitalCharges
            onTextFieldChange={onTextFieldChange}
            emptyFieldError={emptyFieldError}
            currencyLabel={currencyLabel}
          />
        </div>
        <div className={classes.buttonGroup}>
          <PAFCaseDetailButton
            onClickBack={onClickBack}
            onClickProceed={onClickProceed}
            textCondition={onTextFieldChange}
            displayCondition={status.includes('Action Needed')}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export const ViewPAFCaseIDStatus = ({ caseId, status, onClickPDF }) => {
  const renderStatuses = () => {
    return (
      status &&
      status.map((st, i) => (
        <ListItem className={classes.listItems} key={i} component="div">
          <Typography variant="h6">{st}</Typography>
        </ListItem>
      ))
    );
  };

  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Grid container direction="row" spacing={2}>
        <Grid item md={6}>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            Case ID: {caseId}
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography variant="h6" className={classes.statusList}>
            <b>Status:</b> <div style={{ marginLeft: 8 }}>{renderStatuses()}</div>
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Link
            href={onClickPDF}
            className={classes.downloadLink}
            style={{ display: !status.includes('Completed') ? 'none' : null }}
          >
            View Details - Download PDF
          </Link>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
    </Grid>
  );
};

export const PAFCaseDetailButton = ({
  onClickBack,
  onClickProceed,
  textCondition,
  displayCondition,
}) => {
  return (
    <div style={{ display: 'flex' }}>
      <LoadingButton
        loading={false}
        text="Back"
        variant="contained"
        component="span"
        onClick={onClickBack}
        style={{ backgroundColor: backButtonColor, color: 'white' }}
        disableElevation
        disableRipple
      />
      <LoadingButton
        loading={false}
        text={textCondition ? 'Submit' : 'Proceed to signature'}
        variant="contained"
        component="span"
        onClick={onClickProceed}
        style={{
          backgroundColor: buttonColor,
          color: 'white',
          marginLeft: 16,
          display: textCondition ? null : 'none', // replace textCondition with displayCondition to enable the "proceed to signature" button
        }}
        disableElevation
        disableRipple
      />
    </div>
  );
};

export const PAFHospitalCharges = ({ onTextFieldChange, emptyFieldError, currencyLabel }) => {
  const classes = useStyles();
  const excludeKeys = ['e', 'E', '+', '-'];
  return (
    <>
      <Typography variant="h6" className={classes.hospitalField}>
        Hospital Charges
      </Typography>
      <div className={classes.textboxGroup}>
        <div style={{ marginRight: 24 }}>
          <Typography>{`Min (${currencyLabel})`}</Typography>
          <SmarterTextField
            name="minCost"
            variant="outlined"
            className={classes.textbox}
            value={''}
            onChange={(v) => onTextFieldChange(v, 'minCost')}
            helperText={emptyFieldError['minCost']}
            inputProps={{ type: 'number', min: '0' }}
            onKeyDown={(e) => excludeKeys.includes(e.key) && e.preventDefault()}
          />
        </div>
        <div>
          <Typography>{`Max (${currencyLabel})`}</Typography>
          <SmarterTextField
            name="maxCost"
            variant="outlined"
            className={classes.textbox}
            value={''}
            onChange={(v) => onTextFieldChange(v, 'maxCost')}
            helperText={emptyFieldError['maxCost']}
            inputProps={{ type: 'number', min: '0' }}
            onKeyDown={(e) => excludeKeys.includes(e.key) && e.preventDefault()}
          />
        </div>
      </div>
    </>
  );
};

export const PAFSubmissionCompletion = () => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.title} style={{ fontWeight: 'bold', paddingTop: 50 }}>
        Submission Complete
      </Typography>
      <Typography className={classes.title}>
        You will receive an email notification once the insurer has approved this PAF and the Letter
        of Guarantee (LOG) is ready.
      </Typography>
    </>
  );
};
