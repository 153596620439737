import Cookies from 'universal-cookie';
import PhoneInput from 'react-phone-input-2';
import React from 'react';
import { clientCountryKey, malaysia, indonesia, singapore, hongKong } from 'features/common/config';

const PhoneNumber = ({ ...props }) => {
  const getDefaultCountryCode = () => {
    const cookies = new Cookies();
    const clientCountry = cookies.get(clientCountryKey);

    const countrySettings = {
      [singapore]: 'sg',
      [malaysia]: 'my',
      [indonesia]: 'id',
      [hongKong]: 'hk',
    };

    return countrySettings[clientCountry?.toLowerCase()] ?? countrySettings[singapore];
  };
  return <PhoneInput country={getDefaultCountryCode()} {...props} />;
};

export default PhoneNumber;
