import { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import { blockCalculation, fetchCurrencyInfo } from 'features/paf/utils/utils';
import { pennify } from 'utils/utils';
import { CostDescription } from './CostDescription';

export const TreatmentCostSummary = ({ treatmentCosts = [], payorId, formData, ...props }) => {
  const classes = useStyles();
  const { isSurgicalProcedure, lengthOfStay = 0 } = formData;
  const [currency, setCurrency] = useState({});

  const calculateTreatment = blockCalculation(lengthOfStay, currency.rate);

  const TreatmentBreakdown = ({ title, secondaryTitle, isTaxed, breakdown }) => {
    return (
      <>
        <h3>{title}</h3>
        <CostDescription
          currency={currency}
          description={secondaryTitle ?? title}
          amount={pennify(breakdown.fee)}
        />
        {!!Number(lengthOfStay) && breakdown.totalDailyAttendanceFee && (
          <CostDescription
            description="Total Daily Attendance Fee"
            amount={pennify(breakdown.totalDailyAttendanceFee)}
            currency={currency}
          />
        )}
        {isTaxed && (
          <CostDescription
            currency={currency}
            description={`${currency.taxName} ${Math.round(currency.rate * 100)}%`}
            amount={pennify(breakdown.gstFee)}
          />
        )}
        <CostDescription
          strong
          currency={currency}
          description="Total Fee"
          amount={pennify(breakdown.totalFee)}
        />
      </>
    );
  };

  function costsBreakdown(treatments) {
    const { principleFee, anaesthetist, others } = treatments;
    return [
      calculateTreatment(principleFee),
      calculateTreatment(anaesthetist),
      ...others.map((other) => calculateTreatment(other)),
    ];
  }

  function renderTreatmentCosts(treatments, treatmentIdx) {
    const { principleFee, anaesthetist, others } = treatments;
    const [principleCost, anaesthetistCost, ...othersCost] = costsBreakdown(treatments);
    const totalFee = costsBreakdown(treatments).reduce(
      (acc, curr) => acc + Number(curr.totalFee),
      0,
    );

    return (
      <Fragment key={`treatment-cost-summary_${treatmentIdx}`}>
        <h2>Procedure</h2>
        <TreatmentBreakdown
          isTaxed={principleFee.isGstPercent}
          breakdown={principleCost}
          item={principleFee}
          title={isSurgicalProcedure ? "Principle Surgeon's Fee" : "Principle Doctor's Fee"}
        />
        {anaesthetist && (
          <>
            <TreatmentBreakdown
              isTaxed={anaesthetist.isGstPercent}
              breakdown={anaesthetistCost}
              item={anaesthetist}
              title="Anaesthetist's Fee"
            />
          </>
        )}
        {others.map((other, idx) => {
          const categoryDetails = other.doctorName ? ` - ${other.doctorName}` : '';
          return (
            <TreatmentBreakdown
              key={`treatment-breakdown-others_${idx}`}
              isTaxed={other.isGstPercent}
              breakdown={othersCost[idx]}
              item={other}
              title="Others"
              secondaryTitle={other.category + categoryDetails}
            />
          );
        })}
        <Divider />

        <CostDescription
          strong
          currency={currency}
          description="Subtotal"
          amount={pennify(totalFee)}
        />
      </Fragment>
    );
  }

  const treatmentsGrandTotal = treatmentCosts.reduce((acc, curr) => {
    const totalFee = costsBreakdown(curr).reduce(
      (costs, treatment) => costs + Number(treatment.totalFee),
      0,
    );
    return acc + Number(totalFee);
  }, 0);

  useEffect(() => {
    async function setCurrencyInfo() {
      const response = await fetchCurrencyInfo(payorId);
      setCurrency(response ?? {});
    }
    if (payorId) setCurrencyInfo();
  }, [payorId]);

  return (
    <div className={classes.root} {...props}>
      <h1>Treatment Cost Summary</h1>
      {treatmentCosts.map(renderTreatmentCosts)}
      <div className={classes.spacing} />

      <CostDescription
        strong
        currency={currency}
        description="Grand Total"
        amount={pennify(treatmentsGrandTotal)}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: { wordWrap: 'break-word', padding: theme.spacing(2) },
  spacing: { marginTop: theme.spacing(4) },
}));
