import React from 'react';
import PropTypes from 'prop-types';
import { Visibility, VisibilityOff } from '@material-ui/icons';

export const ToggleVisibilityIcon = ({ visible = false, onToggle }) => {
  let visiblityIcon = (
    <Visibility
      style={{
        color: '#C4C4C4',
      }}
      className="visibility-outlined-icon visibility-outlined-icon-visible"
    />
  );
  if (visible) {
    visiblityIcon = (
      <VisibilityOff
        style={{
          color: '#000',
        }}
        className="visibility-outlined-icon visibility-outlined-icon-visiblilityOff"
      />
    );
  }

  return <div onClick={onToggle}>{visiblityIcon}</div>;
};

ToggleVisibilityIcon.propTypes = {
  visible: PropTypes.bool,
  onToggle: PropTypes.func,
};
