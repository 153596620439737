/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { usePagination } from '@hooks';
import { SPREAD, ELEMENT } from '@features/doctor-search/utilities/constants';
import { SelectableButton } from '.';

export const PaginatedButtonBar = ({
  className,
  dataLength,
  itemsPerPage,
  toPage,
  currentItem,
  pageSpreadMax,
}) => {
  const classes = useStyles();
  const [pageSpread] = usePagination({
    dataLength,
    itemsPerPage,
    count: pageSpreadMax,
    currentItem,
  });
  const currentPage =
    dataLength - currentItem <= itemsPerPage
      ? Math.ceil(currentItem / itemsPerPage)
      : Math.floor(currentItem / itemsPerPage);

  return (
    <Grid container className={className}>
      <SelectableButton
        className={classes.button}
        clicked={false}
        handleClick={() => toPage(currentPage - 1)}
        component={
          <ArrowBackIcon color={pageSpread[0]?.value === currentPage + 1 ? 'disabled' : 'action'} />
        }
        disabled={pageSpread[0]?.value === currentPage + 1}
        disableFocusRipple
        disableRipple
      />
      {pageSpread.map(({ value, type }) => (
        <SelectableButton
          key={`paginated-selectable-buttons-${value}`}
          className={classes.innerbuttons}
          component={type === SPREAD ? <MoreHorizIcon color="action" /> : value}
          disabled={false}
          clicked={type === ELEMENT && value === currentPage + 1}
          handleClick={() => toPage(value - 1)}
          disableFocusRipple
          disableRipple
        />
      ))}
      <SelectableButton
        className={classes.button}
        clicked={false}
        handleClick={() => toPage(currentPage + 1)}
        component={
          <ArrowForwardIcon
            color={
              pageSpread[pageSpread.length - 1]?.value === currentPage + 1 ? 'disabled' : 'action'
            }
          />
        }
        disabled={pageSpread[pageSpread.length - 1]?.value === currentPage + 1}
        disableFocusRipple
        disableRipple
      />
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  innerbuttons: {
    maxHeight: '30px',
    minWidth: '30px',
    minHeight: '30px',
  },
  button: {
    maxWidth: '40px',
    maxHeight: '30px',
    minWidth: '40px',
    minHeight: '30px',
  },
}));
