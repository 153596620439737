import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { ServiceOption } from './my_services_components';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { ReactHelmet } from '@components/react-helmet/Helmet';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const useStyles = (theme) => ({
  container: {
    flexGrow: 1,
  },
  circle: {
    border: `4px solid black`,
    width: '100px',
    height: '100px',
    borderRadius: '50%',
  },
  icon: {
    textAlign: 'center',
    marginTop: 20,
  },
});

class MyServices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Grid container className={this.props.classes.container}>
        <ReactHelmet
          title="My Services"
        />
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={4}>
            <ServiceOption
              onClick={() => this.props.history.push(`/search-doctor`)}
              text="Find A Doctor/Make An Appointment"
            >
              <div className={this.props.classes.circle}>
                <div className={this.props.classes.icon}>
                  <AssignmentIndIcon style={{ fontSize: 50 }} />
                </div>
              </div>
            </ServiceOption>
            <ServiceOption
              onClick={() => this.props.history.push(`/health-screening/form`)}
              text="Find A Health Screening Package/Make An Appointment"
              style={{ marginBottom: -25, textAlign: 'center' }}
            >
              <div className={this.props.classes.circle}>
                <div className={this.props.classes.icon}>
                  <AssignmentIcon style={{ fontSize: 50 }} />
                </div>
              </div>
            </ServiceOption>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(useStyles)(MyServices);
