import React from 'react';
import { Grid, withStyles, Link } from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';
import EmailIcon from '@material-ui/icons/Email';
import AllianzLogo from '@images/clients/allianz/logo.jpeg';
import AdmedikaLogo from '@images/clients/admedika/logo.jpg';
import LippoLifeLogo from '@images/clients/lippolife/logo.jpeg';
import SmarterHealthLogo from '@images/clients/smarterhealth/logoWithOnlyText.svg';
import AllianzLandingMobile from '@images/clients/allianz/landing-page-mobile.svg';
import AllianzLandingDesktop from '@images/clients/allianz/landing-page-desktop.svg';
import { SignInFields, LoginActions } from './login_components';
import LandingPage from '../components/landing-page/LandingPage';
import { post } from '@services/http_services/http_services';
import {
  arrowApiPrefix,
  allianz,
  admedika,
  redirectKey,
  clientKey,
  getClientNameForLoginAndRegistration,
  lippolife,
} from '../../common/config';
import { ReactHelmet, ReactHelmetGtag } from '@components/react-helmet/Helmet';

const textColor = '#666666';
const backgroundColor = '#03ACEF';

const useStyles = (theme) => ({
  container: {
    flexGrow: 1,
  },
  divContainer: {
    [theme.breakpoints.up('sm')]: {
      width: '400px',
    },
  },
  blueRibbonPopup: {
    [theme.breakpoints.up('sm')]: {
      width: '500px',
    },
  },
  userInput: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '400px',
    },
  },
  text: {
    fontSize: 14,
    textAlign: 'center',
    color: textColor,
  },
  signInText: {
    fontSize: 14,
    textAlign: 'center',
    color: textColor,
  },
  textFieldIconColor: {
    color: textColor,
  },
  passwordCheck: {
    marginBottom: -5,
  },
  textFieldControl: {
    display: 'grid',
    [theme.breakpoints.up('sm')]: {
      justifyItems: 'center',
    },
  },
  allianzLogo: {
    [theme.breakpoints.up('sm')]: {
      width: '23em',
    },
    width: '18em',
    margin: 'auto',
    marginBottom: '-30px',
    '& img': {
      width: '100%',
    },
  },
  smarterHealthLogo: {
    width: '18em',
  },
  admedikaLogo: {
    width: '18em',
  },
  lippoLifeLogo: {
    width: '12em',
  },
  textSpan: {
    color: backgroundColor,
    cursor: 'pointer',
  },
});

const loginAPI = `${arrowApiPrefix}/access/authenticate`;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowPassword: false,
      isNewAccount: false,
      isBlueRibbon: false,
      isEmptyFieldError: { email: false, password: false },
      email: null,
      password: null,
      helperText: 'This field cannot be empty',
      loginError: null,
      clientName: null,
      isLoading: false,
    };
  }

  clientConfig = () => {
    if (this.state.clientName === admedika) {
      return { logo: AdmedikaLogo, className: this.props.classes.admedikaLogo };
    } else if (this.state.clientName === allianz) {
      return {
        logo: AllianzLogo,
        className: this.props.classes.allianzLogo,
        landingImage: {
          mobile: AllianzLandingMobile,
          desktop: AllianzLandingDesktop,
        },
      };
    } else if (this.state.clientName === lippolife) {
      return { logo: LippoLifeLogo, className: this.props.classes.lippoLifeLogo };
    } else {
      return { logo: SmarterHealthLogo, className: this.props.classes.smarterHealthLogo };
    }
  };

  componentDidMount() {
    const client = getClientNameForLoginAndRegistration(this.props.match.params.clientName);
    this.setState({
      isBlueRibbon: this.props.location.state
        ? this.props.location.state.isBR
        : false,
      clientName: client,
    });
    if (client === admedika) {
      window.location = '/admedika/login';
    }
  }

  handleShowPassword = () => {
    this.setState({ isShowPassword: !this.state.isShowPassword });
  };

  handleNewAccountView = () => {
    this.setState({ isNewAccount: true, loginError: null });
  };

  handleSignInView = () => {
    this.setState({ isNewAccount: false });
  };

  handleBlueRibbonPopup = () => {
    this.setState({ isBlueRibbon: false });
  };

  onSubmit = (event) => {
    if (event) event.preventDefault();

    if (!this.state.email || !this.state.password) {
      this.setState({
        isEmptyFieldError: {
          email: this.state.email ? false : true,
          password: this.state.password ? false : true,
        },
      });
      return;
    }

    this.setState({ isLoading: true });

    const loginJson = {
      email: this.state.email,
      password: this.state.password,
      clientName: this.state.clientName,
      refererUrl: window.sessionStorage.getItem(redirectKey) ?? null,
    };

    post(loginAPI, loginJson)
      .then((res) =>
        //successful
        {
          if (res.data) {
            window.location = res.data.link;
          }
          //remove session key after successful login
          window.sessionStorage.removeItem(redirectKey);

          /* store client name in session to redirect user to client specific login page
          in case client name cookies couldn't be captured */
          window.sessionStorage.setItem(clientKey, this.state.clientName);
        },
      )
      .catch((error) =>
        //fail
        {
          const defaultState = {
            email: null,
            password: null,
            isShowPassword: false,
          };

          const errorMessage = error.response // The request was made and the server responded with a status code
            ? error.response.data?.error
            : error.request // The request was made but no response was received
            ? 'Error connecting to server!'
            : 'Error!';

          const resetWithError = {
            ...defaultState,
            loginError: errorMessage,
          };
          this.setState(resetWithError);
          this.setState({ isLoading: false });
        },
      );
  };

  handleTextChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      isEmptyFieldError: { ...this.state.isEmptyFieldError, [event.target.name]: false },
    });
  };

  handleHelperText = (field) => (field ? this.state.helperText : false);

  handleForgetPassword = () => {
    window.location.assign('/user/change-pw/request');
  };

  render() {
    return (
      <>
        {this.state.clientName === allianz && <ReactHelmetGtag />}
        <div style={{ display: !this.state.isBlueRibbon && 'none' }}>
          <LandingPage
            onClick={this.handleBlueRibbonPopup}
            landingImage={this.clientConfig().landingImage}
          />
        </div>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          className={this.props.classes.container}
        >
          <ReactHelmet title="Login" name={this.state.clientName} />
          <Grid item xs={12} className={this.props.classes.textFieldControl}>
            <div
              className={
                this.state.isBlueRibbon
                  ? this.props.classes.blueRibbonPopup
                  : this.props.classes.divContainer
              }
            >
              {this.state.loginError && <Alert severity="error">{this.state.loginError}</Alert>}
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <form autoComplete="off">
                    <div
                      style={{
                        display: (this.state.isBlueRibbon || this.state.isNewAccount) && 'none',
                      }}
                    >
                      <SignInFields
                        handleEmptyFieldError={[
                          this.state.isEmptyFieldError.email,
                          this.state.isEmptyFieldError.password,
                        ]}
                        handleHelperText={this.handleHelperText}
                        values={[this.state.email ?? '', this.state.password ?? '']}
                        handleTextOnChange={this.handleTextChange}
                        classNames={[
                          this.props.classes.userInput,
                          this.props.classes.textFieldIconColor,
                        ]}
                        fieldType={this.state.isShowPassword ? null : 'password'}
                        checkValue={this.state.isShowPassword}
                        handleCheckOnChange={this.handleShowPassword}
                        logo={this.clientConfig().logo}
                        classNameLogo={this.clientConfig().className}
                      />

                      <LoginActions
                        classNames={[this.props.classes.userInput, this.props.classes.signInText]}
                        text="Sign In"
                        onClick={this.onSubmit}
                        variant="contained"
                        style={{ backgroundColor, color: 'white', height: 50 }}
                        loading={this.state.isLoading}
                      >
                        <React.Fragment>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Link
                              className={this.props.classes.textSpan}
                              underline="none"
                              onClick={this.handleForgetPassword}
                            >
                              Forgot Password?
                            </Link>
                            <div>
                              Create{' '}
                              <span
                                className={this.props.classes.textSpan}
                                onClick={this.handleNewAccountView}
                              >
                                New Account
                              </span>{' '}
                              Instead
                            </div>
                          </div>

                          <div style={{ textAlign: 'left', marginTop: 8 }} className="privacy">
                            Smarter Health{' '}
                            <span
                              className={this.props.classes.textSpan}
                              onClick={() =>
                                window.open(
                                  `https://www.smarterhealth.sg/privacy-policy/`,
                                  '_blank',
                                )
                              }
                            >
                              Privacy Statement
                            </span>{' '}
                          </div>
                        </React.Fragment>
                      </LoginActions>
                    </div>
                  </form>

                  <div style={{ display: !this.state.isNewAccount && 'none' }}>
                    <LoginActions
                      classNames={[this.props.classes.userInput, this.props.classes.signInText]}
                      text="Sign Up Using Email"
                      onClick={() =>
                        this.props.history.push(`/signup/${this.state.clientName}/accountsettings`)
                      }
                      variant="outlined"
                      style={{ backgroundColor: 'white', color: textColor, height: 50 }}
                      startIcon={<EmailIcon style={{ color: backgroundColor }} />}
                    >
                      <span className={this.props.classes.textSpan} onClick={this.handleSignInView}>
                        Sign In
                      </span>{' '}
                      Instead
                    </LoginActions>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default withStyles(useStyles)(Login);
export { Login as OriginalLogin };
