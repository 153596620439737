import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import { createTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import format from 'date-fns/format';
import locale from 'date-fns/locale/en-US';
import moment from 'moment';

const main_background = '#03ACEF';
const filter_border = '#C4C4C4';
const calendarTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: main_background,
      },
    },
    MuiTabs: {
      root: {
        backgroundColor: main_background,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: main_background,
        marginTop: '0',
        marginBottom: '30px',
      },
      dayLabel: {
        color: 'black',
        fontWeight: 'bold',
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: main_background,
        '&:hover': {
          backgroundColor: main_background,
        },
      },
      current: {
        color: 'black',
        textDecorationLine: 'underline',
      },
      hidden: {
        color: '#757575',
        opacity: 0.4,
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: '0.3cm',
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        justifyContent: 'unset',
      },
    },

    MuiOutlinedInput: {
      root: {
        '& fieldset': {
          borderColor: filter_border,
        },
        '&:hover fieldset': {
          borderColor: `rgba(0, 0, 0, 0.87) !important`,
        },
        '&.Mui-focused fieldset': {
          borderColor: `#13ACEF !important`,
        },
        '&.Mui-disabled fieldset': {
          borderColor: `${filter_border} !important`,
        },
        '&.Mui-error fieldset': {
          borderColor: 'red !important',
        },
        backgroundColor: 'white',
      },
      input: {
        padding: '10.5px 14px',
      },
    },
    MuiInputLabel: {
      outlined: {
        color: 'grey !important',
        '&.Mui-error': {
          color: 'red !important',
        },
      },
    },
  },
});

class LocalizedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, 'MMM yyyy').toUpperCase();
  }
  getWeekdays() {
    return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  }
}

if (locale && locale.options) {
  locale.options.weekStartsOn = 1;
}

const useStyles = makeStyles((theme) => ({
  calendarRoot: (props) => ({
    '& fieldset': {
      border: props.isOpenCalendar ? '2px solid #13ACEF' : '1px solid rgba(0, 0, 0, 0.42)',
    },
  }),
}));
export const SmarterDateTimePicker = ({ value, label, onChange = () => {}, ...props }) => {
  const [selectedTime, setSelectedTime] = useState(value);
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);

  const handleChange = (tvalue) => {
    setSelectedTime(tvalue);
    onChange && onChange(moment(tvalue).format('dd MMM yyyy hh:mm a'));
  };

  return (
    <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
      <ThemeProvider theme={calendarTheme}>
        <DateTimePicker
          onOpen={() => setIsOpenCalendar(true)}
          onClose={() => setIsOpenCalendar(false)}
          variant="inline"
          inputVariant="outlined"
          value={selectedTime}
          onChange={handleChange}
          {...props}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};
