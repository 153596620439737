import React from 'react';
import {
  Grid,
  withStyles,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Link,
  Button,
  IconButton,
  Card,
  CardContent,
  CardActions,
  Avatar,
  Step,
  Stepper,
  StepLabel,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import BigCalendar from '../../common/big_calendar/big_calendar';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import { get, post } from '@services/http_services/http_services';
import { withRouter } from 'react-router';
import {
  maxPatientChoice,
  bentoApiPrefix,
  minPatientChoice,
  getTimeZone,
  maxPatientChoiceString,
  tzValue,
  dateConvert,
  getTimeZoneString,
  arrowApiPrefix,
  convertAppointmentDate,
  convertAppointmentTime,
} from '../../common/config';
import './create_patient_appointment.css';
import { ReactHelmet } from '@components/react-helmet/Helmet';
import { LoadingButton } from '@components/button';
import compose from 'recompose/compose';
import 'moment-timezone';

import Cookies from 'universal-cookie';
import moment from 'moment';
const cookies = new Cookies();

const useStyles = (theme) => ({
  calenderContainer: {
    flexGrow: 1,
    padding: 5,
  },
  card: {
    width: '100%',
  },
  avatarAvailable: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: 'white',
  },
  avatarWaitingList: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: 'lightgrey',
  },
  list: {
    display: 'flex',
    flexGrow: 1,
  },
  avatarList: {
    minWidth: 25,
  },
  customButton: {
    textTransform: 'none',
    backgroundColor: 'white',
    width: '60%',
    height: '45px',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  customButtonSelected: {
    textTransform: 'none',
    backgroundColor: 'white',
    width: '60%',
    height: '45px',
    border: '3px solid orange',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  customButtonAction: {
    backgroundColor: '#03ACEF',
    color: 'white',
    width: '30%',
    '&:hover': {
      backgroundColor: '#03ACEF',
    },
  },
  customDisable: {
    '&:disabled': {
      backgroundColor: 'white',
      color: 'black',
      border: '3px solid orange',
    },
  },
  customDisableWaitinglist: {
    '&:disabled': {
      backgroundColor: 'lightgrey',
      color: 'black',
      border: '3px solid orange',
    },
  },
  customWaitinglist: {
    backgroundColor: 'lightgrey',
    color: 'black',
    '&:hover': {
      backgroundColor: 'lightgrey',
    },
  },
  customButtonViewSessions: {
    textTransform: 'none',
    backgroundColor: 'white',
    width: '100%',
    height: '45px',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
});

const steps = ['Select a Date', 'Select Preferred Session(s)'];

class SelectSession extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: null,
      doctorCalendar: [],
      sessionSchedule: [],
      selectedSession: [],
      addedSession: [],
      next: false,
      waitinglistConfirm: false,
      copyDoctorCalendar: [],
      errorMessage: false,
      tooltip: false,
      activeStep: 0,
      timezone: null,
      patientDisplayName: null,
      isLoading: false,
    };
    this.state.sessionSchedule.forEach((session) => {
      this.setState({
        [session.id]: false,
      });
    });
    this.state.addedSession.forEach((session) => {
      this.setState({
        [session.start]: false,
      });
    });
  }

  componentDidMount() {
    if (this.props.serviceType.toLowerCase() === 'mcu') {
      localStorage.setItem(
        'urlAfterApptSessionSelection',
        `/app/mcu/patient/client/form/${this.props.requesterId}`,
      );
    }

    this.initDoctorCalendar();
    this.initSessionSchedule();
    this.getPatientDisplayName();
  }

  /**
   * Capture user's selections and update the state data
   * */
  onClickSession = (value) => {
    this.setState({ [value.id]: !this.state[value.id] });

    if (value.status === 'waiting-list' && !this.state[value.id]) {
      this.setState({ [value.start]: !this.state[value.start] });
    } else if (value.status === 'waiting-list' && this.state[value.id]) {
      this.setState({ [value.start]: false });

      let selection = [...this.state.addedSession];
      const index = selection.indexOf(value);
      selection.splice(index, 1);

      this.setState({ addedSession: selection, activeStep: selection.length === 0 ? 1 : 2 });
    } else {
      let selection = [...this.state.addedSession];
      const index = selection.indexOf(value);
      if (!this.state[value.id]) {
        selection.push(value);
        this.setState({ activeStep: 2 });
      } else {
        selection.splice(index, 1);
        this.setState({ activeStep: selection.length === 0 ? 1 : 2 });
      }
      this.setState({ addedSession: selection });
    }
  };

  /**
   * Add another session
   * */
  onClickAdd = (selectedsession) => {
    this.setState({ doctorCalendar: this.state.copyDoctorCalendar });
    let update = [...this.state.copyDoctorCalendar];
    selectedsession.map((s) => {
      const elementsIndex = this.state.doctorCalendar.findIndex(
        (element) =>
          new Date(element.calendarDate).toLocaleDateString() ===
          new Date(s.start).toLocaleDateString(),
      );

      //total sessions available in the particular date
      var count = 0;
      for (var i = 0; i < this.state.sessionSchedule.length; ++i) {
        if (
          new Date(this.state.sessionSchedule[i].start).toLocaleDateString() ===
          new Date(s.start).toLocaleDateString()
        ) {
          count++;
        }
      }

      //total sessions selected in the particular date
      var total = 0;
      selectedsession.map((selected) => {
        if (
          new Date(s.start).toLocaleDateString() === new Date(selected.start).toLocaleDateString()
        ) {
          total++;
        }
      });

      if (count === total) {
        update[elementsIndex] = { ...update[elementsIndex], status: 'busy' };
        this.setState({ doctorCalendar: update });
      }
    });
  };

  /**
   * Update user's selections state data when proceeding to submit screen
   * */
  onClickNext = (isProceedToSubmit, isAddAtSubmit) => {
    this.setState({ next: isProceedToSubmit });

    let session = [...this.state.selectedSession];

    if (isAddAtSubmit === true) {
      this.setState({ selectedDate: null, next: false, addedSession: [], activeStep: 0 });
      this.onClickAdd(session);
      return;
    }

    this.state.addedSession.map((s) => {
      session.push(s);
    });

    this.setState({ selectedSession: session, waitinglistConfirm: false, selectedDate: null });

    this.state.addedSession.map((s) => {
      this.setState({ doctorCalendar: this.state.copyDoctorCalendar });
    });

    //when user have not selected maximum number of choices yet
    if (isProceedToSubmit === false) {
      this.setState({ selectedDate: null, next: false, addedSession: [], activeStep: 0 });
      this.onClickAdd(session);
    }
  };

  /**
   * Delete user's selection
   * */
  onClickDelete = (value) => {
    if (this.state.selectedSession.length === 1) {
      let selection = [...this.state.selectedSession];
      const index = selection.indexOf(value);
      selection.splice(index, 1);

      this.setState({ next: false, selectedSession: selection, selectedDate: null, activeStep: 0 });
      this.onClickAdd(selection);
    } else {
      let selection = [...this.state.selectedSession];
      const index = selection.indexOf(value);
      selection.splice(index, 1);

      this.setState({ selectedSession: selection, activeStep: 0, selectedDate: null, next: true });
      this.onClickAdd(selection);
    }
  };

  /**
   * Edit user's selection
   * */
  onClickEdit = (value) => {
    let selection = [...this.state.selectedSession];
    const index = selection.indexOf(value);
    selection.splice(index, 1);

    this.setState({
      next: false,
      selectedSession: selection,
      selectedDate: new Date(value.start),
      addedSession: [],
      activeStep: 1,
      doctorCalendar: this.state.copyDoctorCalendar,
    });

    this.state.sessionSchedule.forEach((session) => {
      this.setState({
        [session.id]: false,
      });
    });

    let update = [...this.state.copyDoctorCalendar];
    selection.map((s) => {
      if (new Date(s.start).toLocaleDateString() !== new Date(value.start).toLocaleDateString()) {
        //total sessions available in the particular date
        var count = 0;
        for (var i = 0; i < this.state.sessionSchedule.length; ++i) {
          if (
            new Date(this.state.sessionSchedule[i].start).toLocaleDateString() ===
            new Date(s.start).toLocaleDateString()
          ) {
            count++;
          }
        }

        //total sessions selected in the particular date
        var total = 0;
        selection.map((selected) => {
          if (
            new Date(s.start).toLocaleDateString() === new Date(selected.start).toLocaleDateString()
          ) {
            total++;
          }
        });

        if (count === total) {
          const elementsIndex = this.state.doctorCalendar.findIndex(
            (element) =>
              new Date(element.calendarDate).toLocaleDateString() ===
              new Date(s.start).toLocaleDateString(),
          );

          update[elementsIndex] = { ...update[elementsIndex], status: 'busy' };
          this.setState({ doctorCalendar: update });
        }
      }
    });
  };

  /**
   * To proceed with waiting list slot
   * */
  onClickYes = (value) => {
    this.setState({ [value.start]: !this.state[value.start], activeStep: 2 });

    let selection = [...this.state.addedSession];
    const index = selection.indexOf(value);
    if (this.state[value.id]) {
      selection.push(value);
    } else {
      selection.splice(index, 1);
    }
    this.setState({ addedSession: selection });
  };

  /**
   * To cancel waiting list slot
   * */
  onClickNo = (value) => {
    this.setState({ [value.id]: !this.state[value.id], [value.start]: !this.state[value.start] });
  };

  /**
   * Submit function to save user's preferred sessions
   * */
  onSubmit = () => {
    this.setState({ isLoading: true });

    const bookSchedule = {
      bookedSchedule: this.state.selectedSession.map((s, id) => ({
        start: moment(new Date(s.start)).tz(tzValue(this.state.timezone), true).toISOString(),
        end: moment(new Date(s.end)).tz(tzValue(this.state.timezone), true).toISOString(),
        expireAt: moment(new Date(s.expireAt)).tz(tzValue(this.state.timezone), true).toISOString(),
        priority: id + 1,
      })),
      clientCaseData: {
        displayName: this.state.patientDisplayName ? this.state.patientDisplayName : '',
        personId: cookies.get('roleId') === 'Agent' ? 1 : Number(cookies.get('userId')),
        serviceType: this.props.serviceType,
        referenceId: this.props.requesterId,
      },
    };

    post(
      `${bentoApiPrefix}/schedule/book/unitId/${this.props.unitId}/requesterId/${this.props.requesterId}`,
      bookSchedule,
    )
      .then((res) => {
        window.location = window.localStorage.getItem('urlAfterApptSessionSelection')
          ? window.localStorage.getItem('urlAfterApptSessionSelection')
          : '/';
        window.localStorage.removeItem('urlAfterApptSessionSelection');
      })
      .catch((err) =>
        {
          console.log('something went wrong', err);
          this.setState({ errorMessage: true, isLoading: false });
        },
      );
  };

  /**
   * Store calendar data to state
   * */
  initDoctorCalendar = async () => {
    const respondData = await get(`${bentoApiPrefix}/schedule/unitId/${this.props.unitId}`);
    if (respondData.code && respondData.data !== null) {
      if (Array.isArray(respondData.data) && respondData.data.length > 0) {
        const calendarSummary = respondData.data.map((data) => {
          if (!this.state.timezone) {
            this.setState({ timezone: getTimeZoneString(data.start) });
          }

          return {
            status: data.dayStatus === 'busy-tentative' ? 'waiting-list' : data.dayStatus,
            calendarDate: dateConvert(data.start, this.state.timezone),
          };
        });
        this.setState({ doctorCalendar: calendarSummary, copyDoctorCalendar: calendarSummary });
      }
    }
  };

  /**
   * Store session data to state
   * */
  initSessionSchedule = async () => {
    const respondData = await get(`${bentoApiPrefix}/schedule/unitId/${this.props.unitId}`);
    if (respondData.code && respondData.data !== null) {
      if (Array.isArray(respondData.data) && respondData.data.length > 0) {
        let sessionSchedules = [...this.state.sessionSchedule];
        respondData.data.forEach((schedule, idx) => {
          if (
            schedule.hasOwnProperty('schedules') &&
            Array.isArray(schedule.schedules) &&
            schedule.schedules.length > 0
          ) {
            schedule.schedules.forEach((session, si) => {
              const sessionObject = {
                start: dateConvert(session.start, this.state.timezone),
                end: dateConvert(session.end, this.state.timezone),
                id: 'session' + (si + 1),
                status: session.status === 'busy-tentative' ? 'waiting-list' : session.status,
                expireAt: dateConvert(session.expireAt, this.state.timezone),
              };
              sessionSchedules.push(sessionObject);
            });
          }
        });
        this.setState({ sessionSchedule: sessionSchedules });
      }
    }
  };

  getPatientDisplayName = async () => {
    if (cookies.get('roleId') !== 'Agent') {
      get(
        `${arrowApiPrefix}/v1/${this.props.serviceType}/refNo/${
          this.props.requesterId
        }/details/${cookies.get('userId')}`,
      ).then((res) => {
        if (res.code && res.data !== null) {
          if (Array.isArray(res.data.data.items)) {
            res.data.data.items.map((item) => {
              if (item.patient) {
                this.setState({ patientDisplayName: item.patient.name });
              }
            });
          }
        }
      });
    }
  };

  onCalendarSelect = (value) => {
    this.setState({
      selectedDate: this.state.selectedSession.length === maxPatientChoice ? null : new Date(value),
      addedSession: [],
      activeStep: this.state.selectedSession.length === maxPatientChoice ? 2 : 1,
      next: this.state.selectedSession.length === maxPatientChoice ? true : false,
    });

    this.state.sessionSchedule.forEach((session) => {
      this.setState({
        [session.id]: false,
      });
    });
  };

  tooltip = () => {
    this.setState({ tooltip: !this.state.tooltip });
  };

  selectionBox = () => (
    <Grid item style={{ marginBottom: 'auto' }}>
      {this.state.selectedSession.length === 0 ? (
        <div>
          {[...Array(maxPatientChoice)].map((box, id) => (
            <p key={`initial-selectionbox-${id}`}>
              <Button
                className={this.props.classes.customButtonViewSessions}
                variant="contained"
                disableRipple
                disableElevation
              >
                <AddIcon fontSize="large" color="disabled" />
              </Button>
            </p>
          ))}
        </div>
      ) : (
        <div>
          {this.state.selectedSession.map((session, id) => {
            return (
              <p key={id}>
                <Button
                  style={{ fontSize: '15px' }}
                  className={this.props.classes.customButtonViewSessions}
                  variant="contained"
                  disableRipple
                  disableElevation
                  value={session}
                >
                  {`${convertAppointmentDate(session.start)} ${convertAppointmentTime(
                    session.start,
                    session.end,
                  )}`}

                  <div style={{ marginLeft: 'auto', display: 'flex' }}>
                    <EditIcon
                      style={{ marginRight: '20px' }}
                      color="action"
                      fontSize="small"
                      onClick={() => this.onClickEdit(session)}
                    />
                    <DeleteIcon
                      color="action"
                      fontSize="small"
                      onClick={() => this.onClickDelete(session)}
                    />
                  </div>
                </Button>
              </p>
            );
          })}
          {[...Array(maxPatientChoice - this.state.selectedSession.length)].map((box, id) => (
            <p key={`selectionbox-${id}`}>
              <Button
                className={this.props.classes.customButtonViewSessions}
                variant="contained"
                disableRipple
                disableElevation
              >
                <AddIcon fontSize="large" color="disabled" />
              </Button>
            </p>
          ))}
        </div>
      )}

      <Typography variant="h6" style={{ marginTop: '20px' }}>
        You have selected {this.state.selectedSession.length} of {maxPatientChoice} session(s)
      </Typography>
    </Grid>
  );

  render() {
    return (
      <React.Fragment>
        <ReactHelmet title="Appointment" />
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          spacing={3}
          className={this.props.classes.calenderContainer}
          style={{ marginBottom: this.state.tooltip ? 0 : 50 }}
        >
          <Grid item xs={12} style={{ height: 200 }}>
            <Link component="button" onClick={() => this.props.back()}>
              <Typography variant="h6" style={{ marginTop: 40 }}>
                <ArrowBackIosIcon style={{ fontSize: '1rem' }} /> Back
              </Typography>
            </Link>

            <Typography variant="h6" style={{ marginTop: 50 }}>
              Please select <b>{maxPatientChoiceString}</b> preferred sessions
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container direction="row" justifyContent="center" wrap="wrap">
              <Stepper
                activeStep={this.state.activeStep}
                alternativeLabel
                style={{ backgroundColor: 'transparent', width: '90%' }}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          </Grid>

          <Grid item xs={12} md={4}>
            <Grid container direction="column" justifyContent="space-evenly" wrap="wrap">
              <Grid item xs={12} style={{ justifyContent: 'center', display: 'grid' }}>
                <BigCalendar
                  onChange={this.onCalendarSelect}
                  selectedDate={this.state.selectedDate}
                  calendarSummary={this.state.doctorCalendar}
                />
              </Grid>

              <Grid item xs={12} style={{ justifyContent: 'center', display: 'grid' }}>
                <List className={this.props.classes.list} dense={true}>
                  {/* <ListItem style={{paddingLeft: 0, paddingRight: 0}}>
                              <ListItemAvatar classes={{root: this.props.classes.avatarList}}>
                                  <Avatar variant="square" className={this.props.classes.avatarAvailable}> </Avatar>
                              </ListItemAvatar>
                              <ListItemText style={{width: '150px'}}
                                  primary="Sessions Available"
                              />
                          </ListItem> */}

                  <div>
                    <ListItem style={{ marginRight: '170px' }}>
                      <ListItemAvatar classes={{ root: this.props.classes.avatarList }}>
                        <Avatar variant="square" className={this.props.classes.avatarWaitingList}>
                          {' '}
                        </Avatar>
                      </ListItemAvatar>
                      <span style={{ display: 'flex' }}>
                        <ListItemText style={{ width: '110px' }} primary="Waiting List Only" />
                        <IconButton
                          size="small"
                          style={{ color: this.state.tooltip ? 'black' : 'grey' }}
                          disableTouchRipple
                          onClick={() => this.tooltip()}
                        >
                          <InfoIcon fontSize="small" />
                        </IconButton>
                      </span>
                    </ListItem>
                    {this.state.tooltip ? (
                      <div
                        style={{
                          backgroundColor: 'grey',
                          color: 'white',
                          padding: '5px',
                          width: '60%',
                          borderRadius: '0.5em',
                        }}
                      >
                        <span>
                          No Available Timeslots. <br />
                          You can choose to be put on the Waiting List.
                        </span>
                      </div>
                    ) : null}
                  </div>
                </List>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            item
            md={4}
            direction="column"
            justifyContent="space-evenly"
            spacing={4}
            wrap="wrap"
          >
            {this.state.errorMessage ? <Alert severity="error">Failed to submit.</Alert> : null}
            {!this.state.selectedDate && !this.state.next ? (
              this.selectionBox()
            ) : !this.state.next ? (
              <React.Fragment>
                <Grid item>
                  {this.state.sessionSchedule.map((session, id) => {
                    if (
                      new Date(session.start).toLocaleDateString() ===
                      new Date(this.state.selectedDate).toLocaleDateString()
                    ) {
                      return (
                        <div key={id}>
                          {(this.state.addedSession.length < maxPatientChoice &&
                            this.state.selectedSession.length === 0) ||
                          (this.state.addedSession.length <
                            maxPatientChoice - this.state.selectedSession.length &&
                            this.state.selectedSession.length > 0) ? (
                            <p style={{ display: 'flex', justifyContent: 'center' }}>
                              <Button
                                style={{ fontSize: '15px', width: '100%' }}
                                classes={
                                  this.state[session.id]
                                    ? { root: this.props.classes.customButtonSelected }
                                    : { root: this.props.classes.customButton }
                                }
                                variant="contained"
                                disableRipple
                                disableElevation
                                value={session}
                                onClick={() => {
                                  this.onClickSession(session);
                                }}
                                className={
                                  this.state.selectedSession.find(
                                    (s) =>
                                      new Date(s.start).toLocaleString() ===
                                        new Date(session.start).toLocaleString() &&
                                      s.status !== 'waiting-list',
                                  )
                                    ? this.props.classes.customDisable
                                    : this.state.selectedSession.find(
                                        (s) =>
                                          new Date(s.start).toLocaleString() ===
                                            new Date(session.start).toLocaleString() &&
                                          s.status === 'waiting-list',
                                      )
                                    ? this.props.classes.customDisableWaitinglist
                                    : session.status === 'waiting-list'
                                    ? this.props.classes.customWaitinglist
                                    : null
                                }
                                disabled={
                                  this.state.selectedSession.find(
                                    (s) =>
                                      new Date(s.start).toLocaleString() ===
                                      new Date(session.start).toLocaleString(),
                                  )
                                    ? true
                                    : false
                                }
                              >
                                {convertAppointmentTime(session.start, session.end)}
                                {session.status === 'waiting-list' ? '*' : null}
                              </Button>
                            </p>
                          ) : (
                            <p style={{ display: 'flex', justifyContent: 'center' }}>
                              <Button
                                style={{ fontSize: '15px', width: '100%' }}
                                classes={
                                  this.state[session.id]
                                    ? { root: this.props.classes.customButtonSelected }
                                    : { root: this.props.classes.customButton }
                                }
                                variant="contained"
                                disableRipple
                                disableElevation
                                value={session}
                                onClick={() => {
                                  this.onClickSession(session);
                                }}
                                className={
                                  this.state.selectedSession.find(
                                    (s) =>
                                      new Date(s.start).toLocaleString() ===
                                        new Date(session.start).toLocaleString() &&
                                      s.status !== 'waiting-list',
                                  )
                                    ? this.props.classes.customDisable
                                    : this.state.selectedSession.find(
                                        (s) =>
                                          new Date(s.start).toLocaleString() ===
                                            new Date(session.start).toLocaleString() &&
                                          s.status === 'waiting-list',
                                      )
                                    ? this.props.classes.customDisableWaitinglist
                                    : session.status === 'waiting-list'
                                    ? this.props.classes.customWaitinglist
                                    : null
                                }
                                disabled={this.state[session.id] ? false : true}
                              >
                                {convertAppointmentTime(session.start, session.end)}
                                {session.status === 'waiting-list' ? '*' : null}
                              </Button>
                            </p>
                          )}
                          {session.status === 'waiting-list' && this.state[session.start] ? (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '-15px',
                                fontSize: '15px',
                              }}
                            >
                              <Card
                                variant="outlined"
                                style={{ width: '100%', backgroundColor: 'transparent' }}
                              >
                                <CardContent>
                                  <p>
                                    The timeslot is <b>FULL</b>.
                                  </p>
                                  <p>
                                    Would you like to be placed on the <b>WAITING LIST</b>?
                                  </p>
                                </CardContent>
                                <CardActions>
                                  <div style={{ marginLeft: 'auto' }}>
                                    <Button
                                      disableElevation
                                      disableRipple
                                      variant="contained"
                                      size="small"
                                      style={{
                                        backgroundColor: 'white',
                                        marginRight: '5px',
                                        fontSize: '15px',
                                      }}
                                      onClick={() => this.onClickYes(session)}
                                    >
                                      Yes
                                    </Button>
                                    <Button
                                      disableElevation
                                      disableRipple
                                      variant="contained"
                                      size="small"
                                      style={{ backgroundColor: 'white', fontSize: '15px' }}
                                      onClick={() => this.onClickNo(session)}
                                    >
                                      No
                                    </Button>
                                  </div>
                                </CardActions>
                              </Card>
                            </div>
                          ) : null}
                        </div>
                      );
                    }
                  })}

                  <Typography variant="h6" style={{ marginTop: '20px', marginBottom: '20px' }}>
                    You have selected{' '}
                    {this.state.selectedSession.length + this.state.addedSession.length} of{' '}
                    {maxPatientChoice} session(s)
                  </Typography>

                  <p style={{ fontSize: 15 }}>
                    Note: Selected time slots are in accordance to clinic's local time.
                  </p>
                </Grid>

                <Grid item style={{ marginTop: 'auto' }}>
                  {this.state.sessionSchedule.find(
                    (session) =>
                      new Date(session.start).toLocaleDateString() ===
                      new Date(this.state.selectedDate).toLocaleDateString(),
                  ) ? (
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '-10px' }}
                    >
                      {this.state.addedSession.length > 0 ? (
                        <Button
                          style={{ fontSize: '15px' }}
                          className={this.props.classes.customButtonAction}
                          variant="contained"
                          disableElevation
                          disableRipple
                          onClick={() =>
                            (this.state.addedSession.length < maxPatientChoice &&
                              this.state.selectedSession.length === 0) ||
                            (this.state.addedSession.length <
                              maxPatientChoice - this.state.selectedSession.length &&
                              this.state.selectedSession.length > 0)
                              ? this.onClickNext(false, false)
                              : this.onClickNext(true, false)
                          }
                        >
                          Next
                        </Button>
                      ) : null}
                    </div>
                  ) : null}
                </Grid>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {this.selectionBox()}

                <Grid item style={{ marginTop: 'auto' }}>
                  <div
                    style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '-10px' }}
                  >
                    {this.state.selectedSession.length >= minPatientChoice &&
                    this.state.selectedSession.length <= maxPatientChoice ? (
                      <LoadingButton
                        loading={this.state.isLoading}
                        text="Submit"
                        variant="contained"
                        component="span"
                        onClick={() => this.onSubmit()}
                        className={this.props.classes.customButtonAction}
                        style={{ fontSize: '15px' }}
                        disableElevation
                        disableRipple
                      />
                    ) : null}
                  </div>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
export default compose(withRouter, withStyles(useStyles))(SelectSession);
