import React, { useEffect, useState } from 'react';
import { SelectionTreeView } from '@components/treeview';
import { getProcedureLevel, getProcedureCode } from '@api/search-doctor';
import { Loading } from '@components/loading';
import { makeStyles } from '@material-ui/core/styles';
const DEFAULT_COUNTRY = 'malaysia';
const useStyle = makeStyles(() => ({
  wrapper: {
    height: '300px',
    witdth: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    height: '300px',
    width: '100%',
  },
}));

const ProcedureCodeSearch = ({ setValueSearch }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const initialState = [
    [0, ''],
    [1, ''],
    [2, ''],
  ];
  const classes = useStyle();
  useEffect(() => {
    fetchLevel();
  }, []);

  const [selected, setSelected] = useState(new Map(initialState));

  const fetchLevel = () => {
    setLoading(true);
    let params = {
      country: DEFAULT_COUNTRY,
    };
    getProcedureLevel(params)
      .then((res) => {
        if (res?.data) {
          setData(res.data);
        } else {
          setData([]);
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const updateMap = (k, v) => {
    setSelected(new Map(selected.set(k, v)));
  };

  const fetchProcedureCode = () => {
    const params = {
      country: DEFAULT_COUNTRY,
      l1: selected.get(0),
      l2: selected.get(1),
    };
    getProcedureCode(params)
      .then((res) => {
        if (res?.data) {
          const temp = [...data];
          const firstIndex = temp.findIndex(
            (item) => item.nodeDetails.icd10Code === selected.get(0),
          );
          const secondIndex = temp[firstIndex].children.findIndex(
            (item) => item.nodeDetails.icd10Code === selected.get(1),
          );
          temp[firstIndex]['children'][secondIndex]['children'] = res.data || [];
          setData(temp);
        }
      })
      .catch(() => {})
      .finally(() => {});
  };

  const handleOnSelect = (code, description, level) => {
    updateMap(level, code);
    if (code?.trim().length && level === 1) {
      fetchProcedureCode();
    } else if (code?.trim().length && level === 2) {
      setValueSearch(code, description);
    }
  };

  return (
    <div className={classes.wrapper}>
      <Loading isLoading={loading}>
        <div className={classes.content}>
          <p style={{ opacity: '0.7', marginTop: 0 }}>
            <i>* Please choose procedure</i>
          </p>
          <SelectionTreeView data={data} selected={selected} onSelect={handleOnSelect} />
        </div>
      </Loading>
    </div>
  );
};
export default ProcedureCodeSearch;
