import { AppTheme } from './theme_type';
import { grey } from '@material-ui/core/colors';

const main_color = '#03ACEF';
const secondary_mainBackground = '#ffffff';
const main_textColor = '#1b212b';
const main_navColor = '#03ACEF';
const main_titleBarColor = '#FFFFFF';

const appThemeOptions = {
  [AppTheme.light]: {
    palette: {
      primary: {
        light: secondary_mainBackground,
        main: main_color,
        dark: '#D3D3D3',
      },
      secondary: {
        light: '#EEF1F4',
        main: secondary_mainBackground,
        dark: grey[500],
      },
      primaryBlue: '#03ACEF',
    },
    overrides: {
      MuiAppBar: {
        root: {
          backgroundColor: main_titleBarColor,
        },
      },
      MuiBottomNavigation: {
        root: {
          backgroundColor: main_color,
        },
      },
      MuiBottomNavigationAction: {
        root: {
          color: 'rgb(255,255,255)',
        },
      },
      MuiBadge: {
        badge: {
          backgroundColor: '#F37D2A',
          color: 'white',
        },
      },
      MuiTabs: {
        root: {
          color: main_navColor,
          '&$selected': {
            backgroundColor: '#03ACEF',
          },
        },
      },
    },
    breakpoints: {
      keys: ['xs', 'mobile', 'sm', 'md', 'lg', 'xl'],
      values: {
        xs: 0,
        mobile: 360,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  },
  [AppTheme.dark]: {
    palette: {
      primary: {
        light: '#424242',
        main: '#424242',
        dark: grey[500],
      },
      secondary: {
        light: grey[200],
        main: '#cfd8dc',
        dark: grey[600],
      },
      text: {
        primary: '#EBECEE',
        secondary: '#CECACA',
        disabled: '#CECACA',
      },
      background: {
        light: 'rgba(0, 0, 0, 0.38)',
        main: '#424242',
        dark: '#303030',
      },
    },
    overrides: {
      MuiBreadcrumbs: {
        root: {
          background: 'lightgray',
        },
      },
      label: {
        root: {
          color: main_textColor,
        },
      },
      MTableToolbar: {
        root: {
          color: 'rgb(255,255,255)',
        },
      },
      MuiPaper: {
        root: {
          backgroundColor: '#424242',
          color: 'rgb(255,255,255)',
        },
        elevation2: {
          color: 'rgb(255,255,255)',
        },
      },
      MuiTypography: {
        colorPrimary: {
          color: '#03ACEF',
        },
        root: {
          color: 'rgb(255,255,255)',
        },
        h6: {
          color: 'rgb(255,255,255)',
        },
      },
      MuiBottomNavigation: {
        root: {
          backgroundColor: grey[500],
          color: 'rgb(255,255,255)',
        },
      },
      MuiGid: {
        main_textColor: 'rgb(255,255,255)',
        main_btnTextColor: 'rgb(255,255,255)',
      },
    },
  },
};

export default appThemeOptions;
