import { ARROW_PREFIX, privacyLink } from './utils/constant';
import Cookies from 'universal-cookie';
import { postFormConfig } from '../apis/apis';
import { axiosInstance } from '../services/http_services/http_services';
import { authValue } from '@features/common/config';
import _ from 'lodash';
const cookies = new Cookies();
const userId = cookies.get('userId');
const client = cookies.get('client');

export const getOAFDetail = () =>
  postFormConfig({
    clientName: client,
    version: 1,
    formType: 'MCU_PATIENT_FORM',
  }).then(async (res) => {
    const result = {
      data: {
        formConfig: [],
        version: '',
      },
    };
    if (res.data?.data) {
      const { formConfig, version } = res.data.data;
      result.data.formConfig = [
        ...formConfig,
        {
          fields: [
            {
              inputName: 'privacyConfirm',
              isRequired: true,
              value: false,
              uiLabel: 'Privacy Confirmation',
              name: 'privacyConfirm',
              type: 'confirmCheckbox',
              props: {
                label: `I agree for Smarter Health Pte Ltd to process my personal data and share my personal information with relevant third parties for the purposes of the services that I have requested. <br /> I understand that my personal data will be handled in accordance with the Smarter Health <a style="text-decoration: none; color: #4183C4"  target="_blank" href="${privacyLink}">Privacy Statement.</a> `,
              },
            },
          ],
        },
      ];
      result.data.version = version;
      return result;
    }
  });

export const submitOAF = (payload) => {
  const url = `/${ARROW_PREFIX}/mcu/patient/${userId}/form/oaf/refNo/${payload.requesterId}/submit`;
  let bodyFormData = new FormData();
  for (const key in payload.data) {
    if (payload.data.hasOwnProperty(key)) {
      const item = payload.data[key];
      if (_.isArray(item)) {
        item.forEach((o) => {
          bodyFormData.append(key, o);
        });
      } else {
        bodyFormData.append(key, item);
      }
    }
  }
  return axiosInstance.post(url, bodyFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: authValue,
    },
  });
};
