import React, { useState } from 'react';
import {
  BottomNavigation,
  BottomNavigationAction,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import admedikaLogo from '@images/clients/admedika/logo.jpg';
import allianzLogo from '@images/clients/allianz/logo.jpeg';
import lippoLifeLogo from '@images/clients/lippolife/logo.jpeg';
import smarterhealthLogo from '@images/clients/smarterhealth/logoWithOnlyText.svg';

import Cookies from 'universal-cookie';
import login from '../../features/account-management/login/login';
import { useHistory } from 'react-router-dom';
import { lippolife } from 'features/common/config';
const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 8,
    marginBottom: 10,
    height: '40px',
    display: 'inherit',
    justifyContent: 'center',
    backgroundColor: 'white',
    border: '1px solid rgba(34, 36, 38, 0.15)',
  },
  menuIcon: {
    color: 'black',
    height: 'inherit',
    paddingTop: '5px !important',
    borderRight: '1px solid rgba(34, 36, 38, 0.15)',
    minWidth: '60px',
  },
  list: {
    width: 260,
  },
  sidebar: {
    padding: 12,
  },
  logoAdmedika: {
    width: '9em',
  },
  logoAllianz: {
    width: '11em',
  },
  logoLippoLife: {
    width: '6em',
  },
  logoSmarterHealth: {
    width: '14em',
  },

  //to match with arrow side bar styling
  listItem: {
    fontSize: '0.85714286em',
    padding: '0em 1.33333333em',
    color: 'rgba(0, 0, 0, 0.5)',
    '&:hover': {
      color: 'black',
      backgroundColor: 'transparent',
    },
  },
  listPadding: {
    paddingTop: '16px',
    paddingBottom: '16px',
  },
}));

export default function SideBarMenu({ menu }) {
  const classes = useStyles();
  const [anchor] = useState('left');
  const [toggleState, setToggleState] = useState(false);
  const [cookie] = useState({
    client: cookies.get('client'),
    userId: cookies.get('userId'),
  });
  const history = useHistory();

  const clientConfig = () => {
    if (cookies.get('client') === 'Admedika') {
      return { logo: admedikaLogo, className: classes.logoAdmedika };
    } else if (cookie.client === 'Allianz') {
      return { logo: allianzLogo, className: classes.logoAllianz };
    } else if (cookie.client === lippolife) {
      return { logo: lippoLifeLogo, className: classes.logoLippoLife };
    } else {
      return { logo: smarterhealthLogo, className: classes.logoSmarterHealth };
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setToggleState(open);
  };

  const menuList = () => (
    <div className={classes.list} onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <div className={classes.sidebar}>
        <a href="/">
          <img src={clientConfig().logo} alt="logo" className={clientConfig().className} />
        </a>
      </div>
      <Divider />
      <List className={classes.listPadding}>
        {Array.isArray(menu) &&
          menu.map((text, index) => (
            <React.Fragment key={index}>
              <ListItem button key={text.label} className={classes.listItem}>
                <ListItemText
                  disableTypography
                  primary={text.label}
                  onClick={() => (window.location = text.url)}
                />
              </ListItem>
              {text.subLinks &&
                text.subLinks.map((sub) => (
                  <ListItem button key={sub.label} className={classes.listItem}>
                    <ListItemText
                      style={{ marginLeft: 5 }}
                      disableTypography
                      primary={`- ${sub.label}`}
                      onClick={() => (window.location = sub.url)}
                    />
                  </ListItem>
                ))}
            </React.Fragment>
          ))}
      </List>
      <Divider />
    </div>
  );

  return (
    <React.Fragment>
      <BottomNavigation onClick={toggleDrawer(true)} className={classes.root}>
        <BottomNavigationAction
          className={classes.menuIcon}
          value="menu"
          icon={<MenuIcon fontSize="small" />}
        />
      </BottomNavigation>
      <div>
        <React.Fragment key={anchor}>
          <Drawer anchor={anchor} open={toggleState} onClose={toggleDrawer(false)}>
            {menuList()}
          </Drawer>
        </React.Fragment>
      </div>
    </React.Fragment>
  );
}
