export const generateTimeRangeByDuration = (start, end, duration) =>{
    if(start !== undefined && end !== undefined && duration !== undefined){
        const startTime = new Date(start);
        const endTime = new Date(end);
        const durationMinutes = parseInt(duration);
        let startTimer = new Date(startTime).getTime();
        let endTimer = new Date(endTime).getTime();
        let timeSeries = [{id: startTimer, name: new Date(startTimer)}];
        for(let i = startTimer; startTimer<=endTimer; i=startTimer){
            startTimer = new Date(new Date(startTimer).setHours(new Date(startTimer).getHours(), new Date(startTimer).getMinutes()+durationMinutes, 0 , 0)).getTime();
            timeSeries.push({id: startTimer, name: new Date(startTimer)})
        }
        return timeSeries;
    }else{
        return [];
    }
};
