import React from 'react';
import PropTypes from 'prop-types';
import styles from './event_card.css';
import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const style = makeStyles((theme) => ({
  //fully booked slots
  card_scheduled: {
    display: 'flex',
    flexDirection: 'column',
    //padding: 5,
    //width: '85%',
    height: '100%',
    flexWrap: 'wrap',
    border: '1px solid white',
    backgroundColor: 'darkgray',
    //boxShadow: '1px 1px 1px #fdfdfd'
  },
  // card_attended:{
  //     display: 'flex',
  //     flexDirection: 'column',
  //     padding: 5,
  //     flexWrap:'wrap',
  //     width: '85%',
  //     height: '100%',
  //     borderTop: '1px solid #ddd',
  //     borderLeft: '3px solid #00C047',
  //     borderBottom: '1px solid #ddd',
  //     backgroundColor: '#fdfdfd',
  //     boxShadow: '1px 1px 1px #fdfdfd'
  // },

  //patient preferred choice (orange)
  booked: {
    display: 'flex',
    flexDirection: 'column',
    //padding: 5,
    flexWrap: 'wrap',
    //width: '85%',
    height: '100%',
    borderTop: '1px solid #fff',
    borderLeft: '1px solid #fff',
    borderBottom: '1px solid #fff',
    backgroundColor: '#F37D2A',
    boxShadow: '1px 1px 1px #fdfdfd',
  },

  //opening hours
  open: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    height: '100%',
    backgroundColor: 'grey',
    opacity: '0.3',
  },
}));

/**
 *Reusable Session card function
 **@Event EVENT object
 **/
export default function EventCard({ Event }) {
  const classes = style();
  return (
    <div
      className={
        Event.extendedProps.type === 'booked'
          ? classes.booked
          : Event.extendedProps.type === 'open'
          ? classes.open
          : classes.card_scheduled
      }
    >
      {/* <span style={{fontSize: '0.5rem', fontWeight: 400, color: 'rgb(63, 58, 58)'}}>
                  {Event.extendedProps.type !== "booked"  && `${new Date(Event.start).toLocaleString('en-US', { hour: 'numeric', hour12: true })} : ${new Date(Event.end).toLocaleString('en-US', { hour: 'numeric', hour12: true })}`}
              </span>
                <br/>
              <span style={{fontSize: '0.5rem', fontWeight: 400, color: 'black', paddingLeft: 5}}>
                  <strong> {Event.extendedProps.type !== "booked"  && Event.title}</strong>
              </span> */}

      <span style={{ color: 'white', paddingLeft: 3 }}>
        <strong> {Event.extendedProps.type === 'slot' && Event.title} </strong>
      </span>
    </div>
  );
}
