import { createTheme } from '@material-ui/core';

const main_background = '#03ACEF';
const filter_border = '#C4C4C4';

const materialTheme = createTheme({
  overrides: {
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: main_background,
        color: 'white',
        marginTop: '0',
        marginBottom: '30px',
      },
      iconButton: {
        backgroundColor: main_background,
        color: 'white',
      },
      dayLabel: {
        color: 'black',
        fontWeight: 'bold',
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: main_background,
        '&:hover': {
          backgroundColor: main_background,
        },
      },
      current: {
        color: 'black',
        textDecorationLine: 'underline',
      },
      hidden: {
        color: '#757575',
        opacity: 0.4,
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: '0.3cm',
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        justifyContent: 'unset',
      },
    },

    MuiOutlinedInput: {
      root: {
        '& fieldset': {
          borderColor: filter_border,
        },
        '&:hover fieldset': {
          borderColor: `${filter_border} !important`,
        },
        '&.Mui-focused fieldset': {
          borderColor: `${filter_border} !important`,
        },
        '&.Mui-disabled fieldset': {
          borderColor: `${filter_border} !important`,
        },
        '&.Mui-error fieldset': {
          borderColor: 'red !important',
        },
        backgroundColor: 'white',
      },
    },
    MuiInputLabel: {
      outlined: {
        color: 'grey !important',
        '&.Mui-error': {
          color: 'red !important',
        },
      },
    },
  },
});

export default materialTheme;
