import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { AppTheme } from './features/app_theme/theme_type';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Sentry } from '@smarterhealth/utilities';
import { SENTRY_DSN } from 'features/common/config';

/* istanbul ignore next */
Sentry.init(process.env.REACT_APP_SENTRY_DSN || SENTRY_DSN);

const Root = () => {
  const [theme, setTheme] = React.useState(AppTheme.light);

  const changeTheme = () => {
    setTheme(theme === AppTheme.light ? AppTheme.dark : AppTheme.light);
  };

  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App changeTheme={changeTheme} theme={theme} />
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  );
};

Sentry.withProfiler(Root);

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
