import React from "react";
import PropTypes from "prop-types";
import styles from "./big_calendar.css";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import {get} from "@services/http_services/http_services";


const style = makeStyles(theme => ({
    card:{
        width: '100%',
    },
    cardAfter:{
        width: '100%',
        justifyContent:'center',
        backgroundColor:'lightgrey'
    },
    avatar:{
        [theme.breakpoints.down('sm')]: {
            width: theme.spacing(4),
            height: theme.spacing(4),
        },
        [theme.breakpoints.up('md')]: {
            width: theme.spacing(5),
            height: theme.spacing(5),
        },
    },
}));

/**
*Reusable Session Calendar function
 **@data data for patient/doctor"
 **@user current user/doctor
**/
export default class BigCalendar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            summaryData:[]
        }
    }

    /*
    * Capture user selection for Session
    **/
  onDateSelect = value =>{
      this.props.onChange(value);
  };

  tileDisableClosed = ({date, view}) => (view === 'month' &&  this.props.calendarSummary.find(dt => dt.status === "free" && dt.calendarDate.toLocaleDateString() === date.toLocaleDateString()) !== undefined ? false : 
  this.props.calendarSummary.find(dt => dt.status === "selected" && dt.calendarDate.toLocaleDateString() === date.toLocaleDateString()) !== undefined ? false : 
  this.props.calendarSummary.find(dt => dt.status === "busy-tentative" && dt.calendarDate.toLocaleDateString() === date.toLocaleDateString()) !== undefined ? false : 
  this.props.calendarSummary.find(dt => dt.status === "busy" && dt.calendarDate.toLocaleDateString() === date.toLocaleDateString()) !== undefined ? true : 
  this.props.calendarSummary.find(dt => dt.status === "closed" && dt.calendarDate.toLocaleDateString() === date.toLocaleDateString()) !== undefined ? true :
  view === 'month' && this.props.calendarSummary.find(dt => dt.calendarDate.toLocaleDateString() === date.toLocaleDateString()) === undefined ? true : false)
 
    
  

  render() {
      return (
          <Calendar
              className={this.props.class}
              onChange={this.onDateSelect}
              tileClassName={({ activeStartDate, date, view }) => view === 'month' &&  this.props.calendarSummary.find(dt => dt.status === "free" && dt.calendarDate.toLocaleDateString() === date.toLocaleDateString()) !== undefined ? 'available-slot' : 
                this.props.calendarSummary.find(dt => dt.status === "selected" && dt.calendarDate.toLocaleDateString() === date.toLocaleDateString()) !== undefined ? 'patient-choice' : 
                this.props.calendarSummary.find(dt => dt.status === "waiting-list" && dt.calendarDate.toLocaleDateString() === date.toLocaleDateString()) !== undefined ? 'waitinglist-slot' :
                this.props.calendarSummary.find(dt => dt.status === "force-booking" && dt.calendarDate.toLocaleDateString() === date.toLocaleDateString()) !== undefined ? 'forcebooking-slot' :
                this.props.calendarSummary.find(dt => dt.status === "closed" && dt.calendarDate.toLocaleDateString() === date.toLocaleDateString()) !== undefined ? 'closed' : null} value={this.props.selectedDate}
              tileDisabled={this.tileDisableClosed}
              minDate={new Date()}
              formatMonthYear={(locale, date) => date.toLocaleDateString("en-US", { month: 'short', year: 'numeric'}).toUpperCase()}
          />
      );
  }

}
