import React from 'react';
import { Step, StepLabel, Stepper, makeStyles } from '@material-ui/core';
import './layout.css';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
    minHeight: '100vh',
    // mobile viewport bug fix
    [theme.breakpoints.down('sm')]: {
      minHeight: '-webkit-fill-available',
    },
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default function Landing(props) {
  const classes = useStyles();
  const isStep1 = window.location.pathname.includes('/accountsettings');
  const isStep2 = window.location.pathname.includes('/personalprofile');
  const isStep3 = window.location.pathname.includes('/review/submit');
  const isLoginPage = window.location.pathname.includes('login') ? true : false;
  const isResetPasswordPage = window.location.pathname.includes('/reset-password') ? true : false;

  const isSignUpCompleted = window.location.hash.includes('#completed') ? true : false;

  const activeStep = () => {
    if (isSignUpCompleted) {
      return 3;
    } else if (isStep1) {
      return 0;
    } else if (isStep2) {
      return 1;
    } else if (isStep3) {
      return 2;
    }
  };

  return (
    <React.Fragment>
      {!isLoginPage && !isResetPasswordPage && (
        <div style={{ background: 'white', paddingTop: 30 }}>
          <Stepper
            activeStep={activeStep()}
            alternativeLabel
            style={{ backgroundColor: 'transparent', width: '100%' }}
          >
            <Step>
              <StepLabel>Account Settings</StepLabel>
            </Step>
            <Step>
              <StepLabel>Personal Profile</StepLabel>
            </Step>
            <Step>
              <StepLabel>{`Review & Submit`}</StepLabel>
            </Step>
          </Stepper>
        </div>
      )}
      <div className={classes.root} style={{ alignItems: isLoginPage && 'center' }}>
        <div style={{ minWidth: 200, margin: 30, flex: 1 }}>{props.children}</div>
      </div>
    </React.Fragment>
  );
}
