import React from "react";
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";
import styles from "./single_calendar.css";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import EventCard from "../event_card/index"
import {get} from '@services/http_services/http_services';
import ListDialog from '../list_dialog/list_dialog';
import { getTimeZone } from '../config';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const listStatus = [
    "Attended",
    "No Show",
    "Cancel",
    "Reschedule"
];

/**
*Reusable Session Calendar function
 **@data data for patient/doctor"
 **@user current user/doctor
**/
export default class SingleCalendar extends React.Component {
    calendarComponentRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            bookedSchedule: [],
            openDialog: false,
            optionSelectedValue: "",
            selectedEvent: {},
        }
    }

    componentDidMount() {
        this.initBooking();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.selectedDoctor !== this.props.selectedDoctor){
            this.initBooking()
        }

        if(prevProps.selectedDate !== this.props.selectedDate){
            const calendar = this.calendarComponentRef.current.getApi();
            calendar.gotoDate(this.props.selectedDate);
        }
    }

    initBooking = async () =>{
        const doctorSchedules = await get(`slot/unitId/${this.props.selectedDoctor.unitId}`);
        if(doctorSchedules.code && Array.isArray(doctorSchedules.data) && doctorSchedules.data.length > 0){
            let bookedSchedule = [...this.state.bookedSchedule];
            doctorSchedules.data.forEach((booked, idx) => {
                if(booked.hasOwnProperty('schedules') && Array.isArray(booked.schedules) && booked.schedules.length > 0){
                    booked.schedules.forEach((schedule, si) => {
                        if(schedule.hasOwnProperty('bookedSlots') && Array.isArray(schedule.bookedSlots) && schedule.bookedSlots.length > 0) {
                            schedule.bookedSlots.forEach((bookedSlot, index) =>{
                                const bookedObject = {
                                    id: bookedSchedule.length + index,
                                    title: bookedSlot.bookedCaseData.displayName,
                                    start: new Date(bookedSlot.slotStartAt.toString().replace(getTimeZone(bookedSlot.slotStartAt),"")),
                                    end: new Date(bookedSlot.slotEndAt.toString().replace(getTimeZone(bookedSlot.slotEndAt),""))
                                };
                                bookedSchedule.push(bookedObject);
                            });
                        }
                    });
                }
            });
            this.setState({bookedSchedule: bookedSchedule});
        }
    };

    /*
    * Capture user selection for Session
    **/
    onAppointmentClick = value =>{
        this.setState({selectedEvent: {id:value.event, name: 'Mr.Sample', start: value.event.start, end: value.event.end}});
        this.openDialog();
    };

      /**
       * Open Dialog
       * **/
      openDialog = () =>{
          this.setState({openDialog: !this.state.openDialog});
      };

      /***
       *  On Option Select
       * **/
      onOptionSelect = (value) =>{
        this.setState({optionSelectedValue: value});
      };

  render() {
      return (
          <div style={{width: '100%', height: 'auto'}}>
              <FullCalendar
                  schedulerLicenseKey={"0150100070-fcs-1591606365"}
                  defaultView="timeGridDay"
                  header={{
                      left: '',
                      center: '',
                      right:''
                  }}
                  showNonCurrentDates={false}
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  ref={this.calendarComponentRef}
                  weekends={true}
                  allDay={false}
                  height={'auto'}
                  slotWidth={60}
                  minTime={'00:00'}
                  maxTime={'23:00'}
                  views={{
                      resourceTimelineDay: {
                          titleFormat: {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                              weekday: 'long',
                          },
                      },
                  }}
                  events={this.state.bookedSchedule}
                  eventRender={(info) => {
                      ReactDOM.render(<EventCard Event={info.event} />, info.el);
                      return info.el
                  }}
                  eventClick={this.onAppointmentClick}
              />
              <ListDialog data={this.state.selectedEvent} dialogState={this.state.openDialog} dialogTitle={"Change Status"} dialogOnClick={this.openDialog} dialogAction={this.onOptionSelect} actionSelectedValue={this.state.optionSelectedValue} listItems={listStatus} listType={"Radio"}/>
          </div>
      );
  }

}
