import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSmarterForm } from 'hooks';
import { fetchPAFSchema } from 'features/paf/utils/utils';
import { SECTION_D } from 'features/common/config';

export const SectionDForm = forwardRef(({ payorId, ...props }, ref) => {
  const [data, setData] = useState([]);
  const { fields, renderFields, handleSubmit, handleValidation } = useSmarterForm({
    schema: data,
    parentId: 'epaf-sectionD',
  });

  async function prepForm() {
    const res = await fetchPAFSchema(payorId, SECTION_D);
    setData(res);
  }

  useEffect(() => {
    if (payorId) prepForm();
  }, [payorId]);

  useImperativeHandle(ref, () => ({
    next: async () => {
      const hasErrors = await handleValidation();
      if (hasErrors) return false;
      const results = handleSubmit();
      return results[0]['Section D: Treatment Details and Estimate Fees'];
    },
    back: () => true,
  }));

  return <form {...props}>{fields.map(renderFields)}</form>;
});
