export class ProcedureCodeResponse {
  constructor(data) {
    this.data = data;
  }

  setData(data){
    this.data = data
  }

  addElement(node){
    this.data.push(node)
  }
}

export class Node {
  constructor(nodeDetails, children ) {
    this.nodeDetails = nodeDetails;
    this.children = children;
  }
  addChildrenNode(nodeDetails){
    this.children.push(nodeDetails)
  }
}

export class NodeDetails {
  constructor(icd10Code, icd10Description){
    this.icd10Code = icd10Code;
    this.icd10Description = icd10Description;
  }
}

 
