import React, { forwardRef, useImperativeHandle, useEffect, useState, useRef } from 'react';
import { createPAF, savePolicyInfo, getIdentificationDocType } from 'apis/paf';
import {
  buildFormModel,
  checkAgeUnder18,
  convertDateTimeForServer,
  validate,
} from 'features/paf/utils/utils';
import { DynamicForm } from 'components/form';

import { PolicyHolderInfo } from './PolicyHolderInfo';
import { GuardianOfKin } from './GuardianOfKin';
import _ from 'lodash';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Typography } from '@material-ui/core';

export const SectionAForm = forwardRef(
  (
    {
      isCreating = false,
      caseId = null,
      setCaseId,
      setIsLegalGuardianRequiredToSign,
      formModel,
      setFormModel,
      isUnder18,
      setIsCreating,
      setSignerName,
      payorId,
    },
    ref,
  ) => {
    const [showPolicy, setShowPolicy] = useState(false);
    const [showGuardian, setShowGuardian] = useState(false);

    const [errors, setErrors] = useState([]);
    const errorRef = useRef(null);

    const [isPolicySameWithGuardian, setIsPolicySameWithGuardian] = useState(false);
    const [identificationDocTypeList, setIdentificationDocTypeList] = useState([]);

    const executeScroll = () =>
      errorRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });

    const patientConfig = [
      {
        section: 'Section A: Patient’s Information',
        fields: [
          {
            inputName: 'firstName',
            isRequired: true,
            value: '',
            uiLabel: 'First Name',
            name: 'patientFirstName',
            type: 'textField',
            props: {},
          },
          {
            inputName: 'lastName',
            isRequired: true,
            value: '',
            uiLabel: 'Last Name',
            name: 'patientLastName',
            type: 'textField',
            props: {},
          },
          {
            inputName: 'gender',
            isRequired: true,
            value: '',
            uiLabel: 'Gender',
            name: 'patientGender',
            type: 'dropdown',
            props: {
              dataSource: [{ value: 'Male' }, { value: 'Female' }],
            },
          },
          {
            inputName: 'dateOfBirth',
            isRequired: true,
            value: '',
            uiLabel: 'Date of Birth',
            name: 'patientDateOfBirth',
            type: 'date',
            props: {
              maxDate: new Date(),
            },
            onChange: (value) => {
              setShowGuardian(checkAgeUnder18(value));
              handleSetFormModel('patient', {
                ...formModel.patient,
                dateOfBirth: value,
                email: checkAgeUnder18(value) ? '' : formModel.patient.email,
                mobile: checkAgeUnder18(value) ? '' : formModel.patient.mobile,
                isPolicyHolder: 'No',
              });
            },
          },
          {
            inputName: 'email',
            isRequired: true,
            value: '',
            uiLabel: 'Email Address',
            name: 'patientEmailAddress',
            type: 'textField',
            props: {
              disabled: isUnder18,
            },
          },

          {
            inputName: 'mobile',
            isRequired: true,
            value: '',
            uiLabel: 'Phone number',
            name: 'patientPhoneNumber',
            type: 'phoneNumber',
            props: {
              disabled: isUnder18,
            },
          },
          {
            inputName: 'identificationDocType',
            isRequired: true,
            value: '',
            name: 'patientIdentificationDocType',
            uiLabel: 'Identification Document (ID) Type',
            type: 'dropdown',
            props: {
              dataSource: identificationDocTypeList,
            },
          },
          {
            inputName: 'patientOthers',
            isRequired: true,
            value: '',
            uiLabel: 'Others',
            name: 'patientOthers',
            type: 'textField',
            props: {
              visible: { inputName: 'identificationDocType', value: 'Others (please specify)' },
            },
          },
          {
            inputName: 'identificationDocNum',
            isRequired: true,
            value: '',
            uiLabel: 'Identification Document (ID) No',
            name: 'idType',
            type: 'textField',
            props: {},
          },
          {
            inputName: 'isPolicyHolder',
            isRequired: true,
            value: isUnder18 ? 'No' : '',
            uiLabel: 'Is patient the policyholder?',
            name: 'isPolicyHolder',
            type: 'radio',
            props: {
              dataSource: ['Yes', 'No'],
            },
            optionProps: {
              disabled: isUnder18,
            },
          },
          {
            inputName: 'isRequireLegalGuardian',
            isRequired: true,
            value: '',
            uiLabel: 'Does the Patient require a Legal Guardian to sign on his/her behalf?',
            name: 'isPolicyHolder',
            type: 'radio',
            props: {
              dataSource: ['Yes', 'No'],
              visible: { inputName: 'isPolicyHolder', value: 'Yes' },
            },
            onChange: (value) => {
              setShowGuardian(value === 'Yes');
            },
          },
        ],
      },
    ];

    const policyConfig = [
      {
        section: 'Policy Holder’s Information',
        fields: [
          {
            inputName: 'firstName',
            isRequired: true,
            value: '',
            uiLabel: 'First Name',
            name: 'policyFirstName',
            type: 'textField',
            props: {},
          },
          {
            inputName: 'lastName',
            isRequired: true,
            value: '',
            uiLabel: 'Last Name',
            name: 'policyLastName',
            type: 'textField',
            props: {},
          },
          {
            inputName: 'identificationDocType',
            isRequired: true,
            value: '',
            uiLabel: 'Identification Document (ID) Type',
            name: 'policyIdentificationDocType',
            props: {
              dataSource: identificationDocTypeList,
            },
            type: 'dropdown',
          },
          {
            inputName: 'identificationDocTypeOthers',
            isRequired: true,
            value: '',
            uiLabel: 'Others',
            name: 'identificationDocTypeOthers',
            type: 'textField',
            props: {
              visible: { inputName: 'identificationDocType', value: 'Others (please specify)' },
            },
          },
          {
            inputName: 'identificationDocNum',
            isRequired: true,
            value: '',
            uiLabel: 'Identification Document (ID) No',
            name: 'policyIdentificationNo',
            type: 'textField',
            props: {},
          },
          {
            inputName: 'email',
            isRequired: true,
            value: '',
            uiLabel: 'Email Address',
            name: 'policyEmailAddress',
            type: 'textField',
            props: {},
          },
          {
            inputName: 'mobile',
            isRequired: true,
            value: '',
            uiLabel: 'Phone number',
            name: 'policyPhoneNumber',
            type: 'phoneNumber',
            props: {
              editable: true,
            },
          },
          {
            inputName: 'isRequireLegalGuardian',
            isRequired: true,
            value: '',
            uiLabel: 'Does the Patient require a Legal Guardian to sign on his/her behalf?',
            name: 'isPolicyHolder',
            type: 'radio',
            props: {
              dataSource: ['Yes', 'No'],
              visible: !isUnder18,
            },
            onChange: (value) => {
              setShowGuardian(value === 'Yes');
            },
          },
        ],
      },
    ];

    const guardianConfig = [
      {
        section: 'Guardian/Next of Kin’s Information',
        fields: [
          {
            inputName: 'policyIsGuardian',
            value: false,
            name: 'policyIsGuardian',
            type: 'confirmCheckbox',
            props: {
              label: `The Policy Holder and the Guardian/Next of Kin are the same person`,
              visible: showPolicy,
            },
            onChange: (value) => {
              setIsPolicySameWithGuardian(value);
              if (value) {
                handleSetFormModel('guardian', formModel.policyOwner);
              }
            },
          },
          {
            inputName: 'firstName',
            isRequired: true,
            value: '',
            uiLabel: 'First Name',
            name: 'guardianFirstName',
            type: 'textField',
            props: {
              disabled: isPolicySameWithGuardian,
            },
          },
          {
            inputName: 'lastName',
            isRequired: true,
            value: '',
            uiLabel: 'Last Name',
            name: 'guardianLastName',
            type: 'textField',
            props: {
              disabled: isPolicySameWithGuardian,
            },
          },
          {
            inputName: 'identificationDocType',
            isRequired: true,
            value: '',
            type: 'dropdown',
            uiLabel: 'Identification Document (ID) Type',
            name: 'guardianIdentificationDocType',
            props: {
              dataSource: identificationDocTypeList,
              disabled: isPolicySameWithGuardian,
            },
          },
          {
            inputName: 'identificationDocTypeOthers',
            isRequired: true,
            value: '',
            uiLabel: 'Others',
            name: 'identificationDocTypeOthers',
            type: 'textField',
            props: {
              disabled: isPolicySameWithGuardian,
              visible: { inputName: 'identificationDocType', value: 'Others (please specify)' },
            },
          },
          {
            inputName: 'identificationDocNum',
            isRequired: true,
            value: '',
            uiLabel: 'Identification Document (ID) No',
            name: 'guardianIdentificationNo',
            type: 'textField',
            props: {
              disabled: isPolicySameWithGuardian,
            },
          },
          {
            inputName: 'email',
            isRequired: true,
            value: '',
            uiLabel: 'Email Address',
            name: 'guardianEmailAddress',
            type: 'textField',
            props: {
              disabled: isPolicySameWithGuardian,
            },
          },
          {
            inputName: 'mobile',
            isRequired: true,
            value: '',
            uiLabel: 'Phone number',
            name: 'guardianPhoneNumber',
            type: 'phoneNumber',
            props: {
              disabled: isPolicySameWithGuardian,
            },
          },
          {
            inputName: 'relationType',
            isRequired: true,
            value: '',
            uiLabel: 'Relationship with Patient',
            name: 'relationType',
            type: 'dropdown',
            props: {
              dataSource: [{ value: 'Guardian' }, { value: 'Next of Kin' }],
            },
          },
          {
            inputName: 'relationWithPatient',
            isRequired: true,
            value: '',
            uiLabel: 'Please Specify',
            name: 'relationWithPatient',
            type: 'textField',
            props: {
              visible: { inputName: 'relationType', value: 'Next of Kin' },
            },
          },
        ],
      },
    ];

    const handleSetFormModel = (sectionName, value) => {
      let temp = { ...formModel };
      switch (sectionName) {
        case 'patient':
          temp = {
            ...formModel,
            patient: value,
          };
          break;
        case 'policyOwner':
          temp = {
            ...formModel,
            policyOwner: value,
          };
          break;
        case 'guardian':
          temp = {
            ...formModel,
            guardian: value,
          };
          break;
        default:
          break;
      }
      setFormModel(() => temp);
    };

    const validateSection = () =>
      new Promise((resolve, reject) => {
        let errsPatient = validate(patientConfig, formModel.patient);
        let errsPolicyOwner = showPolicy ? validate(policyConfig, formModel.policyOwner) : [];
        let errsGuardian = showGuardian ? validate(guardianConfig, formModel.guardian) : [];

        if (!_.isEmpty(errsPatient) || !_.isEmpty(errsPolicyOwner) || !_.isEmpty(errsGuardian)) {
          let errs = [...errsPatient, ...errsPolicyOwner, ...errsGuardian];
          reject(errs);
        } else {
          resolve();
        }
      });

    const submit = () =>
      new Promise((resolve, reject) => {
        validateSection()
          .then(() => {
            setErrors([]);
            if (isCreating && !caseId) {
              submitNewPAF().then((res) => {
                setIsCreating(false);
                resolve(true);
              });
            } else {
              savePatientInformation(caseId);
              resolve(true);
            }
          })
          .catch((err) => {
            reject(false);
            setErrors(err);
            executeScroll();
          });
      });

    const getIdentificationDocTypeValue = () => {
      let dataSource = [];
      getIdentificationDocType(payorId)
        .then((res) => {
          res.code &&
            res.data?.identificationDocumentTypes.forEach((doc) => dataSource.push({ value: doc }));
          setIdentificationDocTypeList(dataSource);
        })
        .catch(() => {
          console.log('catch');
          setErrors(['Unable to retrieve dropdown for identification document type']);
        });
    };

    useEffect(() => {
      setShowPolicy(formModel.patient.isPolicyHolder === 'No');
      handleSetFormModel('patient', {
        ...formModel.patient,
        isRequireLegalGuardian: '',
      });
    }, [formModel.patient.isPolicyHolder]);

    const buildPayload = () => {
      const { patient, policyOwner, guardian } = formModel;
      let result = {
        patient: {
          firstName: patient.firstName,
          lastName: patient.lastName,
          gender: patient.gender,
          dateOfBirth: convertDateTimeForServer(patient.dateOfBirth),
          email: patient.email,
          mobile: patient.mobile,
          identificationDocType: patient.identificationDocType,
          identificationDocNum: patient.identificationDocNum,
        },
      };
      if (showGuardian) {
        result.guardian = {
          firstName: guardian.firstName,
          lastName: guardian.lastName,
          email: guardian.email,
          mobile: guardian.mobile,
          relationType: guardian.relationType,
          relationWithPatient: guardian.relationWithPatient,
          identificationDocType:
            guardian.identificationDocType === 'Others (please specify)'
              ? guardian.identificationDocTypeOthers
              : guardian.identificationDocType,
          identificationDocNum: guardian.identificationDocNum,
        };
      }
      if (showPolicy) {
        result.policyOwner = {
          firstName: policyOwner.firstName,
          lastName: policyOwner.lastName,
          email: policyOwner.email,
          mobile: policyOwner.mobile,
          relationType: 'policyOwner',
          identificationDocType:
            policyOwner.identificationDocType === 'Others (please specify)'
              ? policyOwner.identificationDocTypeOthers
              : policyOwner.identificationDocType,
          identificationDocNum: policyOwner.identificationDocNum,
        };
      }
      return result;
    };

    const savePatientInformation = (id) => {
      const payload = {
        caseId: id,
        ...buildPayload(),
      };
      return savePolicyInfo(payload)
        .then((res) => res)
        .catch((error) => console.log(error));
    };

    const submitNewPAF = () =>
      new Promise((resolve, reject) => {
        createPAF(payorId)
          .then((res) => {
            if (res?.data?.data?.caseId) {
              setCaseId(res.data.data.caseId);
              savePatientInformation(res.data.data.caseId)
                .then((resSaving) => {
                  if (resSaving?.code) resolve(resSaving?.code);
                  else reject(false);
                })
                .catch((error) => {
                  reject(false);
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            reject(false);
            console.log(error);
          });
      });

    useEffect(() => {
      payorId && getIdentificationDocTypeValue();
      const patientModel = buildFormModel(patientConfig);
      handleSetFormModel('patient', patientModel);
    }, [payorId]);

    useImperativeHandle(ref, () => ({
      next: async () => {
        return await submit();
      },
    }));

    useEffect(() => {
      const patient = `${formModel.patient?.firstName} ${formModel.patient?.lastName}`;
      const guardian = `${formModel.guardian?.firstName} ${formModel.guardian?.lastName}`;
      setSignerName && setSignerName(showGuardian ? guardian : patient);
    }, [
      formModel.patient.firstName,
      formModel.patient.lastName,
      formModel.guardian.firstName,
      formModel.guardian.lastName,
      showGuardian,
    ]);

    useEffect(() => {
      setIsLegalGuardianRequiredToSign(
        isUnder18 ||
          formModel.patient.isRequireLegalGuardian === 'Yes' ||
          formModel.policyOwner.isRequireLegalGuardian === 'Yes',
      );
    }, [
      formModel.patient.isRequireLegalGuardian,
      formModel.policyOwner.isRequireLegalGuardian,
      formModel.patient.dateOfBirth,
    ]);

    useEffect(() => {
      if (!showPolicy) handleSetFormModel('policyOwner', {});
    }, [showPolicy]);

    useEffect(() => {
      if (!showGuardian) {
        handleSetFormModel('guardian', {});
        setIsPolicySameWithGuardian(false);
      }
    }, [showGuardian]);

    return (
      <div ref={errorRef}>
        {!_.isEmpty(errors) && (
          <div>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {errors.map((error, index) => {
                const idx = index + 1;
                return (
                  <Typography key={`search-form-error-${idx}`}>{`${idx}: ${error}`}</Typography>
                );
              })}
            </Alert>
          </div>
        )}
        <DynamicForm
          formConfig={patientConfig}
          formModel={formModel.patient}
          setFormModel={(value) => handleSetFormModel('patient', value)}
        />
        {showPolicy && (
          <PolicyHolderInfo
            config={policyConfig}
            formModel={formModel.policyOwner}
            setFormModel={(value) => handleSetFormModel('policyOwner', value)}
          />
        )}
        {showGuardian && (
          <GuardianOfKin
            config={guardianConfig}
            formModel={formModel.guardian}
            setFormModel={(value) => handleSetFormModel('guardian', value)}
          />
        )}
      </div>
    );
  },
);
