import { Button, IconButton } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

export const SmarterFormList = ({ id, children, methods, indelible = [], title }) => {
  const classes = useStyles();
  const { handleAdd, handleDelete } = methods;

  return (
    <>
      <div className={classes.title}>{title}</div>
      {children.map((field, idx) => {
        return (
          <div key={`${field.id}-${idx}`} className={classes.content}>
            {field}
            <div className={classes.delete}>
              {!indelible.includes(idx) && (
                <IconButton onClick={handleDelete(id, idx)}>
                  <DeleteOutline />
                </IconButton>
              )}
            </div>
          </div>
        );
      })}
      <Button variant="outlined" onClick={handleAdd(id)} style={{ height: 'fit-content' }}>
        Add
      </Button>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  delete: { width: theme.spacing(5), marginTop: theme.spacing(3) },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: { width: `calc(100% + ${theme.spacing(5)}px)`, display: 'flex' },
}));
