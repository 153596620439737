import React from "react";
import {
    InputAdornment,
    TextField,
    withStyles
  } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import { convertAppointmentDate } from '../config';


const useStyles = theme => ({
    inputRoot: {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'black'
        },
    }
});

class DoctorLocationSearch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchOptions: [],
            searchValue: null,
            openSearchPopup: false,
            inputChange: null,
        }
    }

    filterValue = (value) => {    
        this.setState({searchValue: value, openSearchPopup: false})
        
        this.props.filter(value) 
    }
    
    handleInputChange = (event, newInputValue) => {
    this.setState({inputChange: newInputValue})
    if (newInputValue.length > 0) {
        this.setState({openSearchPopup: true})
    } else {
        this.setState({openSearchPopup: false})
        this.props.transformDataToTree()
    }
    };
    
  

  render() {
      return (
        <Autocomplete
            classes={{inputRoot: this.props.classes.inputRoot}}
            autoHighlight
            size="small"
            forcePopupIcon={false}
            open={this.state.openSearchPopup}
            value={this.state.searchValue}
            onChange={(event, newValue) => {
                this.filterValue(newValue);
            }}
            onInputChange={(event, newInput) => this.handleInputChange(event, newInput)}
            getOptionLabel={(option) => option}
            options={this.props.searchOptionsDoctorLocation}
            renderInput={(params) => (
                <TextField {...params} margin="normal" variant="outlined" placeholder="Search for Doctor / Location" InputProps={{...params.InputProps,
                    startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
            }}/>
            )}
        />
      );
  }

}

export default withStyles(useStyles)(DoctorLocationSearch);