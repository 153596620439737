import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormDropdown } from '../form';
import { MONTHS } from './const';

export const DateSelection = ({ value, label, ...props }) => {
  const useStyles = makeStyles((theme) => ({
    dateContainer: {
      marginTop: '0px',
    },
  }));

  const generateRange = (from = 1, to = 31) => {
    const result = [];
    for (let i = from; i <= to; i++) {
      result.push(i);
    }
    return result;
  };

  const classes = useStyles();
  return (
    <Grid container className={classes.dateContainer} item xs={12} sm={12} md={6} spacing={1}>
      <Grid item xs={12} sm={4} md={4}>
        <FormDropdown state={value.substr(0, 2)} data={generateRange()} {...props}></FormDropdown>
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <FormDropdown
          state={MONTHS[[parseInt(value.substr(3, 2)) - 1]]}
          data={MONTHS}
          {...props}
        ></FormDropdown>
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <FormDropdown
          state={value.substr(6, 4)}
          data={generateRange(1900, 2000)}
          {...props}
        ></FormDropdown>
      </Grid>
    </Grid>
  );
};
