import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import { postChangePassword } from '../../apis';
import { Sidebar } from './components/Sidebar.jsx';
import { Grid, Typography, Button } from '@material-ui/core';
import { Check, LockOutlined } from '@material-ui/icons';
import { SmarterTextField } from '@components/input';
import { Heading } from './components/Heading';
import { UserProfileDialog } from './components/UserProfileDialog';
import { ToggleVisibilityIcon } from './components/ToggleVisibilityIcon';
import { isPasswordValid } from '../../utils/utils';
import { makeStyles } from '@material-ui/core/styles';
import { useStylesBase } from './useStylesBase.js';

const useStyles = makeStyles((theme) => ({
  requiredLabels: {
    marginTop: '30px',
    paddingLeft: '5px',
  },
  passwordRequirementTitle: {
    color: '#000',
    fontWeight: 600,
    fontSize: theme.spacing(1.5),
    lineHeight: `${theme.spacing(2.25)}px`,
    display: 'block',
    marginBottom: theme.spacing(1.25),
  },
  passwordRequirementElement: {
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.spacing(1.5),
    lineHeight: `${theme.spacing(2.25)}px`,
  },
  passwordRequirementImage: {
    marginRight: theme.spacing(1),
    width: theme.spacing(2.5),
  },
  textLabel: {
    fontSize: theme.spacing(2),
    lineHeight: `${theme.spacing(3)}px`,
    color: '#000',
    marginBottom: theme.spacing(1.25),
    display: 'block',
  },
  buttonCancel: {
    backgroundColor: '#fff',
    color: '#000',
    borderColor: '#000',
  },
  buttonContinue: {
    backgroundColor: '#000',
    color: '#fff',
  },
}));
export const AccountSecurity = (_props) => {
  const [password, setPassword] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [passwordFieldVisibilities, setPasswordFieldVisibilities] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [activeModalType, setActiveModalType] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [errors, setErrors] = useState({});

  const baseClasses = useStylesBase();
  const classes = useStyles();

  const onChangePassword = (key) => {
    return (value) => {
      if (key === 'newPassword') {
        setErrors({ ...isPasswordValid(value) });
      }
      setPassword((prev) => {
        return {
          ...prev,
          [key]: value,
        };
      });
    };
  };

  const createOnToggleVisibilityHandler = (key) => {
    return function () {
      setPasswordFieldVisibilities((prev) => {
        return {
          ...prev,
          [key]: !prev[key],
        };
      });
    };
  };

  const onCancel = () => {
    setPassword({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
  };

  const dialogConfirmation = () => {
    if (activeModalType === 'passwordChanged') {
      window.location.href = '/access/logout';
    } else if (activeModalType === 'error') {
      setIsDialogOpen(false);
    }
  };

  const onSubmit = async () => {
    let body = {
      old_password: password.oldPassword,
      new_password: password.newPassword,
      repeat_password: password.confirmPassword,
    };
    setIsDialogOpen(true);
    try {
      await postChangePassword(body);
      setActiveModalType('passwordChanged');
    } catch (err) {
      setActiveModalType('error');
    }
  };
  const userDialogClose = () => {
    setIsDialogOpen(false);
  };

  let isChangeButtonPasswordDisabled =
    !errors['eightCharacters'] ||
    !errors['uppercase'] ||
    !errors['lowercase'] ||
    !errors['specialCharacter'] ||
    !errors['number'] ||
    password.newPassword !== password.confirmPassword;
  const errorColor = '#62BE37';
  const successColor = '#ABABAB';

  return (
    <>
      <Heading title="Account Security" />
      <Grid container spacing={2}>
        <Grid item md={2}>
          <Sidebar activePage="account-security" />
        </Grid>
        <Grid item md={10}>
          <div className={baseClasses.container}>
            <Typography component="h2" variant="subtitle1" className={baseClasses.pageTitle}>
              Account Security
            </Typography>
            <Typography component="h3" variant="subtitle2" className={baseClasses.formTitle}>
              Change Password
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div className={baseClasses.textField}>
                  <label className={classes.textLabel} htmlFor="userProfile-old_password">
                    Old Password
                  </label>
                  <LockOutlined className="lock-outlined-icon" />
                  <SmarterTextField
                    id="userProfile-old_password"
                    inputProps={{ 'data-testid': 'userProfile-old-password' }}
                    value={password.oldPassword}
                    type={passwordFieldVisibilities.oldPassword ? 'text' : 'password'}
                    onChange={onChangePassword('oldPassword')}
                    allowClear={false}
                  />
                  <ToggleVisibilityIcon
                    visible={passwordFieldVisibilities.oldPassword}
                    onToggle={createOnToggleVisibilityHandler('oldPassword')}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={baseClasses.textField}>
                  <label className={classes.textLabel} htmlFor="userProfile-new_password">
                    New Password
                  </label>
                  <LockOutlined className="lock-outlined-icon" />

                  <SmarterTextField
                    id="userProfile-new_password"
                    inputProps={{ 'data-testid': 'userProfile-new-password' }}
                    type={passwordFieldVisibilities.newPassword ? 'text' : 'password'}
                    value={password.newPassword}
                    onChange={onChangePassword('newPassword')}
                    allowClear={false}
                  />
                  <ToggleVisibilityIcon
                    visible={passwordFieldVisibilities.newPassword}
                    onToggle={createOnToggleVisibilityHandler('newPassword')}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={baseClasses.textField}>
                  <label className={classes.textLabel} htmlFor="userProfile-reenter_password">
                    Re-enter New Password
                  </label>
                  <LockOutlined className="lock-outlined-icon" />
                  <SmarterTextField
                    id="userProfile-reenter_password"
                    inputProps={{ 'data-testid': 'userProfile-reenter-new-password' }}
                    type={passwordFieldVisibilities.confirmPassword ? 'text' : 'password'}
                    value={password.confirmPassword}
                    onChange={onChangePassword('confirmPassword')}
                    allowClear={false}
                    error={
                      password.confirmPassword.length &&
                      password.newPassword !== password.confirmPassword
                    }
                    helperText={
                      password.confirmPassword.length &&
                      password.newPassword !== password.confirmPassword
                        ? 'Passwords don’t match'
                        : ''
                    }
                  />
                  <ToggleVisibilityIcon
                    visible={passwordFieldVisibilities.confirmPassword}
                    onToggle={createOnToggleVisibilityHandler('confirmPassword')}
                  />
                </div>
              </Grid>

              <div className={classes.requiredLabels}>
                <span className={classes.passwordRequirementTitle}>Password must contain</span>
                <div
                  className={classes.passwordRequirementElement}
                  style={{ color: errors['eightCharacters'] ? errorColor : successColor }}
                >
                  <Check className={classes.passwordRequirementImage} /> 8 or more characters
                </div>
                <div
                  className={classes.passwordRequirementElement}
                  style={{ color: errors['uppercase'] ? errorColor : successColor }}
                >
                  <Check className={classes.passwordRequirementImage} /> 1 Upper case character
                </div>
                <div
                  className={classes.passwordRequirementElement}
                  style={{ color: errors['lowercase'] ? errorColor : successColor }}
                >
                  <Check className={classes.passwordRequirementImage} /> 1 Lower case character
                </div>
                <div
                  className={classes.passwordRequirementElement}
                  style={{ color: errors['specialCharacter'] ? errorColor : successColor }}
                >
                  <Check className={classes.passwordRequirementImage} /> 1 Special character
                </div>
                <div
                  className={classes.passwordRequirementElement}
                  style={{ color: errors['number'] ? errorColor : successColor }}
                >
                  <Check className={classes.passwordRequirementImage} /> 1 Number
                </div>
              </div>
            </Grid>
            <div className={baseClasses.actionContainer}>
              <Button variant="outlined" className={classes.buttonCancel} onClick={onCancel}>
                Cancel
              </Button>
              <Button
                variant="contained"
                className={classes.buttonContinue}
                disabled={isChangeButtonPasswordDisabled}
                onClick={onSubmit}
                data-testid="userProfile-accountSecurity-change-password-button"
              >
                Change Password
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
      <UserProfileDialog
        onClose={userDialogClose}
        isOpen={isDialogOpen}
        type={activeModalType}
        onConfirm={dialogConfirmation}
      />
    </>
  );
};
