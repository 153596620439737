import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { postAuthenticateToken } from '../../apis';

const GlobalAuthTokenHandler = ({ onAuthComplete }) => {
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);  // Track loading state for authentication
  const tokenKey = "token";

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get(tokenKey);

    // Skip authentication if no token exists
    if (!token) {
      setLoading(false);
      onAuthComplete();
      return;
    }

    // Call the authentication API
    postAuthenticateToken({ token })
      .then((res) => {
        if(res.code && res.data){
          // Remove 'token' query parameter and keep other params intact
          queryParams.delete(tokenKey);

          // Replace the URL with the updated query params
          const newUrl = `${location.pathname}?${queryParams.toString()}`;
          history.replace(newUrl);  // Update the URL
          window.location.reload();
        } else {
          setLoading(false);  // Stop loading on failure
          onAuthComplete();
        }
      })
      .catch((err) => {
        console.error("Authentication error:", err);
        setLoading(false);  // Stop loading even on error
        onAuthComplete();
      });
  }, [location.search, onAuthComplete]);

  if (loading) {
    return <div>Loading...</div>;  // Show loading state until authentication is complete
  }

  return null;
};

export default GlobalAuthTokenHandler;
