import { DynamicForm } from '../../../../components/form';
import React, { useEffect } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { buildFormModel } from '../../utils/utils';

export const defaultConfigDOA = [
  {
    section: '',
    fields: [
      {
        inputName: 'dateTimeOfAccident',
        isRequired: true,
        value: '',
        uiLabel: 'Date & Time of Accident',
        name: 'dateTimeOfAccident',
        type: 'datetime',
        props: {
          maxDate: new Date(),
        },
      },

      {
        inputName: 'detailsOfAccident',
        isRequired: true,
        value: '',
        uiLabel: 'Details of Accident',
        name: 'detailsOfAccident',
        type: 'textField',
        props: {
          editable: true,
        },
      },
    ],
  },
];

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
  },
  form: {
    width: '100%',
    padding: '12px 54px',
    border: 'solid 1px rgba(0, 0, 0, 0.54)',
    borderRadius: '16px',
  },
}));

export const DetailOfAccident = ({ formModel, setFormModel }) => {
  const classes = useStyles();

  useEffect(() => {
    const detail = buildFormModel(defaultConfigDOA);
    handleSetForm(detail);
  }, []);

  const handleSetForm = (value) => {
    const temp = { ...formModel, ...value };
    setFormModel(temp);
  };
  return (
    <Grid className={classes.container}>
      <div className={classes.form}>
        <DynamicForm
          formConfig={defaultConfigDOA}
          formModel={formModel}
          setFormModel={handleSetForm}
        />
      </div>
    </Grid>
  );
};
