import { useState } from 'react';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const useCookies = () => {
  const [client] = useState(cookies.get('client'));
  const [userId] = useState(cookies.get('userId'));
  const [clientCountry] = useState(cookies.get('clientCountry'));

  return { client, userId, clientCountry };
};
