import React, { useState } from 'react';
import { SmarterTextField } from '@components/input';
import { FormItem } from '../../components/form/FormItem.jsx';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  textField: {
    position: 'relative',
    marginBottom: '30px',
    '& label': {
      fontSize: theme.spacing(2),
      lineHeight: `${theme.spacing(3)}px`,
    },
    '& svg': {
      position: 'absolute',
      right: theme.spacing(1.25),
      top: theme.spacing(7.25),
    },
    '& p': {
      marginLeft: '0px',
    },
  },
  visibilityIcon: { color: '#C4C4C4' },
  visibilityOffIcon: {
    color: '#000',
  },
}));

export const Password = ({ label, id, onChangePassword, error, helperText = '' }) => {
  const [isVisibility, setIsVisibility] = useState(false);
  const classes = useStyles();

  const alternateIcon = () => {
    setIsVisibility(!isVisibility);
  };
  return (
    <div className={classes.textField}>
      <FormItem label={label} id={id}>
        <SmarterTextField
          onChange={onChangePassword}
          id={id}
          error={error}
          helperText={helperText}
          type={isVisibility ? 'text' : 'password'}
          inputProps={{ 'data-testid': id }}
          allowClear={false}
        />
      </FormItem>
      {isVisibility ? (
        <VisibilityOff
          className={classes.visibilityOffIcon}
          onClick={alternateIcon}
          data-testid={`${id}-visibilityOffIcon`}
          id={`${id}-visibilityOffIcon`}
        />
      ) : (
        <Visibility
          className={classes.visibilityIcon}
          onClick={alternateIcon}
          data-testid={`${id}-visibilityIcon`}
          id={`${id}-visibilityIcon`}
        />
      )}
    </div>
  );
};

Password.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  onChangePassword: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};
