import { useState, useEffect } from 'react';
import moment from 'moment';
import 'date-fns';
import format from 'date-fns/format';
import DateFnsUtils from '@date-io/date-fns';
import locale from 'date-fns/locale/en-US';

import { makeStyles } from '@material-ui/core/styles';
import { createTheme, InputAdornment } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import DateRangeIcon from '@material-ui/icons/DateRange';

import { muiDateFormat } from '../../features/common/config';

const main_background = '#03ACEF';
const filter_border = '#C4C4C4';
const calendarTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: main_background,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: main_background,
        marginTop: '0',
        marginBottom: '30px',
      },
      dayLabel: {
        color: 'black',
        fontWeight: 'bold',
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: main_background,
        '&:hover': {
          backgroundColor: main_background,
        },
      },
      current: {
        color: 'black',
        textDecorationLine: 'underline',
      },
      hidden: {
        color: '#757575',
        opacity: 0.4,
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: '0.3cm',
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        justifyContent: 'unset',
      },
    },

    MuiOutlinedInput: {
      root: {
        '& fieldset': {
          borderColor: filter_border,
        },
        '&:hover fieldset': {
          borderColor: `rgba(0, 0, 0, 0.87) !important`,
        },
        '&.Mui-focused fieldset': {
          borderColor: `#13ACEF !important`,
        },
        '&.Mui-disabled fieldset': {
          borderColor: `${filter_border} !important`,
        },
        '&.Mui-error fieldset': {
          borderColor: 'red !important',
        },
        backgroundColor: 'white',
      },
      input: {
        padding: '10.5px 14px',
      },
    },
    MuiInputLabel: {
      outlined: {
        color: 'grey !important',
        '&.Mui-error': {
          color: 'red !important',
        },
      },
    },
  },
});

class LocalizedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, 'MMM yyyy').toUpperCase();
  }
  getWeekdays() {
    return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  }
}

if (locale && locale.options) locale.options.weekStartsOn = 1;

const useStyles = makeStyles((theme) => ({
  calendarRoot: (props) => ({
    '& fieldset': {
      border: props.isOpenCalendar ? '2px solid #13ACEF' : '1px solid rgba(0, 0, 0, 0.42)',
    },
  }),
}));

export const SmarterCalendar = ({
  dateFormat = muiDateFormat,
  value,
  label,
  onChange = () => {},
  ...props
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  const classes = useStyles({ isOpenCalendar });

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onChange && onChange(moment(date).format('YYYY-MM-DD'));
  };

  useEffect(() => {
    if (value) setSelectedDate(value);
  }, [value]);

  return (
    <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
      <ThemeProvider theme={calendarTheme}>
        <DatePicker
          onOpen={() => setIsOpenCalendar(true)}
          onClose={() => setIsOpenCalendar(false)}
          inputVariant="outlined"
          className={classes.calendarRoot}
          variant="inline"
          format={dateFormat}
          value={selectedDate}
          onChange={handleDateChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <DateRangeIcon style={{ color: '#666666' }} />
              </InputAdornment>
            ),
          }}
          autoOk
          disableToolbar
          openTo="year"
          views={['year', 'month', 'date']}
          {...props}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};
