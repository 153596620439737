import axios from 'axios';
import dotenv from 'dotenv';
import { authValue, redirectKey, clientKey, smarterhealth } from '@features/common/config';
import { Sentry } from '@smarterhealth/utilities';

dotenv.config();

const location_origin = window && window.location && window.location.origin;

/**
 * Axios instance for all request
 * **/
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_ARROW_URL || `${location_origin}/` || 'http://localhost:8080/',
  xsrfCookieName: process.env.REACT_APP_ARROW_XSRFCOOKIENAME ?? 'XSRF-TOKEN',
  xsrfHeaderName: process.env.REACT_APP_ARROW_XSRFHEADERNAME ?? 'X-XSRF-TOKEN',
  timeout: process.env.REACT_AXIOS_REQUEST_TIMEOUT ?? 30000,
});

/**
 * Request Interceptor
 * ** Modify headers/Authentication token and any requests configuration
 * */
axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    /* istanbul ignore next */
    Sentry.captureException(error);
    return Promise.reject(error);
  },
);

/**
 * Response Interceptor
 * ** Modify respond format, error handling, status handling
 * */

axiosInstance.interceptors.response.use(
  (response) => {
    const baseURL = response.config.baseURL;
    const apiURL = response.config.url;

    const urlWithoutLeadingSlash = apiURL.substring(0, 1) === '/' ? apiURL.substring(1) : apiURL;
    const responseURL = response.request.responseURL.replace(baseURL, '');
    // check if response url and api url is different, then redirect user to login page
    if (
      process.env.NODE_ENV !== 'test' &&
      decodeURI(responseURL) !== decodeURI(urlWithoutLeadingSlash)
    ) {
      const clientParam = window.location.search.split('=')[1];
      window.sessionStorage.setItem(redirectKey, window.location.pathname);
      window.location = `/app/${
        clientParam ?? window.sessionStorage.getItem(clientKey) ?? smarterhealth
      }/login`;
    } else if (response.status === 200 && response.data) {
      return {
        code: true,
        data: response.data,
        status: response.status,
      };
    } else {
      return {
        code: false,
        data: response.data,
        status: response.status,
      };
    }
  },
  (error) => {
    /* istanbul ignore next */
    Sentry.captureException(error);
    return Promise.reject(error);
  },
);

const getAuthorizeHeader = (configHeader = {}) => {
  return {
    headers: {
      accept: 'application/json',
    },
  };
};
/**
 * Get Request
 * **/
export const get = (url) => {
  return axiosInstance.get(url, {
    timeout: process.env.REACT_AXIOS_REQUEST_TIMEOUT ?? 30000,
    headers: {
      Authorization: authValue,
    },
  });
};

/**
 * Post Request
 * **/
export const post = (url, data, config = {}) => {
  return axiosInstance.post(url, data, {
    timeout: process.env.REACT_AXIOS_REQUEST_TIMEOUT ?? 30000,
    ...config,
    headers: {
      'Content-Type': 'application/json',
      Authorization: authValue,
    },
  });
};

/**
 * Patch Request
 * **/
export const patch = (url, data) => {
  return axiosInstance.patch(url, data, getAuthorizeHeader());
};

/**
 * Put Request
 * **/
export const put = (url, data) => {
  return axiosInstance.put(url, data);
};

/**
 * Delete Request
 * **/
export const del = (url) => {
  return axiosInstance.delete(url);
};
