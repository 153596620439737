import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  Checkbox,
  ListItemText,
  Typography,
  Chip,
} from '@material-ui/core';
import { isMobile, isIos } from 'react-device-detect';

const DEFAULT = 'DEFAULT';
const APPEND = 'APPEND';
const CHIPS = 'CHIPS';

export const FormDropdown = ({
  id,
  state,
  label = '',
  innerLabel = '',
  data = [],
  handleChange,
  defaultState = '',
  disabled = false,
  multiselect = false,
  chips = false,
  checked = [],
  error,
  helperText,
  formControlStyles,
  textMarginBottom,
  size = 'medium',
  selectStyles,
  ...props
}) => {
  const classes = useStyles({ ...props, disabled, rootLabelMarginTop: textMarginBottom });
  const renderFunction = {
    [DEFAULT]: (value) => value,
    [APPEND]: (selected) => selected.join(', '),
    [CHIPS]: (selected) => (
      <div className={classes.chips}>
        {selected.map((value) => (
          <Chip
            key={value}
            label={value}
            className={classes.chip}
            onDelete={() => onRemoveChips(value, selected)}
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
          />
        ))}
      </div>
    ),
  };

  function onRemoveChips(value, selected) {
    const newSelection = selected.filter((item) => item !== value);
    onChangeSelection({ target: { value: newSelection } });
  }

  function onChangeSelection(event) {
    handleChange(event.target.value);
  }

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }

    handleChange(value);
  };

  return (
    <div className={clsx(classes.root, label && classes.rootWithLabel)}>
      <Typography className={classes.text}>{label}</Typography>
      <FormControl
        variant="outlined"
        className={formControlStyles}
        error={error}
        disabled={disabled}
        size={size}
      >
        <Select
          value={state ?? defaultState}
          labelId={`form-dropdown-${id}`}
          id={'form-dropdown-outlined'}
          onChange={multiselect && isMobile ? handleChangeMultiple : onChangeSelection}
          error={error}
          native={isMobile}
          multiple={multiselect}
          className={selectStyles}
          renderValue={
            !multiselect
              ? renderFunction[DEFAULT]
              : chips
              ? renderFunction[CHIPS]
              : renderFunction[APPEND]
          }
          inputProps={{ classes: { disabled: classes.disabled } }}
          {...props}
        >
          {isIos && (
            <option value={defaultState} disabled={!defaultState}>
              {disabled ? innerLabel : defaultState ? defaultState : innerLabel}
            </option>
          )}
          {defaultState &&
            (isMobile ? (
              <option value={defaultState}>{defaultState}</option>
            ) : (
              <MenuItem value={defaultState}>{defaultState}</MenuItem>
            ))}
          {state &&
            disabled &&
            (isMobile ? (
              <option value={state}>{state}</option>
            ) : (
              <MenuItem value={state}>{state}</MenuItem>
            ))}
          {data.map((item) => {
            const itemKey = `form-dropdown-${item}`;
            return item && isMobile ? (
              <option key={itemKey} value={item}>
                {item}
              </option>
            ) : multiselect ? (
              <MenuItem key={itemKey} value={item}>
                <Checkbox checked={checked.indexOf(item) > -1} />
                <ListItemText primary={item} />
              </MenuItem>
            ) : (
              <MenuItem key={itemKey} value={item}>
                {item}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText className={classes.formHelperText}>{helperText}</FormHelperText>
      </FormControl>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex', flexDirection: 'column' },
  rootWithLabel: { marginTop: -theme.spacing(3) },
  text: (props) => ({
    marginBottom: props.rootLabelMarginTop ? props.rootLabelMarginTop : -theme.spacing(1),
  }),
  inputLabel: {
    backgroundColor: 'white',
  },
  formHelperText: {
    marginLeft: theme.spacing(2),
    marginBottom: ({ helperText }) => (helperText ? -theme.spacing(2.5) : ''),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  disabled: {
    backgroundColor: '#ECECEC',
  },
}));
