import React from "react";
import PropTypes from "prop-types";
import styles from "./doctor_dropdown.css";
import {FormControl, InputLabel, MenuItem, Select, withStyles, Input, Checkbox, ListItemText, TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import {get} from "@services/http_services/http_services";


class DoctorDropdown extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            doctorList:[]
        }
    }

    async componentDidMount() {
        this.initDoctorList();
    }

    async initDoctorList (){
        const respondData = await get(`provider/${this.props.providerId}/booking`);
        if(respondData.code && respondData.data && respondData.data.hasOwnProperty("appointmentUnits") && Array.isArray(respondData.data.appointmentUnits) && respondData.data.appointmentUnits.length > 0){
            this.setState({doctorList: respondData.data.appointmentUnits});
        }
    }

    handleChange = (event, values) =>{
        console.log(values);
        let options  = values;
        const value = values.filter(x => typeof x === "object");
        for (const item of options) {
           if(typeof  item === "number"){
               const isExist = value.findIndex(x => x.unitId === item);
               if(isExist < 0){
                   const doctorData = this.state.doctorList.find(x => x.unitId === item);
                   if(doctorData){
                       value.push(doctorData);
                   }
               }else{
                   value.splice(isExist, 1);
               }
           }
        }
      this.props.onDoctorSelect(value)
    };

    render() {
        return (
            <FormControl  margin={"dense"} fullWidth>
                <Autocomplete
                    multiple
                    id="size-small-standard-multi"
                    size="medium"
                    options={this.state.doctorList}
                    getOptionLabel={(option) => option.resourceName}
                    onChange={this.handleChange}
                    renderInput={(params) => (
                        <TextField {...params} variant="standard" label="Select Doctors" placeholder="Select Doctors" />
                    )}
                />
            </FormControl>
        );
    }
}
export default DoctorDropdown;
