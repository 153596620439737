import React from 'react';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: (props) => ({
    pointerEvents: props.disabled && 'none',
  }),
}));

export const SmarterNumeric = ({
  inputRef,
  InputProps,
  maxValue,
  minValue,
  value,
  thousandSeparator = ',',
  decimalSeparator = '.',
  allowNegative = false,
  setValue = null,
  variant = 'outlined',
  suffix = null,
  onChange = null,
  disabled = false,
  textAlign = 'left',
  ...props
}) => {
  const classes = useStyles({ disabled });
  const handleOnChange = (event) => {
    onChange(event.target.value.replace('$', '').replace(',', '') || '');
  };

  return (
    <NumberFormat
      variant={variant}
      fullWidth
      inputRef={inputRef}
      value={value}
      onChange={handleOnChange}
      disabled={disabled}
      customInput={TextField}
      InputProps={{
        inputProps: {
          min: minValue,
          max: maxValue,
          style: { textAlign },
        },
      }}
      className={classes.root}
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      allowNegative={allowNegative}
      {...props}
    />
  );
};
