import { Typography } from '@material-ui/core';
import { FilterBar } from './components/work-list/filter-bar';
import { makeStyles } from '@material-ui/core/styles';
import { PAFTable } from './components/work-list/table';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { STATUS_LIST } from './utils/constant';
import { convertDateTimeForServer } from './utils/utils';
const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '1200px',
    margin: 'auto',
  },
}));
export const WorkList = ({ setShowPAFForm }) => {
  const startDate = moment().startOf('day');
  const endDate = moment().add(60, 'd').endOf('day');
  const [isReloadTable, setIsReloadTable] = useState(true);
  const [filter, setFilter] = useState({
    searchText: '',
    startDate: startDate,
    endDate: endDate,
    status: 'All',
  });

  const [params, setParams] = useState({
    status: 'all',
    startDate: convertDateTimeForServer(startDate),
    endDate: convertDateTimeForServer(endDate),
  });

  const [isNewFilter, setIsNewFilter] = useState(true);

  const handleFilter = () => {
    const tmp = {
      searchText: filter.searchText || undefined,
      startDate: convertDateTimeForServer(filter.startDate) || undefined,
      endDate: convertDateTimeForServer(filter.endDate) || undefined,
      status: filter.status?.toLowerCase() || 'all',
    };
    setParams(tmp);
    setIsReloadTable(true);
    setIsNewFilter(true);
  };

  useEffect(() => {}, []);

  return (
    <>
      <Typography gutterBottom variant="h6">
        <b>Pre-Authorisation</b>
      </Typography>
      <FilterBar
        togglePAFForm={() => setShowPAFForm(true)}
        setFilter={setFilter}
        filter={filter}
        handleFilter={handleFilter}
      />
      <PAFTable
        isReloadTable={isReloadTable}
        setIsReloadTable={setIsReloadTable}
        params={params}
        isNewFilter={isNewFilter}
        setIsNewFilter={setIsNewFilter}
      />
    </>
  );
};
