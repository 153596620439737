import { Button, Typography } from '@material-ui/core';
import { GeneralError } from './GeneralError';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: `${theme.spacing(75)}px`,
    margin: 'auto',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 'calc(100% - 30px)',
    '& label': {
      textAlign: 'left',
      display: 'block',
      color: '#000',
    },
    '& *': {
      fontFamily: 'Poppins, sans-serif !important',
    },
  },
  innerContainer: {
    maxWidth: `${theme.spacing(50)}px`,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '50px',
    '& p': {
      marginLeft: '0px',
    },
  },
  title: {
    fontWeight: 700,
    fontSize: '30px',
    lineHeight: '40px',
    marginBottom: '15px',
    fontFamily: 'Playfair Display, sans-serif !important',
  },
  button: {
    color: '#fff',
    background: '#000',
    fontSize: theme.spacing(2.25),
    width: '100%',
    marginTop: theme.spacing(1),
    lineHeight: `${theme.spacing(3.25)}px`,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(7.5),
    },
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#000',
    },
  },
  marginTopZero: {
    marginTop: '0px',
  },
}));
export const ResetPasswordPage = ({
  title,
  description,
  children,
  buttonProps,
  error,
  handleClose,
  resendCode = null,
  successfulPage = false,
}) => {
  const classes = useStyles();
  const { click, text, ...props } = buttonProps;
  return (
    <div className={classes.container}>
      <Typography className={classes.title} component="h2">
        {title}
      </Typography>
      <Typography component="p">{description}</Typography>
      <div className={classes.innerContainer}>
        {children}
        <Button
          variant="contained"
          className={`${classes.button} ${successfulPage ? classes.marginTopZero : ''}`}
          onClick={click}
          {...props}
        >
          {text}
        </Button>
        {resendCode}
      </div>
      <GeneralError onClose={handleClose} isOpen={error} />
    </div>
  );
};
