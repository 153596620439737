import { cloneElement } from 'react';
import clsx from 'clsx';
import { FormControl, InputLabel, Grid, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const SmarterFormItem = ({
  id,
  value,
  label,
  type,
  isRequired,
  editable = true,
  children,
  isVisible = true,
  className = '',
  disabled,
  helperText,
  ...props
}) => {
  const classes = useStyles({ editable, type, label });

  return (
    <Grid
      container
      style={{ display: isVisible ? 'flex' : 'none' }}
      className={clsx(classes.formItem, className)}
      gutter={4}
      {...props}
    >
      <Grid item xs={editable ? 12 : 6} lg={editable ? 12 : 3}>
        <InputLabel className={classes.inputLabel} required={isRequired} htmlFor={id}>
          {label}
        </InputLabel>
      </Grid>
      <Grid item xs={editable ? 12 : 6} lg={editable ? 12 : 9} className={classes.content}>
        {editable ? (
          <FormControl fullWidth={true} disabled={disabled}>
            {cloneElement(children, { id })}
          </FormControl>
        ) : (
          <span>{value || 'N/A'}</span>
        )}
        {helperText && <FormHelperText error>{helperText}</FormHelperText>}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  inputLabel: (props) => ({
    display: props.type === 'confirmCheckbox' || !props.label ? 'none' : 'inline-block',
    alignItems: 'top',
    whiteSpace: 'pre-line',
    marginBottom: props.editable ? theme.spacing(1) : 'none',
    '& .MuiFormLabel-asterisk ': { color: 'red' },
    position: 'unset',
    transform: 'unset',
  }),
  formItem: { padding: theme.spacing(1.5, 0) },
  content: (props) => ({ wordBreak: !props.editable ? 'break-all' : '' }),
}));
