import { useState } from 'react';

export const useSearchDoctorTextState = (initialValue = '') => {
  const [state, setState] = useState({ value: initialValue, label: '', error: false });

  function validate(func) {
    const isError = func(state.value);
    setState((state) => ({ ...state, error: !!isError }));
    return isError;
  }

  function setTextState({ value, label = '', error }) {
    if (value !== undefined) setState({ value, label, error: false });
    else setState({ ...state, error });
  }
  return [state, setTextState, validate];
};
