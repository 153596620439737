import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';

export const SmarterAutoComplete = ({
  dataSource = [],
  multiple = true,
  onChange = () => {},
  ...props
}) => {
  const handleOnChange = (event, value) => {
    if (value && multiple) {
      onChange && onChange(value.map((item) => item.value) || '');
    } else {
      onChange && onChange(value);
    }
  };
  return (
    <Autocomplete
      multiple
      freeSolo
      onChange={handleOnChange}
      options={dataSource}
      getOptionLabel={(option) => option.label}
      {...props}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" placeholder="Select all that apply" />
      )}
    />
  );
};
