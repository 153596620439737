import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const InputTitle = (props) => {
  const { index, items, setAsPreferred, deleteItem, type, userDataLength } = props;
  const useStyles = makeStyles((theme) => ({
    columnTitle: {
      fontSize: theme.spacing(2),
      fontWeight: '600',
      lineHeight: `${theme.spacing(3)}px`,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(-0.5),
      '& > span:nth-child(1)': {
        textTransform: 'capitalize',
      },
    },
    preferredSpan: {
      fontStyle: 'italic',
      color: '#088CCA',
      marginLeft: theme.spacing(1),
      fontWeight: 400,
    },
    setAsPreferred: {
      color: '#088CCA',
      marginLeft: theme.spacing(1),
      fontWeight: 400,
      textDecorationLine: 'underline',
      cursor: 'pointer',
    },
    delete: {
      color: '#ED5E58',
      textDecorationLine: 'underline',
      fontWeight: 400,
      cursor: 'pointer',
      display: 'block',
      [theme.breakpoints.up('sm')]: {
        display: 'inline-block',
        float: 'right',
      },
    },
  }));
  const classes = useStyles();
  const showDelete = userDataLength === 1 && index === 0 ? false : userDataLength;
  return (
    <Typography component="p" variant="h4" className={classes.columnTitle}>
      <span>
        {index + 1}. {items[index].preferred === 'true' ? `Primary ${type}` : `${type}`}
      </span>
      {items[index].id &&
        (items[index].preferred === 'true' ? (
          <span className={classes.preferredSpan}>(Preferred)</span>
        ) : (
          <span
            className={classes.setAsPreferred}
            onClick={setAsPreferred}
            data-testid={`set-as-preferred-${type}-${index}`}
          >
            Set as preferred
          </span>
        ))}
      {showDelete && (
        <span
          className={classes.delete}
          onClick={deleteItem}
          data-testid={`delete-${type}-${index}`}
        >
          Delete
        </span>
      )}
    </Typography>
  );
};
InputTitle.propTypes = {
  index: PropTypes.number,
  setAsPreferred: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  deleteItem: PropTypes.func.isRequired,
  userDataLength: PropTypes.number.isRequired,
};
