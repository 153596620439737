import React from "react";
import { 
  Grid,
  Typography,
  withStyles, 
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { RegistrationButtons, ReviewForm, CompleteMsg } from '../registration_components';
import { post } from '@services/http_services/http_services';
import { arrowApiPrefix, getClientNameForLoginAndRegistration } from '../../../common/config';
import { ReactHelmet } from '@components/react-helmet/Helmet';

const useStyles = theme => ({
  container:{
    flexGrow: 1,
  },
  divContainer: {
    [theme.breakpoints.up('sm')]: {
      width: '520px',
    }
  },
  textColor: {
    color: '#666666',
  },
  text: {
    fontSize: 16,
    textAlign: 'center',
  },
  buttons: {
    width: '180px',
    height: '50px',
    color: 'white',
    marginTop: 25,
  },
  mobileView: {
    display: 'grid',
    [theme.breakpoints.up('sm')]: {
      justifyItems: 'center'
    }
  },
  userInput: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '520px',
    }
  },
});

const registerAPI = (client) => `${arrowApiPrefix}/register/${client}`

class ReviewSubmit extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      completed: false,
      reviewInfo: [],
      fieldValue: null,
      userId: null,
      version: null,
      dateFormat: null,
      isErrorSubmit: false,
      clientName: null,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.initFormFields();
    this.setState({clientName: getClientNameForLoginAndRegistration(this.props.match.params.clientName)})
  }

  initFormFields = () => {
    if(this.props.location.state){
      this.setState({
        reviewInfo: this.props.location.state.formFields, 
        userId: this.props.location.state.userId, 
        version: this.props.location.state.version, 
        dateFormat: this.props.location.state.dateFormat,
        fieldValue: this.props.location.state.formValues})
    }
  }

  submitPopup = () => {

    this.setState({isErrorSubmit: false})

    if(!this.state.userId || !this.state.version || !this.state.fieldValue || !this.state.dateFormat){
      console.log('Could not retrieve state information from previous page')
      this.setState({isErrorSubmit: true})
      return
    }

    this.setState({ isLoading: true })

    const registerJson = {
      userId: this.state.userId.toString(),
      version: this.state.version.toString(),
      bdayDtPattern: this.state.dateFormat,
      firstname: this.state.fieldValue.firstname,
      lastname: this.state.fieldValue.lastname,
      gender: this.state.fieldValue.gender,
      dateofbirth: this.state.fieldValue.dateofbirth,
      mobilenumber: this.state.fieldValue.mobilenumber
    }

    post(registerAPI(this.state.clientName), registerJson).then(res =>
      //successful
      {
        window.location.hash = 'completed';
        this.setState({ completed: true, isLoading: false })
      }
    ).catch(err =>
      //fail
      {
        console.log('something went wrong', err.response.data)
        this.setState({ isErrorSubmit: true, isLoading: false })
      }
    )   
  }


  render() {
    return (
      <Grid container direction="row" justifyContent="space-evenly" className={this.props.classes.container}>
        <ReactHelmet
          title={"Review & Submit"}
          name={this.state.clientName}
        />
        <Grid item xs={12} className={this.props.classes.mobileView}>
          <div className={this.props.classes.divContainer}>
            {
              this.state.isErrorSubmit && <Alert severity="error">Failed to submit.</Alert>
            }
            <div style={{display: this.state.completed ? 'none' : null}}>
              <Grid container direction="column" spacing={3} >
                <Grid item style={{marginRight: 'auto'}}>
                    <Typography id="title">{`Review & Submit`}</Typography>
                </Grid>
                <Grid item style={{width: '100%', marginBottom: 20}}>
                  
                  <span style={{fontSize: 16}}>Are the following details correct?</span>

                  <ReviewForm
                      fieldArray={this.state.reviewInfo ? this.state.reviewInfo : []}
                      className={this.props.classes.userInput}
                      fieldValue={this.state.fieldValue}
                  />

                  <RegistrationButtons
                      onClickPrevious={() => this.props.history.push({pathname: `/signup/${this.state.clientName}/personalprofile`, state: {userId: this.state.userId, formValues: this.state.fieldValue}})}
                      onClick={this.submitPopup}
                      text="Submit"
                      loading={this.state.isLoading}
                  />
                </Grid>
              </Grid>
            </div>

            <div style={{display: this.state.completed ? null : 'none'}}>
              <CompleteMsg
                className={this.props.classes.text}
                onClick={() => this.props.history.push({pathname: `/${this.state.clientName}/login`, state: {isBR: false}})}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(useStyles)(ReviewSubmit);
