import { makeStyles } from '@material-ui/core/styles';
import { WorkList } from './WorkList.jsx';
import { useState } from 'react';
import { PAFForm } from './PAFForm.jsx';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: theme.spacing(150),
    margin: 'auto',
  },
}));

export const PAFContainer = () => {
  const classes = useStyles();
  const [showPAFForm, setShowPAFForm] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const handleShowPAFForm = () => {
    setShowPAFForm(true);
    setIsCreating(true);
  };

  return (
    <div className={classes.container}>
      {showPAFForm ? (
        <div className={classes.content}>
          <PAFForm
            setShowPAFForm={setShowPAFForm}
            isCreating={isCreating}
            setIsCreating={setIsCreating}
          />
        </div>
      ) : (
        <WorkList setShowPAFForm={handleShowPAFForm} />
      )}
    </div>
  );
};
