import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { XL, LG, MD, SM, XS, HORIZONTAL, VERTICAL } from '../../../../utilities/constants';

export const HiddenContent = ({ content, size }) => {
  const classes = useStyles({ size });
  return (
    <div className={classes.root}>
      {content.map(
        ({ title, data, view = VERTICAL }) =>
          data?.length && (
            <div key={`background-info-${title}`} className={classes.secondaryContent}>
              <Typography className={classes.title}>{title}</Typography>
              <div className={view === HORIZONTAL ? classes.horizontalFlex : ''}>
                {data.map((item, index) => (
                  <Typography className={classes.text} key={`${item.substring(0, 5)}-${index}`}>
                    {item}
                  </Typography>
                ))}
              </div>
            </div>
          ),
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: '90%',
  },
  secondaryContent: { marginBottom: theme.spacing(1) },
  title: {
    fontWeight: 600,
    fontSize: ({ size }) => {
      const config = {
        [XL]: '18px',
        [LG]: '18px',
        [MD]: '16px',
        [SM]: '16px',
        [XS]: '14px',
      };
      return config[size];
    },
  },
  text: {
    fontSize: ({ size }) => {
      const config = {
        [XL]: '16px',
        [LG]: '16px',
        [MD]: '16px',
        [SM]: '14px',
        [XS]: '14px',
      };
      return config[size];
    },
    marginRight: '4px',
  },
  horizontalFlex: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
  },
}));
