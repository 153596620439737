import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Typography } from '@material-ui/core';
import { getQR } from '@api/paf';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1rem',
  },
}));

export const SectionAuthorisation = React.forwardRef(
  ({ isPatient, caseId, isLegalGuardianRequiredToSign, signerName, isSubmitted, payorId }, ref) => {
    const [QR, setQR] = useState('');
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    useImperativeHandle(ref, () => ({
      next: () => true,
      back: () => true,
    }));

    useEffect(() => {
      setLoading(true);
      if (caseId && isSubmitted) {
        const roleType = isLegalGuardianRequiredToSign ? 'guardian' : 'patient';
        const payload = {
          clientId: payorId,
          caseId,
          roleType,
          signerName,
          isLegalGuardianRequiredToSign,
        };
        getQR(payload)
          .then((res) => {
            if (res.code && res.data) {
              const newFile = new File([res.data], 'qr', { type: 'image/png' });
              const url = URL.createObjectURL(newFile);
              setQR(url);
            }
          })
          .catch((err) => console.log(err))
          .finally(() => setLoading(false));
      }
    }, [caseId, isSubmitted, payorId]);

    return (
      <div ref={ref} style={{ width: '700px', margin: 'auto' }}>
        <h2>Authorisation for Release of Medical Information</h2>
        <Typography className={classes.title}>
          {`The ${isPatient ? 'Patient' : 'Guardian/Next-of-kin'} will be required to perform a
          digital signature to complete his/her portion of the PAF`}
        </Typography>

        {
          <div style={{ marginTop: '10px' }}>
            <Typography className={classes.title}>
              {`Please request the ${
                isPatient ? 'Patient' : 'Guardian/Next-of-kin'
              } to scan the QR code below to initiate the digital signature
              process`}
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {loading ? (
                <div>
                  <Skeleton animation="wave" variant="rect" width={250} height={250} />
                </div>
              ) : (
                <img src={QR} />
              )}
            </div>
          </div>
        }
      </div>
    );
  },
);
