import React, { useState } from 'react';
import { Button, Tooltip } from '@material-ui/core';

export const SelectableButtonWithTooltip = ({
  clicked,
  handleClick,
  component,
  label,
  disabled,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  return (
    <Tooltip title={label} placement="top" open={open}>
      <div
        onMouseOver={() => setOpen(true)}
        onMouseOut={() => setOpen(false)}
        onFocus={() => setOpen(true)}
        onBlur={() => setOpen(false)}
      >
        <SelectableButton
          className={props.className}
          clicked={clicked}
          handleClick={handleClick}
          component={component}
          disabled={disabled}
          {...props}
        />
      </div>
    </Tooltip>
  );
};
export const SelectableButton = ({
  handleClick,
  component,
  className,
  clicked,
  disabled,
  ...props
}) => {
  return (
    <Button
      onClick={handleClick}
      style={{ backgroundColor: clicked && '#EDF1F7' }}
      className={className}
      disabled={disabled}
      {...props}
    >
      {component}
    </Button>
  );
};
