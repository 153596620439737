import { useRef, useState } from 'react';
import clsx from 'clsx';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { LoadingButton } from '@components/button';
import {
  SectionA,
  SectionB,
  SectionC,
  SectionD,
  Referral,
  DigitalSignature,
  PayorSelection,
} from './sections';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: theme.spacing(62.5),
    maxWidth: theme.spacing(112.5),
    paddingBottom: theme.spacing(2.5),
    backgroundColor: 'white',
    [theme.breakpoints.up('md')]: { margin: 'auto' },
    padding: theme.spacing(6),
  },
  button: {
    display: 'inline-block',
    color: 'white',
    backgroundColor: theme.palette.primaryBlue,
    boxSizing: 'border-box',
    '&:hover': { backgroundColor: theme.palette.primaryBlue },
    margin: `0 ${theme.spacing(5)}px`,
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(1.5)}px ${theme.spacing(8.75)}px`,
    },
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(1.5)}px ${theme.spacing(6.25)}px`,
    },
  },
  orange: {
    backgroundColor: '#F37D2A',
    '&:hover': { backgroundColor: '#F37D2A' },
  },
  hidden: { display: 'none' },
  buttonGroup: { textAlign: 'center' },
}));

export const PAFForm = ({ setShowPAFForm, isCreating, setIsCreating }) => {
  const classes = useStyles();
  const scrollRef = useRef();
  const [activeStep, setActiveStep] = useState(0);
  const [caseId, setCaseId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [payor, setPayor] = useState({ id: 0, name: null });

  const payorSelectionRef = useRef(null);
  const sectionARef = useRef(null);
  const sectionBRef = useRef(null);
  const sectionCRef = useRef(null);
  const sectionDRef = useRef(null);
  const referralRef = useRef(null);
  const sectionRefs = [
    payorSelectionRef,
    sectionARef,
    sectionBRef,
    sectionCRef,
    sectionDRef,
    referralRef,
  ];

  function handleBack() {
    const goBack = sectionRefs[activeStep]?.current?.back();
    if (goBack) setActiveStep((prev) => prev - 1);
  }
  const handleNext = async () => {
    try {
      setLoading(true);
      const goNext = await sectionRefs[activeStep]?.current?.next();
      setLoading(false);
      if (goNext) setActiveStep((prev) => prev + 1);
      scrollRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const sections = [
    <PayorSelection ref={payorSelectionRef} setShowPAFForm={setShowPAFForm} onSubmit={setPayor} />,
    <SectionA
      ref={sectionARef}
      isCreating={isCreating}
      setIsCreating={setIsCreating}
      setCaseId={setCaseId}
      caseId={caseId}
      payorId={payor.id}
    />,
    <SectionB ref={sectionBRef} caseId={caseId} payorId={payor.id} />,
    <SectionC ref={sectionCRef} caseId={caseId} payorId={payor.id} />,
    <SectionD ref={sectionDRef} caseId={caseId} payorId={payor.id} />,
    <Referral ref={referralRef} caseId={caseId} payorName={payor.name} />,
    <DigitalSignature caseId={caseId} showQR={activeStep === 5} payorId={payor.id} />,
  ];

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={12} ref={scrollRef}>
        {sections.map((section, sectionIdx) => (
          <div
            key={`pafform-section-${sectionIdx}`}
            className={clsx(activeStep !== sectionIdx && classes.hidden)}
          >
            {section}
          </div>
        ))}
      </Grid>
      {activeStep !== sections.length - 1 && (
        <Grid item xs={12} className={classes.buttonGroup}>
          <Button className={classes.button} variant="contained" onClick={handleBack}>
            BACK
          </Button>
          <LoadingButton
            className={clsx(classes.button, classes.orange)}
            variant="contained"
            onClick={handleNext}
            loading={loading}
            text="NEXT"
          />
        </Grid>
      )}
    </Grid>
  );
};
