import React, { Component, createRef } from 'react';
import { withStyles, Container } from '@material-ui/core';
import { ReactHelmet } from '@components/react-helmet/Helmet';
import {
  ViewPAFCaseDetails,
  PAFSubmissionCompletion,
} from './components/case-details/PAFCaseDetailsComponent';
import { getPAFCaseDetails, postHospitalCharges } from '@api';
import { getClientConfig } from 'apis/paf';
import Alert from '@material-ui/lab/Alert';
import { roleTypeKey, liaisonManager, specialist } from '@features/common/config';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const useStyles = (theme) => ({});

const errorEmptyField = 'This field cannot be empty';

class PAFCaseDetails extends Component {
  constructor(props) {
    super(props);
    this.errorRef = createRef();
    this.state = {
      caseDetails: {},
      hospitalCharge: {
        minCost: '',
        maxCost: '',
      },
      emptyFieldError: {
        minCost: '',
        maxCost: '',
      },
      submissionComplete: false,
      isShowHospitalCharge: false,
      errorMessage: '',
      statuses: [],
      currencyLabel: null,
    };
  }

  componentDidMount() {
    this.getCaseDetails(cookies.get(roleTypeKey));
  }

  getCaseDetails = async (roleTypeValue) => {
    try {
      const respondData = await getPAFCaseDetails(this.props.match.params.caseId);
      if (respondData.code && respondData.data) {
        this.setState({
          caseDetails: respondData.data.data,
          isShowHospitalCharge:
            roleTypeValue === liaisonManager && !respondData.data.data.isHospitalPartCompleted,
        });
        this.evaluateStatus(respondData.data.data, roleTypeValue);
        this.state.isShowHospitalCharge && this.getCurrencyLabel(respondData.data.data?.clientId);
      } else {
        this.setState({ errorMessage: 'Something went wrong' });
      }
    } catch (error) {
      console.log('something went wrong', error);
      this.setState({ errorMessage: 'Something went wrong' });
    }
  };

  evaluateStatus = (response, roleTypeValue) => {
    const actionNeeded = 'Action Needed';
    const pendingPatient = 'Pending Patient Signature';
    const pendingSpecialist = 'Pending Specialist Signature';
    const pendingHospital = 'Pending Hospital Charges';
    const completed = 'Completed';
    const pendingPolicyHolder = `Pending Policy Holder's Signature`;

    let statuses = [];
    if (
      (roleTypeValue === liaisonManager && !response.isHospitalPartCompleted) ||
      (roleTypeValue === specialist && !response.isSpecialistPartCompleted)
    ) {
      statuses.push(actionNeeded);
      this.setState({ statuses: statuses });
      return;
    }
    if (
      response.isPatientPartCompleted &&
      response.isSpecialistPartCompleted &&
      response.isHospitalPartCompleted &&
      (response.isPolicyHolderPartCompleted !== undefined &&
      response.isPolicyHolderPartCompleted !== null
        ? response.isPolicyHolderPartCompleted
        : true)
    ) {
      statuses.push(completed);
      this.setState({ statuses: statuses });
      return;
    }
    response.isPolicyHolderPartCompleted !== undefined &&
      response.isPolicyHolderPartCompleted !== null &&
      !response.isPolicyHolderPartCompleted &&
      statuses.push(pendingPolicyHolder);
    !response.isPatientPartCompleted && statuses.push(pendingPatient);
    !response.isSpecialistPartCompleted && statuses.push(pendingSpecialist);
    roleTypeValue === specialist &&
      !response.isHospitalPartCompleted &&
      statuses.push(pendingHospital);

    this.setState({ statuses: statuses });
  };

  onTextFieldChange = (v, field) => {
    this.setState({
      hospitalCharge: { ...this.state.hospitalCharge, [field]: v },
      emptyFieldError: { ...this.state.emptyFieldError, [field]: '' },
    });
  };

  getCurrencyLabel = async (clientId) => {
    try {
      const respondData = await getClientConfig(clientId);
      this.setState({
        currencyLabel: respondData?.data?.data?.currency,
      });
    } catch (err) {}
  };

  postHospitalCost = () => {
    const json = {
      caseId: this.state.caseDetails['caseId'],
      minCost: Number(this.state.hospitalCharge.minCost),
      maxCost: Number(this.state.hospitalCharge.maxCost),
    };
    postHospitalCharges(json)
      .then(() => {
        this.setState({ submissionComplete: true });
      })
      .catch((err) => {
        console.log('something went wrong', err);
        this.setState({ errorMessage: 'Failed to save hospital charges.' });
        this.executeScroll();
      });
  };

  onSubmitCharge = () => {
    this.setState({ errorMessage: '' });
    if (Object.keys(this.state.hospitalCharge).every((c) => this.state.hospitalCharge[c])) {
      if (Number(this.state.hospitalCharge.minCost) >= Number(this.state.hospitalCharge.maxCost)) {
        this.setState({ errorMessage: 'Minimum cost cannot be larger than maximum cost.' });
        this.executeScroll();
        return;
      }
      this.postHospitalCost();
    } else {
      this.setState({
        emptyFieldError: {
          minCost: !this.state.hospitalCharge.minCost && errorEmptyField,
          maxCost: !this.state.hospitalCharge.maxCost && errorEmptyField,
        },
      });
    }
  };

  executeScroll = () =>
    this.errorRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });

  render() {
    return (
      <Container ref={this.errorRef}>
        <ReactHelmet title="Case Details" />
        {this.state.errorMessage && <Alert severity="error">{this.state.errorMessage}</Alert>}
        <div style={{ display: this.state.submissionComplete ? 'none' : null }}>
          <ViewPAFCaseDetails
            status={this.state.statuses}
            caseInfo={this.state.caseDetails}
            onClickBack={() => this.props.history.goBack()}
            onClickProceed={
              this.state.isShowHospitalCharge
                ? this.onSubmitCharge
                : () => this.props.history.push('/paf') //TO REPLACE PATH WITH SPECIALIST QR CODE PAGE IN FUTURE
            }
            onTextFieldChange={this.state.isShowHospitalCharge && this.onTextFieldChange}
            emptyFieldError={this.state.emptyFieldError}
            currencyLabel={this.state.currencyLabel ?? 'SGD'}
          />
        </div>
        <div style={{ display: this.state.submissionComplete ? null : 'none' }}>
          <PAFSubmissionCompletion />
        </div>
      </Container>
    );
  }
}
export default withStyles(useStyles)(PAFCaseDetails);
