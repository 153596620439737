import { makeStyles } from '@material-ui/core/styles';
import { Select, MenuItem, Checkbox, ListItemText, Chip } from '@material-ui/core';
import { isMobile, isIOS, isSafari } from 'react-device-detect';

const DEFAULT = 'default';
const APPEND = 'append';
const CHIPS = 'chips';
const optionItem = (value, disabled) => ({ value, disabled });
// no default value on multiselect

export const SmarterDropdown = ({
  id,
  value,
  innerLabel = '',
  data = [],
  onChange,
  defaultValue = '',
  disabled = false,
  multiselect = false,
  inputVariant = 'default',
  checked = [],
  error,
  helperText,
  variant = 'outlined',
  ...props
}) => {
  const classes = useStyles({ ...props, disabled });
  if (multiselect && inputVariant === 'default') inputVariant = 'append'; // no strong logic for append yet

  const isApple = isIOS || isSafari;

  const Option = isMobile ? 'option' : MenuItem;

  const content = (item) => {
    if (!multiselect || isMobile || isApple) return item;
    return (
      <>
        <Checkbox checked={value.indexOf(item) > -1} />
        <ListItemText primary={item} />
      </>
    );
  };

  const options = (() => {
    let dataOptions = [...data];
    if (defaultValue) dataOptions = [optionItem(defaultValue, false), ...dataOptions];
    if (disabled) {
      const item = value || defaultValue || innerLabel;
      dataOptions = item ? [optionItem(item, false), ...dataOptions] : dataOptions;
    }

    return dataOptions;
  })();

  const innerItems = {
    [DEFAULT]: (selection) => selection,
    [APPEND]: (selected) => selected.join(', '),
    [CHIPS]: (selected) => (
      <div className={classes.chips}>
        {selected.map((selection) => (
          <Chip
            key={selection}
            label={selection}
            className={classes.chip}
            onDelete={onRemoveChips(selection, selected)}
            onMouseDown={(event) => event.stopPropagation()}
          />
        ))}
      </div>
    ),
  };

  function onRemoveChips(target, selected) {
    return () => {
      const newSelection = selected.filter((item) => item !== target);

      handleChange({ target: { value: newSelection } });
    };
  }

  function handleChange(event) {
    if (!(multiselect || isMobile)) return onChange(event.target.value);

    let values;
    if (isMobile) {
      values = Array.from(event.target.options)
        .filter((item) => !!item.selected)
        .map((item) => item.value);
    } else {
      values = Array.from(event.target.value).filter((item) => !!item);
    }

    onChange(values);
  }

  return (
    <>
      <Select
        value={value || defaultValue || innerLabel}
        labelId={`form-dropdown-${id}`}
        id={id}
        onChange={handleChange}
        error={error}
        native={!!isMobile}
        multiple={multiselect}
        renderValue={innerItems[inputVariant]}
        inputProps={{ classes: { disabled: classes.disabled } }}
        MenuProps={{ variant: 'menu' }}
        variant={variant}
        {...props}
      >
        {options.map((item) => (
          <Option key={`form-dropdown-${item.value}`} value={item.value} disabled={item.disabled}>
            {content(item.value)}
          </Option>
        ))}
      </Select>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  formHelperText: {
    marginLeft: theme.spacing(2),
    marginBottom: ({ helperText }) => (helperText ? -theme.spacing(2.5) : ''),
  },
  chips: { display: 'flex', flexWrap: 'wrap' },
  chip: { margin: 2 },
  disabled: { backgroundColor: '#ECECEC' },
}));
