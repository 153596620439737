import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { numberSeparator } from 'utils/utils';

export const CostDescription = ({ description, amount, strong, currency, ...props }) => {
  const classes = useStyles();
  const { currAbbr } = currency;

  return (
    <Grid container {...props}>
      <Grid item xs={7}>
        <Typography className={strong && classes.bold}>{description}</Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography className={clsx(classes.symbol, strong && classes.bold)}>{`${numberSeparator(
          amount,
        )} ${currAbbr}`}</Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  bold: { fontWeight: theme.typography.fontWeightBold },
  symbol: { textAlign: 'right' },
}));
