import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  MenuItem,
  Badge,
  IconButton,
  makeStyles,
  Button,
  Paper,
  Grow,
  Popper,
  ClickAwayListener,
  MenuList,
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import smarterhealthLogo from '@images/clients/smarterhealth/logoWithOnlyText.svg';
import admedikaLogo from '@images/clients/admedika/logo.jpg';
import allianzLogo from '@images/clients/allianz/logo.jpeg';
import lippoLifeLogo from '@images/clients/lippolife/logo.jpeg';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PersonIcon from '@material-ui/icons/Person';
import './layout.css';
import { get } from '@services/http_services/http_services';
import Logout from '@features/common/icons_svg/logout_create_svg';
import { arrowApiPrefix, lippolife } from '../common/config';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    //marginLeft: '7%'
  },
  appBar: {
    zIndex: 999,
    backgroundColor: theme.palette.primary.light,
    boxShadow: '0.2em 0.2em 1em rgba(153, 153, 153, 0.2)',
    padding: '1.4em 0em 0.5em',
    height: '5.7rem',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  logoSmarterHealth: {
    width: '14em',
  },
  logoAdmedika: {
    width: '9em',
  },
  logoAllianz: {
    width: '11em',
  },
  logoLippoLife: {
    width: '6em',
  },
  iconBtn: {
    color: '#03ACEF',
  },
  customBadge: {
    backgroundColor: '#EF5350',
    // color: "#F37D2A"
  },
  iconText: {
    margin: 2,
    color: 'black',
  },
}));

/**
 **@onThemeTypeSwitch higher order component function to trigger on theme change
 * **/
export default function BentoAppBar({ onThemeTypeSwitch }) {
  const classes = useStyles();
  const [auth, setAuth] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null); //for desktop view
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [themeType, setThemeType] = useState(true);

  const [displayName, setDisplayName] = useState(null);
  const [dropdownList, setDropdownList] = useState(null);
  const [logoutURL, setLogoutURL] = useState(null);
  const [cookie, setCookie] = useState({
    client: cookies.get('client'),
    userId: cookies.get('userId'),
  });

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const clientConfig = () => {
    if (cookie.client === 'Admedika') {
      return { logo: admedikaLogo, className: classes.logoAdmedika };
    } else if (cookie.client === 'Allianz') {
      return { logo: allianzLogo, className: classes.logoAllianz };
    } else if (cookie.client === lippolife) {
      return { logo: lippoLifeLogo, className: classes.logoLippoLife };
    }
    else {
      return { logo: smarterhealthLogo, className: classes.logoSmarterHealth };
    }
  };

  /**
   * Webview, open profile Dropdown on user account trigger
   * **/

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  // new menu dropdown for user to match UI
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  // desktop view onclick
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const logout = () => {
    window.location = logoutURL ?? '/access/logout';
  };

  const username = () => {
    get(`/user/profile/${cookie.userId}/attributes`)
      .then((res) => {
        if (res.code && res.data) {
          setDisplayName(res.data.firstname);
          localStorage.setItem('userName', `${res.data.firstname} ${res.data.lastname}`); //TODO: TEMP, need to move this to global state
        }
      })
      .catch((err) => console.log('failed ', err));
  };

  const userDropdown = () => {
    get(`/${arrowApiPrefix}/user/menu`)
      .then((res) => {
        if (res.code && res.data) {
          const dropdownObj = res.data.map((data) => {
            if (data[0] === 'Log out') {
              setLogoutURL(data[1]);
            }
            return {
              title: data[0],
              url: data[1],
            };
          });
          setDropdownList(dropdownObj);
        }
      })
      .catch((err) => console.log('failed ', err));
  };

  useEffect(() => {
    userDropdown();
    username();
  }, []);

  const dropdownMenuList =
    (clickAway) =>
    ({ TransitionProps, placement }) =>
      (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <Paper>
            <ClickAwayListener onClickAway={clickAway}>
              <MenuList
                style={{ outline: 'none', paddingTop: 'unset' }}
                autoFocusItem={open}
                id="menu-list-grow"
                onKeyDown={handleListKeyDown}
              >
                <MenuItem
                  style={{
                    fontFamily: 'Montserrat-Regular',
                    fontSize: '14px',
                    backgroundColor: '#03ACEF',
                    color: 'white',
                    opacity: 1,
                    borderTopLeftRadius: '4px',
                    borderTopRightRadius: '4px',
                    paddingTop: '10px',
                    justifyContent: 'flex-end',
                  }}
                  disabled
                >
                  Settings
                </MenuItem>
                {dropdownList &&
                  dropdownList.map((data, i) => (
                    <MenuItem
                      key={i}
                      style={{
                        fontFamily: 'Montserrat-Regular',
                        fontSize: '14px',
                        justifyContent: 'flex-end',
                      }}
                      onClick={() => (window.location = data.url)}
                    >
                      {data.title}
                    </MenuItem>
                  ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      );

  const notificationIcon = (
    <IconButton
      aria-label="show 17 new notifications"
      color="inherit"
      disableTouchRipple
      style={{ backgroundColor: 'transparent' }}
    >
      <Badge badgeContent={2}>
        <NotificationsIcon className={classes.iconBtn} />
      </Badge>
    </IconButton>
  );

  const logoutIcon = (margin) => (
    <IconButton
      style={{ marginTop: margin, marginLeft: '20px', backgroundColor: '#E0E1E2' }}
      disableTouchRipple
      onClick={logout}
    >
      <Logout style={{ fontSize: 18 }} />
    </IconButton>
  );

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <div className={classes.title}>
            <a href="/">
              <img src={clientConfig().logo} alt="logo" className={clientConfig().className} />
            </a>
          </div>
          {auth && (
            <div>
              <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
                <Button
                  ref={anchorRef}
                  aria-controls={open ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                  style={{ backgroundColor: 'transparent', textTransform: 'none' }}
                  endIcon={<ArrowDropDownIcon color="primary" />}
                  disableTouchRipple
                >
                  <p
                    style={{
                      color: '#088CCA',
                      fontFamily: 'Montserrat-Regular',
                      fontSize: '1rem',
                      fontWeight: 'bold',
                    }}
                  >
                    {displayName}
                  </p>
                </Button>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
                  {dropdownMenuList(handleClose)}
                </Popper>
                <div>{logoutIcon('15px')}</div>
              </div>

              <div className={classes.sectionMobile}>
                <Button
                  ref={mobileMoreAnchorEl}
                  aria-controls={isMobileMenuOpen ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  style={{ backgroundColor: 'transparent', textTransform: 'none' }}
                  endIcon={<ArrowDropDownIcon color="primary" />}
                  disableTouchRipple
                >
                  <PersonIcon className={classes.iconBtn} />
                </Button>
                <Popper
                  open={isMobileMenuOpen}
                  anchorEl={mobileMoreAnchorEl}
                  role={undefined}
                  transition
                >
                  {dropdownMenuList(handleMobileMenuClose)}
                </Popper>
                <div>{logoutIcon('unset')}</div>
              </div>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
