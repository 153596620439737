import React, { useState } from 'react';
import { useTheme } from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { DynamicForm } from '../form/DynamicForm';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleSharpIcon from '@material-ui/icons/RemoveCircleSharp';
const useStyles = makeStyles((theme) => ({
  addLabel: {
    color: theme.palette.primaryBlue,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
    cursor: 'pointer',
  },
  wrapper: {
    [theme.breakpoints.up('sm')]: {
      padding: '15px 30px 15px 30px',
      marginBottom: '30px',
    },
    padding: '5px 15px',
    marginBottom: '10px',
    border: `2px solid ${theme.palette.primaryBlue}`,
    borderRadius: '8px',
  },
}));

export const Group = ({
  fields = [],
  addLabel = 'Click here to add more',
  onChange = null,
  ...props
}) => {
  const [groups, setGroups] = useState([fields]);
  const [data, setData] = useState([]);
  const classes = useStyles();
  const theme = useTheme();

  const addGroup = () => {
    setGroups([...groups, { ...fields }]);
  };

  const removeGroup = (index) => {
    const temp = [...groups];
    temp.splice(index, 1);
    setGroups(temp);
    updateGroupData({}, index);
  };

  const updateGroupData = (value, index) => {
    const temp = [...data];
    if (_.isEmpty(value)) {
      temp.splice(index, 1);
    } else {
      temp[index] = value;
    }
    setData(temp);
    onChange && onChange(temp);
  };

  const buildFormModel = (formConfig) => {
    let result = {};
    formConfig.map((section) => {
      section.fields.map((field) => {
        result[field.inputName] = field.value || '';
      });
    });
    return result;
  };

  return (
    <>
      {groups.map((e, index) => {
        return (
          <div className={classes.wrapper} key={'section-' + index}>
            {groups.length > 1 && (
              <span className={classes.addLabel} onClick={() => removeGroup(index)}>
                <RemoveCircleSharpIcon style={{ color: theme.palette.primaryBlue }} />
              </span>
            )}
            {!_.isEmpty(fields) ? (
              <DynamicForm
                formConfig={fields}
                formModel={buildFormModel(fields)}
                setFormModel={(e) => updateGroupData(e, index)}
              />
            ) : (
              props.children
            )}
          </div>
        );
      })}
      <span className={classes.addLabel} onClick={addGroup}>
        <AddCircleIcon style={{ color: theme.palette.primaryBlue }} />
        <span style={{ marginLeft: '10px' }}>{addLabel}</span>
      </span>
    </>
  );
};
