import React from 'react';
import {
  Button,
  Grid,
  Divider,
  Typography,
  ListItem,
  List,
  Link,
  Paper,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
  convertAppointmentDate,
  convertAppointmentTime,
  convertFullDisplayDate,
  dateConvert,
  getTimeZoneString,
} from '../../common/config';
import { getValueOrDefault } from '../../../utils/utils'

const buttonColor = '#03ACEF';
const backgroundColor = '#EEF1F4';
const borderRadius = '0.3cm';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    flexGrow: 1,
    padding: 5,
  },
  root: {
    maxWidth: '700px'
  },
  mobileViewControlCaseDetails: {
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  paper: {
    borderRadius: borderRadius,
    width: '100%',
    padding: '20px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      overflowX: 'auto',
    },
  },
  detail: {
    justifyContent: 'center',
    marginBottom: 8,
    display: 'grid',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  customRescheduleButton: {
    textTransform: 'none',
    color: 'white',
    fontSize: 15,
    backgroundColor: buttonColor,
    '&:hover': {
      backgroundColor: buttonColor,
    },
    marginLeft: theme.spacing(1),
  },
  listItems: {
    paddingTop: 0,
    paddingLeft: 0,
  },
  status: {
    marginTop: '20px'
  },
  divider: {
    padding: 'unset !important',
    paddingTop: '8px !important',
    paddingBottom: '8px !important',
  },
  wrapper: {
    width: 'max-content',
    display: 'flex',
    '& .wrapper__inner': {
      width: 'auto',
    },
    '& .item': {
      width: 'auto',
      display: 'flex',
      minWidth: '100%',
    },
    '& .label': {
      flex: '0 0 35%',
      padding: '20px',
      backgroundColor: backgroundColor,
    },
    '& .content': {
      padding: '20px',
    },
  },
}));

export const ViewCaseDetails = ({
  onClick,
  serviceType,
  appointmentDetails,
  patientDetails,
  medicalCondition,
  checkDocumentsLength,
  otherReason,
  ...props
}) => {
  const classes = useStyles();
  const isOPD = serviceType.toLowerCase() === 'opd';
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const renderMedicalCondition = () => {
    return medicalCondition.length > 1
      ? medicalCondition.map((condition, i) => (
          <ListItem className={classes.listItems} key={i} component="div">
            <Typography>
              <li>{condition}</li>
            </Typography>
          </ListItem>
        ))
      : medicalCondition.map((condition, i) => (
          <ListItem className={classes.listItems} key={i} component="div">
            <Typography>{condition === '' ? 'N/A' : condition}</Typography>
          </ListItem>
        ));
  };

  const renderDocument = () => {
    return (
      <List style={{ paddingTop: 0 }}>
        {checkDocumentsLength !== 0 ? (
          patientDetails.documentLinks.map((doc, i) => {
            return (
              <ListItem className={classes.listItems} key={i}>
                <Link href={doc} key={i + 1}>
                  <Typography>Document {i + 1}</Typography>
                </Link>
              </ListItem>
            );
          })
        ) : (
          <Typography>No Documents</Typography>
        )}
      </List>
    );
  };

  const detail = [
    {
      label: isOPD ? 'Specialist' : 'Screening',
      content: isOPD ? appointmentDetails[0] : appointmentDetails[1],
    },
    {
      label: 'Facility',
      content: appointmentDetails[2],
    },
    {
      is: 'divider',
    },
    {
      label: 'Name',
      content: getValueOrDefault(patientDetails.name),
    },
    {
      label: 'Gender',
      content: patientDetails.gender,
    },
    {
      label: 'Age',
      content: patientDetails.age,
    },
    {
      label: 'Date of Birth',
      content: convertFullDisplayDate(patientDetails.dob),
    },
    {
      label: 'Email',
      content: getValueOrDefault(patientDetails.email),
    },
    {
      label: 'Mobile',
      content: getValueOrDefault(patientDetails.sms),
    },
    {
      label: 'Medical History',
      customRender: renderMedicalCondition,
    },
    {
      label: ' Reason for Consultation / Any Other Information',
      content: getValueOrDefault(otherReason, 'N/A'),
    },
    {
      label: 'Documents',
      customRender: renderDocument,
    },
  ];
  return (
    <Grid container direction="row" justifyContent="space-evenly" className={classes.tableContainer}>
      <Grid item xs={12} style={{ height: 150 }}>
        <Link component="button" onClick={onClick}>
          <Typography variant="h6" style={{ marginTop: 40 }}>
            <ArrowBackIosIcon style={{ fontSize: '1rem' }} /> Back
          </Typography>
        </Link>
      </Grid>

      {props.children}

      <Grid item xs={12} className={classes.detail}>
        <Paper className={classes.paper}>
          {matches ? (
            // MOBILE VIEW, DO NOT LIMIT WIDTH
            <div className={classes.wrapper}>
              <div className="wrapper__inner">
                {detail.map(({ label, content, customRender, is = '' }) => {
                  return is === 'divider' ? (
                    <Divider />
                  ) : (
                    <div className="item">
                      <div className="label">
                        <Typography>{label}</Typography>
                      </div>
                      <div className="content">
                        {customRender ? customRender() : <Typography>{content}</Typography>}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            // DESKTOP VIEW LAYOUT COLUMN
            <Grid container direction="row" spacing={5}>
              <Grid
                item
                xs={6}
                style={{ backgroundColor: backgroundColor, borderTopLeftRadius: borderRadius }}
              >
                <Typography>{isOPD ? 'Specialist' : 'Screening'}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{isOPD ? appointmentDetails[0] : appointmentDetails[1]}</Typography>
              </Grid>
              <Grid item xs={6} style={{ backgroundColor: backgroundColor }}>
                <Typography>Facility</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{appointmentDetails[2]}</Typography>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ backgroundColor: backgroundColor }}
                className={classes.divider}
              >
                <Divider />
              </Grid>
              <Grid item xs={6} className={classes.divider}>
                <Divider />
              </Grid>
              <Grid item xs={6} style={{ backgroundColor: backgroundColor }}>
                <Typography>Name</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{getValueOrDefault(patientDetails.name)}</Typography>
              </Grid>
              <Grid item xs={6} style={{ backgroundColor: backgroundColor }}>
                <Typography>Gender</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{patientDetails.gender}</Typography>
              </Grid>
              <Grid item xs={6} style={{ backgroundColor: backgroundColor }}>
                <Typography>Age</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{patientDetails.age}</Typography>
              </Grid>
              <Grid item xs={6} style={{ backgroundColor: backgroundColor }}>
                <Typography>Date of Birth</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{convertFullDisplayDate(patientDetails.dob)}</Typography>
              </Grid>
              <Grid item xs={6} style={{ backgroundColor: backgroundColor }}>
                <Typography>Email</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{getValueOrDefault(patientDetails.email)}</Typography>
              </Grid>
              <Grid item xs={6} style={{ backgroundColor: backgroundColor }}>
                <Typography>Mobile</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{getValueOrDefault(patientDetails.sms)}</Typography>
              </Grid>
              <Grid item xs={6} style={{ backgroundColor: backgroundColor }}>
                <Typography>Medical History</Typography>
              </Grid>
              <Grid item xs={6}>
                {renderMedicalCondition()}
              </Grid>
              <Grid item xs={6} style={{ backgroundColor: backgroundColor }}>
                <Typography>Reason for Consultation / Any Other Information</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{getValueOrDefault(otherReason, 'N/A')}</Typography>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ backgroundColor: backgroundColor, borderBottomLeftRadius: borderRadius }}
              >
                <Typography>Documents</Typography>
              </Grid>
              <Grid item xs={6}>
                {renderDocument()}
              </Grid>
            </Grid>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export const AppointmentDate = ({ appointmentDates, appointmentStatus, onClick }) => {
  const classes = useStyles();
  console.log('appointments', appointmentDates);
  return (
    <Grid item xs={12} className={classes.mobileViewControlCaseDetails}>
      <Grid container direction="row" justifyContent="flex-end" spacing={1} className={classes.root}>
        <Grid item xs={12} md={5}>
          <Typography variant="h6">Appointment Schedule: </Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <div>
            {appointmentDates.map((date) => {
              if (date) {
                const convertEndDate = dateConvert(date.end, getTimeZoneString(date.end));
                const convertStartDate = dateConvert(date.start, getTimeZoneString(date.start));
                return (
                  <Typography variant="h6">
                    {convertAppointmentDate(convertStartDate) +
                      ', ' +
                      convertAppointmentTime(convertStartDate, convertEndDate)}
                  </Typography>
                );
              }
              return '';
            })}
          </div>
        </Grid>
        {onClick && (
          <Grid item xs={12} md={2}>
            <div>
              <Button className={classes.customRescheduleButton} onClick={onClick}>
                Reschedule
              </Button>
            </div>
          </Grid>
        )}
      </Grid>
      <Typography style={{ fontSize: 16 }} className={classes.status}>
        {appointmentStatus && `Status: ${appointmentStatus}`}
      </Typography>
    </Grid>
  );
};
