import React from 'react';
import {
  Checkbox,
  withStyles
} from "@material-ui/core";
import DisableBox from '../../common/icons_svg/disable_box_svg';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';


const StyledTreeItem = withStyles((theme) => ({
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed black`
  }
}))((props) => <TreeItem id="tree" {...props} />);

export default class AutoComplete extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    renderTree = (nodes) => {
      return nodes.map((node) => (
        <StyledTreeItem
          key={node.id}
          nodeId={node.id}
          onIconClick={() => this.handleClickTree(node.id)}
          onLabelClick={() => this.handleClickTree(node.id)}
          label={
            node.children === undefined ? (
              <div style={{ display: "flex" }}>
  
                <Checkbox
                  style={{ alignItems: "start" }}
                  onChange={(event) =>
                    this.props.handleLocationChange(event.target.checked, node.unitId, node.resourceId, node.resourceName, node.name) 
                  }
                  checked={this.props.checked(node.unitId)}
                />
                <div style={{ paddingTop: 9 }}>{node.name}</div>
                
              </div>
            ) : (
              node.name
            )
          }
        >
          {Array.isArray(node.children) ? this.renderTree(node.children) : null}
        </StyledTreeItem>
      ));
    };
  
    //tree item control expand
    handleClickTree = (id) => {
      const expanded = this.props.defaultExpanded.slice();
      const index = expanded.indexOf(id);

      if (index === -1) {
        expanded.push(`${id}`);
      } else {
        expanded.splice(index, 1);
      }
      this.props.setDefaultExpanded(expanded);
    };

 

  render() {
      return (
        <TreeView
          expanded={this.props.defaultExpanded}
          defaultCollapseIcon={<IndeterminateCheckBoxOutlinedIcon />}
          defaultExpandIcon={<AddBoxOutlinedIcon />}
          defaultEndIcon={<DisableBox id="tree" style={{ marginTop: 12, opacity: 0.5 }} />}
        >
          {this.renderTree(this.props.treeSearchData)}
        </TreeView>
      );
  }

}
