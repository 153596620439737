import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import { getQR } from '@api/paf';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  title: { fontSize: theme.typography.htmlFontSize, textAlign: 'center' },
  root: { width: theme.spacing(31.25), height: theme.spacing(31.25) },
}));

export const DigitalSignature = ({ caseId, showQR, payorId }) => {
  const classes = useStyles();
  const [QR, setQR] = useState('');
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (caseId && showQR) {
      const cookies = new Cookies();
      const payload = {
        clientId: payorId,
        caseId,
        roleType: cookies.get('roleId')?.toLowerCase(),
        signerId: parseInt(cookies.get('userId')),
        signerName: localStorage.getItem('userName'), // TEMP ,
      };
      getQR(payload)
        .then((res) => {
          if (res.code && res.data) {
            const newFile = new File([res.data], 'qr', { type: 'image/png' });
            const url = URL.createObjectURL(newFile);
            setQR(url);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }
  }, [showQR]);

  return (
    <div>
      <Typography variant="h6" style={{ textAlign: 'center', fontWeight: '600' }}>
        Digital Signature{' '}
      </Typography>
      <Typography className={classes.title}>
        Please use your mobile device to scan the QR code below to initiate the digital signature
        process
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        {loading ? (
          <div className={classes.root}>
            <Skeleton animation="wave" variant="rect" width={250} height={250} />
          </div>
        ) : (
          <img src={QR} />
        )}
      </div>
    </div>
  );
};
