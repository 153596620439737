import React from 'react';
import { useCookies } from '@hooks';
import { Helmet } from 'react-helmet';
import { GTAG_SRC, GTAG_DATA_LAYER } from 'features/common/config';

export const ReactHelmet = ({ title, name }) => {
  const { client } = useCookies();
  return (
    <Helmet>
      <title>{`${title} | ${name ?? client}`}</title>
    </Helmet>
  );
};

export const ReactHelmetGtag = () => {
  return (
    <Helmet>
      <script async src={GTAG_SRC}></script>
      <script>{GTAG_DATA_LAYER}</script>
    </Helmet>
  );
};
