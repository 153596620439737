import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MinusSquare, PlusSquare } from '../icons';
import { TreeView } from '@material-ui/lab';
import _ from 'lodash';
import StyledTreeItem from './StyleTreeItem';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'auto',
    flexGrow: 1,
    width: '100%',
  },
  highlight: {
    color: theme.palette.primary.main,
    fontWeight: '600',
  },
}));

export const SmarterTreeView = ({ highlight = '', data, showCode = false, onClick = null }) => {
  const classes = useStyles();

  const renderTree = (nodes = data) => {
    return (
      <>
        {nodes.map((node) => (
          <StyledTreeItem
            id={node.nodeDetails.icd10Code}
            key={node.nodeDetails.icd10Code}
            nodeId={node.nodeDetails.icd10Code}
            label={computeLabel(node.nodeDetails)}
          >
            {Array.isArray(node.children) && node.children.length
              ? renderTree(node.children)
              : null}
          </StyledTreeItem>
        ))}
      </>
    );
  };

  const highlightSearch = ({ icd10Code, icd10Description }, highlightIndex) => {
    const previous = icd10Description.slice(0, highlightIndex);
    const after = icd10Description.slice(
      highlightIndex + highlight.length,
      icd10Description.length,
    );
    const actualHighlightSearch = icd10Description.slice(
      highlightIndex,
      highlightIndex + highlight.length,
    );
    return (
      <div
        onClick={(event) => {
          handleOnClick(event, icd10Code, icd10Description);
        }}
        role={icd10Code}
      >
        {showCode && <span className={classes.truncated}>{icd10Code}: </span>}
        <span className={classes.truncated}>{previous}</span>
        <span className={classes.highlight}>{actualHighlightSearch}</span>
        <span className={classes.truncated}>{after}</span>
      </div>
    );
  };

  const handleOnClick = (event, code, description) => {
    event.preventDefault();
    const temp = showCode ? `${code}: ${description}` : description;
    onClick && onClick(code, temp);
  };

  const computeLabel = (nodeDetail, level) => {
    const { icd10Code, icd10Description } = nodeDetail;
    const highlightIndex = icd10Description.toLowerCase().indexOf(highlight.toLowerCase());
    let result = (
      <div
        onClick={(event) => {
          handleOnClick(event, icd10Code, icd10Description);
        }}
      >
        <span className={classes.truncated} style={{ width: '100%' }}>
          {showCode ? `${icd10Code}: ${icd10Description}` : icd10Description}
        </span>
      </div>
    );
    if (highlightIndex !== -1 && !_.isEmpty(highlight)) {
      result = highlightSearch(nodeDetail, highlightIndex);
    }
    return result;
  };

  const getNodeIds = (nodes = data, nodeIds = []) => {
    nodes.map((node) => {
      if (Array.isArray(node.children) && node.children.length) {
        nodeIds.push(node.nodeDetails.icd10Code);
        getNodeIds(node.children, nodeIds);
      }
    });
    return nodeIds;
  };

  useEffect(() => {
    if (data && !data.length) {
      renderTree();
    }
  }, [data]);

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      defaultExpanded={getNodeIds(data, [])}
    >
      {renderTree(data)}
    </TreeView>
  );
};
