import React, { useRef } from 'react';
import SearchIcon from '@material-ui/icons/Search';

import SmarterPopover from 'components/popover';
import { SmarterTextField } from 'components/input';
import { ProgressiveSearch } from 'components/progressiveSearch';
import { getProcedureCode } from 'apis/paf';
import { useProgressiveSearch } from './useProgressiveSearch';

const DEFAULT_PROCEDURE = { code: '', description: '' };

export const ProcedureSearch = ({
  value,
  onChange,
  disabled = false,
  allowClear = true,
  showCode = false,
  ...props
}) => {
  const anchorRef = useRef();
  const {
    isVisiblePopover,
    anchorEl,
    result: procedure,
    search,
    error,
    width,
    HelperText,
    handleSearch,
    handleSelect,
    handleClose,
    handleChange,
  } = useProgressiveSearch({ defaultResult: DEFAULT_PROCEDURE, onChange, value });

  return (
    <div {...props}>
      <SmarterTextField
        error={error}
        placeholder="Enter Procedure"
        value={procedure.description ? `${procedure.code}: ${procedure.description}` : search}
        onChange={handleChange}
        disabled={disabled}
        inputRef={anchorRef}
        onEnter={handleSearch}
        allowClear={allowClear}
        InputProps={{
          endAdornment: (
            <SearchIcon position="end" style={{ cursor: 'pointer' }} onClick={handleSearch} />
          ),
        }}
        helperText={<HelperText />}
      />
      <SmarterPopover
        id={isVisiblePopover ? 'simple-popover' : undefined}
        open={isVisiblePopover}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <ProgressiveSearch
          apiGetList={getProcedureCode}
          search={search}
          onSearch={handleChange}
          select={procedure}
          onSelect={handleSelect}
          width={width}
          {...props}
        />
      </SmarterPopover>
    </div>
  );
};
