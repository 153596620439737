import React from 'react';
import { Button } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Alert from '@material-ui/lab/Alert';
import { convertAppointmentDate, convertAppointmentTime } from 'features/common/config';
import { getValueByCondition } from 'utils/utils';

const backgroundColor = '#03ACEF';

const confirmationPreferred = (patientName, apptStartDate, apptEndDate, apptLocation) => (
  <>
    Appointment with <b>{patientName}</b> on <b>{convertAppointmentDate(apptStartDate)}</b>{' '}
    <b>{convertAppointmentTime(apptStartDate, apptEndDate)}</b> at <b>{apptLocation}</b> is
    confirmed.
  </>
);

const confirmationPending = (patientName, apptStartDate, apptEndDate, apptLocation) => (
  <>
    You will be notified when <b>{patientName}</b> has responded to your proposed appointment for{' '}
    <b>{convertAppointmentDate(apptStartDate)}</b>{' '}
    <b>{convertAppointmentTime(apptStartDate, apptEndDate)}</b> at <b>{apptLocation}</b>.
  </>
);

export const ConfirmationAlert = ({
  patientName,
  apptStartDate,
  apptEndDate,
  apptLocation,
  isPreferredSlot,
  onClick,
}) => {
  return (
    <Alert
      style={{ textAlignLast: 'center', fontSize: 16, padding: 30 }}
      icon={<InfoIcon style={{ color: backgroundColor }} />}
      variant="outlined"
      severity="info"
    >
      {getValueByCondition(
        isPreferredSlot,
        confirmationPreferred(patientName, apptStartDate, apptEndDate, apptLocation),
        confirmationPending(patientName, apptStartDate, apptEndDate, apptLocation),
      )}
      <div style={{ textAlignLast: 'right', marginTop: 20 }}>
        <Button
          onClick={onClick}
          disableElevation
          disableRipple
          variant="contained"
          style={{ backgroundColor: backgroundColor, color: 'white' }}
        >
          Ok
        </Button>
      </div>
    </Alert>
  );
};
