import React, { useState } from 'react';
import { ResetPasswordPage } from './ResetPasswordPage';
import { get } from '@services/http_services/http_services';
import { arrowApiPrefix } from '../common/config';

export const PasswordSuccessfullyUpdated = () => {
  const [logoutURL, setLogoutURL] = useState('');
  const redirect = () => {
    get(`/${arrowApiPrefix}/user/menu`)
      .then((res) => {
        if (res.code && res.data) {
          res.data.forEach((data) => {
            if (data[0] === 'Log out') {
              setLogoutURL(data[1]);
            }
          });
        }
      })
      .catch((err) => console.log('failed ', err));
    window.location.href = logoutURL ?? '/access/logout';
  };
  return (
    <ResetPasswordPage
      title="Password Successfully Updated"
      description="You may now proceed to login"
      buttonProps={{
        text: 'Login now',
        onClick: redirect,
      }}
      successfulPage={true}
    />
  );
};
