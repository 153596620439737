import { useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { XL, LG, MD, SM, XS } from '@features/doctor-search/utilities/constants';

export const useResolution = () => {
  const theme = useTheme();
  const [size, setSize] = useState('');

  const extraLarge = useMediaQuery(theme.breakpoints.up(XL));
  const large = useMediaQuery(theme.breakpoints.up(LG));
  const medium = useMediaQuery(theme.breakpoints.up(MD));
  const small = useMediaQuery(theme.breakpoints.up(SM));
  // const mobile = useMediaQuery('(max-width:360px)');
  const extraSmall = useMediaQuery(theme.breakpoints.up(XS));

  useEffect(() => {
    function calculateSize() {
      switch (true) {
        case extraLarge:
          return XL;
        case large:
          return LG;
        case medium:
          return MD;
        case small:
          return SM;
        // case mobile:
        //   return XSMOBILE;
        case extraSmall:
          return XS;
        default:
          return '';
      }
    }
    setSize(calculateSize());
  }, [extraLarge, large, medium, small, extraSmall]);

  return size;
};
