import { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Divider } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

export const SmarterAccordion = ({ id, initialExpanded = false, children, title, ...props }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(initialExpanded);

  function handleExpansion(state) {
    return () => setExpanded(state);
  }

  return (
    <Accordion
      key={`accordion-${id}`}
      className={classes.accordion}
      expanded={expanded}
      onChange={handleExpansion(!expanded)}
      elevation={0}
      {...props}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <h4>{title}</h4>
      </AccordionSummary>
      <Divider />
      <AccordionDetails className={classes.details} {...props.details}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

const useStyles = makeStyles((theme) => ({
  accordion: {
    border: '1px solid lightgray',
    backgroundColor: '#f5faff',
    width: '100%',
  },
  details: {
    backgroundColor: '#f5faff',
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(3)}px ${theme.spacing(6)}px`,
  },
}));
