import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { HORIZONTAL, VERTICAL, XL, LG, MD, SM, XS } from '../../../../utilities/constants';

export function MappedPracticeLocations(locations, size = XL, orientation = HORIZONTAL) {
  const classes = useStyles({ orientation, size });
  return locations.map(({ name, panels }) => {
    const Item = (
      <span className={classes.item}>
        {name}
        {!!panels.length &&
          panels.map(
            (panel, idx) =>
              panel.iconUrl && (
                <img
                  className={classes.icon}
                  key={`panel-logo-${idx}`}
                  src={panel.iconUrl}
                  alt="panel"
                />
              ),
          )}
      </span>
    );
    return Item;
  });
}

const useStyles = makeStyles((theme) => ({
  listItemText: {
    display: 'flex',
    fontWeight: ({ orientation, size }) => orientation === HORIZONTAL && size !== XS && 'lighter',
    fontSize: ({ orientation, size }) => {
      const config = {
        [HORIZONTAL]: {
          [XL]: '14px',
          [LG]: '13px',
          [MD]: '12px',
          [SM]: '12px',
          [XS]: '12px',
        },
        [VERTICAL]: {
          [XL]: '14px',
          [LG]: '12px',
          [MD]: '10px',
          [SM]: '9px',
          [XS]: '8px',
        },
      };
      return config[orientation][size];
    },
  },
  item: { display: ({ orientation }) => (orientation === VERTICAL ? 'inline' : 'inline-flex') },
  icon: {
    verticalAlign: 'text-bottom',
    marginLeft: '1px',
    width: ({ size, orientation }) => {
      const config = {
        [VERTICAL]: {
          [XL]: '24px',
          [LG]: '24px',
          [MD]: '18px',
          [SM]: '17px',
          [XS]: '16px',
        },
        [HORIZONTAL]: {
          [XL]: '19px',
          [LG]: '19px',
          [MD]: '19px',
          [SM]: '19px',
          [XS]: '19px',
        },
      };
      return config[orientation][size];
    },
    height: ({ size, orientation }) => {
      const config = {
        [XL]: '16px',
        [LG]: '16px',
        [MD]: '16px',
        [SM]: '16px',
        [XS]: '16px',
      };
      return orientation === VERTICAL ? '' : config[size];
    },
  },
}));
