import React from 'react';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { makeStyles } from '@material-ui/core/styles';
import { CloudUpload, RemoveRounded, ClearRounded, AddRounded } from '@material-ui/icons';
import { Grid, IconButton } from '@material-ui/core';
import { FormDropdown } from 'components/form';
import { UploadFileContainer } from 'components/input/upload-file';

const useStyles = makeStyles((theme) => ({
  hidden: { display: 'none' },
  headers: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    padding: '8px',
    border: `1px solid rgba(34, 36, 38, 0.15)`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
    maxHeight: '32px',
    '&:hover': {
      borderColor: 'rgba(34, 36, 38, 0.35)',
    },
  },
  label: {
    width: '1rem',
    height: '1rem',
    cursor: 'pointer',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  border: {
    marginTop: '1px',
    width: '100%',
    border: `1px solid`,
    height: theme.spacing(6),
  },
  divider: { marginBottom: theme.spacing(3) },
  buttonText: {
    textTransform: 'none',
  },
}));

export const OtherDocuments = ({ documents, setDocuments }) => {
  const otherSupportingDoc = [
    {
      inputName: 'docType',
      isRequired: true,
      value: '',
      uiLabel: 'Document Type',
      name: 'docType',
      type: 'dropdown',
      props: {
        dataSource: [
          { value: 'Financial Counselling' },
          { value: 'Diagnostic Test Results/Reports' },
          { value: 'Other Medical Reports' },
        ],
      },
    },
    {
      inputName: 'otherDocument',
      isRequired: false,
      value: '',
      uiLabel: ' ',
      name: 'isPatientReferred',
      type: 'uploadImage',
      props: {
        allowUploadMultiDoc: false,
      },
    },
  ];
  const classes = useStyles();

  function handleOnChangeFile(event, idx) {
    const newDocs = [...documents];
    newDocs[idx].document = event?.length ? event[0] : '';
    setDocuments(newDocs);
  }
  function handleOnchangeDocType(event, idx) {
    const newDocs = [...documents];
    newDocs[idx].documentType = event;
    setDocuments(newDocs);
  }

  function handleNewDocument() {
    const newDocument = {
      documentType: '',
      document: '',
    };
    setDocuments([...documents, newDocument]);
  }

  function handleDeleteDocument(index) {
    const newDocs = [...documents];
    newDocs.splice(index, 1);
    setDocuments(newDocs);
  }

  return (
    <>
      <div>
        <h3>Other Supporting Documents</h3>
        <h5>(Financial Counselling, Diagnostic Test Results/Reports or Other Medical Reports)</h5>
      </div>
      {documents.map((document, index) => {
        return (
          <Grid container className={classes.group}>
            <Grid container>
              <Grid item md={1} className={classes.btnContainer}>
                <IconButton
                  className={classes.button}
                  aria-label="delete"
                  onClick={(event) => handleDeleteDocument(event, index)}
                >
                  <RemoveRounded
                    fontSize={'large'}
                    style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '1rem' }}
                  />
                </IconButton>
              </Grid>
              <Grid item md={6}>
                <FormDropdown
                  size="small"
                  data={otherSupportingDoc[0].props.dataSource?.map((option) => option.value) || []}
                  state={document.documentType}
                  handleChange={(event) => handleOnchangeDocType(event, index)}
                  style={{ marginTop: '8px', marginBottom: '8px' }}
                />
              </Grid>
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item md={2}>
              <UploadFileContainer
                label=" "
                allowUploadMultiDoc={false}
                uploadIcon={
                  <CloudUpload
                    fontSize={isMobile ? 'small' : undefined}
                    style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '1rem' }}
                  />
                }
                removeIcon={
                  <ClearRounded
                    fontSize={isMobile ? 'small' : undefined}
                    style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '1rem' }}
                  />
                }
                onChange={(event) => handleOnChangeFile(event, index)}
              />
            </Grid>
          </Grid>
        );
      })}
      <div className={classes.divider} />
      <Grid container alignItems="center">
        <Grid item className={classes.btnContainer} md={1}>
          <IconButton
            className={clsx(classes.btnContainer, classes.button)}
            aria-label="delete"
            onClick={handleNewDocument}
          >
            <AddRounded
              fontSize={'large'}
              style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '1rem' }}
            />
          </IconButton>
        </Grid>
        <label>Add Document</label>
      </Grid>
    </>
  );
};
