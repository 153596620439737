import * as yup from 'yup';

export function validationSchema(fields) {
  let yupShape = {};
  let yupValues = {};

  function buildYup(field) {
    yupShape[field.id] = yupValidation(field);
    const value = matchTypeToValues(field);
    if (value) yupValues[field.id] = value;
  }

  flattenNestedFields(fields).forEach(buildYup);
  yupShape = yup.object().shape(yupShape);

  function handleErrors(error) {
    function reduceErrors(acc, err) {
      acc[err.path] = err.message;
      return acc;
    }
    return error.inner.reduce(reduceErrors, {});
  }

  return yupShape
    ?.validate(yupValues, { abortEarly: false })
    .then(() => {})
    .catch(handleErrors);
}

function yupValidation(field) {
  const validationType = evaluateFieldType(field);
  let validator = yup[validationType]();

  function constructValidator({ params, type }) {
    if (!validator[type]) return;
    validator = validator[type](...params);
  }
  field.validation?.forEach(constructValidator);
  return validator;
}

function flattenNestedFields(fields) {
  let flattenedFields = [];

  function determineIfValidField(node) {
    const isVisible = node.isVisible || node.isVisible === undefined;
    if (!isVisible) return;

    if (node.children) flatten(node.children);
    if (node.validation) flattenedFields.push(node);
  }
  function flatten(nodes) {
    nodes.forEach(determineIfValidField);
  }
  flatten(fields);
  return flattenedFields;
}

const matchTypeToValues = (field) => {
  switch (field.type) {
    case 'procedureCode':
    case 'diagnosisCode':
      return field.value?.description;
    default:
      return field.value;
  }
};

function evaluateFieldType(field) {
  if (['number', 'date'].includes(field.type)) return field.type;
  return 'string';
}
