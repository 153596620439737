import { DynamicForm } from '../../../components/form';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { buildFormModel, convertDateTimeForServer, validate } from '../utils/utils';
import { DetailOfAccident, defaultConfigDOA } from '../components/SectionB/DetailOfAccident';
import { saveAdmissionDetail, getHospitalListByID } from '../../../apis/paf';
import _ from 'lodash';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import { muiDateFormat, roleTypeKey, userIdKey } from '../../common/config';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const SectionB = forwardRef(({ caseId, payorId, ...props }, ref) => {
  const [formModel, setFormModel] = useState({});
  const [errors, setErrors] = useState([]);
  const [hospitalList, setHospitalList] = useState([]);

  const defaultConfig = [
    {
      section: 'Section B: Admission Details',
      fields: [
        {
          inputName: 'admissionDate',
          isRequired: true,
          value: '',
          uiLabel: 'Date Of Admission',
          name: 'dateOfAdmission',
          type: 'date',
          props: {
            dateFormat: `${muiDateFormat}`,
            minDate: new Date(),
          },
        },
        {
          inputName: 'hospital',
          isRequired: true,
          value: '',
          uiLabel: 'Hospital/Facility',
          name: 'presentingSymptoms',
          type: 'dropdown',
          props: {
            dataSource: hospitalList,
          },
        },
        {
          inputName: 'isCausedByAccident',
          isRequired: true,
          value: 'No',
          uiLabel: 'Is this injury/illness caused by an accident?',
          name: 'isCausedByAccident',
          type: 'radio',
          props: {
            dataSource: ['Yes', 'No'],
          },
        },
      ],
    },
  ];

  useEffect(() => {
    payorId && getDataSource();
    const admissionDetail = buildFormModel(defaultConfig);
    setFormModel(admissionDetail);
  }, [caseId, payorId]);

  const getDataSource = () => {
    getHospitalListByID(payorId)
      .then((res) => {
        const dataSource = [];
        if (res.data && res.code) {
          res.data.data?.map((entities) => {
            entities.panelEntity?.map((entity) => {
              if (entity.tpe === 'Hospital' || entity.tpe === 'Facility') {
                entity.userDetail?.find((detail) => {
                  detail.role === cookies.get(roleTypeKey) &&
                    detail.userIds.includes(Number(cookies.get(userIdKey))) &&
                    dataSource.push({ value: entity.spaceName, id: entity.spaceId });
                });
              }
            });
          });
          setHospitalList(dataSource);
        }
      })
      .catch((err) => setErrors(['Unable to retrieve list of Hospital/Facility']));
  };

  const errorRef = useRef(null);
  const executeScroll = () =>
    errorRef?.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });

  const validateSection = () =>
    new Promise((resolve, reject) => {
      let errs = validate(defaultConfig, formModel);
      let errsDOA =
        formModel.isCausedByAccident === 'Yes' ? validate(defaultConfigDOA, formModel) : [];
      if (!_.isEmpty(errs) || !_.isEmpty(errsDOA)) {
        let combineError = [...errs, ...errsDOA];
        reject(combineError);
      } else {
        resolve();
      }
    });

  const retrieveHospitalId = (hospital) => {
    const hospitalData = hospitalList.find((element) => {
      return element.value === hospital;
    });
    return hospitalData.id;
  };

  const submit = () =>
    new Promise((resolve, reject) => {
      validateSection()
        .then(() => {
          setErrors([]);
          const payload = {
            caseId,
            admissionDate: convertDateTimeForServer(formModel.admissionDate),
            hospital: formModel.hospital,
            dateTimeOfAccident: convertDateTimeForServer(formModel.dateTimeOfAccident) || undefined,
            detailsOfAccident: formModel.detailsOfAccident,
            hospitalId: retrieveHospitalId(formModel.hospital),
          };
          saveAdmissionDetail(payload)
            .then((res) => resolve(true))
            .catch(() => reject(false));
        })
        .catch((err) => {
          reject(false);
          setErrors(err);
          executeScroll();
        })
        .finally();
    });

  useImperativeHandle(ref, () => ({
    next: async () => {
      try {
        return await submit();
      } catch (e) {
        console.log(e);
      }
    },
    back: () => {
      return true;
    },
  }));

  return (
    <div ref={errorRef} style={{ width: '700px', margin: 'auto' }}>
      {!_.isEmpty(errors) && (
        <div>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errors.map((error, index) => {
              const idx = index + 1;
              return <Typography key={`search-form-error-${idx}`}>{`${idx}: ${error}`}</Typography>;
            })}
          </Alert>
        </div>
      )}
      <DynamicForm formConfig={defaultConfig} formModel={formModel} setFormModel={setFormModel} />
      {formModel.isCausedByAccident === 'Yes' && (
        <DetailOfAccident formModel={formModel} setFormModel={setFormModel} />
      )}
    </div>
  );
});
