import React, { Component } from 'react';
import {
  Grid,
  Card,
  CardActions,
  CardContent,
  Typography,
  Button,
  withStyles,
} from '@material-ui/core';
import {
  convertAppointmentDate,
  convertAppointmentTime,
  getTimeZoneString,
  dateConvert,
} from '../../common/config';
import { getCaseDetails, getBookingDetails } from '@api';
import { ReactHelmet } from '@components/react-helmet/Helmet';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const background = '#03ACEF';
const borderRadius = '0.3cm';

const useStyles = (theme) => ({
  bodyText: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  buttonAction: {
    justifyContent: 'center',
    paddingBottom: theme.spacing(3),
  },
});

class AppointmentMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unitId: null,
      timezone: null,
      opdSpecialist: null,
      mcuPackage: null,
      preferredDates: [],
    };
  }

  async componentDidMount() {
    await this.getAppointmentInfo();
    await this.getPreferredDates();
  }

  getAppointmentInfo = async () => {
    const respondData = await getCaseDetails(
      this.props.match.params.serviceType,
      this.props.match.params.requesterId,
    );
    if (respondData.code && respondData.data) {
      if (Array.isArray(respondData.data.data.items)) {
        if (this.props.match.params.serviceType.toLowerCase() === 'opd') {
          respondData.data.data.items.map((item) => {
            if (item.specialist) {
              this.setState({ opdSpecialist: item.specialist.displayName, unitId: item.unitId });
            }
          });
        } else {
          respondData.data.data.items.map((item) => {
            if (item.screening) {
              this.setState({ mcuPackage: item.screening.packageName, unitId: item.unitId });
            }
          });
        }
      }
    }
  };

  getPreferredDates = async () => {
    try {
      const respondData = await getBookingDetails(
        this.state.unitId,
        this.props.match.params.requesterId,
      );
      let pDates = [...this.state.preferredDates];
      if (respondData.code && respondData.data) {
        if (Array.isArray(respondData.data.bookedSchedule)) {
          respondData.data.bookedSchedule.map((slot, id) => {
            if (!this.state.timezone) {
              this.setState({ timezone: getTimeZoneString(slot.start) });
            }
            pDates.push({
              start: dateConvert(slot.start, this.state.timezone),
              end: dateConvert(slot.end, this.state.timezone),
            });
          });
          this.setState({ preferredDates: pDates });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    return (
      <React.Fragment>
        <ReactHelmet title="Complete" />
        <Grid container direction="row" justifyContent="space-evenly">
          <Card style={{ borderRadius: borderRadius }}>
            <CardContent>
              <Typography id="title" className={this.props.classes.bodyText}>
                Appointment Request Sent
              </Typography>
              <Typography className={this.props.classes.bodyText}>
                {this.props.match.params.serviceType.toLowerCase() === 'opd'
                  ? `Appointment booked with Dr ${this.state.opdSpecialist}`
                  : `Appointment booked for package: ${this.state.mcuPackage}`}
              </Typography>
              <Typography className={this.props.classes.bodyText}>
                Preferred Date/Time:
                <br />
                {this.state.preferredDates.map((date, id) => (
                  <React.Fragment key={`pdate-${id}`}>
                    {`${convertAppointmentDate(date.start)} ${convertAppointmentTime(
                      date.start,
                      date.end,
                    )}`}
                    <br />
                    {id !== this.state.preferredDates.length - 1 && ['or', <br key={`br-${id}`} />]}
                  </React.Fragment>
                ))}
              </Typography>
              <Typography className={this.props.classes.bodyText}>
                You will be notified once the hospital/doctor accepts your booking.
              </Typography>
            </CardContent>
            <CardActions className={this.props.classes.buttonAction}>
              <Button
                disableRipple
                disableElevation
                variant="contained"
                style={{ backgroundColor: background, color: 'white' }}
                onClick={() => this.props.history.push(`/patient`)}
              >
                Continue
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </React.Fragment>
    );
  }
}
export default withStyles(useStyles)(AppointmentMessage);
