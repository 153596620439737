import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useTheme } from '@material-ui/core';
import { useWidth } from '@hooks';

export const useProgressiveSearch = ({ defaultResult, onChange, value }) => {
  const theme = useTheme();
  const [isVisiblePopover, setIsVisiblePopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [result, setResult] = useState(defaultResult);
  const [search, setSearch] = useState('');
  const [error, setError] = useState(false);
  const width = useWidth();

  const HelperText = () => {
    if (!search.length || (search.length && search.length < 3)) {
      const color = error ? theme.palette.error.main : theme.palette.primary.main;
      return (
        <span style={{ color }}>
          <i>* Input must be more than 2 characters</i>
        </span>
      );
    }
    return (
      <span style={{ color: theme.palette.primary.main }}>
        <i>* Press Enter button to search diagnosis</i>
      </span>
    );
  };

  function handleSearch(event) {
    if (event && search.length > 2) {
      setIsVisiblePopover(true);
      setAnchorEl(event.currentTarget);
    }
    setError(search.length < 3);
  }

  function handleSelect(selected) {
    setResult(selected);
    onChange && onChange({ search, ...selected });
    handleClose();
  }

  function handleClose() {
    setIsVisiblePopover(false);
    setAnchorEl(null);
  }

  function handleChange(newValue) {
    onChange({ ...result, search: newValue });
    setSearch(newValue);
    if (isEmpty(newValue)) {
      setError(false);
      setResult(defaultResult);
      onChange({ ...defaultResult, search: '' });
    }
  }

  useEffect(() => {
    if (value.search || value.code) {
      setSearch(value.search || '');
      setResult({ code: value.code || '', description: value.description || '' });
    }
  }, [value]);

  return {
    isVisiblePopover,
    anchorEl,
    result,
    search,
    error,
    width,
    HelperText,
    handleSearch,
    handleSelect,
    handleClose,
    handleChange,
  };
};
