import React from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Hidden,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Typography,
  GridList,
  GridListTile,
  withStyles
} from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import {enGB} from 'date-fns/locale';
import BigCalendar from '../../common/big_calendar/big_calendar';
import EventCard from "../../common/event_card/event_card";
import {Calendar, dateFnsLocalizer, Views} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {get} from '@services/http_services/http_services';
import DoctorDropdown from "../../common/doctor_dropdown_compoment/doctor_dropdown";
import SingleCalendar  from "../../common/single_view_calendar/index";
import { getTimeZone } from '../../common/config';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const locales = enGB;

const localizer = dateFnsLocalizer({format, parse, startOfWeek, getDay, locales,});

const useStyles = theme => ({
  calenderContainer:{
    flexGrow: 1,
    padding: 5,
    marginBottom: 30
  },
  card:{
    width: '100%',
  },
  avatar:{
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
  },
  cardContent:{
    margin: 3,
    paddingLeft: 20
  },
  cardBtn:{
    backgroundColor:theme.palette.secondary.dark,
  },
  avatarSelected: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: '#03ACEF',
  },
  avatarAvailable: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: '#91DAAC',
  },
  avatarFull: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: '#FF4444',
  },
  avatarScheduled: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: '#4285F4',
  },
  avatarAttended: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: '#00C047',
  },
  avatarNotShow: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: 'yellow',
  },
  avatarCancelled: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: 'red',
  },
  avatarReschedule: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: '#03ACEF',
  },
  list: {
    display: 'flex',
    flexGrow: 1,
  },
  contentList:{
    display: 'flex',
  },
  contentAvatarList:{
    minWidth: 40
  },
  avatarList:{
    minWidth: 25
  },
  card_red:{
    borderLeft: '3px solid red'
  },
  card_green:{
    borderLeft: '3px solid green'
  }
});

class AgentHome extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      selectedDate: new Date(),
      providerCalendar: [],
      selectedDoctor: [],
      bookedSchedule:[],
      bookedSlot:[],
      calendarData:[],
      openDialog: false,
      openEventDialog: false,
      selectedAppointmentData:{},
      appointLabels:[
        "Accepted",
        "Canceled",
        "Consult",
        "ReScheduled"
      ],
      appointmentStatus:"",
      isLoading: false,
    };
  }

  async componentDidMount() {
    await this.initProviderCalendar();
  }

  /**
   * init provider calendar
   * **/
  initProviderCalendar = async () =>{
    const respondData = await get(`provider/${this.props.match.params.providerId}/booking`);
    if(respondData.code && respondData.data !== null){
      if(respondData.data && respondData.data.hasOwnProperty('calendarSummary') && Array.isArray(respondData.data.calendarSummary) && respondData.data.calendarSummary.length > 0){
        const calendarSummary = respondData.data.calendarSummary.map((data => {
          return {
            status : data.status,
            calendarDate: new Date(data.start.toString().replace(getTimeZone(data.start),""))
          }
        }));
        this.setState({providerCalendar: calendarSummary})
      }
    }
  };

  /**
   * Capture User actions and update the state data
   ** @value user selected/enter value
   ** @property action/element name
   * */
  handleChange = (value, propertyName) => {
    this.setState({[propertyName]: value});
  };

  /**
   * Capture Selected appointment
   * @event selected event information
   * * **/
  onEventSelect = (event) =>{
    this.setState({openEventDialog: !this.state.openEventDialog});
  };

  /**
   * Capture New Appoint Data
   * @event capture user event
   * * **/
  onAddAppointment = (event) =>{
    let newAppointmentData = {...this.state.selectedAppointmentData};
    newAppointmentData[event.target.name] = event.target.value;
    this.setState({selectedAppointmentData: newAppointmentData});
  };

  /**
   *
   * **/
  onAppointmentLabelSelect=(e)=>{
    this.setState({appointmentStatus: e.target.value});
  };

  /**
   *
   * **/
  onCalendarSelect = (valaue) =>{
    this.setState({selectedDate: valaue});
  };

  /**
   *
   * **/
  OnDoctorSelect = (doctorUnitId) =>{
    this.setState({isLoading: true});
    new Promise((resolve, reject) => {
      setTimeout(async () => {
        this.setState({selectedDoctor: doctorUnitId, isLoading: false});
        resolve();
      }, 500);
    });
  };

  /**
   * Capture Selected Date & Time
   * @start start Date & Time
   * @end end Date & Time
   * * **/
  onDateSelect = (start, end) =>{
    let newAppointmentData = {...this.state.selectedAppointmentData};
    newAppointmentData.StartTime = 1;
    newAppointmentData.EndTime = 1;
    newAppointmentData.SelectedDate = new Date();
    this.setState({openDialog: true, selectedAppointmentData: newAppointmentData});
  };

  onSubmit = () =>{
    let appointments = [...this.state.bookedSchedule];
    let newAppointmentData = {};
    newAppointmentData.id = 1;
    newAppointmentData.start =new Date( new Date().setHours(14, 0, 0, 0));
    newAppointmentData.end = new Date(new Date().setHours(16, 0, 0, 0));
    newAppointmentData.title = `Consultation `;
    appointments.push(newAppointmentData);
    this.setState({appointment: appointments, openDialog: false});
  };

  render() {
    return (
        <Grid container direction="row" justifyContent="space-evenly" spacing={3} className={this.props.classes.calenderContainer} >
          <Grid item xs={12} align="center" style={{height: 180}}>
            <Typography variant="h4" color="primary" >
              Scheduled Appointment
            </Typography>
          </Grid>
          {/*Calender Component**/}
          <Grid item xs={12} md={3}>
            <Grid container direction="column" justifyContent="flex-start" spacing={4} wrap="wrap">
              <Hidden xsDown>
                <Grid item xs={12}>
                  <BigCalendar
                      user={"Dr.Sample"}
                      onChange={this.onCalendarSelect}
                      selectedDate={this.state.selectedDate}
                      calendarSummary={this.state.providerCalendar}
                  />
                </Grid>
                <Grid item xs={12} >
                  <List className={this.props.classes.list} dense={true}>
                    <ListItem style={{paddingLeft: 2}}>
                      <ListItemAvatar classes={{root: this.props.classes.avatarList}}>
                        <Avatar variant="square" className={this.props.classes.avatarSelected}> </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                          primary="Selected"
                      />
                    </ListItem>
                    <ListItem >
                      <ListItemAvatar classes={{root: this.props.classes.avatarList}}>
                        <Avatar variant="square" className={this.props.classes.avatarAvailable}> </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                          primary="Available"
                      />
                    </ListItem>
                    <ListItem >
                      <ListItemAvatar classes={{root: this.props.classes.avatarList}}>
                        <Avatar variant="square" className={this.props.classes.avatarFull}> </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                          primary="Booking Full"
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
          {/* End Calender Component**/}
          <Grid item xs={12} md={9}>
            <Paper style={{padding: '10px 10px 10px 10px'}}>
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography variant="h4" color="primary" >
                    Appointments
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h5" color="inherit"  align={"right"}>
                    {new Date(this.state.selectedDate).toLocaleDateString("en-GB", { year: 'numeric', month: 'short', day: 'numeric' })}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} style={{padding: '10px 10px 10px 10px'}}>
                <List className={this.props.classes.contentList} dense={true}>
                  <ListItem style={{paddingLeft: 2}}>
                    <ListItemAvatar classes={{root: this.props.classes.contentAvatarList}}>
                      <Avatar variant="square" className={this.props.classes.avatarScheduled}> </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="Scheduled"
                    />
                  </ListItem>
                  <ListItem >
                    <ListItemAvatar classes={{root: this.props.classes.contentAvatarList}}>
                      <Avatar variant="square" className={this.props.classes.avatarAttended}> </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="Attended"
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={5}>
                  <DoctorDropdown
                      selectedDoctor={this.state.selectedDoctor}
                      providerId={this.props.match.params.providerId}
                      onDoctorSelect={this.OnDoctorSelect}
                  />
                </Grid>
                <Grid item xs={12} style={{paddingTop: 25}}>
                  {this.state.isLoading ? 
                    <Grid container direction="row" justifyContent="center" alignContent="center" spacing={3} style={{height: 700}}><CircularProgress /></Grid>
                      :<Grid container justifyContent="space-between" alignContent="flex-start" wrap="wrap" spacing={3} style={{padding: 10}}>
                        <GridList style={{flexWrap: 'nowrap', height: '100%'}} cols={2.5}>
                          {
                            this.state.selectedDoctor.map((doctor, idx) =>{
                              return (
                                  <GridListTile style={{height: 'auto', padding: 10}} key={idx}>
                                    <div style={{textAlign: 'center' }}>
                                      <Typography variant="subtitle1" display="block" gutterBottom>
                                        {doctor.resourceName}
                                      </Typography>
                                    </div>
                                    <SingleCalendar selectedDoctor={doctor} onChange={this.onAppointmentLabelSelect} key={doctor.unitId} selectedDate={new Date(this.state.selectedDate)}/>
                                  </GridListTile>
                              )
                            })
                          }
                        </GridList>
                      </Grid>
                  }
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Dialog
                open={this.state.openDialog}
                onClose={e => {this.handleChange(false, 'openDialog')}}
                fullWidth={true}
            >
              <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Add Appointment
              </DialogTitle>
              <DialogContent dividers>
                <Grid container direction="row" spacing={3} justifyContent="space-around" alignItems="center">
                  <Grid item xs={12}>
                    <Typography variant="button" display="block" gutterBottom>
                      Date: {new Date(this.state.selectedAppointmentData.SelectedDate).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Start Time</InputLabel>
                      <Select
                          labelId="startTimeLabel"
                          name="StartTime"
                          id="startTime"
                          value={this.state.selectedAppointmentData.StartTime}
                          onChange={this.onAddAppointment}
                      >
                        <MenuItem value={1}>1.00 PM</MenuItem>
                        <MenuItem value={2}>2.00 PM</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Start Time</InputLabel>
                      <Select
                          labelId="endTimeLabel"
                          name="EndTime"
                          id="endTime"
                          value={this.state.selectedAppointmentData.EndTime}
                          onChange={this.onAddAppointment}
                      >
                        <MenuItem value={1}>3.00 PM</MenuItem>
                        <MenuItem value={2}>4.00 PM</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.onSubmit} variant="contained" color="primary" >
                  Create Appointment
                </Button>
                <Button onClick={e => {this.handleChange(false,'openDialog')}} variant="contained" className={this.props.classes.cardBtn}>
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
          <Grid item xs={12}>
            <Dialog
                open={this.state.openEventDialog}
                onClose={e => {this.handleChange(false, 'openEventDialog')}}
                fullWidth={true}
                style={{alignContent: 'center'}}
            >
              <DialogTitle id="simple-dialog-title">Change Appointment Status</DialogTitle>
              <DialogContent>
                <FormControl component="fieldset">
                  <RadioGroup aria-label="appointmentStatus" name="appointmentStatus" value={this.state.bookedScheduleStatus} onChange={this.onAppointmentLabelSelect}>
                    {
                      this.state.appointLabels.map(label => <FormControlLabel key={label} value={label} control={<Radio color="primary" />} label={label} />)
                    }
                  </RadioGroup>
                </FormControl>
              </DialogContent>
            </Dialog>
          </Grid>
        </Grid>
    );
  }
}
export default withStyles(useStyles)(AgentHome);
