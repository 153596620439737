import React from "react";
import { 
  Grid,
  Typography,
  withStyles, 
} from "@material-ui/core";
import { RegistrationForm } from '../registration_components';
import { arrowApiPrefix, getClientNameForLoginAndRegistration } from '../../../common/config';
import { get, post} from '@services/http_services/http_services';
import { ReactHelmet } from '@components/react-helmet/Helmet';
import { LoadingButton } from '@components/button';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const backgroundColor = "#03ACEF"

const useStyles = theme => ({
  container:{
    flexGrow: 1,
  },
  divContainer: {
    [theme.breakpoints.up('sm')]: {
      width: '520px',
    }
  },
  userInput: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '520px',
    }
  },
  buttons: {
    width: '180px',
    height: '50px',
    color: 'white',
    marginTop: 25,
  },
  mobileView: {
    display: 'grid',
    [theme.breakpoints.up('sm')]: {
      justifyItems: 'center'
    }
  }
});

const convertDOB = (date) => new Date(date).toLocaleDateString("en-GB", { year: 'numeric', month: 'short', day: 'numeric' })

const dateFormat = "d MMM yyyy"

const registrationGETAPI = (client) => `${arrowApiPrefix}/registration/${client}`

const registrationPOSTAPI = (client) => `${arrowApiPrefix}/registration/validate/${client}`

class PersonalProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      formFields: [],
      isEmptyFieldError: {},
      isValidationError: {},
      ValidationErrorHelperText: {},
      fieldValue: {},
      helperText: 'This field cannot be empty',
      version: null,
      userId: null,
      clientName: null,
      clientCountry: null,
      isEdit: false,
      isLoading: false,
    };
  }

  async componentDidMount() {
    const client = getClientNameForLoginAndRegistration(this.props.match.params.clientName)
    this.setState({
      userId: this.props.location.state ? this.props.location.state.userId : null,
      clientName: client
    })
    await this.initFormFields(client);
    this.editFields();
    this.setState({clientCountry: this.getDefaultCountryCode()});
  }

  getDefaultCountryCode = () => {
    const clientCountry = cookies.get('clientCountry')

    if (clientCountry) {
      if (clientCountry.toLowerCase() === 'malaysia'){
        return 'my'
      }
      else if (clientCountry.toLowerCase() === 'indonesia'){
          return 'id'
      }
      else {
          return 'sg'
      }
    }
    else {
      return 'sg'
    }
  }

  editFields = () => {
    if(this.props.location.state && this.props.location.state.formValues){
      this.setState({ fieldValue: this.props.location.state.formValues, isEdit: true })
    }
  }

  initFormFields = async (clientName) => {
    const respondData = await get(registrationGETAPI(clientName))

    if(respondData.code && respondData.data){
      this.setState({formFields: respondData.data.fields, version: respondData.data.version})

      const fieldObject = {}
      const errorObject = {}
      respondData.data.fields && respondData.data.fields.map((field, id) => {
        fieldObject[field.inputName] = null
        errorObject[field.inputName] = false
      })
      this.setState({fieldValue: fieldObject, isEmptyFieldError: errorObject, isValidationError: errorObject, ValidationErrorHelperText: fieldObject})
    }
  }


  handleTextChange = (event) => {
    this.state.formFields.map((field, id) => {
      if(event.target.name === field.inputName){
        this.setState({ 
          fieldValue: {...this.state.fieldValue, [event.target.name]: event.target.value},
          isEmptyFieldError: {...this.state.isEmptyFieldError, [event.target.name]: false},
          isValidationError: {...this.state.isValidationError, [event.target.name]: false}
        })
      }
    })
  }

  handleDoBChange = (event, name) => {
    this.setState({ 
      fieldValue: {...this.state.fieldValue, [name]: convertDOB(event)},
      isEmptyFieldError: {...this.state.isEmptyFieldError, [name]: false}
    })
  }

  handleMobileChange = (phone, name) => {
    this.setState({ 
      fieldValue: {...this.state.fieldValue, [name]: `+${phone}`},
      isEmptyFieldError: {...this.state.isEmptyFieldError, [name]: false}
    })
  }

  onSubmit = () => {
    const isPass = Object.keys(this.state.fieldValue).every((f) => this.state.fieldValue[f])
    if(!isPass){
      const newObject = {}
      Object.keys(this.state.fieldValue).map((key, index) => {
        newObject[key] = !this.state.fieldValue[key] ? true : false
      });
      this.setState({ isEmptyFieldError: newObject })
      return
    }
    
    if(!this.state.userId){
      console.log('could not retrieve userId')
      return;
    }

    this.setState({ isLoading: true })

    const registrationJson = {
      userId: this.state.userId.toString(),
      version: this.state.version.toString(),
      bdayDtPattern: dateFormat,
      firstname: this.state.fieldValue.firstname,
      lastname: this.state.fieldValue.lastname,
      gender: this.state.fieldValue.gender,
      dateofbirth: this.state.fieldValue.dateofbirth,
      mobilenumber: this.state.fieldValue.mobilenumber
    }

    post(registrationPOSTAPI(this.state.clientName), registrationJson).then(res =>
      //successful
      {
        let hasError = false;
        if(res.code && res.data){
          res.data.fields.map((field, id) => {
            if(field.hasOwnProperty("errorMsg")){
              this.setState({ 
                isValidationError: {...this.state.isValidationError, [field.inputName]: true},
                ValidationErrorHelperText: {...this.state.ValidationErrorHelperText, [field.inputName]: field.errorMsg} 
              })
              hasError = true
            }
            else{
              hasError = false
            }
          })
        }
        this.setState({ isLoading: false })
        !hasError && this.props.history.push({pathname: `/signup/${this.state.clientName}/review/submit`, state: { userId: this.state.userId, version: this.state.version, formFields: this.state.formFields, formValues: this.state.fieldValue, dateFormat: dateFormat }})
      }
    ).catch(err =>
      //fail
      {
        console.log('something went wrong', err.response.data)
        this.setState({ isLoading: false })
      }
    )   
  }

  render() {
    return (
      <Grid container direction="row" justifyContent="space-evenly" className={this.props.classes.container}>
        <ReactHelmet
          title="Personal Profile"
          name={this.state.clientName}
        />
        <Grid item xs={12} className={this.props.classes.mobileView}>
          <div className={this.props.classes.divContainer}>
            <Grid container direction="column" alignItems="center" spacing={3}>
                <Grid item style={{marginRight: 'auto'}}>
                    <Typography id="title">Personal Profile</Typography>
                </Grid>
                <Grid item style={{width: '100%', marginBottom: 20}}>
                  <form autoComplete="off">
                    <RegistrationForm
                      fieldArray={this.state.formFields ? this.state.formFields : []}
                      error={this.state.isEmptyFieldError}
                      validationError={this.state.isValidationError}
                      helperText={this.state.helperText}
                      validationHelperText={this.state.ValidationErrorHelperText}
                      className={this.props.classes.userInput}
                      fieldValue={this.state.fieldValue}
                      handleTextChange={this.handleTextChange}
                      handleDoBChange={this.handleDoBChange}
                      handleMobileChange={this.handleMobileChange}
                      dateFormat={dateFormat}
                      defaultCountryCode={this.state.isEdit ? null : this.state.clientCountry}
                    />

                    <LoadingButton
                      loading={this.state.isLoading}
                      text="Next"
                      onClick={this.onSubmit} 
                      disableElevation
                      disableRipple 
                      variant="contained"
                      component="span"
                      className={this.props.classes.buttons} 
                      style={{backgroundColor: backgroundColor, marginLeft: 'auto', width: '100%'}}
                    />

                  </form>
                </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(useStyles)(PersonalProfile);
