import { useState, useEffect } from 'react';
import { prepValuesForSubmission, triggerMethods as trigger } from './useSmarterForm.utilities';
import { SmarterTextField, SmarterRadio, SmarterCalendar, SmarterCheckbox } from 'components/input';
import { SmarterDropdown, SmarterFormItem, SmarterFormList } from 'components/form';
import { DiagnosisSearch } from 'components/input/textfield/DiagnosisSearch';
import { ProcedureSearch } from 'components/input/textfield/ProcedureSearch';
import { SmarterAccordion } from 'components/accordion/AlternativeSmarterAccordion';

import { SmarterNumeric } from 'components/input/numeric/Numeric';
import { validationSchema } from './validationUtils';

const isValid = (item) => !!item;
// need a better distribution of subtype
const fieldsLibrary = {
  date: SmarterCalendar,
  radio: SmarterRadio,
  textfield: SmarterTextField,
  diagnosisCode: DiagnosisSearch,
  wrapper: SmarterFormItem,
  list: SmarterFormList,
  default: 'div',
  title: 'h2',
  dropdown: SmarterDropdown,
  procedureCode: ProcedureSearch,
  number: SmarterNumeric,
  confirmCheckbox: SmarterCheckbox,
  subtype: { accordion: SmarterAccordion },
};

export const useSmarterForm = ({ schema, parentId, library = fieldsLibrary }) => {
  const [fields, setFields] = useState([]);
  const [triggers, setTriggers] = useState({});
  const [count, setCount] = useState(0);

  function handleState(newFields) {
    setFields(newFields);
    handleTriggers(newFields);
  }

  function handleTriggers(forms) {
    const builtTriggers = trigger.build(forms);
    setTriggers(builtTriggers);
  }

  function prepareForm(children) {
    return children.map((item) => {
      let newItem = { ...item };
      if (item.type === 'form') newItem.children = prepareForm(newItem.children);
      if (item.type === 'list') {
        if (item.props?.renderFirst) {
          newItem.children = [constructItemWithCount(newItem.config, count)];
        }
      }
      return newItem;
    });
  }

  function constructItemWithCount(config, currentCount = count) {
    const { id, children, ...items } = config;
    setCount((prev) => prev + 1);
    let newChild = { ...items, children, id: `${currentCount}_${id}` };

    const buildFromChildren = (item) => constructItemWithCount(item, currentCount + 1);
    if (config.type === 'form') newChild.children = children.map(buildFromChildren);

    if (config.type === 'list') {
      if (config.props?.renderFirst) {
        newChild.children = [...newChild.children, buildFromChildren(newChild.config)];
      }
    }

    return newChild;
  }

  function handleListChange(children, id, callback) {
    return children.map((item) => {
      if (item.id === id) item = { ...item, children: callback(item) };
      if (['form', 'list'].includes(item.type)) {
        item = { ...item, children: handleListChange(item.children, id, callback) };
      }
      return item;
    });
  }

  function handleAdd(id) {
    return () => {
      const addItem = (item) => {
        const newAddedItem = constructItemWithCount(item.config, count + 1);
        return [...item.children, newAddedItem];
      };
      const newFields = handleListChange(fields, id, addItem);
      handleState(newFields);
    };
  }

  function handleDelete(id, idx) {
    return () => {
      const deleteItem = (item) => {
        return item.children.filter((_, childIdx) => childIdx !== idx);
      };
      const newFields = handleListChange(fields, id, deleteItem);
      handleState(newFields);
    };
  }

  function handleChange(targetId) {
    return (event) => {
      const value = event?.target?.value ?? event;
      function applyValues(targetField, nodes) {
        targetField.value = value;
        if (triggers[targetId]) {
          nodes = trigger.applyTriggers({
            fields: nodes,
            targetId,
            triggers,
          });
        }
        targetField.helperText = '';
        return [...nodes];
      }
      const updatedWithValue = findAndUpdateTargetField(targetId, applyValues);
      setFields(updatedWithValue(fields));
    };
  }

  function renderNestedFields(field) {
    const Title = field.component ?? library.title;
    const FormItem = library[field?.wrapper] ?? library.wrapper;
    const Field = library.subtype?.[field.subtype] ?? library[field.type] ?? library.default;
    const { props, ...wrapperProps } = field;
    const { style, title, ...nonLabelProps } = wrapperProps;

    let fieldProps = { ...props };

    if (field.type === 'list') {
      fieldProps.id = wrapperProps.id;
      fieldProps.methods = { handleDelete, handleAdd };
      if (fieldProps.renderFirst) delete fieldProps.renderFirst;
      if (field.props?.keepFirst) fieldProps.indelible = [0];
    }

    const Header = () => {
      if (!title) return null;
      if (field.subtype === 'accordion') return title;
      return <Title>{title}</Title>;
    };

    return (
      <FormItem key={field.id} {...nonLabelProps}>
        <Field {...fieldProps} title={<Header />}>
          {field.children.map(renderFields)}
        </Field>
      </FormItem>
    );
  }

  function renderFields(field) {
    const { props, id, ...wrapperProps } = field;
    if (['list', 'form'].includes(field.type)) return renderNestedFields(field);
    const Title = field.component ?? library.title;
    if (field.type === 'title') return <Title key={field.title}>{field.title}</Title>;

    const FormItem = library[field?.wrapper] ?? library.wrapper;
    const Field = library[field.type] ?? library.default;

    return (
      <FormItem key={field.id} id={generateId(id, field.parentId)} {...wrapperProps}>
        <Field
          id={field.id}
          value={field.value || ''}
          onChange={handleChange(field.id)}
          {...props}
        />
      </FormItem>
    );
  }

  function handleSubmit(e) {
    e?.preventDefault();

    function parseFields(field) {
      const { name, children, value, isVisible, type } = field;
      if (isVisible !== undefined && !isVisible) return null;
      let parsedValue;

      if (!children) parsedValue = prepValuesForSubmission(value, type);
      else if (type === 'form') {
        const reduceForm = (acc, curr) => ({ ...acc, ...parseFields(curr) });
        parsedValue = children.reduce(reduceForm, {});
      } else if (type === 'list') parsedValue = children.map(parseFields).filter(isValid);

      return { [name]: parsedValue };
    }

    let section = { key: '', idx: -1 };

    function sectionalize(acc, curr) {
      if (curr.type === 'title') {
        section.key = curr.title;
        section.idx = section.idx + 1;
        return [...acc, { [curr.title]: [] }];
      }
      acc[section.idx][section.key] = [...acc[section.idx][section.key], curr];
      return acc;
    }

    function parseSections(sect) {
      const key = Object.keys(sect)[0];
      const value = sect[key].map(parseFields).filter(isValid);

      return { [key]: value };
    }
    return fields.reduce(sectionalize, []).map(parseSections);
  }

  async function handleValidation() {
    const validations = await validationSchema(fields);

    for (const targetId in validations) {
      function applyValues(targetField, nodes) {
        targetField.helperText = validations[targetId];
        return [...nodes];
      }
      const updatedWithValidation = findAndUpdateTargetField(targetId, applyValues);
      setFields(updatedWithValidation(fields));
    }
    return !!validations;
  }

  function generateId(fieldId, fieldParentId) {
    let application = 'booking-portal';
    let subParentId = fieldParentId ? `_${fieldParentId}` : '';
    const rootId = `${application}_${parentId}${subParentId}_`;
    return rootId + fieldId;
  }

  useEffect(() => {
    const forms = prepareForm(schema);
    handleState(forms);
  }, [schema]);

  return { fields, handleChange, renderFields, handleSubmit, handleValidation };
};

function findAndUpdateTargetField(targetId, callback) {
  function updateNode(children) {
    let nodes = [...children];
    let targetIdx = nodes.findIndex((node) => node.id === targetId);
    let targetField = nodes[targetIdx] ?? null;

    function next() {
      return nodes.map((node) => {
        if (node.children) node.children = updateNode(node.children);
        return node;
      });
    }
    return targetField ? callback(targetField, nodes) : next();
  }
  return (nodes) => updateNode(nodes);
}
