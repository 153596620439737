import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { useStylesBase } from '../useStylesBase.js';
export const ActionContainer = (props) => {
  const { isError, submit } = props;

  const classes = useStylesBase();
  const redirect = () => {
    window.location.href = document.referrer;
  };

  return (
    <div className={classes.actionContainer}>
      <Button
        variant="outlined"
        data-testid="back-button"
        style={{ backgroundColor: '#fff', color: '#000', borderColor: '#000' }}
        onClick={redirect}
      >
        Back
      </Button>
      <Button
        variant="contained"
        disabled={isError}
        data-testid="save-button"
        style={{ backgroundColor: isError ? '#C4C4C4' : '#000', color: '#fff' }}
        onClick={submit}
      >
        Save
      </Button>
    </div>
  );
};

ActionContainer.propTypes = {
  isError: PropTypes.bool.isRequired,
  submit: PropTypes.func,
};
