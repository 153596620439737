import React from "react";
import {
    InputAdornment,
    TextField,
    withStyles
  } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import PeopleIcon from '@material-ui/icons/People';
import { convertAppointmentDate } from '../config';


const useStyles = theme => ({
    inputRoot: {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'black'
        },
    }
});

class PatientSearch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchOptions: [],
            searchValue: null,
            openSearchPopup: false,
            inputChange: null,
        }
    }

    filterValue = (value) => {    
        this.setState({searchValue: value, openSearchPopup: false})
        if(value){
          this.props.onCalendarSelect(value.start) 
        }
      }
    
      handleInputChange = (event, newInputValue) => {
        this.setState({inputChange: newInputValue})
        if (newInputValue.length > 0) {
          this.setState({openSearchPopup: true})
        } else {
          this.setState({openSearchPopup: false})
        }
      };
    
  

  render() {
      return (
        <Autocomplete
            classes={{inputRoot: this.props.classes.inputRoot}}
            autoHighlight
            size="small"
            forcePopupIcon={false}
            open={this.state.openSearchPopup}
            filterOptions = {(options, {inputValue, getOptionLabel}) => {
                const input = inputValue.trim().toLowerCase();
                const filteredOptions = options.filter(option => getOptionLabel(option).toLowerCase().includes(input) || option.title.toLowerCase().includes(input));
                return filteredOptions;
            }}
            value={this.state.searchValue}
            onChange={(event, newValue) => {
                this.filterValue(newValue);
            }}
            onInputChange={(event, newInput) => this.handleInputChange(event, newInput)}
            groupBy={(option) => option.title}
            getOptionLabel={(option) => convertAppointmentDate(option.start)}
            options={this.props.optionList}
            renderInput={(params) => (
            <TextField {...params} margin="normal" variant="outlined" placeholder="Search for patient" InputProps={{...params.InputProps,
                startAdornment: <InputAdornment position="start"><PeopleIcon/></InputAdornment>,
            }}/>
            )}
        />
      );
  }
}
export default withStyles(useStyles)(PatientSearch);
