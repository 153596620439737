import { Radio, FormControlLabel, RadioGroup } from '@material-ui/core';

export const SmarterRadio = ({
  value = '',
  data = [],
  handleChange,
  labelPlacement = 'end',
  optionProps,
  ...props
}) => (
  <RadioGroup row value={value} onChange={(e) => handleChange(e.target.value)} {...props}>
    {data.map((option) => (
      <FormControlLabel
        key={`label-${option}`}
        control={<Radio color="primary" />}
        value={option}
        label={option}
        labelPlacement={labelPlacement}
        {...optionProps}
      />
    ))}
  </RadioGroup>
);
