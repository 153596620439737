export const STATUS_LIST = [
  {
    label: 'Action Needed',
    value: 'action needed',
  },
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Completed',
    value: 'completed',
  },
  {
    label: 'All',
    value: 'all',
  },
];

export const HOSPITAL_LIST = [
  {
    value: 'Raffles Hospital',
  },
  {
    value: 'Raffles Juniors (Waterway Point)',
  },
  {
    value: 'Raffles Medical (Tampines 1)',
  },
  {
    value: 'Raffles Specialist Centre',
  },
];

export const requiredField = 'This field is required';
