import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const RestrictedRoute = ({ children, redirection, condition, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        condition ? React.cloneElement(children, { ...props }) : <Redirect to={redirection} />
      }
    />
  );
};
