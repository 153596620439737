import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import withWidth from '@material-ui/core/withWidth';
import _ from 'lodash';
const useStyles = makeStyles((theme) => ({
  container: {
    margin: '-30px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '30px',
    },
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primaryBlue,
    left: '50%',
    transform: 'translateX(-50%)',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: theme.palette.primaryBlue,
    },
    [theme.breakpoints.up('sm')]: {
      padding: '12px 70px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '12px 50px',
    },
  },
  wrapper: {
    '& img': {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '900px',
    },
  },
}));

const LandingPage = ({ landingImage, ...props }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      {!_.isEmpty(landingImage) && (
        <div className={classes.container}>
          <div className={classes.wrapper}>
            <div className={classes.landingImage}>
              {props.width === 'mobile' ? (
                <img className="image" src={landingImage?.mobile} />
              ) : (
                <img className="image" src={landingImage?.desktop} />
              )}
            </div>
            <Button className={classes.button} variant="contained" onClick={props.onClick}>
              GOT IT!
            </Button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default withWidth()(LandingPage);
