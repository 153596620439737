import { Typography } from '@material-ui/core';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import { getPayors } from '@api/paf';
import { FormDropdown } from 'components/form';
import { userIdKey } from 'features/common/config';
import { SmarterErrors } from '@components/alert';

const useStyles = makeStyles((theme) => ({
  dropdown: { marginTop: theme.spacing(3) },
}));

export const PayorSelection = forwardRef(({ setShowPAFForm, onSubmit }, ref) => {
  const classes = useStyles();
  const [payors, setPayors] = useState([]);
  const [selectedPayor, setSelectedPayor] = useState({ id: null, name: null, error: null });
  const [errors, setErrors] = useState([]);

  function handleSelect(event) {
    const targetPayor = (el) => el.value === event;
    setSelectedPayor({ ...payors.find(targetPayor), error: null });
  }

  useEffect(() => {
    const cookies = new Cookies();
    function setPayorsData(res) {
      const data = res.data?.data.map((payor) => ({
        value: payor.businessFullName,
        id: payor.clientId,
        name: payor.name,
      }));
      setPayors(data ?? []);
    }
    getPayors(cookies.get(userIdKey))
      .then(setPayorsData)
      .catch(() => setErrors(['Unable to retrieve list of payors']));
  }, []);

  useImperativeHandle(ref, () => ({
    next: async () => {
      if (selectedPayor.name) onSubmit(selectedPayor);
      else setSelectedPayor({ ...selectedPayor, error: true });
      return !!selectedPayor.name;
    },
    back: () => setShowPAFForm(false),
  }));

  return (
    <div>
      <SmarterErrors errors={errors} />
      <Typography variant="h6" style={{ fontWeight: '600' }}>
        Select Payor
      </Typography>
      <div className={classes.dropdown}>
        <FormDropdown
          data={payors.map((option) => option.value)}
          handleChange={handleSelect}
          state={selectedPayor.value}
          error={selectedPayor.error}
          helperText={selectedPayor.error && 'This field is required'}
        />
      </div>
    </div>
  );
});
