import React from "react";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  withStyles
} from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeDoubleIcon from '../icons_svg/chevron_double_left_svg';
import NavigateNextDoubleIcon from '../icons_svg/chevron_double_right_svg';


const useStyles = theme => ({
  calendarHeaderButton: {
    backgroundColor: 'white',
    color: 'black',
    padding: 'unset',
    borderBottom: '1px solid black',
    borderTop: '1px solid black',
    '&:hover': {
      backgroundColor: 'white'
    },
  },
  calendarDropdownHeaderButton: {
    backgroundColor: 'white',
    height: 40,
    color: 'black',
    border: '1px solid black',
    '&:hover': {
      backgroundColor: 'white'
    },
  },
  mobileViewFullCalendarHeaderControl: {
    float: 'left',
    [theme.breakpoints.up('sm')]: {
      float: 'right'
    },
  },


});

class FullCalendarHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          anchor: null,
          openDropdown: false,
          dropdrownOptions: ["Week", "Day"],
        }
    }

  handleDropdownClick = (event) => {
    this.setState({anchor: event.currentTarget, openDropdown: !this.state.openDropdown})
  };

  handleDropdownClose = () => {
    this.setState({anchor: null})
  };

  handlePopperClose = () => {
    this.setState({openDropdown: false})
  };

  handleOptionOnClick = (option) => {
    this.setState({openDropdown: false})

    this.props.handleOptionClick(option)
  }

  render() {
      return (
        <Grid item xs={12} className={this.props.classes.mobileViewFullCalendarHeaderControl}>
          <div style={{display: 'inline-flex'}}>
            <ButtonGroup disableElevation disableRipple variant="contained" size="small" style={{height: 40, marginRight: 10}}>
              <Button onClick={() => this.props.onCalendarArrowChange('none', 'prev')} className={this.props.classes.calendarHeaderButton} style={{borderLeft: '1px solid black'}}><NavigateBeforeDoubleIcon/></Button>
              <Button onClick={() => this.props.onCalendarArrowChange(this.props.selectedDate, 'goback')} className={this.props.classes.calendarHeaderButton}><NavigateBeforeIcon/></Button>
              <Button onClick={() => this.props.onCalendarArrowChange('none', 'today')} style={{backgroundColor: 'white', color: 'black', borderTop: '1px solid black', borderBottom: '1px solid black'}}>Today</Button>
              <Button onClick={() => this.props.onCalendarArrowChange(this.props.selectedDate, 'goto')} className={this.props.classes.calendarHeaderButton}><NavigateNextIcon/></Button>
              <Button onClick={() => this.props.onCalendarArrowChange('none', 'next')} className={this.props.classes.calendarHeaderButton} style={{borderRight: '1px solid black'}}><NavigateNextDoubleIcon/></Button>
            </ButtonGroup>

            <Button
              size="small"
              variant="contained"
              disableElevation
              disableRipple
              onClick={this.handleDropdownClick}
              className={this.props.classes.calendarDropdownHeaderButton}
            >
              {this.props.selectDropdownOption}
              <ArrowDropDownIcon />
            </Button>
            <Popper open={this.state.openDropdown} anchorEl={this.state.anchor} onClose={this.handleDropdownClose} transition>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={this.handlePopperClose}>
                      <MenuList>
                        {this.state.dropdrownOptions.map((option, index) => (
                          <MenuItem
                            key={option}
                            selected={option === this.props.selectDropdownOption}
                            onClick={() => this.handleOptionOnClick(option)}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </Grid>
      );
  }

}

export default withStyles(useStyles)(FullCalendarHeader)
