import { Alert, AlertTitle } from '@material-ui/lab';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState, useRef } from 'react';
import { getOAFDetail, submitOAF } from '@api/mcuForm';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { DynamicForm } from 'components/form';
import { LoadingButton } from 'components/button';
import { Loading } from 'components/loading';
import { validateEmail } from '../../utils/utils.js';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '500px',
    maxWidth: '1200px',
    overflow: 'hidden',
    paddingBottom: '20px',
    [theme.breakpoints.up('md')]: {
      margin: 'auto',
    },
  },
  content: {
    padding: '5px 30px 20px 30px',
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '6px',
  },
  item: {
    padding: '12px',
  },
  linkTag: {
    color: '#4183C4',
  },
  submitBtn: {
    '&.Mui-disabled': {
      border: '1px solid rgba(0, 0, 0, 0.12)',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
  },
  errorsBlock: {
    marginTop: '16px',
  },
}));

export const McuFormContainer = ({ ...props }) => {
  const classes = useStyles();
  const [config, setConfig] = useState([]);
  const [loading, setLoading] = useState(false);
  const { requesterId } = useParams();
  const [errors, setErrors] = useState([]);
  const [disableBtn, setDisableBtn] = useState(true);
  const [formModel, setFormModel] = useState({});
  const [versionAPI, setVersionAPI] = useState('');

  const myRef = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });

  const validate = () =>
    new Promise((resolve, reject) => {
      const fields = [...config].reduce((prev, curr) => {
        return prev.concat(curr.fields);
      }, []);
      const errs = [];
      setErrors(errs);
      fields.forEach((field) => {
        if (field.type === 'confirmCheckbox') {
          !_.isEmpty(formModel[field.inputName]) && errs.push(`${field.uiLabel} cannot be empty`);
        } else if (field.isRequired && _.isEmpty(formModel[field.inputName])) {
          errs.push(`${field.uiLabel} cannot be empty`);
        } else if (field.validator) {
          const errMsg = field.validator(formModel[field.inputName]);
          errMsg && errs.push(errMsg);
        } else if (field.type === 'email') {
          const invalidEmail = validateEmail(formModel[field.inputName]);
          if (invalidEmail) errs.push(invalidEmail);
        } else if (field.inputName === 'dateofbirth') {
          // cannot be future date
          if (new Date(formModel[field.inputName]) > new Date()) {
            errs.push('Date of Birth cannot be a future date');
          }
        }
      });
      if (!_.isEmpty(errs)) {
        reject(errs);
      } else {
        resolve();
      }
    });

  const handleSubmit = () => {
    validate()
      .then(() => {
        setLoading(true);
        const payload = {
          requesterId: requesterId,
          data: {
            ...formModel,
            version: versionAPI.toString(),
          },
        };
        submitOAF(payload)
          .then((res) => {
            if (res?.data) {
              window.location = res.data.link;
            }
          })
          .catch((err) => {
            if (err?.response?.status === 400) {
              const error = err.response.data;
              let tmp = [];
              if (_.isArray(error)) {
                for (const [key, value] of Object.entries(error)) {
                  tmp.push(`${key}: ${value}`);
                }
              } else if (_.isString(error)) tmp.push(error);
              setErrors(tmp);
            }
          })
          .finally(() => setLoading(false));
      })
      .catch((err) => {
        setErrors(err);
        executeScroll();
      });
  };

  const buildFormModel = (formConfig) => {
    let result = {};
    formConfig.forEach((section) => {
      section.fields.forEach((field) => {
        result[field.inputName] = field.value || '';
        if (field.inputName === 'gender') {
          field['props']['dataSource'] = [
            {
                "value": "Male",
                "label": "Male"
            },
            {
                "value": "Female",
                "label": "Female"
            }
          ];
        }
      });
    });
    setFormModel(result);
  };

  const fetchDetail = () => {
    const payload = { requesterId };
    setLoading(true);
    getOAFDetail(payload)
      .then((res) => {
        if (res?.data) {
          setConfig(res.data.formConfig);
          setVersionAPI(res.data.version);
          buildFormModel(res.data.formConfig);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchDetail();
  }, []);

  useEffect(() => {
    setDisableBtn(!formModel.privacyConfirm);
  }, [formModel.privacyConfirm]);

  return (
    <Grid ref={myRef} container spacing={3} className={classes.root}>
      <Loading isLoading={loading}>
        <Grid item xs={12} className={classes.item}>
          {!_.isEmpty(errors) && (
            <div className={classes.errorsBlock}>
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {errors.map((error, index) => {
                  const idx = index + 1;
                  return (
                    <Typography key={`search-form-error-${idx}`}>{`${idx}: ${error}`}</Typography>
                  );
                })}
              </Alert>
            </div>
          )}
        </Grid>
        <div className={classes.content}>
          <DynamicForm formConfig={config} formModel={formModel} setFormModel={setFormModel} />
          <Grid container direction="row-reverse">
            <LoadingButton
              classes={classes.submitBtn}
              loading={loading}
              text="Submit"
              onClick={handleSubmit}
              variant="outlined"
              component="span"
              disabled={disableBtn}
            />
          </Grid>
        </div>
      </Loading>
    </Grid>
  );
};
