import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { PaginatedButtonBar } from '@components/button';
import { PortraitDoctorCard } from '../../card';
import { useResolution } from '@hooks';

export const HorizontalList = ({ doctors, handleSelect, submitting }) => {
  const size = useResolution();
  const classes = useStyles({ size });
  const [selectedSlide, setSelectedSlide] = useState(1);
  const sliderRef = useRef();

  const goToSlide = (page) => sliderRef.current.slickGoTo(page * 3);
  const settings = {
    swipe: false,
    slidesToShow: 3,
    beforeChange: (oldIndex, newIndex) => setSelectedSlide(newIndex),
    adaptiveHeight: false,
    loop: false,
    infinite: false,
    nextArrow: <NextArrow disabled={selectedSlide === doctors.length - 3} />,
    prevArrow: <PrevArrow disabled={!selectedSlide} />,
    lazyLoad: 'ondemand',
  };

  return (
    <div className={classes.root}>
      <Slider ref={sliderRef} {...settings}>
        {doctors.map((doctor, idx) => (
          <div className={classes.slider} key={`doctor-card-${idx}`}>
            <PortraitDoctorCard
              key={idx}
              doctor={doctor}
              onSelect={handleSelect}
              submitting={submitting}
            />
          </div>
        ))}
      </Slider>
      <PaginatedButtonBar
        className={classes.pagination}
        dataLength={doctors.length}
        itemsPerPage={3}
        toPage={goToSlide}
        currentItem={selectedSlide}
        pageSpreadMax={7}
      />
    </div>
  );
};

export const NextArrow = ({ className, style, onClick, disabled }) => {
  const color = disabled ? 'rgba(0, 0, 0, 0.26)' : 'rgba(0, 0, 0, 0.54)';
  const cursor = disabled && 'default';
  return (
    <KeyboardBackspaceIcon
      style={{
        ...style,
        display: 'block',
        color,
        transform: 'rotate(180deg)',
        cursor,
      }}
      className={className}
      onClick={onClick}
    />
  );
};

export const PrevArrow = ({ className, style, onClick, disabled }) => {
  const color = disabled ? 'rgba(0, 0, 0, 0.26)' : 'rgba(0, 0, 0, 0.54)';
  const cursor = disabled && 'default';
  return (
    <KeyboardBackspaceIcon
      style={{
        ...style,
        display: 'block',
        color,
        cursor,
      }}
      className={className}
      onClick={onClick}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    '@media (min-width:1550px)': {
      height: '550px',
    },
    '@media (max-width:1550px)': {
      height: '500px',
    },
    position: 'relative',
  },
  slider: {
    aspectRatio: 9 / 12,
  },
  pagination: {
    position: 'absolute',
    right: '0px',
    bottom: '0px',
    width: 'fit-content',
  },
}));
