import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Button,
  CardActions,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import { XL, LG, MD, SM, XS } from 'features/doctor-search/utilities/constants';
import { useResolution } from '@hooks';
import { LoadingButton } from 'components/button';

export const RadioForm = ({
  title,
  state,
  setState,
  list = [],
  handleSubmit,
  handleBack,
  error,
  submitting,
}) => {
  const size = useResolution();
  const classes = useStyles({ size });
  return (
    <Card>
      <CardHeader title={title} classes={{ title: classes.title }} />
      {error && (
        <div className={classes.errors}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            <Typography>{error}</Typography>
          </Alert>
        </div>
      )}
      <CardContent>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label={`${title}-radio-form`}
            name={`${title}-radio-form`}
            value={state}
            onClick={(event) => setState(event.target.value)}
          >
            {list.map(({ id, value, display }) => {
              return (
                <div
                  key={`radio-form-${id}-${value}`}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <FormControlLabel
                    value={value}
                    control={<Radio color="primary" size="small" />}
                    label={
                      <span>
                        {value}
                        {display && React.cloneElement(display, { className: classes.icon })}
                      </span>
                    }
                  />
                </div>
              );
            })}
          </RadioGroup>
        </FormControl>
      </CardContent>
      <CardActions>
        <Button
          className={classes.secondaryButton}
          onClick={handleBack}
          disableRipple
          disableElevation
          variant="contained"
        >
          Back
        </Button>
        <LoadingButton
          onClick={handleSubmit}
          loading={submitting}
          text="Next"
          classes={classes.primaryButton}
        />
      </CardActions>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  title: { fontFamily: 'Lato-Bold' },
  location: {
    display: 'flex',
    '@media (max-width:1550px)': {
      fontSize: '12px',
    },
  },
  icon: {
    verticalAlign: 'text-bottom',
    marginLeft: '1px',
    width: ({ size }) => {
      const config = {
        [XL]: '24px',
        [LG]: '24px',
        [MD]: '20px',
        [SM]: '20px',
        [XS]: '20px',
      };
      return config[size];
    },
    height: ({ size }) => {
      const config = {
        [XL]: '20px',
        [LG]: '20px',
        [MD]: '17px',
        [SM]: '17px',
        [XS]: '17px',
      };
      return config[size];
    },
  },
  errors: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up(MD)]: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.down(MD)]: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },
  primaryButton: {
    color: 'white',
    backgroundColor: '#03ACEF',
    '&:hover': {
      backgroundColor: '#03ACEF',
    },
  },
  secondaryButton: {
    color: '#ffffff',
    backgroundColor: '#f37d2a',
    '&:hover': {
      backgroundColor: '#f37d2a',
    },
  },
}));
