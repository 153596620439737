import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '500px',
    maxWidth: '87%',
    minHeight: '340px',
    padding: '20px',
    maxHeight: '270px'
  }
}));

const SmarterPopover = React.forwardRef( ({id, anchorEl = null, open, onClose, title = '' , ...props}, ref) => {
  const classes = useStyles()
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      marginThreshold={25}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      classes={{paper: classes.root}}
    >
      {props.children}
    </Popover>
  );
});
export default SmarterPopover;
