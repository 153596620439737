import { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { useSmarterForm } from 'hooks';
import { separateObject } from 'utils/utils';
import { SmarterErrors } from 'components/alert';
import { saveDiagnosisDetails } from 'apis/paf';
import { SECTION_C } from 'features/common/config';
import { fetchPAFSchema } from '../utils/utils';

const parseValues = (item) => Object.values(item)[0];

export const SectionC = forwardRef(({ caseId, payorId, ...props }, ref) => {
  const [data, setData] = useState([]);
  const { fields, renderFields, handleSubmit, handleValidation } = useSmarterForm({
    schema: data,
    parentId: 'epaf-sectionC',
  });
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (payorId) prepForm();
  }, [payorId]);

  async function prepForm() {
    const res = await fetchPAFSchema(payorId, SECTION_C);
    setData(res);
  }

  async function submitDiagosisDetails(results) {
    function flattenSections(acc, curr) {
      Object.values(curr)[0].forEach((field) => {
        const [key, value] = separateObject(field);
        acc[key] = convertToBool(value);
      });
      return acc;
    }
    let details = results.reduce(flattenSections, {});

    details['previousTreatmentDetails'] = (details['previousTreatmentDetails'] ?? []).map(
      parseValues,
    );

    try {
      await saveDiagnosisDetails(details, caseId);
      return true;
    } catch (error) {
      setErrors([error.message]);
      return false;
    }
  }

  useImperativeHandle(ref, () => ({
    next: async () => {
      const hasErrors = await handleValidation();
      if (hasErrors) return false;
      const results = handleSubmit();

      return submitDiagosisDetails(results);
    },
    back: () => true,
  }));

  return (
    <>
      <SmarterErrors errors={errors} />
      <form {...props}>{fields.map(renderFields)}</form>
    </>
  );
});

function convertToBool(value) {
  if (value === 'Yes') return true;
  else if (value === 'No') return false;
  return value;
}
