import React, { useState } from 'react';
import { isPasswordValid } from '../../utils/utils';
import { Check as CheckIcon } from '@material-ui/icons';
import { postResetPassword } from '../../apis';
import { ResetPasswordPage } from './ResetPasswordPage';
import { Password } from './Password';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  requiredLabels: {
    '& > div': {
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',

      '& svg': {
        marginRight: '4px',
      },
    },
  },

  visibilityIcon: { color: '#C4C4C4' },
  visibilityOffIcon: {
    color: '#000',
  },
}));

export const EnterNewPassword = ({ onSubmit }) => {
  const [password, setPassword] = useState({
    pwd: '',
    confirmPwd: '',
  });

  const [errors, setErrors] = useState({});
  const [isGeneralError, setIsGeneralError] = useState(false);
  const classes = useStyles();
  const requiredLabels = [
    { text: '8 or more characters', key: 'eightCharacters' },
    { text: '1 Upper case character', key: 'uppercase' },
    { text: '1 Lower case character', key: 'lowercase' },
    { text: '1 Special character', key: 'specialCharacter' },
    { text: '1 Number', key: 'number' },
  ];

  const onChangePassword = (key) => {
    return (value) => {
      if (key === 'pwd') {
        setErrors({ ...isPasswordValid(value) });
      }
      setPassword((prev) => {
        return {
          ...prev,
          [key]: value,
        };
      });
    };
  };

  const closeErrorModal = () => {
    setIsGeneralError(false);
  };

  const clickContinue = async () => {
    try {
      await postResetPassword({ new_password: password.confirmPwd });
      onSubmit();
    } catch (error) {
      setIsGeneralError(true);
      return 400;
    }
  };

  return (
    <ResetPasswordPage
      title="Enter New Password"
      description="Please create a new password for your account"
      buttonProps={{
        text: 'Continue',
        onClick: clickContinue,
        disabled:
          password.pwd !== password.confirmPwd ||
          Object.values(errors).some((error) => error === false),
      }}
      error={isGeneralError}
      handleClose={closeErrorModal}
    >
      <Password
        label="New Password"
        id="resetPassword-new-password"
        onChangePassword={onChangePassword('pwd')}
        error={password.confirmPwd.length && password.pwd !== password.confirmPwd}
      />

      <Password
        label="Re-enter Password"
        id="resetPassword-reenter-new-password"
        onChangePassword={onChangePassword('confirmPwd')}
        error={password.confirmPwd.length && password.pwd !== password.confirmPwd}
        helperText={
          password.confirmPwd.length && password.pwd !== password.confirmPwd
            ? 'Passwords don’t match'
            : ''
        }
      />
      <div className={classes.requiredLabels}>
        {requiredLabels.map((label, index) => {
          return (
            <div
              style={{ color: errors[label.key] ? '#62BE37' : '#ABABAB' }}
              key={`requiredLabel-${index}`}
            >
              <CheckIcon />
              {label.text}
            </div>
          );
        })}
      </div>
    </ResetPasswordPage>
  );
};
