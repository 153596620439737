import { get } from '@services/http_services/http_services';
import { DISCOVERY_PREFIX, PREAUTH_PREFIX } from './utils/constant';
import {
  NodeDetails,
  ProcedureCodeResponse,
  Node,
} from '../features/doctor-search/model/ProcedureCodeResponse';

const sortData = (data, sortBy = 'icd10Code') => {
  return data
    .filter((e) => e.nodeDetails[sortBy] !== 'Others')
    .sort((a, b) => a.nodeDetails[sortBy].localeCompare(b.nodeDetails[sortBy]))
    .concat(data.filter((e) => e.nodeDetails[sortBy] === 'Others'));
};

export const getDiagnosis = (params) =>
  get(`/${DISCOVERY_PREFIX}/search/diagnosiscodes/${params.search}/tree`);

export const getFullDiagnosis = (params) =>
  get(`/${PREAUTH_PREFIX}/v1/search/diagnosiscodes/${params.search}/tree`);

export const getProcedureLevel = (params) =>
  new Promise((resolve) => {
    get(`/${DISCOVERY_PREFIX}/procedurecodes/country/${params.country}`).then((res) => {
      if (res?.data?.data) {
        const data = res.data.data;
        const result = new ProcedureCodeResponse([]);
        data.map((ele) => {
          let children = [];
          const nodeDetails = new NodeDetails(ele.levelOneDescription, '');
          if (Array.isArray(ele.children) && ele.children.length) {
            children = mappingData(ele.children);
          }
          const node = new Node(nodeDetails, sortData(children));
          result.addElement(node);
        });
        result.data = sortData(result.data);
        resolve(result);
      }
    });
  });

export const getProcedureCode = (params) =>
  new Promise((resolve, reject) => {
    if (params.l1 && params.l2) {
      const encodel1 = encodeURIComponent(params.l1);
      const encodel2 = encodeURIComponent(params.l2);
      get(`/${DISCOVERY_PREFIX}/procedurecodes/country/malaysia/l1/${encodel1}/l2/${encodel2}`)
        .then((res) => {
          if (res?.data?.data) {
            const data = res.data.data;
            let result = { data: [] };
            data.map((ele) => {
              const procedureCode = ele.children[0].children;
              if (Array.isArray(ele.children) && ele.children.length) {
                result.data = mappingData(procedureCode);
              }
            });
            result.data = sortData(result.data, 'icd10Description');
            resolve(result);
          }
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      resolve([]);
    }
  });

const mappingData = (data) => {
  const result = [];
  data.map((ele) => {
    const code = ele.levelTwoDescription || ele.procedureCode;
    const description = ele.procedureCodeDescription || '';
    const nodeDetails = new NodeDetails(code, description);
    const node = new Node(nodeDetails, []);
    result.push(node);
  });
  return result;
};
