import SelectSession from './SelectSession';
import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from 'react-router-dom';

export const CreatePatientAppointment = (props) => {
  const { serviceType, unitId, requesterId } = useParams();
  const history = useHistory();

  function handleBack() {
    history.goBack();
  }

  return (
    <SelectSession
      {...props}
      serviceType={serviceType}
      unitId={unitId}
      requesterId={requesterId}
      back={handleBack}
    />
  );
};
