import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { InputTitle } from './InputTitle.jsx';
import { SmarterTextField, SmarterNumeric } from '@components/input';

export const Address = (props) => {
  const {
    index,
    addresses,
    setAsPreferred,
    isDisabled = false,
    deleteAddress,
    setAddresses,
    isSubmitted,
    userDataLength,
  } = props;

  const handleAddress = (type) => {
    return (value) => {
      let newAddress = addresses.map((address, aIdx) => {
        let temp = { ...address };

        if (index === aIdx) {
          temp[type] = value;
        }
        return temp;
      });

      setAddresses(newAddress);
    };
  };

  return (
    <Fragment key={`${index}-address`}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputTitle
            index={index}
            items={addresses}
            setAsPreferred={setAsPreferred}
            deleteItem={deleteAddress}
            type="address"
            userDataLength={userDataLength}
          />
          <label htmlFor="street-name">Address 1(Street Name)*</label>
          <SmarterTextField
            value={addresses[index].street}
            disabled={isDisabled}
            onChange={handleAddress('street')}
            error={isSubmitted && addresses[index].street.length === 0 ? true : false}
            helperText={
              isSubmitted && addresses[index].street.length === 0 ? 'Street cannot be empty' : ''
            }
            id="street-name"
            inputProps={{ 'data-testid': `streetname-${index}` }}
          />
        </Grid>
        <Grid item xs={12}>
          <label htmlFor="buildingName">Address 2(Building Name)</label>
          <SmarterTextField
            value={addresses[index].buildingName}
            disabled={isDisabled}
            onChange={handleAddress('buildingName')}
            id="buildingName"
            inputProps={{ 'data-testid': `buildingName-${index}` }}
          />
        </Grid>
        <Grid item xs={6}>
          <label htmlFor="blockNo">House/Block No*</label>
          <SmarterNumeric
            value={addresses[index].block}
            disabled={isDisabled}
            thousandSeparator={false}
            error={isSubmitted && addresses[index].block.length === 0 ? true : false}
            helperText={
              isSubmitted && addresses[index].block.length === 0 ? 'Block cannot be empty' : ''
            }
            onChange={handleAddress('block')}
            id="blockNo"
            inputProps={{ 'data-testid': `blockNo-${index}` }}
          />
        </Grid>
        <Grid item xs={6}>
          <label htmlFor="postalCode">Postal Code*</label>
          <SmarterNumeric
            value={addresses[index].postalCode}
            disabled={isDisabled}
            thousandSeparator={false}
            error={isSubmitted && addresses[index].postalCode.length === 0 ? true : false}
            helperText={
              isSubmitted && addresses[index].postalCode.length === 0
                ? 'Postal code cannot be empty'
                : ''
            }
            onChange={handleAddress('postalCode')}
            id="postalCode"
            inputProps={{ 'data-testid': `postalCode-${index}` }}
          />
        </Grid>
        <Grid item xs={6}>
          <label htmlFor="level">Level</label>
          <SmarterNumeric
            value={addresses[index]['level'] || addresses[index].unit.split('-')[0]}
            disabled={isDisabled}
            thousandSeparator={false}
            onChange={handleAddress('level')}
            id="level"
            inputProps={{ 'data-testid': `level-${index}` }}
          />
        </Grid>
        <Grid item xs={6}>
          <label htmlFor="number">Number</label>
          <SmarterNumeric
            value={
              addresses[index]['tempUnit'] || addresses[index].unit.split('-')[1]
                ? addresses[index].unit.split('-')[1]
                : ''
            }
            disabled={isDisabled}
            thousandSeparator={false}
            onChange={handleAddress('tempUnit')}
            id="number"
            inputProps={{ 'data-testid': `number-${index}` }}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};
Address.propTypes = {
  index: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
  addresses: PropTypes.array.isRequired,
  setAsPreferred: PropTypes.func.isRequired,
  setAddresses: PropTypes.func.isRequired,
  deleteAddress: PropTypes.func.isRequired,
  userDataLength: PropTypes.number.isRequired,
};
