import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import Transition from './Transition';
import { TreeItem } from '@material-ui/lab';
const useStyles = makeStyles(theme => ({
  iconContainer: props => ({
    '& .close': {
      opacity: 0.3,
    },
    width: '16px',
    color: theme.palette.primary.main,
    display: !props.visibleIcon ? 'none' : 'block'
  }),
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${fade(theme.palette.primary.main, 0.4)}`,
  },
  label: {
    color: theme.palette.text.primary,
    paddingLeft: '5px',
    top: '-5px',
    lineHeight: 1.8,
    '&:hover': {
      backgroundColor: 'rgba(25, 118, 210, 0.08) !important',
    }
  }
}))

const StyledTreeItem = ({visibleIcon= true, ...props}) => {
  const classes = useStyles({ visibleIcon })
  return <TreeItem
    {...props}
    classes={{
      iconContainer: classes.iconContainer,
      group: classes.group,
      label: classes.label
    }}
    TransitionComponent={Transition}
  />
}

export default StyledTreeItem
