import React from "react";
import { 
  Grid,
  InputAdornment,
  Typography,
  TextField,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  withStyles,
  FormControl, 
} from "@material-ui/core";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import { PasswordValidation } from '../registration_components';
import {post} from '@services/http_services/http_services';
import { arrowApiPrefix, getClientNameForLoginAndRegistration } from '../../../common/config';
import { ReactHelmet } from '@components/react-helmet/Helmet';
import { LoadingButton } from '@components/button';

const backgroundColor = "#03ACEF"

const useStyles = theme => ({
  container:{
    flexGrow: 1,
  },
  divContainer: {
    [theme.breakpoints.up('sm')]: {
      width: '400px',
    }
  },
  userInput: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '400px',
    }
  },
  text: {
    fontSize: 14,
    textAlign: 'center',
    color: '#666666',
  },
  signInText: {
    fontSize: 14,
    textAlign: 'center',
    color: '#666666',
  },
  textFieldIconColor: {
    color: '#666666',
  },
  passwordCheck: {
    marginBottom: -5,
  },
  textFieldGap: {
    marginTop: 10
  },
  textSpan: {
    color: backgroundColor,
    cursor: 'pointer'
  }
});

const signUpAPI = `${arrowApiPrefix}/signup`

class AccountSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      showPassword: false,
      termsAndConditions: false,
      passwordValidation: {characterCount: false, number: false, uppercase: false, specialCharacter: false},
      emptyFieldValidation: { email: false,  password: false, password2: false, termsAndConditions: false },
      validationError: { email: false,  password: false, password2: false, termsAndConditions: false },
      validationErrorHelperText: { email: null,  password: null, password2: null, termsAndConditions: null },
      email: null,
      password: null,
      password2: null, 
      helperText: { emptyField: "This field cannot be empty", passwordRequirement: null},
      clientName: null,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.setState({clientName: getClientNameForLoginAndRegistration(this.props.match.params.clientName)})
  }

  handleShowPassword = () => {
    this.setState({showPassword: !this.state.showPassword})
  }

  handleTAC = () => {
    this.setState({termsAndConditions: !this.state.termsAndConditions, emptyFieldValidation: {...this.state.emptyFieldValidation, termsAndConditions: false}})
  }

  checkPasswordStrength = (event) => {

    const containNumber = /\d/
    const containUppercase = /[A-Z]/
    const containSpecialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/

    let validationValues = {characterCount: (event.target.value).length > 7 ? true : false, number: containNumber.test(event.target.value),  uppercase: containUppercase.test(event.target.value), specialCharacter: containSpecialChar.test(event.target.value)}

    this.setState({passwordValidation: validationValues})

    const pwRequirement = !Object.keys(validationValues).every((f) => validationValues[f])
    this.setState({ helperText: {...this.state.helperText, passwordRequirement: pwRequirement && event.target.value !== "" ? "Password does not meet the requirement" : null}})
  }

  onSubmit = () => {

    if (!this.state.email || !this.state.password || !this.state.password2 || !this.state.termsAndConditions){
      this.setState({ emptyFieldValidation: {email: this.state.email ? false : true,  password: this.state.password ? false : true, password2: this.state.password2 ? false : true, termsAndConditions: this.state.termsAndConditions ? false : true} })
      return
    }
    if (!Object.keys(this.state.passwordValidation).every((f) => this.state.passwordValidation[f])){
      return
    }

    this.setState({ isLoading: true })

    const signupJson = {
      email: this.state.email,
      password: this.state.password,
      password2: this.state.password2,
      termsAndConditions: this.state.termsAndConditions,
      clientName: this.state.clientName
    }

    post(signUpAPI, signupJson).then(res =>
      //successful
      {
        this.props.history.push({pathname: `/signup/${this.state.clientName}/personalprofile`, state: {userId: res.data.userId}})
      }
    ).catch(err =>
      //fail
      {
        console.log('something went wrong', err.response.data)
        var errorObject = err.response.data.error 
        for (var key in errorObject) {
          if (errorObject.hasOwnProperty(key)) {
            this.setState({ 
              validationError: {...this.state.validationError, [key]: true},
              validationErrorHelperText: {...this.state.validationErrorHelperText, [key]: errorObject[key]},
              passwordValidation: {characterCount: false, number: false, uppercase: false, specialCharacter: false},
              password: null,
              password2: null,
              isLoading: false
            })
          }
        }
      }
    )   
  }

  handleTextChange = (event) => {
    if(event.target.name === "password"){
      this.checkPasswordStrength(event)   
    }
    this.setState({ [event.target.name]: event.target.value, validationError: {...this.state.validationError, [event.target.name]: false}, emptyFieldValidation: {...this.state.emptyFieldValidation, [event.target.name]: false}})
  }

  render() {
    return (
      <Grid container direction="row" justifyContent="space-evenly" className={this.props.classes.container}>
        <ReactHelmet
          title="Account Settings"
          name={this.state.clientName}
        />
        <Grid item xs={12} style={{display: 'grid', justifyItems: 'center'}}>
          <div className={this.props.classes.divContainer}>
            <Grid container direction="column" alignItems="center" spacing={3} >
                <Grid item style={{marginRight: 'auto'}}>
                    <Typography id="title">Create New Account</Typography>
                </Grid>
                <Grid item>
                <form autoComplete="off">
                  <div>
                    <TextField 
                      error={this.state.emptyFieldValidation.email || this.state.validationError.email}
                      helperText={this.state.emptyFieldValidation.email ? this.state.helperText.emptyField : this.state.validationError.email ? this.state.validationErrorHelperText.email : false}
                      value={this.state.email ? this.state.email : ''} 
                      onChange={this.handleTextChange} 
                      name="email" 
                      className={this.props.classes.userInput} 
                      variant="outlined" 
                      placeholder="User ID (Email)"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircleIcon className={this.props.classes.textFieldIconColor}/>
                          </InputAdornment>
                        ),
                      }}/>
                  </div>
                      
                    <p className={this.props.classes.text}>
                      To safeguard the privacy of your account, <br/>
                      use an email <b>that is accessible only to you.</b>
                    </p>

                    <div>
                      <TextField 
                        error={this.state.emptyFieldValidation.password || this.state.validationError.password || this.state.helperText.passwordRequirement ? true : false} 
                        helperText={this.state.emptyFieldValidation.password ? this.state.helperText.emptyField : this.state.helperText.passwordRequirement ? this.state.helperText.passwordRequirement : false} 
                        value={this.state.password ? this.state.password : ''} 
                        onChange={this.handleTextChange} 
                        name="password" 
                        type={this.state.showPassword ? null : "password"} 
                        className={this.props.classes.userInput} 
                        variant="outlined" 
                        placeholder="Password"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockIcon className={this.props.classes.textFieldIconColor}/>
                            </InputAdornment>
                          ),
                        }}/>
                    </div>
                    <div className={this.props.classes.textFieldGap}>
                      <TextField 
                        error={this.state.emptyFieldValidation.password2 || this.state.validationError.password2} 
                        helperText={this.state.emptyFieldValidation.password2 ? this.state.helperText.emptyField : this.state.validationError.password2 ? this.state.validationErrorHelperText.password2 : false} 
                        value={this.state.password2 ? this.state.password2 : ''} 
                        onChange={this.handleTextChange} 
                        name="password2" 
                        type={this.state.showPassword ? null : "password"} 
                        className={this.props.classes.userInput} 
                        variant="outlined" 
                        placeholder="Re-enter password"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockIcon className={this.props.classes.textFieldIconColor}/>
                            </InputAdornment>
                          ),
                        }}/>
                    </div>
                    <div className={this.props.classes.textFieldGap}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.showPassword}
                            onChange={this.handleShowPassword}
                            color="primary"
                            size="small"
                          />
                        }
                        label={<span style={{fontSize: 14}}>Show Password</span>}
                      />
                    </div>

                    <PasswordValidation 
                      conditions={[
                        this.state.passwordValidation.characterCount,
                        this.state.passwordValidation.number,
                        this.state.passwordValidation.uppercase,
                        this.state.passwordValidation.specialCharacter
                      ]}
                    />

                    <div className={this.props.classes.textFieldGap}>
                    <FormControl error>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.termsAndConditions}
                            onChange={this.handleTAC}
                            color="primary"
                            size="small"
                            style={{color: this.state.emptyFieldValidation.termsAndConditions && 'red'}}
                          />
                        }
                        label={<span style={{fontSize: 12}}>I agree to the <a href="/admedika/user/tnc" style={{color: backgroundColor}}>{`Terms & Conditions`}</a></span>}
                      />
                      {
                        this.state.emptyFieldValidation.termsAndConditions && <FormHelperText>Please agree to the Terms and Conditions</FormHelperText>
                      }
                    </FormControl>
                    </div>

                    <LoadingButton
                      loading={this.state.isLoading}
                      text="Next"
                      onClick={this.onSubmit} 
                      className={this.props.classes.userInput} 
                      disableElevation 
                      disableRipple 
                      component="span"
                      variant="contained" 
                      style={{backgroundColor: backgroundColor, color: 'white', height: 50}}
                    />

                    <p className={this.props.classes.signInText}>
                      <span className={this.props.classes.textSpan} onClick={() => this.props.history.push({pathname: `/${this.state.clientName}/login`, state: {isBR: false}})}>Sign In</span> Instead
                      <br/>
                      Smarter Health <span className={this.props.classes.textSpan} onClick={() => window.location = `https://www.smarterhealth.sg/privacy-policy/`}>Privacy Statement</span> 
                    </p>

                </form>
                </Grid>
            
            </Grid>
          </div>
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(useStyles)(AccountSettings);
