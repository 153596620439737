import React, { useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';
import SearchIcon from '@material-ui/icons/Search';

import { getDiagnosis, getFullDiagnosis } from '@api/search-doctor';
import { ProgressiveSearch } from 'components/progressiveSearch';
import { SmarterTextField } from 'components/input';
import SmarterPopover from 'components/popover';
import { useProgressiveSearch } from './useProgressiveSearch';

const DEFAULT_DIAGNOSIS = {
  code: '',
  description: '',
};

export const DiagnosisSearch = ({
  value,
  onChange,
  disabled,
  allowClear,
  onSearching = null,
  isFullDiagnosisCodes = true,
  ...props
}) => {
  const diagnosisRef = useRef();
  const {
    isVisiblePopover,
    anchorEl,
    result: diagnosis,
    search,
    error,
    width,
    HelperText,
    handleSearch,
    handleSelect,
    handleClose,
    handleChange,
  } = useProgressiveSearch({ defaultResult: DEFAULT_DIAGNOSIS, onChange, value });

  useEffect(() => {
    onSearching && onSearching(!isEmpty(search) && isEmpty(diagnosis.code));
  }, [search, diagnosis]);

  return (
    <div {...props}>
      <SmarterTextField
        error={error}
        placeholder="Enter Diagnosis"
        value={diagnosis.description ? `${diagnosis.code}: ${diagnosis.description}` : search}
        onChange={handleChange}
        disabled={disabled}
        inputRef={diagnosisRef}
        onEnter={handleSearch}
        allowClear={allowClear}
        InputProps={{
          endAdornment: (
            <SearchIcon position="end" style={{ cursor: 'pointer' }} onClick={handleSearch} />
          ),
        }}
        helperText={<HelperText />}
      />
      <SmarterPopover
        id={isVisiblePopover ? 'simple-popover' : undefined}
        open={isVisiblePopover}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <ProgressiveSearch
          apiGetList={isFullDiagnosisCodes ? getFullDiagnosis : getDiagnosis}
          search={search}
          onSearch={handleChange}
          select={diagnosis}
          onSelect={handleSelect}
          width={width}
        />
      </SmarterPopover>
    </div>
  );
};
