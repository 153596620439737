import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import PhoneNumber from '../../../components/input/PhoneNumber';
import { InputTitle } from './InputTitle.jsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  phoneInput: {
    display: 'block',
    width: '100% !important',
    height: theme.spacing(6.25),
    borderRadius: `${theme.spacing(0.5)} !important`,
  },
}));
export const ContactNumber = (props) => {
  const {
    index,
    contactNumbers,
    setAsPreferred,
    isDisabled = false,
    setContactNumbers,
    deleteContactNumber,
    isSubmitted,
    userDataLength,
  } = props;

  const classes = useStyles();
  const isValid = (value) => {
    if (isSubmitted) {
      if (value.length <= 5) {
        return 'Please enter valid phone number';
      }
    }
    return true;
  };
  const changeContactNumbers = (phone) => {
    const newPhones = contactNumbers.map((number, nIdx) => {
      if (index === nIdx) {
        number.value = `+${phone}`;
      }
      return number;
    });
    setContactNumbers(newPhones);
  };

  return (
    <Fragment key={`${index}-number`}>
      <Grid item xs={12}>
        <InputTitle
          index={index}
          items={contactNumbers}
          setAsPreferred={setAsPreferred}
          deleteItem={deleteContactNumber}
          type="number"
          userDataLength={userDataLength}
        />
        <label htmlFor={`mobile-no-${index}`}>Mobile No.*</label>
        <PhoneNumber
          inputClass={classes.phoneInput + `${isDisabled ? ' Mui-disabled' : ''}`}
          value={contactNumbers[index].value}
          isValid={isValid}
          inputProps={{ 'data-testid': `mobile-no-${index}` }}
          onChange={changeContactNumbers}
          disabled={isDisabled}
          id={`mobile-no-${index}`}
        />
      </Grid>
    </Fragment>
  );
};

ContactNumber.propTypes = {
  index: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
  contactNumbers: PropTypes.array.isRequired,
  setAsPreferred: PropTypes.func,
  setContactNumbers: PropTypes.func,
  deleteContactNumber: PropTypes.func,
  userDataLength: PropTypes.number,
};
