import { makeStyles } from '@material-ui/core/styles';
export const useStylesBase = makeStyles((theme) => ({
  container: {
    background: '#fff',
    borderRadius: theme.spacing(1.25),
    padding: theme.spacing(2.5),
    marginBottom: theme.spacing(10),
    '& *': {
      fontFamily: 'Poppins, sans-serif !important',
    },
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(6.25)}px ${theme.spacing(7.5)}px`,
    },

    '& h2': {
      fontSize: theme.spacing(3.5),
      lineHeight: `${theme.spacing(5.25)}px`,
    },
    '& ~ .makeStyles-container-40': {
      marginTop: theme.spacing(3.75),
    },
    '& label': {
      marginBottom: theme.spacing(1.25),
      marginTop: theme.spacing(3),
      display: 'block',
    },
    '& .MuiOutlinedInput-input': {
      padding: `${theme.spacing(2)}px ${theme.spacing(1.75)}px`,
    },
    '& .special-label': {
      display: 'none !important',
    },
    '& .MuiGrid-item': {
      position: 'relative',
    },
    '& .Mui-error': {
      marginLeft: 0,
    },
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
      position: 'absolute',
      top: '100%',
    },
    '& .Mui-disabled': {
      borderColor: '#BFBFBF',
      background: '#F2F2F2',
    },
    '& .invalid-number-message': {
      top: theme.spacing(7),
      left: '0px',
      padding: '0px',
    },
  },
  breadcrumb: {
    '& span': {
      color: '#088CCA',
      fontWeight: 600,
    },
  },
  accountInformationHeading: {
    fontFamily: 'Playfair Display, sans-serif !important',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: theme.spacing(3.5),
    lineHeight: `${theme.spacing(5)}px`,
    [theme.breakpoints.up('sm')]: {
      margin: `${theme.spacing(2.5)}px 0px`,
    },
  },
  pageTitle: {
    fontWeight: '600',
    fontSize: theme.spacing(3.5),
    lineHeight: `${theme.spacing(5.25)}px`,
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(-5.25),
    },
  },
  actionContainer: {
    textAlign: 'center',
    margin: 'auto',
    marginBottom: theme.spacing(6.25),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: theme.spacing(4.5),
    maxWidth: theme.spacing(96.25),
    '& button': {
      maxWidth: theme.spacing(46.25),
      fontSize: theme.spacing(2.5),
      lineHeight: `${theme.spacing(3.5)}px`,
      cursor: 'pointer',
      padding: theme.spacing(2.5),
      textTransform: 'capitalize',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },
  },

  formTitle: {
    borderBottom: '1px solid #088CCA',
    paddingBottom: 5,
    fontSize: theme.spacing(2.75),
    lineHeight: `${theme.spacing(4.125)}px`,
    fontWeight: 600,
    marginTop: theme.spacing(3.75),

    '& > div': {
      color: '#088CCA',
      fontWeight: '600',
      fontSize: theme.spacing(2),
      lineHeight: `${theme.spacing(3)}px`,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      '& > img': {
        marginRight: theme.spacing(0.75),
        width: theme.spacing(2),
      },
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(10),
    },
  },
  textField: {
    position: 'relative',
    '& input': {
      paddingLeft: `${theme.spacing(4.5)}px !important`,
    },
    '& .visibility-outlined-icon': {
      position: 'absolute',
      cursor: 'pointer',
      top: theme.spacing(5.75),
      right: theme.spacing(1.5),
    },
    '& .lock-outlined-icon': {
      position: 'absolute',
      cursor: 'pointer',
      top: theme.spacing(5.75),
      left: theme.spacing(1.5),
      color: '#C4C4C4',
    },
  },
}));
