import React, { useEffect, useState } from 'react';

const withDataFetching = (WrappedComponent) => (props) => {
  const { apiGetList, search, payorId } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const finishLoading = () => setLoading(false);

  const fetchData = () => {
    setLoading(true);
    let params = { search };
    apiGetList(params, payorId)
      .then((res) => setData(res?.data?.data ?? []))
      .catch(finishLoading)
      .finally(finishLoading);
  };

  useEffect(() => {
    if (search?.trim().length) fetchData();
    else setData([]);
  }, [search]);

  return <WrappedComponent data={data} loading={loading} {...props} />;
};

export default withDataFetching;
