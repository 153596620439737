import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { CssBaseline, withStyles, Paper, Grid } from '@material-ui/core';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import BentoAppBar from './app_bar';
import { LeftMenu } from './doctor/LeftMenu';
import Landing from './landing';
import appThemeOptions from '../app_theme/theme';
import './layout.css';

const styles = (theme) => ({
  root: {
    display: 'flex',
    paddingTop: theme.spacing(1),
  },
  logo: {
    padding: 5,
  },
  content: {
    flexGrow: 1,
    margin: 5,
  },
  footer: {
    backgroundColor: '#202223',
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0,
    height: 31,
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '12px',
    color: 'white',
    fontFamily: 'Montserrat-Regular',
    fontWeight: 'bold',
    zIndex: 1,
  },
});
class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appTheme: createTheme(appThemeOptions.light),
    };
  }

  /**
   * Capture higher order component value change and initialize the theme
   * **/
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.theme !== this.props.theme) {
      this.initTheme();
    }
  }

  /**
   *initialize user selected theme
   * **/
  initTheme() {
    this.setState({ appTheme: createTheme(appThemeOptions[this.props.theme]) });
  }

  render() {
    const isClinicSchedule = window.location.pathname.includes('schedule/clinic/provider');
    const isCreateSchedule = window.location.pathname.includes('doctor/schedule/create');
    const isHSPSchedule = window.location.pathname.includes('schedule/clinic/healthscreening');
    return (
      <MuiThemeProvider theme={this.state.appTheme}>
        <Paper
          elevation={0}
          style={{
            height: '100%',
            backgroundColor: this.props.theme === 'light' ? '#f5faff' : '#424242',
          }}
        >
          {/** Css Base Line**/}
          <CssBaseline />
          {/** End Css Base Line**/}
          {/** App Menu Bar**/}
          {this.props.isLoggedIn ? (
            <BentoAppBar onThemeTypeSwitch={this.props.onThemeTypeSwitch} />
          ) : null}
          {!this.props.isLoggedIn && <Landing>{this.props.children}</Landing>}
          {/** End App Menu Bar**/}
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            style={{ height: 'auto' }}
          >
            <Grid item xs={12} style={{ padding: 0 }}>
              {/** Menu for Specific User Type**/}
              <div align="left">
                {/** End Breadcrumb**/}
                {this.props.isLoggedIn && <LeftMenu loginType={this.props.loginType} />}
              </div>
            </Grid>
            {/** End Menu for Specific User Type**/}
            {/** Main Content Container**/}
            {this.props.isLoggedIn && (
              <Grid item xs={isClinicSchedule || isCreateSchedule || isHSPSchedule ? 11 : 10}>
                <main className={this.props.classes.content}>
                  <div style={{ paddingBottom: 25 }}>{this.props.children}</div>
                </main>
              </Grid>
            )}
          </Grid>
          {/** End Main Content Container**/}
          {/** Footer**/}
          <div className={this.props.classes.footer}>
            {/* <img src={logo} alt="logo" width={130} height={28} className={this.props.classes.logo}/> <label style={{fontSize: 18, fontWeight: 350, padding: 1}}>©{new Date().getFullYear()}</label> */}
            <p>powered by Smarter Health Pte. Ltd.</p>
          </div>
          {/** End Footer**/}
        </Paper>
      </MuiThemeProvider>
    );
  }
}
export default compose(withRouter, withStyles(styles))(Layout);
export { Layout as OriginalLayout };
