/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import {
  NEXT,
  BACK,
  FIRST,
  LAST,
  ELEMENT,
  SPREAD,
} from '@features/doctor-search/utilities/constants';

// page spread example:
// [1, 2, 3, 4, 5, "NEXT", 11]
//  innerPages = [2, 3, 4, 5]
// [1, "BACK", 6, 7, 8, 9, "NEXT", 11]
//  innerPages = [6, 7, 8, 9]
// [1, 'BACK', 7, 8, 9, 10, 11];
//  innerPages = [7, 8, 9, 10]

export function usePagination({ dataLength, itemsPerPage, count, currentItem }) {
  const [pages, setPages] = useState([]);
  const [innerPagesStartState, setInnerPagesStartState] = useState(2);

  const firstPage = 1;
  const lastPage = Math.ceil(dataLength / itemsPerPage);
  const innerPagesLength = Math.ceil(count / 2);

  const elementPage = (page) => ({ value: page, type: ELEMENT });
  const spreadPage = (page) => ({ value: page, type: SPREAD });

  const determineInnerPageStartFromDirection = (direction) => {
    switch (direction) {
      case FIRST:
        return 2;
      case LAST:
        return lastPage - innerPagesLength;
      case NEXT:
        return innerPagesStartState + count > lastPage
          ? lastPage - innerPagesLength
          : innerPagesStartState + innerPagesLength;
      case BACK:
        return innerPagesStartState - innerPagesLength <= firstPage
          ? 2
          : innerPagesStartState - innerPagesLength;
      default:
        return;
    }
  };

  function createArray(start, end) {
    const arr = [];
    while (start < end + 1) {
      arr.push(elementPage(start));
      start++;
    }
    return arr;
  }

  function createInnerPages() {
    const derivedInnerPageEnd =
      innerPagesStartState + innerPagesLength - 1 !== lastPage
        ? innerPagesStartState + innerPagesLength - 1
        : innerPagesStartState + innerPagesLength - 2;
    const innerPages = createArray(innerPagesStartState, derivedInnerPageEnd);
    const firstInnerPage = innerPages[0].value;
    const lastInnerPage = innerPages[innerPages.length - 1].value;

    if (firstInnerPage - 1 > firstPage) {
      const previousInnerPage =
        firstInnerPage - innerPagesLength > 1 ? firstInnerPage - innerPagesLength : 1;
      innerPages.unshift(spreadPage(previousInnerPage));
    }
    if (lastPage > lastInnerPage + 1) {
      const nextInnerPage = lastInnerPage + 1;
      innerPages.push(spreadPage(nextInnerPage));
    }
    return innerPages;
  }

  useEffect(() => {
    if (lastPage <= count) setPages(createArray(1, lastPage));
    else {
      const innerPages = createInnerPages();
      setPages([elementPage(firstPage), ...innerPages, elementPage(lastPage)]);
    }
  }, [innerPagesStartState]);

  function computeInnerPagesStatState() {
    const currentPage = Math.floor(currentItem / itemsPerPage) + 1;
    const innerPagesEnd = innerPagesStartState + innerPagesLength - 1;
    if (
      pages
        .slice(1, pages.length - 1)
        .filter((page) => page.value === currentPage && page.type !== SPREAD).length !== 0
    )
      // Guard clause- if currentPage is within the current range, no updates are made
      return;
    else {
      let direction;
      // If the new page is greater than the spread, direction is forward
      if (currentPage > innerPagesEnd) {
        direction = currentPage - innerPagesEnd === 1 ? NEXT : LAST;
      }
      // If the new page is less than the spread, direction is backwards
      else if (currentPage < innerPagesStartState) {
        // If the new current page is a innerPageSpread length away, it's a back function
        direction = currentPage === 1 ? FIRST : BACK;
      }
      const determinedInnerPageStart = determineInnerPageStartFromDirection(direction);
      setInnerPagesStartState(determinedInnerPageStart);
    }
  }

  useEffect(() => {
    computeInnerPagesStatState();
  }, [currentItem]);
  return [pages, setInnerPagesStartState];
}
