import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  InputLabel,
  Select,
  Typography,
  TextField,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { apptAttended, apptCancel, apptNoShow, convertAppointmentDate, convertAppointmentTime } from 'features/common/config';
import { useHistory } from 'react-router-dom';
import { LoadingButton } from '@components/button';

const backgroundColor = '#03ACEF'

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    backgroundColor: backgroundColor,
    color: 'white',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  customButtonSelected: {
    textDecoration: 'unset !important',
    border: `1px solid ${backgroundColor}`,
    '&:hover': {
      backgroundColor: 'white'
    },
    fontWeight: 'bold'
  },
  statusUpdateButton: {
    textTransform: 'none',
    fontSize: 15,
    textDecoration: 'underline',
    color: backgroundColor
  },
  statusUpdateButtonDiv: {
    marginLeft: -theme.spacing(1),
  },
}))

export const ViewAppointmentDialog = ({
  openDialog,
  handleOnClose,
  patientInfo,
  confirmedApptDates,
  name,
  buttonOptions,
  inputNotes,
  rescheduleURL,
  updates,
  handleTextChange,
  loading,
}) => {
  const [unitId, requesterId, serviceType] = rescheduleURL;
  const [isAppointmentDateOver, buttonActive] = buttonOptions;
  const [noteFieldStatus, notes, errorNotes] = inputNotes;
  const [inputStateUpdate, appointmentUpdate] = updates;
  const classes = useStyles();
  const history = useHistory();
  return (
    <Dialog
        open={openDialog}
        onClose={() => handleOnClose(false, 'openConfirmedDialog')}
        fullWidth={true}
        maxWidth={"xs"}
        disableBackdropClick
        disableEscapeKeyDown
    >
      <DialogTitle className={classes.dialogTitle}>
        <IconButton disabled>
          <InfoIcon style={{ color: 'white' }}/>
        </IconButton>
        <IconButton style={{ float: 'right', color: 'white' }} onClick={() => handleOnClose(false, 'openConfirmedDialog')}>
          <CloseIcon />
        </IconButton>
        Appointment
      </DialogTitle>
      <DialogContent dividers>
        <div>
          <Typography style={{ color: '#F37D2A' }}>{patientInfo.patientName}</Typography>
          <Typography style={{ color: 'gray' }}>
          {`
            ${convertAppointmentDate(confirmedApptDates.startDate)}, 
            ${convertAppointmentTime(confirmedApptDates.startDate, confirmedApptDates.endDate)}
          `}
          </Typography>
          <Typography>{name}</Typography>

          <List style={{ paddingTop: 0, marginTop: 20 }}>
            {
              patientInfo.checkDocumentsLength !== 0 &&
              patientInfo.documentLinks.map((doc, i) =>{
                return  <ListItem style={{ paddingTop: 0, paddingLeft: 0 }} key={i}>
                  <Link href={doc} key={i + 1}>
                    <Typography>Document {i + 1}</Typography>
                  </Link>
                </ListItem>
              })
            }
          </List>
        </div>

        {
          isAppointmentDateOver ?
            <div className={classes.statusUpdateButtonDiv}>
              <Button onClick={() => inputStateUpdate(apptAttended)} classes={buttonActive === 'attended' ? {root: classes.customButtonSelected} : null} className={classes.statusUpdateButton} disableRipple disableElevation>Attended</Button>
              <Button onClick={() => inputStateUpdate(apptNoShow)} classes={buttonActive === 'noShow' ? {root: classes.customButtonSelected} : null}  className={classes.statusUpdateButton} disableRipple disableElevation>No Show</Button>
            </div> :
            <div className={classes.statusUpdateButtonDiv}>
              <Button onClick={() => history.push({ pathname: `/doctor/schedule/create/${unitId}/${requesterId}/${serviceType}`, state: { isReschedule: true } })} className={classes.statusUpdateButton} disableRipple disableElevation>Reschedule</Button>
              <Button onClick={() => inputStateUpdate(apptCancel)} classes={buttonActive === 'cancel' ? {root: classes.customButtonSelected} : null} className={classes.statusUpdateButton} disableRipple disableElevation>Cancel</Button>
            </div>
        }

        {
          noteFieldStatus &&
          <div>
            <form>
              <TextField helperText={errorNotes ? 'This field is mandatory' : null} error={errorNotes} value={notes ? notes : ''} onChange={handleTextChange} multiline required={noteFieldStatus === 'mandatory' ? true : false} style={{ marginBottom: 20, marginTop: 20 }} fullWidth label="Notes" variant="outlined" />
            </form>
            <LoadingButton
              loading={loading}
              text="Confirm"
              variant="contained"
              component="span"
              onClick={() => appointmentUpdate()}
              style={{ backgroundColor: backgroundColor, color: 'white', float: 'right' }}
              disableElevation
              disableRipple
            />
          </div>
        }

      </DialogContent>
    </Dialog>
  );
};



export const CreateAppointmentDialog = ({
  openDialog,
  handleOnClose,
  selectedAppointmentData,
  timeSeries,
  onAddAppointment,
  onSubmit,
  loading,
}) => {
  return (
    <Dialog
        open={openDialog}
        onClose={e => handleOnClose(false, 'openDialog')}
        fullWidth={true}
    >
      <DialogTitle>
        Add Appointment
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction="row" spacing={3} justifyContent="space-around" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="button" display="block" gutterBottom>
              Date: {new Date(selectedAppointmentData.SelectedDate).toLocaleDateString("en-GB", { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' }).toUpperCase()}
            </Typography>
          </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Start Time</InputLabel>
            <Select
                labelId="startTimeLabel"
                name="StartTime"
                id="startTime"
                value={selectedAppointmentData.StartTime}
                onChange={onAddAppointment}
            >
              {
                Array.isArray(timeSeries) && timeSeries.length > 0 && timeSeries.map((time, i) => <MenuItem value={time.id} key={i}>{`${new Date(time.name).getHours()}:${new Date(time.name).getMinutes() > 0 ? new Date(time.name).getMinutes() : '0' + new Date(time.name).getMinutes()}`}</MenuItem>)
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel>End Time</InputLabel>
          <Select
              labelId="endTimeLabel"
              name="EndTime"
              id="endTime"
              value={selectedAppointmentData.EndTime}
              onChange={onAddAppointment}
          >
            {
              Array.isArray(timeSeries) && timeSeries.length > 0 && timeSeries.filter(t => t.name > selectedAppointmentData.StartTime).map((time, i) => <MenuItem value={time.id} key={i}>{`${new Date(time.name).getHours()}:${new Date(time.name).getMinutes() > 0 ? new Date(time.name).getMinutes() : '0' + new Date(time.name).getMinutes()}`}</MenuItem>)
            }
          </Select>
        </FormControl>
      </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={loading}
          text="Create Appointment"
          variant="contained"
          component="span"
          onClick={onSubmit}
          style={{ backgroundColor: backgroundColor, color: 'white', width: '200px' }}
          disableElevation
          disableRipple
        />
        <Button onClick={e => handleOnClose(false, 'openDialog')} variant="contained" style={{backgroundColor: '#9e9e9e', color: 'white'}} disableRipple disableElevation>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};


export const ErrorMessageDialog = ({
  openDialog,
  handleOnClose,
}) => {
  return (
    <Dialog
        open={openDialog}
        onClose={e => handleOnClose(false, 'errorDialog')}
        fullWidth={true}
    >
      <DialogTitle>
        Error
      </DialogTitle>
      <DialogContent dividers>
        <Grid>
          <Typography>
            You can't reschedule this appointment within the same preferred time slot. Please select another preferred time slot or any other available time slots from the calendar.
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={e => handleOnClose(false, 'errorDialog')} variant="contained" style={{backgroundColor: backgroundColor, color: 'white'}} disableRipple disableElevation>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
