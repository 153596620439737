import React, { useEffect, useState } from 'react';
import SideBarMenu from '@components/sidebar/Sidebar';
import { getMenu } from '@api/menu';

export const LeftMenu = ({ loginType = 'doctor' }) => {
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    getMenu({ loginType })
      .then((res) => {
        if (res?.code && res?.data) {
          setMenu(res.data);
        }
      })
      .catch((err) => console.log('failed ', err));
  }, []);

  return <SideBarMenu menu={menu} />;
};
