import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  checkBox: {
    alignItems: 'unset',
    '& .MuiCheckbox-root': {
      display: 'flex',
      paddingTop: theme.spacing(0),
      alignItems: 'flex-start',
      '& .MuiTouchRipple-root': {
        display: 'none !important',
      },
    },
  },
}));

export const SmarterCheckbox = ({ value, label, onChange = null, ...props }) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(null);
  const [checkedOnChange, setCheckedOnChange] = useState(null);

  const handleOnChange = (event, isChecked) => {
    setCheckedOnChange(isChecked);
    onChange && onChange(isChecked);
  };

  useEffect(() => {
    setChecked(value);
  }, [value]);

  return (
    <FormControlLabel
      className={classes.checkBox}
      control={<Checkbox onChange={handleOnChange} {...props} />}
      label={<div dangerouslySetInnerHTML={{ __html: label }} />}
      checked={checkedOnChange ? checkedOnChange : checked}
    />
  );
};
