import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { HORIZONTAL, XL, LG } from '../utilities/constants';
import { HorizontalList, VerticalList } from './list';
import { OrientationButtonBar } from '@components/button';
import { useResolution } from '@hooks';

export const DoctorResults = ({ doctors, handleSelect, submittingSelectedDoctor }) => {
  const classes = useStyles();
  const [view, setView] = useState(HORIZONTAL);
  const size = useResolution();

  const handleScrollToHeader = () =>
    document.getElementById('search-result-header').scrollIntoView({ behavior: 'smooth' });

  const isLargeEnough = size === XL || size === LG;

  useEffect(() => {
    handleScrollToHeader();
  }, []);

  return (
    <div id="search-result-header">
      <div className={classes.header}>
        <SearchText length={doctors.length} />
        <OrientationButtonBar
          state={view}
          setState={setView}
          hidden={!(doctors.length && isLargeEnough)}
        />
      </div>

      {doctors.length > 0 &&
        (view === HORIZONTAL && isLargeEnough ? (
          <HorizontalList
            doctors={doctors}
            handleSelect={handleSelect}
            submitting={submittingSelectedDoctor}
          />
        ) : (
          <VerticalList
            doctors={doctors}
            handleSelect={handleSelect}
            handleNewPage={handleScrollToHeader}
            submitting={submittingSelectedDoctor}
          />
        ))}
    </div>
  );
};

const SearchText = ({ length }) =>
  !length
    ? "We didn't find any record matching your search criteria(s). Please refine your search and try again"
    : `We found ${length} records matching your search criteria(s).`;

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
}));
