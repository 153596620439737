import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { SectionAForm, SectionAuthorisation } from '../components/SectionA';
import { checkAgeUnder18 } from '../utils/utils';

export const SectionA = forwardRef(
  ({ isCreating = false, caseId = null, setCaseId, setIsCreating, payorId }, ref) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const sectionAFormRef = useRef(null);
    const sectionAuthorisationRef = useRef(null);
    const pageRefs = [sectionAFormRef, sectionAuthorisationRef];
    const [isLegalGuardianRequiredToSign, setIsLegalGuardianRequiredToSign] = useState(false);
    const [signerName, setSignerName] = useState('');
    const [formModel, setFormModel] = useState({
      patient: {},
      guardian: {},
      policyOwner: {},
    });
    const [isUnder18, setIsUnder18] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const pages = [
      <SectionAForm
        ref={sectionAFormRef}
        isCreating={isCreating}
        caseId={caseId}
        setCaseId={setCaseId}
        setIsLegalGuardianRequiredToSign={setIsLegalGuardianRequiredToSign}
        formModel={formModel}
        setFormModel={setFormModel}
        isUnder18={isUnder18}
        setIsCreating={setIsCreating}
        setSignerName={setSignerName}
        payorId={payorId}
      />,
      <SectionAuthorisation
        ref={sectionAuthorisationRef}
        caseId={caseId}
        isLegalGuardianRequiredToSign={isLegalGuardianRequiredToSign}
        isUnder18={isUnder18}
        isPatient={!isLegalGuardianRequiredToSign}
        signerName={signerName}
        isSubmitted={isSubmitted}
        payorId={payorId}
      />,
    ];

    useImperativeHandle(ref, () => ({
      next: async () => {
        try {
          const goNext = await pageRefs[activeStep]?.current?.next();
          if (!goNext) return false;
          if (activeStep === pages.length - 1) return true;
          setActiveStep(activeStep + 1);
          setIsSubmitted(true);
        } catch (e) {
          console.log(e);
        }
      },
      back: () => {
        if (activeStep === 0) {
          setIsCreating(false);
          return true;
        } else {
          const isSteppedIn = !!activeStep;
          if (isSteppedIn) setActiveStep((prev) => prev - 1);
          setIsSubmitted(false);
          return !isSteppedIn;
        }
      },
    }));

    useEffect(() => {
      setIsUnder18(checkAgeUnder18(formModel.patient.dateOfBirth));
    }, [formModel.patient.dateOfBirth]);

    return pages.map((page, pageIdx) => (
      <div key={`sectiond-${pageIdx}`} className={clsx(activeStep !== pageIdx && classes.hidden)}>
        {page}
      </div>
    ));
  },
);

const useStyles = makeStyles(() => ({
  hidden: { display: 'none' },
}));
