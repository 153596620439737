import clsx from 'clsx';
import { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { makeStyles } from '@material-ui/styles';
import { saveTreatmentCostDetail } from 'apis/paf';

import { SectionDForm, TreatmentCostSummary, EstimateFeeGenerator } from '../components/SectionD';
import { separateObject } from 'utils/utils';
import { SmarterErrors } from 'components/alert';

export const SectionD = forwardRef(({ caseId, payorId, ...props }, ref) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState([{}]);
  const [errors, setErrors] = useState([]);

  const formRef = useRef(null);
  const feeGeneratorRef = useRef(null);
  const sectionRefs = [formRef, feeGeneratorRef];

  const [baseForm, feeForm] = formData;

  const sections = [
    <SectionDForm ref={formRef} payorId={payorId} />,
    <EstimateFeeGenerator ref={feeGeneratorRef} formData={baseForm} payorId={payorId} />,
    <TreatmentCostSummary
      treatmentCosts={feeForm?.['estimateTreatmentCost'] ?? []}
      formData={baseForm}
      payorId={payorId}
    />,
  ];

  function handleForm(sectionForm) {
    let newFormData = [...formData];

    const newForm = sectionForm.reduce((acc, field) => {
      const [key, value] = separateObject(field);
      acc[key] = value;
      if (key === 'lengthOfStay') acc[key] = Number(value);
      if (key === 'isSurgicalProcedure') acc[key] = value === 'Yes';
      return acc;
    }, {});

    newFormData[activeStep] = newForm;
    setFormData(newFormData);
  }

  async function handleSubmit() {
    setErrors([]);
    try {
      const response = await saveTreatmentCostDetail({
        caseId,
        estimateTreatmentCost: feeForm['estimateTreatmentCost'],
        ...baseForm,
      });
      return response.data;
    } catch (error) {
      setErrors(['Submission Failed. Please try again.']);
      return false;
    }
  }

  function imperativeRefMethods() {
    async function next() {
      if (activeStep !== 2) {
        const sectionForm = await sectionRefs[activeStep]?.current?.next();
        if (sectionForm) {
          handleForm(sectionForm);
          setActiveStep(activeStep + 1);
        }
        return false;
      }
      const submitted = await handleSubmit();
      return !!submitted;
    }

    function back() {
      if (!!activeStep) setActiveStep((prev) => prev - 1);
      setErrors([]);
      return !activeStep;
    }
    return { next, back };
  }

  useImperativeHandle(ref, imperativeRefMethods);

  return (
    <div {...props}>
      <SmarterErrors errors={errors} />
      {sections.map((section, sectionIdx) => (
        <div
          key={`sectiond-${sectionIdx}`}
          className={clsx(activeStep !== sectionIdx && classes.hidden)}
        >
          {section}
        </div>
      ))}
    </div>
  );
});

const useStyles = makeStyles(() => ({ hidden: { display: 'none' } }));
