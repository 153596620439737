import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Empty } from '../icons';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    marginTop: '10px'
  }
}));

const SmarterEmpty = ({text}) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Empty />
      <div className={classes.text}>{text}</div>
    </div>

  );
};

SmarterEmpty.propTypes = {
  text: PropTypes.string
}

SmarterEmpty.defaultProps = {
  text: 'No Data'
}

export default SmarterEmpty;
