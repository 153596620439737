import { MAX_NAME_LEN } from '../../../utilities/constants';

export function splitBackground(text) {
  const split = text.split('');
  const arr = [];
  function mappingText() {
    let phrase = '';
    split.map((char, index) => {
      if (char === '-' && split[index - 1] === '\n') {
        arr.push(`-${phrase}`);
        phrase = '';
        return;
      }
      if (char === '\n' || char === '-') return;
      phrase = phrase + char;
    });
    arr.push(`-${phrase}`);
  }
  mappingText();
  return arr;
}

export function prioritizePanels(list) {
  const withPanels = [];
  const withoutPanels = [];
  function panelPusher(item) {
    if (item.panels.length > 0) withPanels.push(item);
    else withoutPanels.push(item);
  }
  list.map(panelPusher);
  return [...withPanels, ...withoutPanels];
}

export function collapsibleList({ list, func = (arg) => arg, breakpoint, expanded = false }) {
  const shown = list.filter((ss, index) => func(ss) && index < breakpoint);
  const hidden = !expanded ? [] : list.filter((ss, index) => func(ss) && index >= breakpoint);
  return [shown, hidden];
}

export const organizedLang = (languages) =>
  languages?.map((lang, idx) => (idx !== languages.length - 1 ? `${lang},  ` : lang));

export const truncatedName = (name, len = MAX_NAME_LEN) =>
  name.length <= len ? name : `${name.substring(0, len - 4)} ...`;
