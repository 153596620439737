import React from 'react';
import { ReactHelmet } from '@components/react-helmet/Helmet';

import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { MD, XS } from '@features/doctor-search/utilities/constants';
import { LoadingButton } from 'components/button';

export const Form = ({
  helmet = '',
  title = '',
  submit = () => console.log('hi'),
  submitButtonText = 'Search',
  children,
  errors = [],
  submitting = false,
  submitOnKeyPressEnter = true,
  disabledSubmit = false,
}) => {
  const classes = useStyles({ disabledSubmit });

  function handleSubmit(event) {
    if (event) event.preventDefault();
    submit();
  }

  return (
    <Paper className={classes.root}>
      <ReactHelmet title={helmet} />
      <Typography className={classes.header} id="title">
        {title}
      </Typography>
      {!!errors.length && (
        <div className={classes.errors}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errors.map((error, index) => {
              const idx = index + 1;
              return <Typography key={`search-form-error-${idx}`}>{`${idx}: ${error}`}</Typography>;
            })}
          </Alert>
        </div>
      )}

      <form onSubmit={handleSubmit} className={classes.form}>
        {children}
        <div className={classes.buttonBar}>
          <LoadingButton
            loading={submitting}
            text={submitButtonText}
            classes={classes.button}
            onClick={handleSubmit}
            variant="outlined"
            component="span"
            disabled={disabledSubmit}
            type={submitOnKeyPressEnter ? 'submit' : 'button'}
          />
        </div>
      </form>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '0.3cm',
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
  },
  form: {
    [theme.breakpoints.up(MD)]: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.down(MD)]: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },
  errors: {
    [theme.breakpoints.up(MD)]: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.down(MD)]: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },
  header: {
    // fontFamily: 'Lato-Bold',
    [theme.breakpoints.up(XS)]: {
      marginBottom: theme.spacing(1),
      // fontSize: '26px',
    },
    [theme.breakpoints.up(MD)]: {
      marginBottom: theme.spacing(3),
    },
  },
  buttonBar: {
    marginTop: theme.spacing(4),
  },
  button: (props) => ({
    color: 'white',
    backgroundColor: !props.disabledSubmit ? '#03ACEF' : 'rgba(0, 0, 0, 0.12)',
    '&:hover': {
      backgroundColor: '#03ACEF',
    },
  }),
}));
