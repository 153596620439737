export const COUNTRY = 'COUNTRY';
export const REGION = 'REGION';
export const CITY = 'CITY';
export const HORIZONTAL = 'HORIZONTAL';
export const VERTICAL = 'VERTICAL';
export const NEXT = 'NEXT';
export const BACK = 'BACK';
export const FIRST = 'FIRST';
export const LAST = 'LAST';
export const SPREAD = 'SPREAD';
export const ELEMENT = 'ELEMENT';
export const XL = 'xl';
export const LG = 'lg';
export const MD = 'md';
export const SM = 'sm';
export const XS = 'xs';
export const MOBILE = 'mobile';
export const NAME = 'NAME';
export const SPECIALTIES = 'SPECIALTIES';
export const PROCEDURE = 'PROCEDURE';
export const DIAGNOSIS = 'DIAGNOSIS';
export const SHORT_TEXT = 'SHORT_TEXT';
export const SUB = 'SUBSPECIALTY';
export const LOC = 'PRACTICE_LOCATIONS';
export const LANG = 'LANGUAGES';
export const INNER_SEC = 'SECONDARY_INNER_CONTENT';
export const MAX_NAME_LEN = 32;
