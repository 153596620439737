import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  getConsolidatedCountries,
  postBookingInitiation,
  getDoctors,
  getDoctor,
  getSpecialties,
} from '@api';

import { SearchDoctor, DoctorResults } from './pages';
import { Loading } from 'components/loading';
import { RadioForm } from 'components/pages';
import SelectSession from 'features/create-booking/create_patient_appointment/SelectSession';

export const SearchDoctorContainer = () => {
  const history = useHistory();
  const classes = useStyles();
  // database data
  const [doctors, setDoctors] = useState({
    data: [],
    loading: false,
    error: '',
  });
  const [facilities, setFacilities] = useState({
    data: [],
    loading: false,
    error: '',
  });
  const [specialties, setSpecialties] = useState({
    data: [],
    loading: false,
    error: '',
  });
  const [locations, setLocations] = useState(null);
  const [isSearchDoctor, setIsSearchDoctor] = useState(false);
  // selected items
  const [selectedDoctorId, setSelectedDoctorId] = useState('');
  const [selectedFacility, setSelectedFacility] = useState({
    value: '',
    helperText: '',
    loading: false,
  });
  const [serviceType, setServiceType] = useState('');
  const [unitId, setUnitId] = useState('');
  const [requesterId, setRequesterId] = useState('');

  // Functions that affects api behavior
  async function handleSelectClinic() {
    if (!selectedFacility.value) return;
    else {
      setSelectedFacility({ ...selectedFacility, loading: true, error: '' });
      const specialist = doctors.data.find((doctor) => doctor.doctorId === selectedDoctorId);
      const facility = facilities.data.find(
        ({ facilityName: name }) => name === selectedFacility.value,
      );
      try {
        const { response, data } = await postBookingInitiation({
          specialistId: specialist.doctorId,
          specialistSpecialty: specialist.specialties[0],
          specialistFacilityId: facility.facilityId,
          specialistCountry: facility.country,
          specialistRegion: facility.regionState,
          specialistCity: facility.city,
        });
        const { unitId: responseUnitId, referenceNo, cRequestId } = data;
        setSelectedFacility({ ...selectedFacility, loading: false });
        localStorage.setItem(
          'urlAfterApptSessionSelection',
          `/app/opd/patient/client/form/${referenceNo}`,
        );
        setServiceType('opd');
        setUnitId(responseUnitId);
        setRequesterId(referenceNo);
      } catch (error) {
        setSelectedFacility({
          ...selectedFacility,
          loading: false,
          error: error.response.data,
        });
      }
    }
  }

  async function handleSearchDoctors({
    country,
    region,
    city,
    name,
    specIdx,
    diagnosis,
    procedure,
  }) {
    setDoctors({
      data: null,
      loading: true,
      error: '',
    });
    try {
      setIsSearchDoctor(true);
      const { response, data } = await getDoctors({
        country,
        region,
        city,
        doctorName: name,
        specialty: specialties.data[specIdx],
        diagnosis,
        procedure,
      });
      const doctorsData = response?.status === 404 ? [] : data.data;
      setDoctors({
        ...doctors,
        loading: false,
        data: doctorsData,
      });
    } catch (error) {
      setDoctors({
        ...doctors,
        loading: false,
        error: 'Something happened when searching for doctors',
      });
      console.log(error);
    }
  }

  // Functions that affects state
  function resetFacilityState() {
    setFacilities({ data: [], loading: false, error: '' });
    setSelectedDoctorId('');
    setSelectedFacility({ value: '', helperText: '', loading: false });
  }

  function resetScheduleState() {
    setUnitId('');
    setRequesterId('');
    setServiceType('');
  }

  async function handleSetSpecialtiesData(country) {
    setSpecialties({ ...specialties, loading: true });
    const { data: response } = await getSpecialties(country);
    setSpecialties({ data: response.data, loading: false });
  }

  useEffect(() => {
    const initWithCountryData = async () => {
      const { data } = (await getConsolidatedCountries()).data;
      setLocations(data);
    };
    initWithCountryData();
  }, []);

  useEffect(() => {
    async function searchDoctorFacilitiesById() {
      setFacilities({ ...facilities, loading: true, error: '' });
      try {
        const { data } = await getDoctor(selectedDoctorId);
        setFacilities({ loading: false, data: data.facilities, error: '' });
      } catch (error) {
        setFacilities({
          ...facilities,
          loading: false,
          error: 'Something happened when searching for facilities',
        });
        console.error(error);
      }
    }
    if (selectedDoctorId) searchDoctorFacilitiesById();
  }, [selectedDoctorId]);

  useEffect(() => {
    if (!!facilities.data.length) {
      if (facilities.data.length === 1)
        setSelectedFacility({ ...selectedFacility, value: facilities.data[0].facilityName });
    }
  }, [facilities.data]);

  const isSelectSessionVisible = !!(serviceType && unitId && requesterId);

  return (
    <Container className={classes.container}>
      <Loading isLoading={!locations}>
        <div className={!facilities.data.length ? classes.visible : classes.hidden}>
          <div className={classes.form}>
            <SearchDoctor
              locations={locations}
              specialties={specialties.data}
              onSubmit={handleSearchDoctors}
              onCountrySelected={handleSetSpecialtiesData}
              searching={doctors.loading}
            />
          </div>
          <div>
            {isSearchDoctor && !!doctors.data && (
              <DoctorResults
                doctors={doctors.data}
                handleSelect={(id) => setSelectedDoctorId(id)}
                submittingSelectedDoctor={{
                  loading: facilities.loading,
                  id: selectedDoctorId,
                }}
              />
            )}
          </div>
        </div>
        <div
          className={
            facilities.data.length > 0 && !isSelectSessionVisible ? classes.visible : classes.hidden
          }
        >
          <RadioForm
            title="Choose Location"
            state={selectedFacility.value}
            setState={(value) =>
              setSelectedFacility({
                ...selectedFacility,
                value: selectedFacility.value === value ? '' : value,
              })
            }
            list={facilities.data.map(({ facilityId, facilityName, panels }) => {
              return {
                id: facilityId,
                value: facilityName,
                display:
                  panels.length > 0 &&
                  panels
                    .filter((panel) => panel.iconUrl)
                    .map((panel, idx) => (
                      <img key={`panel-logo-${idx}`} src={panel.iconUrl} alt="panel" />
                    ))[0],
              };
            })}
            handleSubmit={handleSelectClinic}
            handleBack={resetFacilityState}
            submitting={selectedFacility.loading}
            error={selectedFacility.error}
          />
        </div>
        <div className={isSelectSessionVisible ? classes.visible : classes.hidden}>
          {isSelectSessionVisible && (
            <SelectSession
              serviceType={serviceType}
              unitId={unitId}
              requesterId={requesterId}
              back={resetScheduleState}
            />
          )}
        </div>
      </Loading>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: '50px',
    '@media (max-width:450px)': {
      width: '120%',
      marginLeft: '-10%',
    },
  },
  form: {
    marginBottom: '20px',
  },
  visible: { display: 'block' },
  hidden: { display: 'none' },
}));
