import { useSmarterForm } from '@hooks';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { blockCalculation, fetchCurrencyInfo, fetchPAFSchema } from '../../utils/utils';
import { SECTION_D_FEE_GENERATOR } from 'features/common/config';

const parseValues = (item) => Object.values(item)[0];

export const EstimateFeeGenerator = forwardRef(({ formData, payorId, ...props }, ref) => {
  const { isSurgicalProcedure, treatmentType, lengthOfStay = 0 } = formData;
  const [data, setData] = useState([]);
  const [currency, setCurrency] = useState({});
  const { fields, renderFields, handleSubmit, handleValidation } = useSmarterForm({
    schema: data,
    parentId: 'epaf-estimate-fee-generator',
  });

  function parseSubmission(results) {
    const calculateTreatment = blockCalculation(lengthOfStay, currency.rate);
    let { estimateTreatmentCost } = results[0]['Estimate Fee Generator'][0];
    if (isSurgicalProcedure) {
      estimateTreatmentCost = estimateTreatmentCost.map((procedure) => {
        let { procedureCode, procedureFees } = parseValues(procedure);
        procedureFees.others = procedureFees.others.map(parseValues);
        return { procedureCode, ...procedureFees };
      });
    } else {
      estimateTreatmentCost.others = estimateTreatmentCost.others.map(parseValues);
    }

    function parseTreatmentCost({ procedureCode, anaesthetist, others, principleFee }) {
      principleFee = calculateTreatment(principleFee);
      others = others.map((other) => calculateTreatment(other));
      let treatment = { others, principleFee };
      if (anaesthetist) treatment['anaesthetist'] = calculateTreatment(anaesthetist);
      if (procedureCode) treatment['procedureCode'] = procedureCode;

      return treatment;
    }

    const newEstimateTreatmentCost = (
      Array.isArray(estimateTreatmentCost) ? estimateTreatmentCost : [estimateTreatmentCost]
    ).map(parseTreatmentCost);

    return [{ estimateTreatmentCost: newEstimateTreatmentCost }];
  }

  async function prepForm() {
    const estimateFeeGeneratorSubType = `${SECTION_D_FEE_GENERATOR}?treatmentType=${treatmentType}&isSurgicalProcedure=${isSurgicalProcedure}`;
    const res = await fetchPAFSchema(payorId, estimateFeeGeneratorSubType);
    setData(res);
  }

  useEffect(() => {
    if (payorId) prepForm();
  }, [isSurgicalProcedure, treatmentType]);

  useEffect(() => {
    async function setCurrencyInfo() {
      const response = await fetchCurrencyInfo(payorId);
      setCurrency(response ?? {});
    }
    if (payorId) setCurrencyInfo();
  }, [payorId]);

  useImperativeHandle(ref, () => ({
    next: async () => {
      const hasErrors = await handleValidation();
      if (hasErrors) return false;
      const results = handleSubmit();
      return parseSubmission(results);
    },
    back: () => true,
  }));

  return <form {...props}>{fields.map(renderFields)}</form>;
});
