import React from "react";
import { 
  Grid,
  Paper,
  Typography,
  makeStyles
} from "@material-ui/core";


const useStyles = makeStyles(theme => ({
  paper: {
    height: 270,
    width: '100%',
    padding: 15,
    borderRadius: '0.3cm',
    display: 'grid',
    [theme.breakpoints.up('350')]: {
      width: 350
    }
  },
  text: {
    cursor: 'pointer',
    fontSize: 18
  },
}));


export const ServiceOption = ({
  ...props
}) => {
  const classes = useStyles();
  return(
    <Grid item>
      <Paper className={classes.paper} onClick={props.onClick}>
        <Grid container alignItems="center">
          <Grid container direction="row" justifyContent="center" spacing={4}>
            <Grid item>
              {props.children}
            </Grid>
            <Grid item>
            <Typography id="latobold" className={classes.text} style={props.style}>
              {props.text}
            </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}