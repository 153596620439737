import React, { useEffect, useState } from 'react';
import { ResetPasswordPage } from './ResetPasswordPage';
import { OTP } from '../../components/OTP/OTP';
import { Typography, Dialog } from '@material-ui/core';
import { ClearRounded, Clear } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { postForgotPassword, postVerifyOTP } from '../../apis';

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    padding: '20px',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(7.5),
    },
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& > div': {
      width: theme.spacing(5.625),
      height: theme.spacing(5.625),
      margin: theme.spacing(0.5),
    },
    '& input': {
      width: theme.spacing(5.625),
      height: theme.spacing(5.625),
      padding: 0,
      textAlign: 'center',
    },
  },
  dialogTitle: {
    fontWeight: 700,
    fontSize: theme.spacing(3.75),
    lineHeight: `${theme.spacing(5)}px`,
    marginTop: theme.spacing(3.5),
    marginBottom: theme.spacing(2.5),
  },
  dialogText: {
    fontSize: theme.spacing(2),
    lineHeight: `${theme.spacing(2.5)}px`,
    color: '#FF0000',
  },
  resendCode: {
    color: '#737373',
    fontSize: theme.spacing(2),
    lineHeight: `${theme.spacing(3)}px`,
    marginTop: theme.spacing(2),
  },
  clearIcon: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    cursor: 'pointer',
  },
  clearRoundedIcon: {
    background: 'red',
    width: '55px',
    height: '55px',
    color: '#fff',
    borderRadius: '50%',
  },
}));

const timeDifferences = () => {
  let currTime = new Date();
  currTime.setSeconds(currTime.getSeconds() + 90);
  let differences = 0;
  if (!localStorage.getItem('endTime')) {
    localStorage.setItem('endTime', currTime);
    differences = 90;
  } else {
    differences = Math.floor(
      (new Date(localStorage.getItem('endTime')).getTime() - new Date().getTime()) / 1000,
    );
  }

  return {
    currTime,
    differences,
  };
};

export const EnterVerification = ({ onSubmit }) => {
  const [values, setValues] = useState([...Array(6)].map(() => ''));
  const [showError, setShowError] = useState(false);
  const [isGeneralError, setIsGeneralError] = useState(false);
  const [endTime] = useState(new Date());
  const [counter, setCounter] = useState(timeDifferences().differences);
  let email = sessionStorage.getItem('email') || '';

  useEffect(() => {
    let intervalId = setInterval(() => {
      if (counter > 0) {
        let countDown = counter - 1;
        setCounter(countDown);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [counter, setCounter, endTime]);

  const classes = useStyles();

  const closeDialog = () => {
    setShowError(false);
  };

  const onPostVerifyOTP = async () => {
    let body = {
      user_email: email,
      verification_code: values.join(''),
    };

    try {
      await postVerifyOTP({ ...body });
      onSubmit();
    } catch (error) {
      setShowError(true);
      return 400;
    }
  };

  const closeErrorModal = () => {
    setIsGeneralError(false);
  };
  const resendCode = async () => {
    if (counter <= 0) {
      try {
        await postForgotPassword({ email: email });
        let now = new Date();
        now.setSeconds(now.getSeconds() + 90);
        localStorage.setItem('endTime', now);
        setCounter(90);
      } catch (error) {
        setIsGeneralError(true);
        return 400;
      }
    }
  };

  let resendCodeStyle = {
    cursor: 'default',
  };
  if (counter <= 0) {
    resendCodeStyle = {
      cursor: 'pointer',
      color: '#4126CF',
      textDecoration: 'underline',
    };
  }

  return (
    <>
      <ResetPasswordPage
        title="Enter Verification Code"
        description="A verification code has been sent to your email address for verification before you can
        reset your password"
        buttonProps={{
          text: 'Continue',
          click: onPostVerifyOTP,
          disabled: values.forEach((value) => !value) || counter <= 0,
          'data-testid': 'continue-verification-code',
        }}
        handleClose={closeErrorModal}
        error={isGeneralError}
        resendCode={
          <div
            className={classes.resendCode}
            onClick={resendCode}
            style={resendCodeStyle}
            data-testid="resetPassword-resendCode"
          >
            Resend Code {counter > 0 && `in ${counter}s`}
          </div>
        }
      >
        <div className={classes.inputWrapper}>
          <OTP values={values} setValues={setValues} />
        </div>
        <Dialog onClose={closeDialog} open={showError}>
          <div className={classes.dialogContainer}>
            <ClearRounded className={classes.clearRoundedIcon} />
            <Clear className={classes.clearIcon} onClick={closeDialog} />
            <Typography component="h3" className={classes.dialogTitle}>
              Invalid Code!
            </Typography>
            <Typography component="p" className={classes.dialogText}>
              Please check the code and enter it again. If the verification code has expired, click
              on "Resend Code" to request for a new one.
            </Typography>
          </div>
        </Dialog>
      </ResetPasswordPage>
    </>
  );
};
