import moment from 'moment';
import _ from 'lodash';
import { getClientConfig, getTaxInfo, getPAFSchema } from 'apis/paf';
import { FORM_TYPE } from 'features/common/config';

export function buildFormModel(formConfig) {
  let result = {};
  formConfig.forEach((section) => {
    section.fields.forEach((field) => {
      result[field.inputName] = field.value || '';
    });
  });

  return [result];
}

export const areEqual = (prev, curr) => (key) => _.isEqual(prev[key], curr[key]);

export const convertDateTimeForServer = (date) => (date ? moment(date).valueOf() : '');

export const convertTimeStampToDate = (timestamp) =>
  new Date(Number(timestamp)).toLocaleDateString('en-GB', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  });

export const checkAgeUnder18 = (date) => {
  const year = moment(date, 'YYYY-MM-DD').year();
  const currentYear = moment().year();
  return currentYear - year < 18;
};

export const validate = (formConfig, formModel) => {
  const fields = [...formConfig].reduce((prev, curr) => {
    return prev.concat(curr.fields);
  }, []);
  const errs = [];
  fields.forEach((field) => {
    if (field.type === 'checkbox') {
      !_.isEmpty(formModel[field.inputName]) && errs.push(`${field.uiLabel} cannot be empty`);
    } else if (
      !field?.props?.disabled &&
      ((typeof field?.props?.visible === 'boolean' && field?.props?.visible === true) ||
        (typeof field?.props?.visible === 'object' &&
          formModel[field?.props?.visible?.inputName] === field?.props?.visible?.value)) &&
      field.isRequired &&
      _.isEmpty(formModel[field.inputName])
    ) {
      errs.push(`${field.uiLabel} cannot be empty`);
    } else if (
      !field?.props?.disabled &&
      typeof field?.props?.visible === 'undefined' &&
      field.isRequired &&
      _.isEmpty(formModel[field.inputName])
    ) {
      errs.push(`${field.uiLabel} cannot be empty`);
    } else if (field.validator) {
      const errMsg = field.validator(formModel[field.inputName]);
      errMsg && errs.push(errMsg);
    }
  });
  return errs;
};

export function blockCalculation(lengthOfStay, rate) {
  return (costs) => {
    if (!costs) return { totalFee: 0 };
    const { fee, dailyAttendanceFee, isGstPercent, ...nonFeeStuff } = costs;

    const totalDailyAttendanceFee = Number(dailyAttendanceFee ?? 0) * Number(lengthOfStay ?? 0);
    const baseTotal = Number(fee) + Number(totalDailyAttendanceFee);
    const gstFee = Math.round((isGstPercent ? rate : 0) * baseTotal * 100) / 100;
    const totalFee = gstFee + baseTotal;

    let payload = { fee: Number(fee), isGstPercent, totalFee, ...nonFeeStuff };

    if (Number(lengthOfStay) && !!dailyAttendanceFee) {
      payload = {
        ...payload,
        dailyAttendanceFee: Number(dailyAttendanceFee),
        totalDailyAttendanceFee,
      };
    }
    if (isGstPercent) payload = { ...payload, gstFee };

    return payload;
  };
}

export async function fetchCurrencyInfo(payorId) {
  try {
    const clientConfigResponse = await getClientConfig(payorId);
    const taxResponse = await getTaxInfo(payorId);
    return {
      taxName: taxResponse.data.taxInfo.taxName,
      rate: Number(taxResponse.data.taxInfo.taxPercent),
      currAbbr: clientConfigResponse.data.data.currency,
      symbol: '$',
    };
  } catch (err) {
    console.error(err);
  }
}

export async function fetchPAFSchema(payorId, subType) {
  try {
    const res = await getPAFSchema(payorId, FORM_TYPE, subType);
    return res.data.formTemplate;
  } catch (err) {
    return [];
  }
}
