import Cookies from 'universal-cookie';
import { get, post, put, del } from '@services/http_services/http_services';
import { arrowApiPrefix, bentoApiPrefix } from '../features/common/config';
import { patch } from 'services/http_services/http_services';

const cookies = new Cookies();
const userId = cookies.get('userId');
const client = cookies.get('client');
const roleId = cookies.get('roleId');

export const discoveryApiPrefix = 'discovery/api/v1';

export const pdsApiPrefix = 'pds/v1';
export const userApiPrefix = 'users/v1/user';

export const getCountries = () => get(`${discoveryApiPrefix}/country`);

export const getConsolidatedCountries = () =>
  get(`${discoveryApiPrefix}/country/consolidated/client/${client}`);

export const getRegionState = (country) => get(`${discoveryApiPrefix}/country/${country}/region`);

export const getCities = (country, region) =>
  get(`${discoveryApiPrefix}/country/${country}/region/${region}/city`);

export const getDoctor = (specialistId) =>
  post(`${arrowApiPrefix}/specialist/${specialistId}/details?clientName=${client}`, {});

export const getDoctors = (payload) =>
  post(`${discoveryApiPrefix}/doctor`, { ...payload, clientName: client });

export const getDoctorProfilePic = (url) => get(url);

export const postBookingInitiation = (payload) =>
  post(`${arrowApiPrefix}/appointment/opd/create/${userId}/client/${client}`, payload);

export const getSpecialties = (country) =>
  get(`${discoveryApiPrefix}/specialty/country/${country}`);

export const getHealthParams = () =>
  get(`${arrowApiPrefix}/mcu/attributes/${userId}/client/${client}`);

export const postQueryForRecommendations = (payload) =>
  post(`${arrowApiPrefix}/mcu/recommendations/${userId}/client/${client}`, payload);

export const getUser = () => get(`${userApiPrefix}/profile/${userId}`);
export const patchUser = (payload) => patch(`${userApiPrefix}/profile`, payload);
export const addEmail = (payload) => post(`${userApiPrefix}/profile/email`, payload);
export const deleteEmail = (id) => del(`${userApiPrefix}/profile/email/${id}`);
export const putEmail = (id) => put(`${userApiPrefix}/profile/email/${id}/preferred`);

export const addContactNumber = (payload) =>
  post(`${userApiPrefix}/profile/contact_number`, payload);
export const deleteContactNumber = (id) => del(`${userApiPrefix}/profile/contact_number/${id}`);
export const putContactNumber = (id) =>
  put(`${userApiPrefix}/profile/contact_number/${id}/preferred`);

export const addAddress = (payload) => post(`${userApiPrefix}/profile/address`, payload);
export const deleteAddress = (id) => del(`${userApiPrefix}/profile/address/${id}`);
export const putAddress = (id) => put(`${userApiPrefix}/profile/address/${id}/preferred`);

export const getUserAttributes = () => get(`user/profile/${userId}/attributes`);

export const postForPackageLocations = ({ packageId, country, region, city }) =>
  post(`${arrowApiPrefix}/mcu/package/${packageId}/locations/client/${client}`, {
    country,
    region,
    city,
  });

export const putSelectedLocation = (payload) =>
  put(`/arrow/api/mcu/selectlocation/${userId}/client/${client}`, payload);

export const postFormConfig = (payload) => post(`/api/form/config/details`, payload);

//case details related
export const getCaseDetails = (serviceType, requesterId) =>
  get(`${arrowApiPrefix}/v1/${serviceType}/refNo/${requesterId}/details/${userId}`);

export const getBookingDetails = (unitId, requesterId) =>
  get(`${bentoApiPrefix}/booking/unitId/${unitId}/requesterId/${requesterId}/details`);

//calendar related
export const getMCUCalendar = () => get(`${bentoApiPrefix}/mcu/provider/booking`);

export const postProviderClinicSchedule = (payload) =>
  post(`${bentoApiPrefix}/slot/unitIds`, payload);

export const updateAppointmentStatus = (payload) => put(`${bentoApiPrefix}/booking`, payload);

export const getOPDCalendarDoctorFirst = (providerId) =>
  get(`${bentoApiPrefix}/provider/${providerId}/booking/resource`);

export const getOPDCalendarLocationFirst = (providerId) =>
  get(`${bentoApiPrefix}/provider/${providerId}/booking/location`);

export const getProviderSchedule = (unitId) => get(`${bentoApiPrefix}/slot/unitId/${unitId}`);

export const postCreateSchedule = (unitId, payload) =>
  post(`${bentoApiPrefix}/slot/time/unitId/${unitId}`, payload);

export const postCreateScheduleSubmit = (unitId, requesterId, payload) =>
  post(`${bentoApiPrefix}/slot/book/unitId/${unitId}/requesterId/${requesterId}`, payload);

export const getPAFCaseDetails = (caseId) =>
  get(`${pdsApiPrefix}/epaf/summary/caseId/${caseId}/roleType/${roleId}`);

export const postHospitalCharges = (payload) => post(`${pdsApiPrefix}/hospital/cost`, payload);

// reset password
export const postForgotPassword = (payload) => post('/users/v1/user/forgot-password', payload);
export const postVerifyOTP = (payload) => post('/auth/v1/auth/verify-otp', payload);
export const postResetPassword = (payload) => post('/users/v1/user/reset-password', payload);

// change password
export const postChangePassword = (payload) => post(`${userApiPrefix}/change-password`, payload);

// authenticate token
export const postAuthenticateToken = (payload) => post('/apis/v1/authenticate/token', payload);
