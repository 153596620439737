import { PDS_PREFIX, PREAUTH_PREFIX } from './utils/constant';
import { post, get } from '@services/http_services/http_services';
import Cookies from 'universal-cookie';
import { axiosInstance } from '../services/http_services/http_services';
import { authValue, arrowApiPrefix } from '../features/common/config';
import _ from 'lodash';

const cookies = new Cookies();
const userId = cookies.get('userId');
const clientCountry = cookies.get('clientCountry');
const roleId = cookies.get('roleId')?.toLowerCase();
export const createPAF = (clientId) =>
  post(`/${PDS_PREFIX}/initialize/paf/client/${clientId}`, {
    clientCountry: clientCountry,
    initiatorUserId: parseInt(userId),
  });

export const savePolicyInfo = (payload) => post(`/${PDS_PREFIX}/patient/policyInfo`, payload);

export const getQR = (payload) => post(`/${PDS_PREFIX}/doc/qr`, payload, { responseType: 'blob' });

export const saveAdmissionDetail = (payload) =>
  post(`/${PDS_PREFIX}/patient/admission/details`, payload);

export const saveReferral = (payload, caseId) => {
  const url = `/${PDS_PREFIX}/patient/referral/documents/case/${caseId}`;
  let bodyFormData = new FormData();
  for (const key in payload) {
    if (payload.hasOwnProperty(key)) {
      const item = payload[key];
      if (_.isArray(item)) {
        item.forEach((o, index) => {
          if (o.documentType && o.document && o.documentType !== 'Referral Letter') {
            bodyFormData.append(`${o.documentType}_${index + 1}`, o.document);
          } else if (o.document && o.documentType === 'Referral Letter') {
            bodyFormData.append(`${o.documentType}`, o.document);
          }
        });
      } else {
        bodyFormData.append(key, item);
      }
    }
  }
  return axiosInstance.post(url, bodyFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: authValue,
    },
  });
};

export const getWorkList = (payload) =>
  post(`/${PDS_PREFIX}/epaf/worklist/role/${roleId}`, payload);

export const saveDiagnosisDetails = (data, caseId) => {
  const payload = { caseId, ...data };
  return post(`/${PDS_PREFIX}/patient/diagnosis/details`, payload);
};
export const saveTreatmentCostDetail = (payload) =>
  post(`/${PDS_PREFIX}/patient/treatment/details`, payload);

export const getProcedureCode = (params, clientId) => {
  let procedureCodeWithCountry = `/${PREAUTH_PREFIX}/v1/search/procedurecodes/${params.search}/country/${clientCountry}`;
  let procedureCodeWithClient = `/${PREAUTH_PREFIX}/v1/search/procedurecodes/${params.search}/client/${clientId}`;
  return new Promise((resolve, reject) => {
    get(clientId ? procedureCodeWithClient : procedureCodeWithCountry)
      .then((res) => {
        if (res?.data?.data) {
          const data = res.data.data;
          const result = data.map((ele) => ({
            nodeDetails: {
              icd10Code: ele.code,
              icd10Description: ele.description,
            },
          }));
          resolve({
            ...res,
            data: {
              data: result,
            },
          });
        }
      })
      .catch(() => reject([]));
  });
};

export const getHospitalListByID = (clientId) =>
  get(`/${arrowApiPrefix}/client/id/${clientId}/panel/entity?includeRoleUserId=true`);

export const getEPAFMain = (caseId) => get(`/${PDS_PREFIX}/epaf/case/${caseId}`);

export const getPayors = (specialistId) =>
  get(`/${arrowApiPrefix}/payors/module/paf/specialist/${specialistId}`);

export const getIdentificationDocType = (clientId) =>
  get(`/${arrowApiPrefix}/identificationDocumentTypes/clientId/${clientId}`);

export const getTaxInfo = (payorId) => get(`/${arrowApiPrefix}/taxInfo/clientId/${payorId}`);

export const getClientConfig = (clientId) => get(`/${arrowApiPrefix}/client/config/id/${clientId}`);

export const getPAFSchema = (clientId, type, subType) =>
  get(`/${arrowApiPrefix}/formTemplate/clientId/${clientId}/type/${type}/subType/${subType}`);
