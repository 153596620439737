import { get } from '@services/http_services/http_services';
import { ARROW_PREFIX, privacyLink } from './utils/constant';
import Cookies from 'universal-cookie';
import { axiosInstance } from '../services/http_services/http_services';
import { authValue } from '@features/common/config';
import _ from 'lodash';
import { formatByte } from 'utils/utils';
import OAFLabelUpdateDocument from '../features/oaf/components/OAFLabelUpdateDocument';
const cookies = new Cookies();
const userId = cookies.get('userId');

export const getNationalities = (payload) =>
  new Promise((resolve, reject) => {
    get(`/${ARROW_PREFIX}/data/nationality`)
      .then((res) => {
        const result = {};
        if (res) {
          const { dataSource } = res.data;
          result.countryofresidence = dataSource.countryofresidence || [];
          result.nationality = dataSource.nationality || [];
          resolve(result);
        }
      })
      .catch((err) => console.log(err));
  });

const buildFormConfig = async (formConfig, dataSource) => {
  await Promise.all(
    formConfig.map(async (section) => {
      await Promise.all(
        section.fields.map(async (field) => {
          if (dataSource[field.inputName]) {
            field.props = { ...field.props } || {};
            field.props.dataSource = dataSource[field.inputName] || [];
          } else if (field.inputName === 'nationality' && field.type === 'dropdown') {
            try {
              const res = await getNationalities();
              if (res?.nationality) {
                field.props = { ...field.props } || {};
                field.props.dataSource = res?.nationality || [];
              }
            } catch (e) {
              console.log(e);
            }
          }
        }),
      );
    }),
  );
  return [
    ...formConfig,
    {
      section: 'Attach Medical Reports (if any)',
      fields: [
        {
          inputName: 'medicalReports',
          uiLabel: <OAFLabelUpdateDocument />,
          name: 'attachment',
          type: 'uploadImage',
          validator: (images, max = 10, unit = 'MB') => {
            if (images.length) {
              const totalSize = images.reduce((prev, curr) => {
                return prev + curr.size;
              }, 0);
              const converted = formatByte(totalSize, unit, 2);
              return converted > max ? `File upload cannot greater than ${max}${unit}` : '';
            }
            return '';
          },
          props: {
            editable: true,
          },
        },
        {
          inputName: 'privacyConfirm',
          isRequired: true,
          value: false,
          uiLabel: 'Privacy Confirmation',
          name: 'privacyConfirm',
          type: 'confirmCheckbox',
          props: {
            label: `I agree for Smarter Health Pte Ltd to process my personal data and share my personal information with relevant third parties for the purposes of the services that I have requested. <br /> I understand that my personal data will be handled in accordance with the Smarter Health <a style="text-decoration: none; color: #4183C4"  target="_blank" href="${privacyLink}">Privacy Statement.</a> `,
          },
        },
      ],
    },
  ];
};

export const getOAFDetail = (payload) =>
  new Promise((resolve, reject) => {
    get(`/${ARROW_PREFIX}/opd/patient/${userId}/form/oaf/refNo/${payload.requesterId}`)
      .then(async (res) => {
        const result = {
          data: {
            formConfig: [],
            version: '',
          },
        };
        if (res.data) {
          const { formConfig, dataSource, version } = res.data;
          result.data.formConfig = (await buildFormConfig(formConfig, dataSource)) || [];
          result.data.version = version;
          resolve(result);
        }
      })
      .catch((err) => console.log(err));
  });

export const submitOAF = (payload) => {
  const url = `/${ARROW_PREFIX}/opd/patient/${userId}/form/oaf/refNo/${payload.requesterId}/submit`;
  let bodyFormData = new FormData();
  for (const key in payload.data) {
    if (payload.data.hasOwnProperty(key)) {
      const item = payload.data[key];
      if (_.isArray(item)) {
        item.forEach((o) => {
          bodyFormData.append(key, o);
        });
      } else {
        bodyFormData.append(key, item);
      }
    }
  }
  return axiosInstance.post(url, bodyFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: authValue,
    },
  });
};
