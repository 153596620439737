import React from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '4px',
    border: '1px solid rgba(0, 0, 0, 0.34)',
    '&:hover': {
      border: '1px solid rgba(0, 0, 0, 0.87)',
    },
    '&:focus': {
      outline: 'none',
      border: '2px solid #13ACEF',
    },
  },
}));

export const SmarterTextArea = ({ onChange = null, ...props }) => {
  const classes = useStyles();
  const handleOnChange = (event) => {
    const { value } = event.target;
    onChange && onChange(value);
  };
  return (
    <TextareaAutosize
      className={classes.root}
      onChange={handleOnChange}
      aria-label="minimum height"
      style={{ width: '100%', height: '80px' }}
      {...props}
    />
  );
};
