import { Add, PhotoCamera, Remove } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';
const useStyles = makeStyles((theme) => ({
  checkBox: {
    alignItems: 'unset',
    '& .MuiCheckbox-root': {
      display: 'flex',
      paddingTop: '0px',
      alignItems: 'flex-start',
    },
  },
  inlineIcon: {
    lineHeight: '30px',
    display: 'flex',
    alignItems: 'center',
    margin: '15px 0',
    '& .MuiSvgIcon-root': {
      margin: '0px 2px 0px 2px',
    },
  },
  action: {
    display: 'flex',
  },
  button: {
    padding: '8px',
    marginRight: '15px',
    border: `1px solid rgba(34, 36, 38, 0.15)`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
    maxHeight: '32px',
    '&:hover': {
      borderColor: 'rgba(34, 36, 38, 0.35)',
    },
  },
  text: {
    lineHeight: '30px',
  },
  hiddenInput: {
    display: 'none',
  },
  label: {
    width: '1rem',
    height: '1rem',
    cursor: 'pointer',
  },
  propLabel: {
    width: '100%',
  },
  previewItem: {
    '& span': {
      margin: '7px 0',
      display: 'block',
      color: theme.palette.primaryBlue,
      wordBreak: 'break-word',
    },
    marginBottom: '15px',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'start',
  },
  imageWrapper: {
    width: '150px',
    backgroundColor: '#CCE2FF',
    '& img': {
      width: '100%',
    },
  },
  previewImage: {
    width: '100%',
    maxWidth: '370px',
  },
  intro: {
    marginBottom: '15px',
  },
}));

export const UploadFileContainer = ({
  onChange = null,
  uiLabel = null,
  allowUploadMultiDoc = true,
  uploadIcon = null,
  removeIcon = null,
}) => {
  const classes = useStyles({ isMobile });
  const [files, setFiles] = useState([]);

  const handleUpload = (event) => {
    if (event?.target.files[0]) {
      setFiles([...files, event.target.files[0]]);
    }
  };

  useEffect(() => {
    onChange && onChange(files);
  }, [files]);

  const removeImage = (index) => {
    const temp = [...files];
    temp.splice(index, 1);
    setFiles(temp);
  };

  const previewImage = (image) => {
    return {
      src: URL.createObjectURL(image),
      name: image.name,
      type: image.type,
    };
  };

  return (
    <>
      {uiLabel && uiLabel}
      <div className={classes.previewImage}>
        {files.map((file, index) => (
          <div className={classes.wrapper} key={'upload-' + index}>
            <div className={classes.button} onClick={() => removeImage(index)} id="remove-upload">
              {removeIcon ? (
                removeIcon
              ) : (
                <Remove
                  fontSize={isMobile ? 'small' : undefined}
                  style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '1rem' }}
                />
              )}
            </div>
            <div className={classes.previewItem}>
              {file.type.includes('image') && (
                <div className={classes.imageWrapper} key={'preview-' + index}>
                  <img src={previewImage(file).src} />
                </div>
              )}
              <span>{previewImage(file).name}</span>
            </div>
          </div>
        ))}
      </div>
      <div className={classes.action}>
        {/*Only show on mobile and ipad */}
        {isMobile && (
          <div className={classes.button}>
            <input
              accept="image/*,application/pdf"
              className={classes.hiddenInput}
              id="contained-button-camera"
              multiple
              type="file"
              capture="camera"
              onChange={handleUpload}
            />
            <label htmlFor="contained-button-camera" className={classes.label}>
              <PhotoCamera
                fontSize={!isMobile ? 'small' : undefined}
                style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '1rem' }}
              />
            </label>
          </div>
        )}
        {(allowUploadMultiDoc || (!allowUploadMultiDoc && files.length === 0)) && (
          <div className={classes.button}>
            <input
              accept="image/*,application/pdf"
              className={classes.hiddenInput}
              id="contained-button-file"
              multiple
              type="file"
              onChange={handleUpload}
            />
            <label htmlFor="contained-button-file" className={classes.label}>
              {uploadIcon ? (
                uploadIcon
              ) : (
                <Add
                  fontSize={isMobile ? 'small' : undefined}
                  style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '1rem' }}
                />
              )}
            </label>
          </div>
        )}
      </div>
    </>
  );
};
