import React from "react";
import {
  Avatar,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Typography,
  withStyles
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import BigCalendar from '../../common/big_calendar/big_calendar';
import AutoComplete from '../../common/search/patient_appointment_search';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import InfoIcon from '@material-ui/icons/Info';
import FullCalendarHeader from '../../common/full_calendar/full_calendar_header';
import FullCalendarGrid from '../../common/full_calendar/full_calendar_grid';
import { apptNoShow, apptCancel, tzValue, dateConvert, getTimeZoneString, convertAppointmentDate } from '../../common/config';
import { getMCUCalendar, postProviderClinicSchedule, getCaseDetails, updateAppointmentStatus } from '@api';
import { FullCalendarComponent } from '@components/full-calendar/FullCalendar';
import { ViewAppointmentDialog } from '@components/dialog/Dialog';
import { ReactHelmet } from '@components/react-helmet/Helmet';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const backgroundColor = '#03ACEF'

const useStyles = theme => ({
  calenderContainer:{
    flexGrow: 1,
    padding: 5,
    marginBottom: theme.spacing(4)
  },
  avatarFull: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: 'darkgray',
  },
  avatarAvailable: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: 'white',
    border: '1px solid gray'
  },
  avatarConfirmed: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: 'darkgray',
  },
  list: {
    flexGrow: 1,
  },
  avatarList:{
    minWidth: 25
  },
  mobileViewCalendarHeaderControl: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginBottom: -48,
      width: '35%',
    },
  },
  mobileViewAlertControl: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '95%',
        float: 'right',
        [theme.breakpoints.up('lg')]: {
          width: '100%',
        },
      },
    },
  },
});

class HSPClinicSchedule extends React.Component {
  calendarComponentRef = React.createRef();
  constructor(props) {
    super(props);
    this.state={
      selectedDate: new Date(),
      healthScreeningCalendar:[],
      bookedSchedule:[],
      confirmedApptDates: {
        startDate: null,
        endDate: null,
      },
      packageName: null,
      patientInfo: {
        patientName: null,
        documentLinks: [],
        checkDocumentsLength: 0,
      },
      unitId: null,
      serviceType: null,
      requesterId: null,
      openConfirmedDialog: false,
      isAppointmentDateOver: false,
      anchorEl: null,
      defaultView: 'timeGridWeek',
      notes: null,
      updateStatus: null,
      noteFieldStatus: null,
      errorNotes: false,
      errorScheduleView: false,
      buttonActive: null,
      errorStatusUpdate: {
        errorUpdate: false,
        statusUpdateMessage: null,
      },
      timezone: null,
      searchOptions: [],
      selectDropdownOption: "Week",
      unitIds: [],
      isLoading: false,
      dateRange: { start: new Date(), end: new Date() },
    };
  }

  async componentDidMount() {
    await this.getHealthScreeningSchedule();
    this.displayHealthScreeningSchedule();

    if (this.calendarComponentRef.current) {
      const calendarRef = this.calendarComponentRef.current.getApi();
      this.setState({ dateRange: { start: calendarRef.view.activeStart, end: calendarRef.view.activeEnd - 1} })
    }
  }

  getHealthScreeningSchedule = async () => {
    const respondData = await getMCUCalendar();
    let unitIds = [...this.state.unitIds]
    if(respondData.code && respondData.data){
      if(respondData.data.allLocations.length > 0){
        respondData.data.allLocations.forEach((location, id1) => {
          location.resources.map((resource, id2) => {
            unitIds.push(resource.unitId)
          })
          this.setState({ unitIds: unitIds })       
        })
      }
      if(respondData.data.calendarSummary.length > 0){
        const calendarSummary = respondData.data.calendarSummary.map((data => {
          if (!this.state.timezone){
            this.setState({timezone: getTimeZoneString(data.start)})
          }
          return {
            status : data.status,
            calendarDate: dateConvert(data.start, this.state.timezone)
          }
        }));
        this.setState({ healthScreeningCalendar: calendarSummary })
      }
    }
  };


  displayHealthScreeningSchedule = () => {
    postProviderClinicSchedule(this.state.unitIds).then(res =>
      {
        if(res.code && res.data){
          if(Array.isArray(res.data) && res.data.length > 0){
            this.setState({ bookedSchedule: [], searchOptions: [] })
            let bookedSchedules = [...this.state.bookedSchedule];
            let searchOptions = [...this.state.searchOptions];
            res.data.forEach((d, idx) => {
              const calendarSummary = d.calendar.map((data => {
                return {
                  status : data.dayStatus === 'busy' ? "force-booking" : data.dayStatus,
                  calendarDate: dateConvert(data.start, this.state.timezone),
                }
              }));
              this.setState({ healthScreeningCalendar: calendarSummary })

              d.calendar.forEach((cal, id) =>{
                if(cal.dayStatus !== "closed" && cal.hasOwnProperty("schedules")){
                  //opening hours
                  cal.schedules.map((schedule, i) => {
                    const bookingObject1 = {
                      groupId: 1,
                      start: dateConvert(schedule.start, this.state.timezone),
                      end: dateConvert(schedule.end, this.state.timezone),
                      display: 'inverse-background',
                      type: 'open'
                    };
                    bookedSchedules.push(bookingObject1);

                    //fully booked slots
                    schedule.bookedSlots.map((booked, i) => {
                      const bookingObject2 = {
                        id: `${d.unitId},${booked.bookedCaseData.serviceType}`,
                        title: booked.bookedCaseData.displayName ? booked.bookedCaseData.displayName : booked.bookedCaseData.referenceId,
                        reference: booked.bookedCaseData.referenceId,
                        start: dateConvert(booked.slotStartAt, this.state.timezone),
                        end: dateConvert(booked.slotEndAt, this.state.timezone),
                        type: 'slot',
                      };
                      bookedSchedules.push(bookingObject2);
                      searchOptions.push(bookingObject2)
                    })
                  })
                }
              })
            });
            this.setState({ bookedSchedule: bookedSchedules, searchOptions: searchOptions });
          }
        }
      }
    ).catch(err =>
      {
        console.log('something went wrong', err)
        this.setState({ errorScheduleView: true })
      }
    )
  }

  //dialog
  handleDialogOnClose = (value, propertyName) => {
    this.setState({ [propertyName]: value, notes: null, updateStatus: null, errorNotes: false, noteFieldStatus: null, buttonActive: null });
  };

  
  onEventSelect = async (selectionInfo) => {
    if(selectionInfo.event.extendedProps.type !== "open"){
      this.setState({
        isLoading: false,
        openConfirmedDialog: true, 
        unitId: selectionInfo.event.id.split(",")[0], 
        serviceType: selectionInfo.event.id.split(",")[1], 
        requesterId: selectionInfo.event.extendedProps.reference, 
        confirmedApptDates: { startDate: selectionInfo.event.start, endDate: selectionInfo.event.end },
        isAppointmentDateOver: new Date(selectionInfo.event.start) > new Date(new Date().toLocaleString('en-US', {timeZone: tzValue(this.state.timezone)})) ? false : true
      });

      getCaseDetails(this.state.serviceType, this.state.requesterId).then(res =>
        //successful
        {
          if(res.code && res.data){
            if(Array.isArray(res.data.data.items)){
              res.data.data.items.map((item => {
                this.setState({ 
                  packageName: item.screening.packageName,
                  patientInfo: { patientName: item.patient.name, documentLinks: item.patient.documentLinks, checkDocumentsLength: item.patient.documentLinks.length }
                })
              }))
            }
          }
        }
      ).catch(err =>
        //fail
        console.log('something went wrong', err)
      )
    }
  };

  onCalendarSelect = (value) =>{
    const calendarRef = this.calendarComponentRef.current.getApi();
    calendarRef.gotoDate(new Date(value));
    this.setState({ selectedDate: new Date(value), dateRange: { start: calendarRef.view.activeStart, end: calendarRef.view.activeEnd - 1} });
  };

  // legend popup
  handleLegend = (event, mode) => {
    this.setState({ anchorEl: mode === 'click' ? event.currentTarget : null })
  }


  //attended (5) / no show (4) / cancel (2)
  inputStateUpdate = (status) => {
    this.setState({
      noteFieldStatus: status === apptCancel ? 'mandatory' : 'optional', 
      updateStatus: status, 
      notes: null, 
      errorNotes: false,
      buttonActive: status === apptCancel ? 'cancel' : status === apptNoShow ? 'noShow' : 'attended'
    })    
  }

  //attended (5) / no show (4) / cancel (2)
  appointmentUpdate = () => {

    if (this.state.updateStatus === apptCancel){
      if (!this.state.notes){
        this.setState({ errorNotes: true })
        return;
      }
    }

    this.setState({ isLoading: true })

    const update = {
      unitId: Number(this.state.unitId),
      requesterId: this.state.requesterId,
      status: this.state.updateStatus,
      notes: this.state.notes
    }

    updateAppointmentStatus(update).then(res =>
      //successful
      {
        console.log('successful')
        this.setState({ errorStatusUpdate: { errorUpdate: false, statusUpdateMessage: 'Successfully updated appointment status.' } },
          ()=>{
            setTimeout(() => {
                this.setState({ errorStatusUpdate: { errorUpdate: false, statusUpdateMessage: null } })
            }, 5000);
          })
      }
    ).catch(err =>
      //fail
      {
        console.log('something went wrong', err)
        this.setState({ errorStatusUpdate: { errorUpdate: true, statusUpdateMessage: 'Failed to update appointment status.'} },
          ()=>{
            setTimeout(() => {
                this.setState({ errorStatusUpdate: { errorUpdate: false, statusUpdateMessage: null } })
            }, 5000);
          })
      }
    )

      this.setState({ openConfirmedDialog: false, notes: null, errorNotes: false, noteFieldStatus: null, buttonActive: null, isLoading: false });
      
      this.displayHealthScreeningSchedule();
  }

  //notes field for appointment status update
  handleTextChange = (event) => {
    this.setState({notes: event.target.value, errorNotes: false})
  }

  onCalendarArrowChange = (value, func) => {
    const calendarRef = this.calendarComponentRef.current.getApi();
    if(func === 'prev'){
      calendarRef.prev();
      if (this.state.defaultView === 'timeGridDay' || this.state.defaultView === 'resourceTimeGridDay') {
        this.setState({ selectedDate: calendarRef.getDate() })
      }
    }
    else if (func === 'next'){
      calendarRef.next();
      if (this.state.defaultView === 'timeGridDay' || this.state.defaultView === 'resourceTimeGridDay') {
        this.setState({ selectedDate: calendarRef.getDate() })
      }
    }
    else if (func === 'goback'){
      let current = new Date(value);
      calendarRef.gotoDate(current.setDate(current.getDate() - 1));
      this.setState({ selectedDate: current });
    }
    else if (func === 'goto'){
      let current = new Date(value);
      calendarRef.gotoDate(current.setDate(current.getDate() + 1));
      this.setState({ selectedDate: current });
    }
    else if (func === 'today'){
      calendarRef.today();
      this.setState({ selectedDate: new Date() });
    }
    this.setState({ dateRange: { start: calendarRef.view.activeStart, end: calendarRef.view.activeEnd - 1} });
  };

  handleOptionClick = (option) => {
    this.setState({selectDropdownOption: option, defaultView: option === "Week" ? "timeGridWeek" : "timeGridDay"})
    setTimeout(() => {
      let calendarApi = this.calendarComponentRef.current.getApi(); 
      calendarApi.changeView(this.state.defaultView);
      calendarApi.gotoDate(new Date(this.state.selectedDate));
      this.setState({ dateRange: { start: calendarApi.view.activeStart, end: calendarApi.view.activeEnd - 1} })
    })
  };

  
  render() {
    return (
      <React.Fragment>
        <ReactHelmet
          title="Health Screening"
        />
        <Grid container direction="row" justifyContent="flex-start" spacing={3} className={this.props.classes.calenderContainer}>
          <Grid item xs={12} style={{height: 180}}>
            <Link component="button" onClick={() => window.location="/"}>
              <Typography variant="h6" style={{marginTop: 40}} >
                <ArrowBackIosIcon style={{fontSize: '1rem'}}/> Back
              </Typography>
            </Link>
          </Grid>
           {/*Calender Component**/}
           <Grid item xs={12} md={3}>
             <Grid container direction="column" justifyContent="space-evenly" spacing={4} wrap="wrap">
                 <Grid item xs={12} style={{justifyContent: 'center', display: 'grid'}}>
                   <BigCalendar
                       class={"calSmall"}
                       onChange={this.onCalendarSelect}
                       selectedDate={this.state.selectedDate}
                       calendarSummary={this.state.healthScreeningCalendar}
                   />
                 </Grid>
                 
                   <Grid item xs={12} style={{justifyContent: 'center', display: 'grid'}}>
                    <List className={this.props.classes.list} dense={true}>
                      <ListItem style={{paddingLeft: 30, paddingRight: 0, marginBottom: 10}}>
                        <ListItemAvatar classes={{root: this.props.classes.avatarList}}>
                          <Avatar variant="square" className={this.props.classes.avatarFull}> </Avatar>
                        </ListItemAvatar>
                        <ListItemText style={{width: '150px', marginRight: '150px'}}
                            primary="Full"
                        />
                      </ListItem>
                    </List>
                  </Grid> 
              </Grid>
           </Grid>
           {/* End Calender Component**/}
          <Grid item xs={12} md={9}>
            {
              this.state.errorScheduleView && <Alert style={{marginBottom: 10}} className={this.props.classes.mobileViewAlertControl} severity="error">Failed to display schedule.</Alert>
            }
            {
              this.state.errorStatusUpdate.statusUpdateMessage && <Alert style={{marginBottom: 10}} className={this.props.classes.mobileViewAlertControl} severity={this.state.errorStatusUpdate.errorUpdate ? "error" : "success"}>{this.state.errorStatusUpdate.statusUpdateMessage}</Alert>
            }
            <FullCalendarGrid>
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography variant="h4" color="primary" >
                    <InfoIcon color="disabled" fontSize="large" onClick={(event) => this.handleLegend(event, 'click')}/>
                  </Typography>  

                  <Popover
                    open={Boolean(this.state.anchorEl)}
                    onClose={(event) => this.handleLegend(event, 'close')}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    elevation={0}
                  >
                    <div style={{padding: '15px 15px 15px 15px', borderRadius: '0.3cm', border: '1px solid grey'}}>
                      <Typography>Legend</Typography>

                      <List className={this.props.classes.list} dense={true}>
                        <ListItem style={{paddingLeft: 0, paddingRight: 0}}>
                          <ListItemAvatar classes={{root: this.props.classes.avatarList}}>
                            <Avatar variant="square" className={this.props.classes.avatarAvailable}> </Avatar>
                          </ListItemAvatar>
                          <ListItemText style={{width: '150px'}}
                              primary="Available"
                          />
                        </ListItem>
                        <ListItem style={{paddingLeft: 0, paddingRight: 0}}>
                          <ListItemAvatar classes={{root: this.props.classes.avatarList}}>
                            <Avatar variant="square" className={this.props.classes.avatarConfirmed}> </Avatar>
                          </ListItemAvatar>
                          <ListItemText style={{width: '150px'}}
                              primary="Confirmed"
                          />
                        </ListItem>
                      </List>
                    </div>   
                  </Popover> 
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="h6" color="inherit" align={"right"}>
                    {this.state.defaultView !== "resourceTimeGridDay" && this.state.defaultView !== "timeGridDay" 
                    ? `${new Date(this.state.dateRange.start).toLocaleDateString('en-GB', { month: 'short', day: 'numeric' })} - ${convertAppointmentDate(this.state.dateRange.end)}`.toUpperCase()
                    : convertAppointmentDate(this.state.selectedDate)}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Grid item xs={12} className={this.props.classes.mobileViewCalendarHeaderControl}>
                      <AutoComplete 
                        optionList={this.state.searchOptions}
                        onCalendarSelect={this.onCalendarSelect}
                      />
                  </Grid>
                  <FullCalendarHeader
                    selectedDate={this.state.selectedDate}
                    onCalendarArrowChange={this.onCalendarArrowChange}
                    selectDropdownOption={this.state.selectDropdownOption}
                    handleOptionClick={this.handleOptionClick}
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} style={{paddingTop: 25, maxHeight: 700}}>
                  <FullCalendarComponent 
                    defaultView={this.state.defaultView}
                    calendarComponentRef={this.calendarComponentRef}
                    bookedSchedule={this.state.bookedSchedule}
                    onEventSelect={this.onEventSelect}
                    selectedDate={this.state.selectedDate}
                  />
                </Grid>
              </Grid>
            </FullCalendarGrid>    
          </Grid>
          <Grid item xs={12}>
            {/* confirmed slot dialog / attended & no show, cancel & reschedule*/}
            <ViewAppointmentDialog
              openDialog={this.state.openConfirmedDialog}
              handleOnClose={this.handleDialogOnClose}
              patientInfo={this.state.patientInfo}
              confirmedApptDates={this.state.confirmedApptDates}
              name={this.state.packageName}
              buttonOptions={[ this.state.isAppointmentDateOver, this.state.buttonActive ]}
              inputNotes={[ this.state.noteFieldStatus, this.state.notes, this.state.errorNotes ]}
              rescheduleURL={[ this.state.unitId, this.state.requesterId, this.state.serviceType ]}
              updates={[ this.inputStateUpdate, this.appointmentUpdate ]}
              handleTextChange={this.handleTextChange}
              loading={this.state.isLoading}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
export default withStyles(useStyles)(HSPClinicSchedule);
