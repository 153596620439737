import React, { useState } from 'react';
import { EnterEmail } from './EnterEmail.jsx';
import { EnterVerification } from './EnterVerification.jsx';
import { EnterNewPassword } from './EnterNewPassword.jsx';
import { PasswordSuccessfullyUpdated } from './SuccessfullyUpdated';

export const ResetPassword = () => {
  const [step, setStep] = useState(0);
  return (
    <>
      {step === 0 && <EnterEmail onSubmit={() => setStep(1)} />}
      {step === 1 && <EnterVerification onSubmit={() => setStep(2)} />}
      {step === 2 && <EnterNewPassword onSubmit={() => setStep(3)} />}
      {step === 3 && <PasswordSuccessfullyUpdated />}
    </>
  );
};
