import { FormControl, InputLabel, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
export const useFormItemStyles = makeStyles((theme) => ({
  inputLabel: (props) => ({
    display: props.inputType === 'confirmCheckbox' ? 'none' : 'inline-block',
    alignItems: 'top',
    whiteSpace: 'pre-line',
    marginBottom: props.editable ? theme.spacing(1) : 'none',
    '& .MuiFormLabel-asterisk ': {
      color: 'red',
    },
    position: 'unset',
    transform: 'unset',
  }),
  formItem: (props) => ({
    display: 'flex',
    flexDirection: props.editable ? 'column' : 'row',
    width: '100%',
    padding: theme.spacing(1.5, 0),
  }),
  content: (props) => ({
    wordBreak: !props.editable ? 'break-all' : '',
  }),
}));
export const FormItem = ({
  id,
  name,
  value,
  label,
  inputType,
  isRequired,
  editable = true,
  labelFor = '',
  ...props
}) => {
  const classes = useFormItemStyles({ editable, inputType });
  return (
    <Grid container className={classes.formItem} gutter={4}>
      {label && (
        <Grid item xs={editable ? 12 : 6} lg={editable ? 12 : 3}>
          <InputLabel className={classes.inputLabel} required={isRequired} htmlFor={labelFor}>
            {label}
          </InputLabel>
        </Grid>
      )}
      <Grid item xs={editable ? 12 : 6} lg={editable ? 12 : 9} className={classes.content}>
        {editable ? (
          <FormControl fullWidth={true}>{props.children}</FormControl>
        ) : (
          <span>{value || 'N/A'}</span>
        )}
      </Grid>
    </Grid>
  );
};
