import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListIcon from '@material-ui/icons/List';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import { HORIZONTAL, VERTICAL } from '@features/doctor-search/utilities/constants';
import { SelectableButtonWithTooltip } from '.';

export const OrientationButtonBar = ({ state, setState, hidden }) => {
  const classes = useStyles({ hidden });
  return (
    <div className={classes.buttonBar}>
      <SelectableButtonWithTooltip
        className={classes.button}
        label="Horizontal View"
        clicked={state === HORIZONTAL}
        handleClick={() => setState(HORIZONTAL)}
        component={<ViewColumnIcon color="action" />}
        disableFocusRipple
        disableRipple
      />
      <SelectableButtonWithTooltip
        className={classes.button}
        label="Vertical View"
        clicked={state === VERTICAL}
        handleClick={() => setState(VERTICAL)}
        component={<ListIcon color="action" />}
        disableFocusRipple
        disableRipple
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  buttonBar: {
    display: (props) => (props.hidden ? 'none' : 'flex'),
    width: 'fit-content',
    '& svg': {
      margin: theme.spacing(0.5),
    },
  },
  button: {
    maxWidth: '40px',
    maxHeight: '30px',
    minWidth: '40px',
    minHeight: '30px',
  },
}));
