import React from 'react';
import moment from 'moment';
import EventCard from "../../features/common/event_card/event_card";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimeGrid from '@fullcalendar/resource-timegrid';
import scrollGrid from '@fullcalendar/scrollgrid';
import { fullCalendarMinTime, fullCalendarMaxTime } from 'features/common/config';

export const FullCalendarComponent = ({
  defaultView,
  calendarComponentRef,
  bookedSchedule,
  onEventSelect,
  resourceTimeDays = 3,
  selectedDate,
  ...props
}) => {
  return (
    <FullCalendar
      schedulerLicenseKey={"0150100070-fcs-1591606365"}
      initialView={defaultView}
      headerToolbar={{
        left: '',
        center: '',
        right: '',
      }}
      showNonCurrentDates={false}
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, resourceTimeGrid, scrollGrid]}
      ref={calendarComponentRef}
      firstDay={1}
      weekends={true}
      allDaySlot={true}
      height={650}
      slotMinWidth={70}
      slotMinTime={fullCalendarMinTime}
      slotMaxTime={fullCalendarMaxTime}
      slotLabelContent={arg => {
        return moment(arg.date).format('HHmm');
      }}
      views={{
        resourceTimelineDay: {
          titleFormat: {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'long',
          },
        },
        resourceTimeGridDays: {
          type: 'resourceTimeGrid',
          duration: { days: resourceTimeDays },
        },
      }}
      dayHeaderContent={arg => {
        const displayDay = moment(arg.date).format('dddd');
        const displayDate = moment(arg.date).format('D');
        const isToday = moment(arg.date).isSame(new Date(), 'day');
        return { html: `<div id="currentDayHeader">${displayDay}</div><div id=${isToday ? "currentDateHeader" : "dateHeader"}>${displayDate}</div>`}
      }}
      dayHeaderClassNames={arg => {
        const isSame = moment(arg.date).isSame(selectedDate, 'day');
        if(isSame && defaultView !== "timeGridDay"){
          return ['highlightCurrentSelectedDate']
        }
      }}
      selectable= {true}
      events={bookedSchedule}
      eventContent={arg => {
        return <EventCard Event={arg.event} />;
      }}
      eventClick={onEventSelect ? event => onEventSelect(event) : false}
      selectConstraint={1}
      slotEventOverlap={false}
      selectLongPressDelay={1}
      {...props}
  />
  );
};
