import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: (props) => ({
    pointerEvents: props.disabled && 'none',
  }),
  disabled: {
    backgroundColor: '#ECECEC',
  },
}));

export const SmarterTextField = ({
  inputRef,
  InputProps,
  onEnter = null,
  value,
  setValue = null,
  variant = 'outlined',
  suffix = null,
  allowClear = true,
  onChange = null,
  disabled = false,
  ...props
}) => {
  const [textFieldValue, setTextFieldValue] = useState(value);
  const classes = useStyles({ disabled });
  const handleKeyPressEnter = (event) => {
    if (event.key === 'Enter') {
      onEnter && onEnter(event);
    }
  };

  const clearValue = (event) => {
    event.stopPropagation();
    setTextFieldValue('');
    onChange('');
  };

  const handleOnChange = (event) => {
    setTextFieldValue(event.target.value || '');
    onChange(event.target.value || '');
  };

  useEffect(() => {
    setTextFieldValue(value || '');
  }, [value]);

  const computeInputProps = () => {
    if (allowClear && !disabled && !_.isEmpty(textFieldValue)) {
      return {
        endAdornment: (
          <ClearIcon position="end" style={{ cursor: 'pointer' }} onClick={clearValue} />
        ),
      };
    }
    return InputProps;
  };

  return (
    <TextField
      variant={variant}
      fullWidth
      inputRef={inputRef}
      value={textFieldValue}
      onChange={handleOnChange}
      disabled={disabled}
      onKeyPress={handleKeyPressEnter}
      InputProps={{ ...computeInputProps(), classes: { disabled: classes.disabled } }}
      className={classes.root}
      {...props}
    />
  );
};
