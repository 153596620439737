import React, { useEffect, useState } from 'react';
import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Link,
  TablePagination,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getWorkList } from '../../../../../apis/paf';
import { convertTimeStampToDate } from 'features/paf/utils/utils';
import { roleTypeKey, PAYOR_PAF_MANAGER } from 'features/common/config';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
}));

const getColumnHeaders = () => {
  let columns = [
    { title: 'Case Id', dataIndex: 'caseId' },
    { title: 'Admission Date', dataIndex: 'admissionDate' },
  ];
  const role = cookies.get(roleTypeKey)?.toLowerCase();
  const pafManagerColumns = [
    { title: 'Admitting Hospital', dataIndex: 'admittingHospital' },
    { title: `Patient's Full Name`, dataIndex: 'fullName' },
  ];
  const nonPafManagerColumns = [
    { title: 'Patient Full Name', dataIndex: 'fullName' },
    { title: 'Identification Document No', dataIndex: 'identificationDocNum' },
  ];
  role === PAYOR_PAF_MANAGER.toLowerCase()
    ? columns.push(...pafManagerColumns)
    : columns.push(...nonPafManagerColumns);

  columns.push({ title: 'Status', dataIndex: 'status' });
  return columns;
};

export const PAFTable = ({
  isReloadTable,
  setIsReloadTable,
  params,
  isNewFilter,
  setIsNewFilter,
}) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const columns = getColumnHeaders();

  const fetchList = () => {
    if (isReloadTable) {
      const payload = {
        limit: pageSize,
        pageNo: isNewFilter ? 0 : pageIndex,
        ...params,
      };
      getWorkList(payload)
        .then((res) => {
          if (res.data) {
            setData(res.data.data.pageData);
            setTotal(res.data.data.totalItems);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setIsReloadTable(false);
        });
    }
  };

  useEffect(() => fetchList(), [isReloadTable]);

  useEffect(() => {
    setIsNewFilter(false);
    setPageIndex(0);
  }, [isNewFilter]);

  const handleChangePage = (event, newPage) => {
    setPageIndex(newPage);
    setIsReloadTable(true);
  };
  const handleChangePageSize = (event) => {
    setPageSize(event.target.value);
    setPageIndex(0);
    setIsReloadTable(true);
  };

  return (
    <div className={classes.table}>
      <TableContainer
        component={Paper}
        elevation={0}
        id="tableScroll"
        style={{ marginTop: 30, borderRadius: '0.3cm' }}
      >
        <Table>
          <TableHead style={{ backgroundColor: '#03ACEF', color: 'white' }}>
            <TableRow>
              {columns.map((column, id) => (
                <TableCell key={`paftable-${id}`} style={{ color: 'white', fontSize: '15px' }}>
                  {column.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length === 0 ? (
              <TableRow>
                <TableCell style={{ textAlign: 'center' }} colSpan={columns.length}>
                  No results found
                </TableCell>
              </TableRow>
            ) : (
              data.map((item, idx) => (
                <TableRow key={`${item.fullName}-${idx}`}>
                  <TableCell component="th" scope="row">
                    {item.caseId}
                  </TableCell>
                  <TableCell align="left">{convertTimeStampToDate(item.admissionDate)}</TableCell>
                  <TableCell align="center">{item.fullName}</TableCell>
                  <TableCell align="center">{item.identificationDocNum}</TableCell>
                  <TableCell align="left">
                    <Link href={item.status.url}>{item.status.value}</Link>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid item xs={12} style={{ display: 'grid', justifyContent: 'right' }}>
        <TablePagination
          component="div"
          count={total}
          page={pageIndex}
          onPageChange={handleChangePage}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleChangePageSize}
          labelRowsPerPage="Rows:"
        />
      </Grid>
    </div>
  );
};
