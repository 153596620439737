/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  Button,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';

import { MappedPracticeLocations, HiddenContent } from './utilities/components';
import { CardInformationList } from '@components/card';
import {
  prioritizePanels,
  splitBackground,
  collapsibleList,
  organizedLang,
  truncatedName,
} from './utilities';
import {
  VERTICAL,
  LG,
  MD,
  SM,
  XS,
  HORIZONTAL,
  XL,
  SUB,
  LOC,
  LANG,
  INNER_SEC,
} from '../../utilities/constants';
import { useResolution } from '@hooks';
import { LoadingButton } from 'components/button';

export const LandscapeDoctorCard = ({ doctor, onSelect, submitting }) => {
  const size = useResolution();
  const [isExpanded, setIsExpanded] = useState(false);
  const [secondaryData, setSecondaryData] = useState([]);
  const classes = useStyles({ isExpanded });
  const {
    doctorId,
    fullname,
    practiceLocations,
    profileImageUrlSession,
    subSpecialties,
    qualifications,
    languages,
    background,
    workExperiences,
    consultFee,
  } = doctor;

  const needTertiary = useMediaQuery('(min-width:780px)');
  const isMedium = useMediaQuery('(min-width:960px)');
  const needInnerSecondary = useMediaQuery('(min-width:1080px)') ? INNER_SEC : '';

  const listsConfig = {
    [SUB]: {
      list: () =>
        collapsibleList({
          list: subSpecialties,
          func: listsConfig[SUB].func,
          breakpoint: listsConfig[SUB].size[size],
          expanded: isExpanded,
        }),
      func: (ss) => ss !== 'Not Otherwise Specified (NOS)',
      size: {
        [XS]: 2,
        [SM]: 3,
        [MD]: 3,
        [INNER_SEC]: 3,
        [LG]: 4,
        [XL]: 4,
      },
    },
    [LOC]: {
      list: () => {
        return collapsibleList({
          list: MappedPracticeLocations(prioritizePanels(practiceLocations), size, VERTICAL),
          breakpoint: listsConfig[LOC].size[!!needInnerSecondary ? needInnerSecondary : size],
          expanded: isExpanded,
        });
      },
      size: {
        [XS]: 2,
        [SM]: 3,
        [MD]: 3,
        [INNER_SEC]: 5,
        [LG]: 6,
        [XL]: 8,
      },
    },
    [LANG]: {
      list: () =>
        collapsibleList({
          list: organizedLang(languages),
          breakpoint: listsConfig[LANG].size[size],
          expanded: isExpanded,
        }),
      size: {
        [XS]: 12,
        [SM]: 12,
        [MD]: 12,
        [INNER_SEC]: 12,
        [LG]: 12,
        [XL]: 12,
      },
    },
  };

  const CardButton = ({ cardButtonStyle }) => (
    <LoadingButton
      className={cardButtonStyle}
      onClick={(event) => {
        event.stopPropagation();
        onSelect(doctorId);
      }}
      text="Select"
      loading={submitting.loading && submitting.id === doctorId}
    />
  );

  useEffect(() => {
    function createSecondaryData() {
      const content = [
        { title: 'Qualifications', data: qualifications },
        { title: 'Work Experiences', data: !!workExperiences.length ? workExperiences : null },
        {
          title: 'Background',
          data: !!background ? splitBackground(background) : null,
        },
      ];
      if (!needInnerSecondary)
        content.unshift({
          title: 'Languages',
          data: organizedLang(languages),
          view: HORIZONTAL,
        });
      setSecondaryData(content);
    }
    createSecondaryData();
  }, [size, needInnerSecondary, doctor]);

  useEffect(() => {
    if (isExpanded) setIsExpanded(false);
  }, [doctor]);

  return (
    <Accordion className={classes.root} expanded={isExpanded}>
      <AccordionSummary
        className={classes.summary}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <div className={classes.primaryContent}>
          <img className={classes.image} src={`${profileImageUrlSession}`} alt="Doctor" />
          {!isMedium && <CardButton cardButtonStyle={classes.button} />}
          <Button
            variant="text"
            className={classes.infoExpandButton}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            More {needTertiary && 'Info'}
            {size === LG || (size === XL && 'Information')}
            {isExpanded ? <ExpandLess /> : <ExpandMoreIcon />}
          </Button>
        </div>
        <div className={classes.secondaryContent}>
          <div className={classes.title}>{`Dr. ${truncatedName(fullname)}`}</div>
          <div className={classes.secondaryContentInfo}>
            <div className={classes.leftSide}>
              <CardInformationList
                className={classes.infoBox}
                list={listsConfig[SUB].list()[0]}
                listItemClassName={classes.infoText}
                secondaryList={listsConfig[SUB].list()[1]}
                leftBorder
              />
              {needInnerSecondary && (
                <CardInformationList
                  className={classes.lang}
                  textClassName={clsx(classes.listTitle, classes.langTitle)}
                  text={!!listsConfig[LANG].list()[0].length && 'Languages: '}
                  listItemClassName={clsx(classes.infoText, classes.langText)}
                  list={listsConfig[LANG].list()[0]}
                />
              )}
            </div>
            {needInnerSecondary && (
              <Divider flexItem orientation="vertical" className={classes.divider} />
            )}
            <CardInformationList
              className={classes.location}
              textClassName={classes.listTitle}
              text="Practice Location(s):"
              listItemClassName={classes.infoText}
              list={listsConfig[LOC].list()[0]}
              secondaryList={listsConfig[LOC].list()[1]}
            />
          </div>
        </div>
        {needTertiary && (
          <div className={classes.tertiaryContent}>
            <div>
              {consultFee ? (
                <div className={classes.fee}>
                  <Typography className={classes.feeTextTitle}>consult fee (est):</Typography>
                  <Typography className={classes.feeText}>
                    {consultFee?.replace(/\s+/g, '')}
                  </Typography>
                </div>
              ) : (
                <div></div>
              )}
            </div>
            {isMedium && <CardButton cardButtonStyle={classes.tertButton} />}
          </div>
        )}
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        <HiddenContent content={secondaryData} size={size} />
      </AccordionDetails>
    </Accordion>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    borderRadius: '0.3cm',
    [theme.breakpoints.up(XS)]: {
      marginBottom: theme.spacing(3),
    },
    '@media (max-width:380px)': {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.up(SM)]: {
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.up(MD)]: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.up(LG)]: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(5),
    },
  },
  summary: {
    [theme.breakpoints.up(XS)]: {
      height: (props) => !props.isExpanded && '191px',
      minHeight: '191px',
    },
    '@media (max-width:380px)': {
      height: (props) => !props.isExpanded && '191px',
      minHeight: '191px',
    },
    [theme.breakpoints.up(SM)]: {
      height: (props) => !props.isExpanded && '221px',
      minHeight: '221px',
    },
    [theme.breakpoints.up(MD)]: {
      height: (props) => !props.isExpanded && '236px',
      minHeight: '236px',
    },
    [theme.breakpoints.up(LG)]: {
      height: (props) => !props.isExpanded && '269px',
      minHeight: '269px',
    },
  },
  primaryContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxWidth: '30%',
    justifyContent: 'space-between',
  },
  image: {
    [theme.breakpoints.up(XS)]: {
      height: '90px',
      maxWidth: '68px',
      minWidth: '68px',
    },
    [theme.breakpoints.up(SM)]: {
      height: '120px',
      maxWidth: '90px',
      minWidth: '90px',
    },
    [theme.breakpoints.up(MD)]: {
      height: '160px',
      maxWidth: '120px',
      minWidth: '120px',
    },
    [theme.breakpoints.up(LG)]: {
      height: '190px',
      maxWidth: '143px',
      minWidth: '143px',
    },
    aspectRatio: 'from-intrinsic',
  },
  infoExpandButton: {
    padding: 0,
    minHeight: theme.spacing(4),
    minWidth: 0,
    color: '#03ACEF',
    marginTop: theme.spacing(1),
    [theme.breakpoints.up(XS)]: {
      fontSize: '12px',
    },
    '@media (max-width:380px)': {
      fontSize: '12px',
    },
    [theme.breakpoints.up(SM)]: {
      fontSize: '12px',
    },
    [theme.breakpoints.up(MD)]: {
      fontSize: '16px',
    },
    [theme.breakpoints.up(LG)]: {
      fontSize: '18px',
    },
  },
  leftSide: {
    '@media (min-width:780px)': {
      width: '240px',
    },
    [theme.breakpoints.up(LG)]: {
      width: '360px',
    },
  },
  title: {
    textDecoration: 'underline',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.up(XS)]: {
      fontSize: '16px',
    },
    '@media (max-width:380px)': {
      fontSize: '16px',
    },
    [theme.breakpoints.up(SM)]: {
      fontSize: '18px',
    },
    [theme.breakpoints.up(MD)]: {
      fontSize: '18px',
    },
    [theme.breakpoints.up(LG)]: {
      fontSize: '22px',
    },
  },
  infoBox: {
    marginTop: '12px',
  },
  listTitle: {
    fontWeight: 600,
    [theme.breakpoints.up(XS)]: {
      fontSize: '12px',
    },
    '@media (max-width:380px)': {
      fontSize: '12px',
    },
    [theme.breakpoints.up(SM)]: {
      fontSize: '16px',
    },
    [theme.breakpoints.up(MD)]: {
      fontSize: '16px',
    },
    [theme.breakpoints.up(LG)]: {
      fontSize: '18px',
    },
  },

  infoText: {
    [theme.breakpoints.up(XS)]: {
      fontSize: '12px',
    },
    '@media (max-width:380px)': {
      fontSize: '12px',
    },
    [theme.breakpoints.up(SM)]: {
      fontSize: '14px',
    },
    [theme.breakpoints.up(MD)]: {
      fontSize: '14px',
    },
    [theme.breakpoints.up(LG)]: {
      fontSize: '18px',
    },
  },
  langText: { marginRight: '4px' },
  secondaryContent: {
    marginLeft: theme.spacing(1),
    '@media (min-width:780px)': {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.up(LG)]: {
      marginLeft: theme.spacing(3),
    },
  },
  divider: {
    '@media (max-width:1080px)': {
      marginBottom: theme.spacing(1),
    },
    '@media (min-width:1080px)': {
      marginRight: theme.spacing(1),
      marginLeft: '4px',
    },
  },
  secondaryContentInfo: {
    '@media (min-width:1080px)': {
      display: 'flex',
      justifyContent: 'space-between',
      height: '80%',
    },
  },
  location: {
    marginTop: '-4px',
  },
  lang: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    marginTop: theme.spacing(2),
  },
  langTitle: { marginRight: theme.spacing(1) },

  button: {
    padding: 0,
    minHeight: 0,
    minWidth: 0,
    color: 'white',
    backgroundColor: '#03ACEF',
    '&:hover': {
      backgroundColor: '#03ACEF',
    },
    marginTop: theme.spacing(1),
    fontSize: '12px',
  },
  tertButton: {
    padding: 0,
    minHeight: theme.spacing(4),
    minWidth: 0,
    width: 'autofit',
    color: 'white',
    backgroundColor: '#03ACEF',
    '&:hover': {
      backgroundColor: '#03ACEF',
    },
    [theme.breakpoints.up(SM)]: {
      fontSize: '16px',
    },
  },
  tertiaryContent: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  fee: {},
  feeTextTitle: {
    [theme.breakpoints.up(SM)]: {
      fontSize: '16px',
    },
    [theme.breakpoints.up(MD)]: {
      fontSize: '16px',
    },
    [theme.breakpoints.up(LG)]: {
      fontSize: '19px',
    },
  },
  feeText: {
    [theme.breakpoints.up(SM)]: {
      fontSize: '18px',
    },
    [theme.breakpoints.up(MD)]: {
      fontSize: '21px',
    },
    [theme.breakpoints.up(LG)]: {
      fontSize: '24px',
    },
    color: '#0099CC',
    fontWeight: 'bold',
    textAlign: 'right',
  },
}));
