import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TreeView } from '@material-ui/lab';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import StyledTreeItem from './StyleTreeItem';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'auto',
    flexGrow: 1,
    width: '100%',
  },
  wrapperOption: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  checkbox: {
    padding: '6px',
    paddingTop: 0,
  },
  formControl: {
    display: 'flex',
    alignItems: 'start',
    marginRight: 0,
    marginBottom: theme.spacing(1),
  },
}));
export const SelectionTreeView = ({ data, selected, onSelect }) => {
  const classes = useStyles();
  const [expandedIds, setExpandedIds] = useState([]);
  const renderTree = (nodes = data, level = 0) => {
    return (
      <>
        {nodes.map((node) => (
          <StyledTreeItem
            id={node.nodeDetails.icd10Code}
            key={node.nodeDetails.icd10Code}
            nodeId={node.nodeDetails.icd10Code}
            label={computeLabel(node.nodeDetails, level)}
            visibleIcon={false}
            onClick={(e) => handleClick(e, level, node.nodeDetails)}
          >
            {Array.isArray(node.children) && node.children.length
              ? renderTree(node.children, level + 1)
              : null}
          </StyledTreeItem>
        ))}
      </>
    );
  };

  const handleClick = (e, level, node) => {
    const { icd10Code, icd10Description } = node;
    const value = selected.get(level) || '';
    if ((!_.isEmpty(value) && icd10Code !== value) || _.isEmpty(value)) {
      onSelect(icd10Code, icd10Description, level);
    } else {
      onSelect('', '', level);
    }
  };

  useEffect(() => {
    updateExpandedIds();
  }, [selected]);

  const computeLabel = (nodeDetail, level) => {
    const { icd10Code, icd10Description } = nodeDetail;
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={!_.isEmpty(selected.get(level)) && selected.get(level) === icd10Code}
            color="primary"
            classes={{ root: classes.checkbox }}
          />
        }
        classes={{ root: classes.formControl, label: classes.formLabel }}
        label={<>{icd10Description ? `${icd10Description}` : `${icd10Code}`}</>}
        key={`label-${icd10Code}`}
      />
    );
  };

  const updateExpandedIds = () => {
    const getExpandedIds = getNodeIds(data, []);
    setExpandedIds(getExpandedIds);
  };

  const findInMap = (val) => {
    for (let [, v] of selected) {
      if (v === val) {
        return true;
      }
    }
    return false;
  };

  const getNodeIds = (nodes, nodeIds = []) => {
    nodes.map((node) => {
      if (
        findInMap(node.nodeDetails.icd10Code) &&
        Array.isArray(node.children) &&
        node.children.length
      ) {
        nodeIds.push(node.nodeDetails.icd10Code);
        getNodeIds(node.children, nodeIds);
      }
    });
    return nodeIds;
  };

  useEffect(() => {
    if (data?.length !== 0) {
      renderTree();
      updateExpandedIds();
    }
  }, [data]);

  return (
    <TreeView className={classes.root} expanded={expandedIds}>
      {renderTree(data)}
    </TreeView>
  );
};
