import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SmarterTextField } from '@components/input';
import { muiDateFormat } from '../common/config';
import format from 'date-fns/format';
import { useStylesBase } from './useStylesBase.js';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import locale from 'date-fns/locale/en-US';
import { CalendarToday } from '@material-ui/icons';
class LocalizedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, 'MMM yyyy').toUpperCase();
  }
  getWeekdays() {
    return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  }
}

export const MyDetails = (props) => {
  const { userData, setUserData } = props;
  const useStyles = makeStyles((theme) => ({
    pageTitle: {
      fontWeight: '600',
      fontSize: theme.spacing(3.75),
      lineHeight: `${theme.spacing(5.25)}px`,
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(-6.25),
      },
    },
    genderButton: {
      '& div.radio-container ': {
        height: theme.spacing(6.25),
        position: 'relative',
      },
      '& label': {
        border: '1px solid #ccc',
        cursor: 'pointer',
        zIndex: '90',
        display: 'block',
        textAlign: 'center',
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        margin: '0',
        height: theme.spacing(6.25),
        lineHeight: `${theme.spacing(6.25)}px`,
        borderRadius: theme.spacing(0.5),
        '&:hover': {
          borderColor: theme.palette.primaryBlue,
          color: theme.palette.primaryBlue,
        },
      },
      '& input': {
        display: 'block',
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
      },
      '& input[type="radio"]': {
        opacity: '0.011',
        zIndex: '100',
        '&:checked + label': {
          borderColor: theme.palette.primaryBlue,
          color: theme.palette.primaryBlue,
        },
      },
    },
    datepicker: {
      width: '100%',
      marginTop: '0px !important',
      '& .MuiInputLabel-root': {
        color: '#03ACEF',
      },
      '& .MuiOutlinedInput-root': {
        marginTop: 0,
        // - The Input-root, inside the TextField-root
        '&:hover fieldset': {
          borderColor: '#03ACEF', // - Set the Input border when parent has :hover
        },
        '&.Mui-focused fieldset': {
          // - Set the Input border when parent is focused
          borderColor: '#03ACEF',
        },
      },
    },
    icCalendar: {
      position: 'absolute',
      right: theme.spacing(1.25),
      bottom: theme.spacing(2.75),
    },
  }));

  const baseClasses = useStylesBase();
  const classes = useStyles();

  const changeFirstname = (value) => {
    setUserData({
      ...userData,
      firstname: value,
    });
  };
  const changeLastname = (value) => {
    setUserData({
      ...userData,
      lastname: value,
    });
  };
  const changeGender = (gender) => () => {
    setUserData({
      ...userData,
      gender: gender,
    });
  };
  const changeBirthdate = (value) => {
    setUserData({
      ...userData,
      birthday: format(value, 'yyyy-MM-dd'),
    });
  };
  return (
    <>
      <Typography component="h2" variant="subtitle1" className={classes.pageTitle}>
        My details
      </Typography>
      <Typography component="h3" variant="subtitle2" className={baseClasses.formTitle}>
        Personal Information
      </Typography>
      <Grid container>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <label htmlFor="first-name">First Name*</label>
            <SmarterTextField
              error={userData.firstname.length > 0 ? false : true}
              helperText={userData.firstname.length > 0 ? '' : 'First name should be filled'}
              value={userData.firstname}
              onChange={changeFirstname}
              inputProps={{ 'data-testid': 'first-name' }}
              id="first-name"
            />
          </Grid>
          <Grid item md={6}>
            <label htmlFor="last-name">Last Name*</label>
            <SmarterTextField
              error={userData.lastname.length > 0 ? false : true}
              helperText={userData.lastname.length > 0 ? '' : 'Last name should be filled'}
              value={userData.lastname}
              onChange={changeLastname}
              inputProps={{ 'data-testid': 'last-name' }}
              id="last-name"
            />
          </Grid>
        </Grid>
        <label>Gender*</label>
        <Grid container spacing={2} className={classes.genderButton} direction="row">
          <Grid item xs={6}>
            <div className="radio-container">
              <input
                type="radio"
                id="male"
                name="gender"
                value="Male"
                defaultChecked={userData.gender === 'Male'}
                onChange={changeGender('Male')}
              />
              <label htmlFor="male" data-testid="male-label">
                Male
              </label>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="radio-container">
              <input
                type="radio"
                id="female"
                name="gender"
                value="Female"
                checked={userData.gender === 'Female'}
                onChange={changeGender('Female')}
              />
              <label htmlFor="female" data-testid="female-label">
                Female
              </label>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <label htmlFor="dob">Date of Birth*</label>
          <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
            <DatePicker
              inputVariant="outlined"
              disableToolbar
              variant="inline"
              format={muiDateFormat}
              margin="normal"
              placeholder="End Date"
              value={userData.birthday}
              autoOk={true}
              onChange={changeBirthdate}
              id="dob"
              className={classes.datepicker}
            />
          </MuiPickersUtilsProvider>
          <CalendarToday className={classes.icCalendar} alt="icon_calendar" color="primary" />
        </Grid>
      </Grid>
    </>
  );
};
MyDetails.propTypes = {
  userData: PropTypes.shape({
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
  }),
  setUserData: PropTypes.func,
};
