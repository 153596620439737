import React from "react";
import {
  Avatar,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Popover,
  Typography,
  withStyles
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import BigCalendar from '../../common/big_calendar/big_calendar';
import AutoComplete from '../../common/search/patient_appointment_search';
import DoctorSearch from '../../common/search/doctor_location_search';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import InfoIcon from '@material-ui/icons/Info';
import CancelIcon from '@material-ui/icons/Cancel';
import FullCalendarHeader from '../../common/full_calendar/full_calendar_header';
import FullCalendarGrid from '../../common/full_calendar/full_calendar_grid';
import { apptNoShow, apptCancel, tzValue, dateConvert, getTimeZoneString, convertAppointmentDate } from '../../common/config';
import TreeSearch from '../../common/tree_search/tree_search';
import { FullCalendarComponent } from '@components/full-calendar/FullCalendar';
import { ViewAppointmentDialog } from '@components/dialog/Dialog';
import { getOPDCalendarDoctorFirst, getOPDCalendarLocationFirst, getCaseDetails, updateAppointmentStatus, postProviderClinicSchedule } from '@api';
import { ReactHelmet } from '@components/react-helmet/Helmet';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const cancelColor = '#666666';
const borderRadius = '0.3cm';
const sliceValue = 0
const resourceTimeDays = 3;
const toggleSettings = 'doctorFirst';

const useStyles = theme => ({
  calenderContainer:{
    flexGrow: 1,
    padding: 5,
    marginBottom: theme.spacing(4)
  },
  avatarFull: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: 'darkgray',
  },
  avatarAvailable: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: 'white',
    border: '1px solid gray'
  },
  avatarConfirmed: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: 'darkgray',
  },
  avatarPatientChoice: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: '#F37D2A',
    opacity: '.3'
  },
  list: {
    flexGrow: 1,
  },
  avatarList:{
    minWidth: 25
  },
  mobileViewCalendarHeaderControl: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginBottom: -48,
      width: '35%',
    },
  },
  mobileViewAlertControl: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '95%',
        float: 'right',
        [theme.breakpoints.up('lg')]: {
          width: '100%',
        },
      },
    },
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

class ClinicSchedule extends React.Component {
  calendarComponentRef = React.createRef();
  constructor(props) {
    super(props);
    this.state={
      selectedDate: new Date(),
      doctorCalendar:[],
      bookedSchedule:[],
      openConfirmedDialog: false,
      confirmedApptDates: {
        startDate: null,
        endDate: null,
      },
      patientInfo: {
        patientName: null,
        documentLinks: [],
        checkDocumentsLength: 0,
      },
      specialistName: null,
      anchorEl: null,
      isSingleDoctorView: true,
      singleDoctorId: [],
      singleDoctorInfo: {
        name: null, 
        location: null
      },
      checkedDoctorId: [],
      checkedDoctorName: [],
      defaultView: 'timeGridWeek',
      resources: [],
      checkBoxList: [],
      singleDoctorCalendar: [],
      unitId: null,
      serviceType: null,
      requesterId: null,
      notes: null,
      updateStatus: null,
      isAppointmentDateOver: false,
      noteFieldStatus: null,
      errorNotes: false,
      buttonActive: null,
      errorStatusUpdate: {
        errorUpdate: false,
        statusUpdateMessage: null,
      },
      errorScheduleView: false,
      timezone: null,
      searchOptions: [],
      selectDropdownOption: "Week",
      searchOptionsDoctorLocation: [],
      treeSearchData: [],
      defaultExpanded: [],
      checkboxSelectionDetails: [],
      locations: [],
      isLoading: false,
      dateRange: { start: new Date(), end: new Date() },
    };
    //LOCATION first view
    this.state.checkBoxList.forEach((location, id1) => {
      this.setState({
        [location.locationId]: false
      });
      location.resources.map((resource, id2) => {
        this.setState({
          [resource.unitId]: false
        });
      })
    });

    //DOCTOR first view
    this.state.checkBoxList.forEach((resource, id1) => {
      this.setState({
        [resource.resourceId]: false
      });
      resource.locations.map((location, id2) => {
        this.setState({
          [location.unitId]: false
        });
      })
    });
  }

  async componentDidMount() {
    await this.initCheckIfSingleDoc();
    await this.initDoctorCalendar();
    if (this.state.isSingleDoctorView){
      this.displaySingleDocSchedule();
    }
    this.getTreeStructure();
  }

  initCheckIfSingleDoc = async () => {
    if (toggleSettings === 'doctorFirst'){
      //doctor first view
      const respondData = await getOPDCalendarDoctorFirst(this.props.match.params.providerId);
      if(respondData.code && respondData.data){
        if(respondData.data.allResources.length === 1){
          respondData.data.allResources.forEach((resource, id) => {
            if(resource.locations.length === 1){
              this.setState({isSingleDoctorView: true})
              resource.locations.map((location, id2) => {
                this.setState({singleDoctorId: [location.unitId], singleDoctorInfo: { name: resource.resourceName, location: location.locationName }})
              })
            }
            else {
              this.setState({isSingleDoctorView: false})
            }
          })
        }
        else{
          this.setState({isSingleDoctorView: false})
        }
      }
    }
    else {
      //location first view
      const respondData = await getOPDCalendarLocationFirst(this.props.match.params.providerId);
      if(respondData.code && respondData.data){
        if(respondData.data.allLocations.length === 1){
          respondData.data.allLocations.forEach((location, id) => {
            if(location.resources.length === 1){
              this.setState({isSingleDoctorView: true})
              location.resources.map((resource, id2) => {
                this.setState({singleDoctorId: [resource.unitId], singleDoctorInfo: { name: resource.resourceName, location: location.locationName }})
              })
            }
            else {
              this.setState({isSingleDoctorView: false})
            }
          })
        }
        else{
          this.setState({isSingleDoctorView: false})
        }
      }
    }
  };

  initDoctorCalendar = async () => {
    let resource = [...this.state.searchOptionsDoctorLocation]
    if (toggleSettings === 'doctorFirst'){
      //doctor first
      const respondData = await getOPDCalendarDoctorFirst(this.props.match.params.providerId);
      if(respondData.code && respondData.data !== null){
          if(Array.isArray(respondData.data.calendarSummary) && respondData.data.calendarSummary.length > 0){
            const calendarSummary = respondData.data.calendarSummary.map((data => {
              if (!this.state.timezone){
                this.setState({ timezone: getTimeZoneString(data.start) })
              }
              return {
                    status : data.status,
                    calendarDate: dateConvert(data.start, this.state.timezone)
                }
            }));

            //to solve issue when choosing more than one doctor, calendar becomes unselectable due to backend returning busy status
            const isAllStatusBusy = calendarSummary.every(function(cal){
              return cal.status === "busy"
            })

            if (!this.state.isSingleDoctorView && isAllStatusBusy) {
              for (var i in calendarSummary) {
                calendarSummary[i].status = "free";
              }
            }

            this.setState({doctorCalendar: calendarSummary})
          }
          if(Array.isArray(respondData.data.allResources) && respondData.data.allResources.length > 0){
            respondData.data.allResources.map((doc => {
              resource.push(doc.resourceName)
              doc.locations.map((loc => {
                resource.push(loc.locationName)
              }))
            }))
            const removeDuplicate = resource.filter((item, index) => resource.indexOf(item) == index)
            this.setState({ checkBoxList: respondData.data.allResources, searchOptionsDoctorLocation: removeDuplicate })
          }
      }
    }
    // locationFirst
    else {
      //location first
      const respondData = await getOPDCalendarLocationFirst(this.props.match.params.providerId);
      if(respondData.code && respondData.data !== null){
          if(Array.isArray(respondData.data.calendarSummary) && respondData.data.calendarSummary.length > 0){
            const calendarSummary = respondData.data.calendarSummary.map((data => {
              if (!this.state.timezone){
                this.setState({timezone: getTimeZoneString(data.start)})
              }
              return {
                    status : data.status,
                    calendarDate: dateConvert(data.start, this.state.timezone)
                }
            }));

            //to solve issue when choosing more than one doctor, calendar becomes unselectable due to backend returning busy status
            const isAllStatusBusy = calendarSummary.every(function(cal){
              return cal.status === "busy"
            })

            if (!this.state.isSingleDoctorView && isAllStatusBusy) {
              for (var i in calendarSummary) {
                calendarSummary[i].status = "free";
              }
            }

            this.setState({doctorCalendar: calendarSummary})
          }
          if(Array.isArray(respondData.data.allLocations) && respondData.data.allLocations.length > 0){
            respondData.data.allLocations.map((loc => {
              resource.push(loc.locationName)
              loc.resources.map((res => {
                resource.push(res.resourceName)
              }))
            }))
            const removeDuplicate = resource.filter((item, index) => resource.indexOf(item) == index)
            this.setState({checkBoxList: respondData.data.allLocations, searchOptionsDoctorLocation: removeDuplicate})            
          }
      }
    }
  };

  //schedules for single doctor view
  displaySingleDocSchedule = () => {
    postProviderClinicSchedule(this.state.singleDoctorId).then(res =>
      //successful
      {
        if(res.code && res.data){
          if(Array.isArray(res.data) && res.data.length > 0){
            this.setState({bookedSchedule: [], searchOptions: []})
            let bookedSchedules = [...this.state.bookedSchedule];
            let searchOptions = [...this.state.searchOptions];
            res.data.forEach((d, idx) =>{
              const calendarSummary = d.calendar.map((data => {
                return {
                      status : data.dayStatus === 'busy' ? "force-booking" : data.dayStatus,
                      calendarDate: dateConvert(data.start, this.state.timezone),
                  }
              }));
              this.setState({doctorCalendar: calendarSummary})

              d.calendar.forEach((cal, id) =>{
                if(cal.dayStatus !== "closed" && cal.hasOwnProperty("schedules")){
                  //opening hours
                  cal.schedules.map((schedule, i) => {
                    const bookingObject1 = {
                      groupId: 1,
                      start: dateConvert(schedule.start, this.state.timezone),
                      end: dateConvert(schedule.end, this.state.timezone),
                      display: 'inverse-background',
                      type: 'open'
                    };
                    bookedSchedules.push(bookingObject1);

                    //fully booked slots
                    schedule.bookedSlots.map((booked, i) => {
                      const bookingObject2 = {
                        id: `${d.unitId},${booked.bookedCaseData.serviceType}`,
                        title: booked.bookedCaseData.displayName ? booked.bookedCaseData.displayName : booked.bookedCaseData.referenceId,
                        reference: booked.bookedCaseData.referenceId,
                        start: dateConvert(booked.slotStartAt, this.state.timezone),
                        end: dateConvert(booked.slotEndAt, this.state.timezone),
                        type: 'slot',
                      };
                      bookedSchedules.push(bookingObject2);
                      searchOptions.push(bookingObject2)
                    })
                  })
                }
              })
            });
            this.setState({ bookedSchedule: bookedSchedules, searchOptions: searchOptions });
          }
        }
      }
    ).catch(err =>
      //fail
      {
        console.log('something went wrong', err)
        this.setState({errorScheduleView: true})
      }
    )
  }


  //schedules of selected doctors for multi-doctor view
  displaySchedule = () => {
    postProviderClinicSchedule(this.state.checkedDoctorId).then(res =>
      //successful
      {
        if(res.code && res.data){
          if(Array.isArray(res.data) && res.data.length > 0){
            this.setState({bookedSchedule: [], searchOptions: []})
            let bookedSchedules = [...this.state.bookedSchedule];
            let checkedName = [...this.state.checkedDoctorName];
            let searchOptions = [...this.state.searchOptions];
            res.data.forEach((d, idx) =>{
              const calendarSummary = d.calendar.map((data => {
                return {
                      status : data.dayStatus === 'busy' ? "force-booking" : data.dayStatus,
                      calendarDate: dateConvert(data.start, this.state.timezone),
                  }
              }));
              this.setState({singleDoctorCalendar: calendarSummary})

              d.calendar.forEach((cal, id) =>{
                const nameIndex = checkedName.findIndex(n => n.id === d.unitId);


                if(cal.dayStatus !== "closed" && cal.hasOwnProperty("schedules")){
                  //opening hours
                  cal.schedules.map((schedule, i) => {
                    const bookingObject1 = {
                      groupId: 1,
                      resourceId: `${checkedName[nameIndex].name.replace(/\s+/g, '')}-${d.unitId}`,
                      start: dateConvert(schedule.start, this.state.timezone),
                      end: dateConvert(schedule.end, this.state.timezone),
                      display: 'inverse-background',
                      type: 'open'
                    };
                    bookedSchedules.push(bookingObject1);

                    //fully booked slots
                    schedule.bookedSlots.map((booked, i) => {
                      const bookingObject2 = {
                        id: `${d.unitId},${booked.bookedCaseData.serviceType}`,
                        title: booked.bookedCaseData.displayName ? booked.bookedCaseData.displayName : booked.bookedCaseData.referenceId,
                        reference: booked.bookedCaseData.referenceId,
                        resourceId: `${checkedName[nameIndex].name.replace(/\s+/g, '')}-${d.unitId}`,
                        start: dateConvert(booked.slotStartAt, this.state.timezone),
                        end: dateConvert(booked.slotEndAt, this.state.timezone),
                        type: 'slot',
                      };
                      bookedSchedules.push(bookingObject2);
                      searchOptions.push(bookingObject2);
                    })
                  })
                }
              })
            });
            this.setState({bookedSchedule: bookedSchedules, searchOptions: searchOptions});
          }
        }
      }
    ).catch(err =>
      //fail
      {
        console.log('something went wrong', err)
        this.setState({errorScheduleView: true})
      }
    )
  }

  //dialog
  handleDialogOnClose = (value, propertyName) => {
    this.setState({[propertyName]: value, notes: null, errorNotes: false, noteFieldStatus: null, buttonActive: null});
  };


  onEventSelect = async (selectionInfo) => {
    if(selectionInfo.event.extendedProps.type !== "open"){
      this.setState({ 
        isLoading: false,
        openConfirmedDialog: true, 
        unitId: selectionInfo.event.id.split(",")[0], 
        serviceType: selectionInfo.event.id.split(",")[1], 
        requesterId: selectionInfo.event.extendedProps.reference, 
        confirmedApptDates: { startDate: selectionInfo.event.start, endDate: selectionInfo.event.end },
        isAppointmentDateOver: new Date(selectionInfo.event.start) > new Date(new Date().toLocaleString('en-US', {timeZone: tzValue(this.state.timezone)})) ? false : true
      });

      getCaseDetails(this.state.serviceType, this.state.requesterId).then(res =>
        //successful
        {
          if(res.code && res.data){
            if(Array.isArray(res.data.data.items)){
              res.data.data.items.map((item => {
                this.setState({ 
                  specialistName: item.specialist.displayName,
                  patientInfo: { patientName: item.patient.name, documentLinks: item.patient.documentLinks, checkDocumentsLength: item.patient.documentLinks.length }
                })
              }))
            }
          }
        }
      ).catch(err =>
        //fail
        console.log('something went wrong', err)
      )
    }
  };

  onCalendarSelect = (value) =>{
    if ((this.state.checkedDoctorId.length !== 0 && !this.state.isSingleDoctorView) || this.state.isSingleDoctorView){
      const calendarRef = this.calendarComponentRef.current.getApi();
      calendarRef.gotoDate(new Date(value));
      this.setState({ selectedDate: new Date(value), dateRange: { start: calendarRef.view.activeStart, end: calendarRef.view.activeEnd - 1} });
    }
  };

  //legend popup
  handleLegend = (event, mode) => {
    this.setState({ anchorEl: mode === 'click' ? event.currentTarget : null })
  }

  calendarChangeView = (checked) => {
      if (checked.length === 2){
        this.setState({defaultView: "resourceTimeGridDays", selectDropdownOption: "Week"})
      }
      else if (checked.length > 2){
        this.setState({defaultView: "resourceTimeGridDay", selectDropdownOption: "Day"})
      }
      else {
        this.setState({defaultView: "timeGridWeek", selectDropdownOption: "Week"})
      }

      setTimeout(() => {
        let calendarApi = this.calendarComponentRef.current.getApi(); 
        calendarApi.changeView(this.state.defaultView);
        this.setState({ dateRange: { start: calendarApi.view.activeStart, end: calendarApi.view.activeEnd - 1} })
        this.displaySchedule();
      })
  }

  //location checkbox for DOCTOR first
  handleLocationChange = (event, unitId, resourceId, resourceName, locationName) => {
    let checked = [...this.state.checkedDoctorId]
    let checkedName = [...this.state.checkedDoctorName]
    let resource = [...this.state.resources]

    const index = checked.indexOf(unitId);
    const nameIndex = checkedName.findIndex(n => n.id === unitId);
    const resIndex = resource.findIndex(r => r.id === `${resourceName.replace(/\s+/g, '')}-${unitId}`);

    if (index === -1) {
      checked.push(unitId);
    } else {
      checked.splice(index, 1);
    }
    this.setState({ checkedDoctorId: checked });

    // store selected checkbox details
    const filtered1 = this.state.checkBoxList.map((data) => {
      // clone object
      const newData = { ...data };
      // filter the locations, return only the locations that match the search query
      newData.locations = newData.locations.filter((location) =>
        checked.includes(location.unitId)
      );
      return newData;
    });

    const filtered2 = filtered1.filter((data) => data.locations.length !== 0);

    this.setState({ checkboxSelectionDetails: filtered2 });

    if (event){
      checkedName.push({id: unitId, name: resourceName})
      resource.push({id: `${resourceName.replace(/\s+/g, '')}-${unitId}`, title: `${resourceName} \n ${locationName}`, order: 1})
      this.setState({checkedDoctorName: checkedName, [unitId]: true, resources: resource});
    }
    else {
      checkedName.splice(nameIndex, 1);
      resource.splice(resIndex, 1)
      this.setState({checkedDoctorName: checkedName, [unitId]: false, resources: resource});
    }

    if(checked.length !== 0){
      this.calendarChangeView(checked)
    }
  };

  //attended (5) / no show (4) / cancel (2)
  inputStateUpdate = (status) => {
    this.setState({
      noteFieldStatus: status === apptCancel ? 'mandatory' : 'optional', 
      updateStatus: status, 
      notes: null, 
      errorNotes: false,
      buttonActive: status === apptCancel ? 'cancel' : status === apptNoShow ? 'noShow' : 'attended'
    })    
  }

  //attended (5) / no show (4) / cancel (2)
  appointmentUpdate = () => {

    if (this.state.updateStatus === apptCancel){
      if (!this.state.notes){
        this.setState({ errorNotes: true })
        return;
      }
    }

    this.setState({ isLoading: true })

    const update = {
      unitId: Number(this.state.unitId),
      requesterId: this.state.requesterId,
      status: this.state.updateStatus,
      notes: this.state.notes
    }

    updateAppointmentStatus(update).then(res =>
      //successful
      {
        console.log('successful')
        this.setState({ errorStatusUpdate: { errorUpdate: false, statusUpdateMessage: 'Successfully updated appointment status.' } },
          ()=>{
            setTimeout(() => {
                this.setState({ errorStatusUpdate: { errorUpdate: false, statusUpdateMessage: null } })
            }, 5000);
          })
      }
    ).catch(err =>
      //fail
      {
        console.log('something went wrong', err)
        this.setState({ errorStatusUpdate: { errorUpdate: true, statusUpdateMessage: 'Failed to update appointment status.'} },
          ()=>{
            setTimeout(() => {
                this.setState({ errorStatusUpdate: { errorUpdate: false, statusUpdateMessage: null } })
            }, 5000);
          })
      }
    )

      this.setState({ openConfirmedDialog: false, notes: null, errorNotes: false, noteFieldStatus: null, buttonActive: null, isLoading: false });
      
      if(this.state.isSingleDoctorView){
        this.displaySingleDocSchedule();
      }
      else{
        this.displaySchedule();
      }
  }

  //notes field for appointment status update
  handleTextChange = (event) => {
    this.setState({ notes: event.target.value, errorNotes: false })
  }

  onCalendarArrowChange = (value, func) => {
    const calendarRef = this.calendarComponentRef.current.getApi();
    if(func === 'prev'){
      calendarRef.prev();
      if (this.state.defaultView === 'timeGridDay' || this.state.defaultView === 'resourceTimeGridDay') {
        this.setState({ selectedDate: calendarRef.getDate() })
      }
    }
    else if (func === 'next'){
      calendarRef.next();
      if (this.state.defaultView === 'timeGridDay' || this.state.defaultView === 'resourceTimeGridDay') {
        this.setState({ selectedDate: calendarRef.getDate() })
      }
    }
    else if (func === 'goback'){
      let current = new Date(value);
      calendarRef.gotoDate(current.setDate(current.getDate() - 1));
      this.setState({selectedDate: current});
    }
    else if (func === 'goto'){
      let current = new Date(value);
      calendarRef.gotoDate(current.setDate(current.getDate() + 1));
      this.setState({selectedDate: current});
    }
    else if (func === 'today'){
      calendarRef.today();
      this.setState({selectedDate: new Date()});
    }
    this.setState({ dateRange: { start: calendarRef.view.activeStart, end: calendarRef.view.activeEnd - 1} });
  };

  handleOptionClick = (option) => {

    if(this.state.resources.length === 1){
      this.setState({defaultView: option === "Week" ? "timeGridWeek" : "timeGridDay", selectDropdownOption: option})
    }
    else {
      this.setState({defaultView: option === "Week" ? "resourceTimeGridDays" : "resourceTimeGridDay", selectDropdownOption: option})
    }

    setTimeout(() => {
      let calendarApi = this.calendarComponentRef.current.getApi(); 
      calendarApi.changeView(this.state.defaultView);
      calendarApi.gotoDate(new Date(this.state.selectedDate));
      this.setState({ dateRange: { start: calendarApi.view.activeStart, end: calendarApi.view.activeEnd - 1} });
    })
  }

  getTreeStructure = () => {
    this.setState({treeSearchData: this.transformDataToTree(this.state.checkBoxList.slice(0, sliceValue))})
  }

  transformDataToTree = (data) => {
    return data.map((root) => {
      return {
        id: `${root.resourceId}`,
        name: root.resourceName,
        children: root.locations.map((location) => {    
          return {
            id: `${location.unitId}`,
            name: location.locationName,
            unitId: location.unitId,
            resourceName: root.resourceName,
            resourceId: root.resourceId
          };
        })
      };
    });
  };
  
  //for auto complete onChange
  filterValue = (value) => {
    this.setState({defaultExpanded: []})
    if(value){
      const filtered1 = this.state.checkBoxList.map((data) => {
        // if search phrase contains parent, return entire parent node
        if (data.resourceName.toLowerCase().includes(value.toLowerCase())) {
          return data;
        }
        // else filter the locations, return only the locations that match the search query
        else {
          // clone object
          const newData = { ...data };
          // filter the locations
          newData.locations = newData.locations.filter((location) =>
            location.locationName.toLowerCase().includes(value.toLowerCase())
          );
          return newData;
        }
      });
  
      // remove root nodes without any locations
      const filtered2 = filtered1.filter((data) => data.locations.length !== 0);

      const expanded = filtered2.map((data) => `${data.resourceId}`);
  
      this.setState({ treeSearchData: this.transformDataToTree(filtered2), defaultExpanded: expanded });

      filtered2.length === 1 && filtered2.map((resource) => {
        resource.locations.length === 1 && resource.locations.map((location, i) => {
          !this.state[location.unitId] && this.handleLocationChange(true, location.unitId, resource.resourceId, resource.resourceName, location.locationName)
        })
      });
    }
  }

  setTreeData = () => {
    this.setState({treeSearchData: this.transformDataToTree(this.state.checkBoxList.slice(0, sliceValue))})
  }

  setDefaultExpanded = (expanded) => {
    this.setState({ defaultExpanded: expanded });
  }

  handleCheckChange = (check) => (
    this.state[check] ? true : false
  )

  render() {
    return (
      <React.Fragment>
        <ReactHelmet
          title="Clinic Schedule"
        />
        <Grid container direction="row" justifyContent="flex-start" spacing={3} className={this.props.classes.calenderContainer}>
          <Grid item xs={12} style={{height: 180}}>
            <Link component="button" onClick={() => window.location="/"}>
              <Typography variant="h6" style={{marginTop: 40}} >
                <ArrowBackIosIcon style={{fontSize: '1rem'}}/> Back
              </Typography>
            </Link>
          </Grid>
           {/*Calender Component**/}
           <Grid item xs={12} md={3}>
             <Grid container direction="column" justifyContent="space-evenly" spacing={4} wrap="wrap">
                 <Grid item xs={12} style={{justifyContent: 'center', display: 'grid'}}>
                   <BigCalendar
                       class={"calSmall"}
                       onChange={this.onCalendarSelect}
                       selectedDate={this.state.selectedDate}
                       calendarSummary={this.state.checkedDoctorId.length === 1 ? this.state.singleDoctorCalendar : this.state.doctorCalendar}
                   />
                 </Grid>
                 { 
                   this.state.checkedDoctorId.length === 1 || this.state.isSingleDoctorView ?
                   <Grid item xs={12} style={{justifyContent: 'center', display: 'grid'}}>
                    <List className={this.props.classes.list} dense={true}>
                      <ListItem style={{paddingLeft: 30, paddingRight: 0, marginBottom: 10}}>
                        <ListItemAvatar classes={{root: this.props.classes.avatarList}}>
                          <Avatar variant="square" className={this.props.classes.avatarFull}> </Avatar>
                        </ListItemAvatar>
                        <ListItemText style={{width: '150px', marginRight: '150px'}}
                            primary="Full"
                        />
                      </ListItem>
                    </List>
                  </Grid> : null
                 }
                 {
                   !this.state.isSingleDoctorView ? <Divider/> : 
                        <Grid item xs={12} style={{justifyContent: 'left', display: 'grid'}}>
                          <Grid container direction="row" spacing={3}>
                            <Grid item xs={12}>
                              <Typography id="latobold">
                                Showing Calendar for
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography id="font15">
                                {this.state.singleDoctorInfo.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography id="font15">
                              {this.state.singleDoctorInfo.location}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                 }
                  <Grid item xs={12} style={{justifyContent: 'center', display: this.state.isSingleDoctorView ? 'none' : 'grid'}}>
                    <Paper style={{padding: 15, borderRadius: borderRadius, width: '300px', display: 'table'}}>
                      <Grid container direction="row" spacing={3}>
                        <Grid item xs={12}>
                            <Typography style={{marginBottom: -15}}>
                              Search for Doctor(s) / Location(s) to view schedule(s)
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <DoctorSearch
                              transformDataToTree={this.setTreeData}
                              filter={this.filterValue}
                              searchOptionsDoctorLocation={this.state.searchOptionsDoctorLocation}
                            />
                        </Grid>
                        <Grid item xs={12}>
                          <TreeSearch
                            defaultExpanded={this.state.defaultExpanded}
                            setDefaultExpanded={this.setDefaultExpanded}
                            treeSearchData={this.state.treeSearchData}
                            handleLocationChange={this.handleLocationChange}
                            checked={this.handleCheckChange}
                          />
                        </Grid>
                        <Grid item xs={12} style={{justifyContent: 'left', display: 'grid'}}>
                          <Grid container direction="row" spacing={3}>
                            {
                              this.state.checkboxSelectionDetails.length > 0 ? 
                              <Grid item xs={12}>
                                <Typography id="latobold">
                                  Showing Calendar for
                                </Typography>
                              </Grid> : null
                            }
                            {
                            this.state.checkboxSelectionDetails.map((selection, id) => (
                                <React.Fragment key={id}>
                                  <Grid item xs={5}>
                                    <Typography id="font15">
                                      {selection.resourceName}
                                    </Typography>
                                  </Grid>
                                  {
                                    selection.locations.map((l, id2) => (
                                      <React.Fragment key={id2}>
                                        {id2 > 0 ? <Grid item xs={5} /> : null}
                                        <Grid item container direction="row" xs={7}>
                                          <Grid item xs={10}>
                                            <Typography id="font15">
                                            {l.locationName}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={2}>
                                            <IconButton size="small" onClick={(e) => this.handleLocationChange(e.target.checked, l.unitId, selection.resourceId, selection.resourceName, l.locationName)}>
                                              <CancelIcon fontSize="small" style={{ color: cancelColor }}/>
                                            </IconButton>
                                          </Grid>
                                        </Grid>
                                      </React.Fragment>
                                    ))
                                  }
                                </React.Fragment>
                            ))
                            }
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
           </Grid>
           {/* End Calender Component**/}
          <Grid item xs={12} md={9}>
            {
              this.state.errorScheduleView && <Alert style={{marginBottom: 8}} className={this.props.classes.mobileViewAlertControl} severity="error">Failed to display schedule.</Alert>
            }
            {
              this.state.errorStatusUpdate.statusUpdateMessage && <Alert style={{marginBottom: 8}} className={this.props.classes.mobileViewAlertControl} severity={this.state.errorStatusUpdate.errorUpdate ? "error" : "success"}>{this.state.errorStatusUpdate.statusUpdateMessage}</Alert>
            }
            {
              this.state.checkedDoctorId.length === 0 && !this.state.isSingleDoctorView ?
                <FullCalendarGrid styling={{height: '100%'}}>
                  <Grid container item xs={12} style={{justifyContent: 'center'}}>
                    <Typography variant="h6">Please select the doctor(s) to view schedule(s)</Typography>
                  </Grid>
                </FullCalendarGrid> : 
                
                <FullCalendarGrid>
                  <Grid container item xs={12}>
                    <Grid item xs={6}>
                      <Typography variant="h4" color="primary" >
                        <InfoIcon color="disabled" fontSize="large" onClick={(event) => this.handleLegend(event, 'click')}/>
                      </Typography>  

                      <Popover
                        open={Boolean(this.state.anchorEl)}
                        onClose={(event) => this.handleLegend(event, 'close')}
                        anchorEl={this.state.anchorEl}
                        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        elevation={0}
                      >
                        <div style={{padding: '15px 15px 15px 15px', borderRadius: borderRadius, border: '1px solid grey'}}>
                          <Typography>Legend</Typography>

                          <List className={this.props.classes.list} dense={true}>
                            <ListItem className={this.props.classes.listItem}>
                              <ListItemAvatar classes={{root: this.props.classes.avatarList}}>
                                <Avatar variant="square" className={this.props.classes.avatarAvailable}> </Avatar>
                              </ListItemAvatar>
                              <ListItemText style={{width: '150px'}}
                                  primary="Available"
                              />
                            </ListItem>
                            <ListItem className={this.props.classes.listItem}>
                              <ListItemAvatar classes={{root: this.props.classes.avatarList}}>
                                <Avatar variant="square" className={this.props.classes.avatarConfirmed}> </Avatar>
                              </ListItemAvatar>
                              <ListItemText style={{width: '150px'}}
                                  primary="Confirmed"
                              />
                            </ListItem>
                            <ListItem className={this.props.classes.listItem}>
                              <ListItemAvatar classes={{root: this.props.classes.avatarList}}>
                                <Avatar variant="square" className={this.props.classes.avatarPatientChoice}> </Avatar>
                              </ListItemAvatar>
                              <ListItemText style={{width: '150px'}}
                                  primary="Patient's Choice"
                              />
                            </ListItem>
                          </List>
                        </div>   
                      </Popover> 
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="h6" color="inherit" align={"right"}>
                        {this.state.defaultView !== "resourceTimeGridDay" && this.state.defaultView !== "timeGridDay" 
                        ? `${new Date(this.state.dateRange.start).toLocaleDateString('en-GB', { month: 'short', day: 'numeric' })} - ${convertAppointmentDate(this.state.dateRange.end)}`.toUpperCase()
                        : convertAppointmentDate(this.state.selectedDate)}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid item xs={12} className={this.props.classes.mobileViewCalendarHeaderControl}>
                          <AutoComplete 
                            optionList={this.state.searchOptions}
                            onCalendarSelect={this.onCalendarSelect}
                          />
                      </Grid>
                      <FullCalendarHeader
                        selectedDate={this.state.selectedDate}
                        onCalendarArrowChange={this.onCalendarArrowChange}
                        selectDropdownOption={this.state.selectDropdownOption}
                        handleOptionClick={this.handleOptionClick}
                      />
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12} style={{paddingTop: 25, maxHeight: 700}}>
                      <FullCalendarComponent
                        defaultView={this.state.defaultView}
                        calendarComponentRef={this.calendarComponentRef}
                        bookedSchedule={this.state.bookedSchedule}
                        onEventSelect={this.onEventSelect}
                        resourceTimeDays={resourceTimeDays}
                        selectedDate={this.state.selectedDate}
                        dayMinWidth={this.state.resources.length > 2 && this.state.defaultView === 'resourceTimeGridDays' ? 110 : this.state.resources.length > 3 ? 220 : 50}
                        resourceOrder={'order'}
                        resources={this.state.resources}
                      />
                    </Grid>
                  </Grid>
                </FullCalendarGrid>
            }
          </Grid>
          <Grid item xs={12}>
            {/* confirmed slot dialog / attended & no show, cancel & reschedule*/}
            <ViewAppointmentDialog
              openDialog={this.state.openConfirmedDialog}
              handleOnClose={this.handleDialogOnClose}
              patientInfo={this.state.patientInfo}
              confirmedApptDates={this.state.confirmedApptDates}
              name={this.state.specialistName}
              buttonOptions={[ this.state.isAppointmentDateOver, this.state.buttonActive ]}
              inputNotes={[ this.state.noteFieldStatus, this.state.notes, this.state.errorNotes ]}
              rescheduleURL={[ this.state.unitId, this.state.requesterId, this.state.serviceType ]}
              updates={[ this.inputStateUpdate, this.appointmentUpdate ]}
              handleTextChange={this.handleTextChange}
              loading={this.state.isLoading}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
export default withStyles(useStyles)(ClinicSchedule);
