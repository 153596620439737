import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { PhotoCamera } from '@material-ui/icons';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  text: {
    lineHeight: '30px',
  },
  intro: {
    marginBottom: '15px',
  },
}));
const OAFLabelUpdateDocument = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.text}>
        Attach copy of medical report(s) and/or referral letter (if available).
      </div>
      <div className={classes.text}> * Required for EMPLOYEE BENEFITS PLANS</div>
      <div className={clsx(classes.text, classes.intro)}>
        <span>Click "+" to attach </span>
        {isMobile && (
          <span>
            or <PhotoCamera style={{ verticalAlign: 'middle' }}></PhotoCamera>
          </span>
        )}{' '}
        <span>
          to take a photo of the reports. You can upload as many documents as you wish, up to a
          total file size of 10MB.
        </span>
      </div>
    </>
  );
};
export default OAFLabelUpdateDocument;
